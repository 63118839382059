import React from 'react'

const StatusIcon = ({status}) => {

  return (
    <div className='status-icon'>
        {status ? <i className="ri-check-line"></i> : <i className="ri-close-line"></i>}
    </div>
  )
}

export default StatusIcon