import React, { useState } from 'react'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import { useBranches } from '../../hooks/useQueryHelpers'
import toastAlert from '../../helpers/toastAlert'
import { attachBranches } from '../../apis/Settings/branchesApi'
import CustomLabel from '../Custom/CustomLabel'
import Select from 'react-select'
import CustomButton from '../Custom/CustomButton'
import { Row, Col } from 'reactstrap'

const types = {
    attachCats: 'Categories',
    attachFoods: 'Foods',
}

const AttachBranchesModal = ({ isOpen, toggle, route, selectedItems }) => {

    const { branches } = useBranches();

    const [selectedBranches, setSelectedBranches] = useState([]);

    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleSubmit = async () => {
        try {
            setIsSubmitting(true);
            await attachBranches(route, {
                ids: selectedItems,
                branches: selectedBranches
            })
            toggle();
            toastAlert({ type: 'success' })
        } catch(error) {
            toastAlert({ type: 'error' })
        } finally {
            setIsSubmitting(false);
        }
    }

    const onClosed = () => {
        setSelectedBranches([]);
    }

  return (
    <Modal
        isOpen={isOpen}
        toggle={toggle}
        onClosed={onClosed}
        centered
    >
        <ModalHeader>
            Attach Branches to {types[route]}
        </ModalHeader>
        <ModalBody>
            <Row className='gy-3'>

                <Col lg={12}>
                    <CustomLabel>Branches</CustomLabel>
                    <Select 
                        value={branches.filter(branch => selectedBranches?.includes(branch.id)).map(branch => ({
                            label: branch.name,
                            value: branch.id
                        }))}
                        options={branches.map(branch => ({
                            label: branch.name,
                            value: branch.id
                        }))}
                        onChange={(values) => setSelectedBranches(values.map(value => value.value))}
                        isMulti
                    />
                </Col>

                <Col className="d-flex gap-3">
                        <CustomButton
                            className="btn btn-success"
                            onClick={handleSubmit}
                            isLoading={isSubmitting}
                        >
                            Save
                        </CustomButton>

                        <CustomButton 
                            className="btn-danger"
                            onClick={toggle}
                        >
                            cancel
                        </CustomButton>
                </Col>

            </Row>
        </ModalBody>
    </Modal>
  )
}

export default AttachBranchesModal