import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { Card, Col, Row } from "reactstrap";
import CustomButton from "../../Custom/CustomButton";
import CustomInput from "../../Custom/CustomInput";
import CustomLabel from "../../Custom/CustomLabel";
import { inventoryApi } from "../../../apis/Inventory/api";
import toastAlert from "../../../helpers/toastAlert";
import { objectFilter } from "../../../helpers/objectFilter";
import { useQuery } from "@tanstack/react-query";
import SearchProduct from "../../Common/SearchProduct";
import { getAllBranchesApi } from "../../../apis/Settings/branchesApi";
import OrderProductsTable from "./OrderProductsTable";
import { getPathname } from "../../../Layouts/LayoutMenuData";
import { printPdf } from "../../../helpers/pdfHelper";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";

const InventoryOrderForm = ({ type, initialValues }) => {

  const { user } = useSelector(state => state.admin);

  if (type === 'create' && user?.role?.[0] === 'warehouse') return <Redirect to={getPathname('inventory/orders')} />

  const changingOrder = type === 'create' || (type === 'update' && ['super-admin', 'admin', 'staff'].includes(user?.role?.[0]) && initialValues?.status === 'new');

  const confirmingOrder = type === 'update' && user?.role?.[0] === 'warehouse' && initialValues?.status === 'new';

  const confirmingRecieving = type === 'update' && ['super-admin', 'admin', 'staff'].includes(user?.role?.[0]) && initialValues?.status === 'sent' ;

  const { register, setValue, watch, getValues } = useForm();

  const [submitting, setSubmitting] = useState(false);

  const [exportingPdf, setExportingPdf] = useState(false);

  const selectedBranch = watch('branche_id');

  const [selectedProduct, setSelectedProduct] = useState(null);

  const [productUnit, setProductUnit] = useState('');

  const [products, setProducts] = useState(type === 'update' ? initialValues?.items.map(item => ({
    ...item.product,
    qty: item.qty,
    new_qty: (changingOrder || confirmingOrder) ? item.qty : confirmingRecieving ? item.qty_confirmed : '',
    qty_confirmed: item.qty_confirmed,
    qty_received: item.qty_received,
  })) : [])

  const history = useHistory();

  const { data: branchesData } = useQuery(['branches'], () => getAllBranchesApi({ page_size: 'all' }))

  const branches = branchesData?.data?.data;

  const exportPdf = async () => {
    try {
        setExportingPdf(true);
        const { data: htmlData } = await inventoryApi.orders.exportPdf(initialValues.id);
        printPdf(htmlData);
    } catch (error) {
        toastAlert({ type: 'error' })
    } finally {
        setExportingPdf(false);
    }
  }

  const handleSubmit = async () => {
    if (type === 'create') {
        if (branches?.length && !getValues('branche_id')) toastAlert({ type: 'error', message: 'Please select a branch' })
            try {
                setSubmitting(true);
                const requestBody = objectFilter({
                    branche_id: getValues('branche_id'),
                    items: products.map(product => ({
                        product_id: product.id,
                        qty: product.new_qty
                    }))
                })
                await inventoryApi.orders.create(requestBody);
                history.push(getPathname('inventory/orders'))
                toastAlert({ type: 'success' })
            } catch (error) {
                toastAlert({ type: 'error' })
            } finally {
                setSubmitting(false);
            }
    } else if (type === 'update') {
        try {
            setSubmitting(true);
            const requestBody = objectFilter({
                branche_id: initialValues?.branche_id,
                items: products.map(product => ({
                    product_id: product.id,
                    [`qty${confirmingOrder ? '_confirmed' : confirmingRecieving ? '_received': ''}`]: product.new_qty,
                }))
            })
            await inventoryApi.orders[confirmingOrder ? 'confirm' : confirmingRecieving ? 'confirmRecieving' : 'update'](initialValues?.id, requestBody);
            if (confirmingOrder || confirmingRecieving) history.go(0);
            toastAlert({ type: 'success' })
        } catch (error) {
            toastAlert({ type: 'error' })
        } finally {
            setSubmitting(false);
        }
    }
  }

  const addProduct = () => {
    if (products.find(prod => prod.id === selectedProduct.id)) {
        setProducts(prev => prev.map(prod => {
            if (prod.id === selectedProduct.id) return {
                ...prod,
                new_qty: prod.qty + +getValues('new_qty')
            }
            return prod
        }));
    } else {
        setProducts(prev => [...prev, {
            ...selectedProduct,
            new_qty: getValues('new_qty'),
        }]);
    }
    setSelectedProduct(null);
    setValue('new_qty', '');
    setProductUnit('');
  }

  const populateProducts = async (branchId) => {
    try {
        const { data: { data }} = await inventoryApi.orders.getDailyProducts(objectFilter({
            branche_id: branchId
        }))
        setProducts(data.map(product => ({
            ...product,
            new_qty: product.default_daily_qty
        })));
    } catch (error) {
        console.log('error', error);
    }
  }

  useEffect(() => {
    if (type === 'create' && Array.isArray(branches)) {
        if (branches.length) {
            setValue('branche_id', branches[0].id);
        } else {
            populateProducts();
        }
    }
  }, [branches])

  useEffect(() => {
    if (type ==='create' && selectedBranch) {
        populateProducts(selectedBranch);
    }
  }, [type, selectedBranch])

  return (
    <Row>
        <Col lg={12}>

            <Card className="p-3">
                <div className="d-flex align-items-center justify-content-between">
                    {
                        type === 'create' ?
                        <Col lg={3}>
                            <div>
                                <CustomLabel>Branch</CustomLabel>
                                <select
                                    className="form-select"
                                    {...register('branche_id')}
                                >
                                    {branches?.map(branch => (
                                        <option key={branch.id} value={branch.id}>{branch.name}</option>
                                    ))}
                                </select>
                            </div>
                        </Col>
                        : 
                        <h4 className="fs-18 mb-0">{initialValues?.branch?.name}</h4>
                    }

                    {type === 'update' &&
                        <CustomButton
                            className="btn btn-success"
                            type="button"
                            onClick={exportPdf}
                            isLoading={exportingPdf}
                        >
                            Export
                        </CustomButton>
                    }
                </div>
            </Card>

            {(changingOrder || confirmingOrder) && 
                <Card className="p-3">
                    <Row>
                        <Col lg={6}>
                            <SearchProduct 
                                onChange={(product) => {
                                    setSelectedProduct(product);
                                    setProductUnit(product.unit?.name)
                                    setValue('new_qty', product.default_daily_qty)
                                }}
                                value={selectedProduct && {
                                    value: selectedProduct.id,
                                    label: selectedProduct.name
                                }}
                                menuPortalTarget={document.body}
                            />
                        </Col>

                        <Col lg={3} className="d-flex align-items-center gap-3">
                            <CustomInput 
                                type="number"
                                step="any"
                                placeholder="Qty"
                                {...register("new_qty")}
                            />
                            <span>{productUnit}</span>
                        </Col>

                        <Col lg={3} className="d-flex justify-content-end">
                            <CustomButton
                                className="btn-success"
                                type="button"
                                onClick={addProduct}
                            >
                                Add Product
                            </CustomButton>   
                        </Col>

                    </Row>
                </Card>
            }

            <Card>
                <OrderProductsTable 
                    status={initialValues?.status}
                    changingOrder={changingOrder}
                    confirmingRecieving={confirmingRecieving}
                    confirmingOrder={confirmingOrder}
                    products={products}
                    setProducts={setProducts}
                />
            </Card>

            <div className="d-flex justify-content-end gap-3">
                
                {(changingOrder || confirmingOrder) && 
                    <CustomButton
                        className="btn btn-success"
                        onClick={handleSubmit}
                        type="button"
                        isLoading={submitting}
                    >
                        {type === 'create' ? 'Place Order' : changingOrder ? 'Save' : confirmingOrder ? 'Confirm Order' : ''}
                    </CustomButton>
                }

                {confirmingRecieving && 
                    <CustomButton
                        className="btn btn-primary"
                        onClick={handleSubmit}
                        type="button"
                        isLoading={submitting}
                    >
                        Confirm Recieving
                    </CustomButton>
                }

            </div>
        </Col>
    </Row>
  );
};

export default InventoryOrderForm;
