import * as yup from "yup";

const menuPageSchema = yup.object().shape({
  name: yup.string().required("Required"),
  type: yup.string().required("Required"),
});

export const menuPageUpdateSchema = yup.object().shape({
  name: yup.string().required("Required"),
});

export default menuPageSchema;

