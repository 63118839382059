import React, { useState } from 'react'
import { useQueries } from '@tanstack/react-query';
import { Card, CardBody, CardHeader } from 'reactstrap';
import PageContainer from '../../Components/Layout/PageContainer';
import { getDeliveryAgents, getDeliverySettingsApi } from '../../apis/Orders/deliverySettingsApis';
import TrackDeliveryAgentsMap from '../../Components/Orders/TrackDeliveryAgents/Map';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { getPathname } from '../../Layouts/LayoutMenuData';
import Text from '../../Components/Custom/Text';

const TrackDeliveryAgents = () => {

    const [deliverySettings, setDeliverySettings] = useState(null);

    const [deliveryAgents, setDeliveryAgents] = useState(null);
    
    const [{isLoading, isRefetching}, {isLoading: isLoading2, isRefetching: isRefetching2}] = useQueries({
        queries: [
        {
            queryKey:['delivery-settings'],
            queryFn: getDeliverySettingsApi,
            onSuccess({ data: { data } }) {
                setDeliverySettings(data);
            }
        },
        {
            queryKey: ['delivery-agents'],
            queryFn: getDeliveryAgents,
            onSuccess({data: {data}}) {
                setDeliveryAgents(data)
            }
        },
        ],
    });

  return (
    <PageContainer isLoading={isLoading || isRefetching || isLoading2 || isRefetching2}>
        {!!deliverySettings &&
            <Card className='my-4'>

                <CardHeader className='text-center'>
                  <h2><Text>Tracking Delivery Agents</Text></h2>
                </CardHeader>

                <CardBody className='text-center'>
                    <div className='mb-3'>
                        <h5><Text>Currently Delivering</Text></h5>
                            {deliveryAgents?.length > 0 ? 
                                <div className='d-flex justify-content-center flex-wrap gap-3'>
                                    {deliveryAgents.map(agent => (
                                    <div key={agent.id} className="p-3 border border-dashed">
                                            <h4 className="text-success fs-18">{agent.name}</h4>
                                            <div className="d-flex gap-2 align-items-center">
                                                <span className="fs-16">Orders:</span>
                                                <div className="d-flex gap-3">
                                                    {agent.orders.map(order => (
                                                        <Link className="fs-14 text-primary" key={order} to={getPathname(`orders/update/${order}`)} target="_blank">
                                                            {order}
                                                        </Link>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            : 
                            <span className='text-danger fs-16'><Text>No agents are delivering at the moment</Text></span>
                        }
                    </div>
                    <TrackDeliveryAgentsMap 
                        shopLocation={deliverySettings.shop_location}
                        apiKey={deliverySettings.google_api_key}
                    /> 
                </CardBody>
            </Card>
        }
    </PageContainer>
  )
}

export default TrackDeliveryAgents