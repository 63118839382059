import React, { useRef, useState } from 'react'
import Select from 'react-select'
import { searchUsers } from '../../../apis/users/usersApi';
import { Controller } from 'react-hook-form';

const SearchUsers = ({ control }) => {

    const [isLoading, setIsLoading] = useState(false);

    const timeout = useRef(null);

    const [users, setUsers] = useState([]);

    const search = async (query) => {
        if (!query) return;
        setIsLoading(true);
        try {
            const { data: { data } } = await searchUsers({
                query
            })
            setUsers(data);
        } catch (error) {
            console.log('error', error);
        } finally {
            setIsLoading(false);
        }
    }

    const debounceSearch = (query) => {
        clearTimeout(timeout.current);
        timeout.current = setTimeout(() => search(query), 500);
    }

  return (
    <Controller 
        name='user_id'
        control={control}
        render={({ field: { onChange, value }}) => (
            <Select 
                value={value}
                options={users.map(user => ({
                    label: user.name,
                    value: user.id
                }))}
                onInputChange={debounceSearch}
                onChange={(values) => onChange(values)}
                isMulti
                menuPortalTarget={document.body}
                isLoading={isLoading}
            />
        )}
    />
  )
}

export default SearchUsers