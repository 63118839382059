import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { Col, Row } from 'reactstrap'
import CustomLabel from '../../Custom/CustomLabel'
import Flatpickr from 'react-flatpickr';
import moment from 'moment'
import CustomInput from '../../Custom/CustomInput'
import useActions from '../../../hooks/useActions'
import { formatExample } from '../../../helpers/formatExample'
import { reportsApi } from '../../../apis/Reports/api'
import toastAlert from '../../../helpers/toastAlert'
import { useQuery } from '@tanstack/react-query'
import SetupActions from '../SetupActions'
import { yupResolver } from '@hookform/resolvers/yup'
import { reportSettingsSchema } from '../../../schemas/reportSchema';
import { errorMessage } from '../../../helpers/errorMessage';
import ReportSettingsEmails from '../../Reports/Settings/Emails';

const ReportSettingsSetup = ({ id, next, skip }) => {

    const { register, handleSubmit, control, reset, formState: { errors }, setValue } = useForm({
        resolver: yupResolver(reportSettingsSchema)
    });

    const {isLoading: isSubmitting, onLoading, onFinally, onError} = useActions();

    const [initialValues, setInitialValues] = useState(null);

    useQuery(['reports-settings'], () => reportsApi.settings.get(), {
      onSuccess({data: { data }}) {
        setInitialValues(data);
      }
    })

    const submitForm = async (data) => {
        onLoading();
        try {
            await reportsApi.settings.update(data);
            next(id);
        } catch (error) {
            onError();
            toastAlert({ type: 'error' });
        } finally {
            onFinally();
        }
    }

    useEffect(() => {
        if (initialValues) {
            reset(initialValues)
        }
    }, [initialValues])


  return (
    <form onSubmit={handleSubmit(submitForm)}>
        <Row className='gy-3'>

            <Col md={6}>
                <CustomLabel htmlFor="company_name">Company Name</CustomLabel>
                <CustomInput 
                    type="text"
                    id="company_name"
                    {...register('company_name')}
                    placeholder={formatExample('Google')}
                />
                {errorMessage(errors, 'company_name')}
            </Col>

            <Col md={6}>
                <CustomLabel htmlFor="org_nr">Org Nr.</CustomLabel>
                <CustomInput 
                    type="text"
                    id="org_nr"
                    {...register('org_nr')}
                    placeholder={formatExample('34452')}
                />
                {errorMessage(errors, 'org_nr')}
            </Col>

            <Col md={6}>
                <CustomLabel htmlFor="address">Address</CustomLabel>
                <CustomInput 
                    type="text"
                    id="address"
                    {...register('address')}
                    placeholder={formatExample(`186 Plumb Branch St. El Dorado, AR 71730`)}
                />
                {errorMessage(errors, 'address')}
            </Col>

            <Col md={6}>
                <CustomLabel htmlFor="z_reporter">Z Rapporter</CustomLabel>
                <Controller 
                    control={control}
                    name="z_reporter"
                    render={({ name, field: { onChange } }) =>       
                    <Flatpickr
                        name={name}
                        className="form-control"
                        id="z_reporter"
                        value={initialValues?.z_reporter ? moment(initialValues?.z_reporter).format("D MMM, YYYY") : ''}
                        options={{
                        dateFormat: "d M, Y",
                        }}
                        placeholder={formatExample('02 Mar, 2023')}
                        onChange={(dates) => onChange(moment(dates[0]).format("YYYY-MM-DD"))}
                    />
                    }
                />
                {errorMessage(errors, 'z_reporter')}
            </Col>

            <Col>
                <ReportSettingsEmails 
                    initialValues={initialValues}
                    setValue={setValue}
                />
            </Col>

        </Row>

        <Row className='mt-3'>
            <Col>
                <SetupActions id={id} submit={handleSubmit(submitForm)} skip={skip} isLoading={isSubmitting} />
            </Col>
        </Row>
    </form>
  )
}

export default ReportSettingsSetup