import React, { useState } from "react";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import PageContainer from "../../../Components/Layout/PageContainer";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { getOneComplaintApi } from "../../../apis/Settings/complaintsApi";
import ComplaintForm from "../../../Components/Settings/Complaints/Form";

const ComplaintDetails = () => {

  const { id } = useParams();

  const [initialValues, setInitialValues] = useState(null);

  const { isLoading } = useQuery(["complaint", id], () => getOneComplaintApi(id), {
    onSuccess({data: { data }}) {
      setInitialValues(data);
    },
  });


  return (
    <PageContainer isLoading={isLoading}>
      <BreadCrumb pageTitle="Complaints" title="Details" to="settings/complaints" />
      {initialValues && 
        <ComplaintForm
          initialValues={initialValues} 
        />
      }
    </PageContainer>
  );
};

export default ComplaintDetails;
