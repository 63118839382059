import React, { useEffect, useRef, useState } from "react";
import { Col, Row } from "reactstrap";
import PageContainer from "../../../Components/Layout/PageContainer";
import { useGetProducts } from "../../../hooks/useQueryHelpers";
import useSelectItems from "../../../hooks/useSelectItems";
import useModal from "../../../hooks/useModal";
import useActions from "../../../hooks/useActions";
import usePagination from "../../../hooks/usePagination";
import ListHeader from "../../../Components/Common/ListHeader";
import { inventoryApi } from "../../../apis/Inventory/api";
import ProductsTable from "../../../Components/Inventory/Products/Table";
import { useLocalStorage } from "../../../hooks/useLocalStorage";
import { objectFilter } from "../../../helpers/objectFilter";
import { useQuery } from "@tanstack/react-query";
import { getAllBranchesApi } from "../../../apis/Settings/branchesApi";

const Products = () => {
  
  const { isLoading: isDeleting, onLoading: onDeleting, onError, onFinally } = useActions();

  const { isDisable, selectedItems, selectItems, getSelectedIds } = useSelectItems();
  
  const { isModalOpen, setIsModalOpen, openModal, closeModal } = useModal();

  const { currentPage, navigateToPage } = usePagination();

  const timeout = useRef(null);

  const { data } = useQuery(["branches"], () => getAllBranchesApi({ page_size: 'all' }));

  const branches = data?.data?.data;
  
  const [name, setName] = useState(null);

  const [code, setCode] = useState(null);
  
  const {values: { productsFilter: localFilterValues }, setNewValues: setLocalFilterValues} = useLocalStorage({
    productsFilter: {
      category_id: '',
      branche_id: '',
      daily: '',
      page_size: 10
    }
  })

  const { isLoading, products, deleteProducts, isRefetching, refetch } = useGetProducts(objectFilter({
    page: currentPage,
    name,
    code,
    ...localFilterValues,
  }), {
    enabled: false,
    cacheTime: 0
  });

  const setPageSize = (val) => {
    setLocalFilterValues({
      productsFilter: {
          page_size: val
        }
    })
  }

  const onDeleteClick = async () => {
    onDeleting();
    try {
      const ids = getSelectedIds();
      await inventoryApi.products.delete({ ids });
      closeModal();
      deleteProducts(ids);
      refetch();
      selectItems([]);
    } catch (error) {
      onError();
    } finally {
      onFinally();
    }
  };

  useEffect(() => {
    if (branches?.length && !localFilterValues.branche_id) setLocalFilterValues({
      productsFilter: {
        branche_id: branches[0].id
      }
    })
  }, [branches])

  const debounceSearch = () => {
    clearTimeout(timeout.current);
    timeout.current = setTimeout(() => refetch(), 500);
  }

  useEffect(() => {
    if (Array.isArray(branches) && !isRefetching) refetch();
  }, [branches, localFilterValues, currentPage]);

  useEffect(() => {
    if (Array.isArray(branches) && (name !== null || code !== null)) debounceSearch();
  }, [branches, name, code])

  return (
    <PageContainer>
      <Row className="gy-4">
        <Col xs={12}>
          <ListHeader
            isDisable={isDisable}
            openModal={openModal}
            pathname="inventory/products/create"
            afterChildren={
              <div>
                <select
                  className="form-select"
                  value={localFilterValues.branche_id}
                  onChange={(e) => setLocalFilterValues({
                    productsFilter: {
                      branche_id: e.target.value
                    }
                  })}
                >
                  {branches?.map(branch => (
                    <option id={branch.id} value={branch.id}>{branch.name}</option>
                  ))}
                </select>
              </div>
            }
          />
        </Col>
        <Col xs={12}>
          <ProductsTable
            isLoading={isLoading || isRefetching}
            data={products}
            selectItems={selectItems}
            modal={isModalOpen}
            selectedItems={selectedItems}
            setModal={setIsModalOpen}
            onDeleteClick={onDeleteClick}
            isDeleting={isDeleting}
            setPageSize={setPageSize}
            navigateToPage={navigateToPage}
            refetch={refetch}
            code={code}
            setCode={setCode}
            name={name}
            setName={setName}
            filters={localFilterValues}
            setFilters={setLocalFilterValues}
          />
        </Col>
      </Row>
    </PageContainer>
  );
};

export default Products;
