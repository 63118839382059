import React, { useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { useGetTables } from '../../../../hooks/useQueryHelpers';
import DataTableContainer from '../../../Common/DataTableContainer';
import CustomTableHeading from '../../../Custom/CustomTableHeading';
import { getPathname } from '../../../../Layouts/LayoutMenuData';
import CustomButton from '../../../Custom/CustomButton';

const BranchTables = () => {

    const { id: branchId } = useParams();

    const history = useHistory();

    const { tables, refetch, isLoading, isRefetching } = useGetTables({
        branche_id: branchId,
        page_size: 'all'
    })

    const columns = [

        {
            name: <CustomTableHeading>ID</CustomTableHeading>,
            selector: row => row.id || '-',
            grow: -1,
        },
               
        {
            name: <CustomTableHeading>Name</CustomTableHeading>,
            selector: row => row.name || '-',
        },

        {
            name: <CustomTableHeading>Persons</CustomTableHeading>,
            selector: row => row.persons || '-',
        },
    
    ]

    useEffect(() => {
        refetch()
    }, [])

  return (
    <div>
        <CustomButton
            type="button"
            className="btn-success mb-3"
            onClick={() => history.push({ pathname: getPathname('orders/tables/create'), state: { branche_id: branchId} })}
        >
            Add
        </CustomButton>
        <DataTableContainer 
            data={tables ?? []}
            columns={columns}
            isLoading={isLoading || isRefetching}
            path={getPathname('orders/tables')}
            noPagination
        />
    </div>
  )
}

export default BranchTables