import React from 'react'
import { Col, Row } from 'reactstrap'
import CustomInput from '../../../Custom/CustomInput'
import CustomLabel from '../../../Custom/CustomLabel'
import { isoCountryList } from '../../../../data/isoCountryList'

const MyFatoorah = ({data, setData}) => {

  const handleChange = (e) => {
    const field = e.target.name;
    const value = e.target.value;
    setData(prev => prev.map(item => {
      if (item.id === data.id) return {
        ...item,
        [field]: value
      }
      return item
    }))
  }

  const handleCheckChange = (e) => {
    const field = e.target.name;
    const value = e.target.checked;
    setData(prev => prev.map(item => {
      if (item.id === data.id) return {
        ...item,
        [field]: value
      }
      return item
    }))
  }

  return (
    <React.Fragment>

        <div className="hstack gap-3">

            <div className="form-check form-switch">
                <input name="status" checked={data.status} onChange={handleCheckChange} className="form-check-input fs-16" type="checkbox" role="switch" id="active-myfatoorah" />
                <CustomLabel className="form-check-label" htmlFor="active-myfatoorah">Active</CustomLabel>
            </div>

            <div className="form-check form-switch">
                <input name="status_test_mode" checked={data.status_test_mode} onChange={handleCheckChange} className="form-check-input fs-16" type="checkbox" role="switch" id="active-myfatoorah-test-mode" />
                <CustomLabel className="form-check-label" htmlFor="active-myfatoorah-test-mode">Test Mode</CustomLabel>
            </div>

        
            <div className="form-check form-switch">
                <input name="guest" checked={data.guest} onChange={handleCheckChange} className="form-check-input fs-16" type="checkbox" role="switch" id="myfatoorah-guest" />
                <CustomLabel className="form-check-label" htmlFor="fatoorah-guest">Guest</CustomLabel>
            </div>

            <div className="form-check form-switch">
                <input name="shop" checked={data.shop} onChange={handleCheckChange} className="form-check-input fs-16" type="checkbox" role="switch" id="fatoorah-shop" />
                <CustomLabel className="form-check-label" htmlFor="fatoorah-shop">Shop</CustomLabel>
            </div>

            <div className="form-check form-switch">
                <input name="pos" checked={data.pos} onChange={handleCheckChange} className="form-check-input fs-16" type="checkbox" role="switch" id="fatoorah-pos" />
                <CustomLabel className="form-check-label" htmlFor="fatoorah-pos">POS</CustomLabel>
            </div>

            <div className="form-check form-switch">
                <input name="cs" checked={data.cs} onChange={handleCheckChange} className="form-check-input fs-16" type="checkbox" role="switch" id="fatoorah-cs" />
                <CustomLabel className="form-check-label" htmlFor="fatoorah-cs">Call Center</CustomLabel>
            </div>

        </div>

        <Row className='mt-1 gy-3'>

            <Col lg={6}>
                <CustomLabel>Live URL</CustomLabel>
                <CustomInput 
                    type="text"
                    name="live_url"
                    value={data.live_url}
                    onChange={handleChange}
                />
            </Col>

            <Col lg={6}>
                <CustomLabel>Test URL</CustomLabel>
                <CustomInput 
                    type="text"
                    name="live_url_test"
                    value={data.live_url_test}
                    onChange={handleChange}
                />
            </Col>

            <Col lg={6}>
                <CustomLabel>Live API Key</CustomLabel>
                <CustomInput 
                    type="text"
                    name="live_api_key"
                    value={data.live_api_key}
                    onChange={handleChange}
                />
            </Col>

            <Col lg={6}>
                <CustomLabel>Test API Key</CustomLabel>
                <CustomInput 
                    type="text"
                    name="test_api_key"
                    value={data.test_api_key}
                    onChange={handleChange}
                />
            </Col>

            <Col lg={6}>
                <CustomLabel>Lang Settings</CustomLabel>
                <select
                  className='form-select'
                  name="lang_settings"
                  value={data.lang_settings}
                  onChange={handleChange}
                >
                  {isoCountryList.map((item, index) => (
                    <option key={index} value={item.code}>{item.name} ({item.code})</option>
                  ))}
                </select>
            </Col>

            <Col lg={6}>
                <CustomLabel>WebHook Signature</CustomLabel>
                <CustomInput 
                    type="text"
                    name="webhook_signature"
                    value={data.webhook_signature}
                    onChange={handleChange}
                />
            </Col>

        </Row>

    </React.Fragment>

  )
}

export default MyFatoorah