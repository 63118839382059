import { getNotifications, markAllNotificationsAsSeen, setNotificationToRead } from "../../apis/notificationsApi";
import toastAlert from "../../helpers/toastAlert";
import { notificationActions } from "../slices/notificationsSlice";

export const getNotificationsAction = (data) => async (dispatch) => {
    try {
      const { data: response } = await getNotifications(data?.query);
      dispatch(notificationActions.setNotifications({
        data: response.data || [],
        total: response.meta?.total,
        new: response.new || 0
      }));
      if (data?.onSuccess) {
        data?.onSuccess();
      }
    } catch (error) {
      if (data?.onError) {
        data?.onError(error);
      }
    }
  };
  
export const readNotificationAction = (data) => async (dispatch) => {
    try {
      await setNotificationToRead(data.id);
      dispatch(notificationActions.markAsSeen({ id: data.id }));
      if (data.onSuccess) {
        data.onSuccess();
      }
    } catch (error) {
      if (data.onError) {
        data.onError(error);
      }
      toastAlert({ type: 'error' });
    }
};

export const readAllNotifications = (data) => async (dispatch) => {
  try {
    await markAllNotificationsAsSeen();
    dispatch(notificationActions.markAllAsSeen());
    if (data.onSuccess) {
      data.onSuccess();
    }
    toastAlert({ type: 'success' })
  } catch (error) {
    if (data.onError) {
      data.onError(error);
    }
    toastAlert({ type: 'error' });
  }
};