import React, { useEffect, useState } from 'react'
import { Droppable } from 'react-beautiful-dnd'
import DraggableCategory from './DraggableCategory'
import { Col, Row } from 'reactstrap';
import CustomLabel from '../../Custom/CustomLabel';
import { useTranslation } from 'react-i18next';
import CustomInput from '../../Custom/CustomInput';
import { menuPageRqBody } from '../../../helpers/requests/Settings/MenuPage';
import CustomButton from '../../Custom/CustomButton';
import toastAlert from '../../../helpers/toastAlert';
import { addPageImage, deleteMenuPage, updateMenuPage } from '../../../apis/Settings/menuApi';
import useDeleteModal from '../../../hooks/useDeleteModal';
import DeleteModal from '../../Common/DeleteModal';
import CustomTextArea from '../../Custom/CustomTextArea';
import SelectFile from '../../Common/SelectFile';
import CustomOption from '../../Custom/CustomOption';
import MenuPageImages from './MenuPageImages';

const MenuPage = ({ setPages, page, categories, openUpdateModal, focusFirstPage }) => {

    const [isSaving, setIsSaving] = useState(false);
    
    const [background, setBackground] = useState({
        id: null,
        file: null,
        url: null,
    })

    const { isLoading: isDeleting, setModal, load, callback: closeModal, modal: isModalOpen } = useDeleteModal();

    const { t } = useTranslation();

    const [searchQuery, setSearchQuery] = useState('');

    const handleSelectCategory = (category) => {
        if (page.items.some(cat => cat.id === category.id)) setPages(prev => ({
            ...prev,
            menu: prev.menu.map(pg => {
                if (pg.id === page.id) return {
                    ...pg,
                    items: pg.items.filter(cat => cat.id !== category.id)
                }
                return pg
            })
        }));
        else setPages(prev => ({
            ...prev,
            menu: prev.menu.map(pg => {
                if (pg.id === page.id) return {
                    ...pg,
                    items: [...pg.items, category]
                }
                return pg
            })
        }))
    }

    const handleChange = (field, value) => {
       setPages(prev => ({
            ...prev,
            menu: prev.menu.map(pg => {
                if (pg.id === page.id) return {
                    ...pg,
                    [field]: value
                }
                return pg
            })
        }))
    }

    const handleSave = async () => {
        try {
            setIsSaving(true);
            const requestBody = menuPageRqBody(page);
            
            await updateMenuPage({
                pages: [requestBody]
            })

            if (background.file) {
                const formData = new FormData();
                const file = background.file;
                const blob = new Blob([file], { type: file.type });
                formData.append("image", blob, file.name);
                const { data: { data } } = await addPageImage(page.id, formData);
                setBackground({
                    id: data.id,
                    file: null,
                    url: data.link
                })
            }

            if (page.images?.some(image => image.isNew)) {
                const formData = new FormData();
                page.images.filter(image => image.isNew).forEach(image => {
                    const file = image.file;
                    const blob = new Blob([file], { type: file.type });
                    formData.append("images[]", blob, file.name);
                })
                const { data: { data } } = await addPageImage(page.id, formData);
                setPages((prev) => ({
                ...prev,
                menu: prev.menu.map(pg => {
                    if (pg.id === page.id) return {
                        ...pg,
                        images: [...pg.images.filter(img => !img.isNew), ...data.map(img => ({
                            id: img.id,
                            url: img.link,
                        }))]
                    }
                    return pg
                })
                }));
            }

            toastAlert({ type: 'success' });

        } catch (error) {
            toastAlert({ type: 'error' })
        } finally {
            setIsSaving(false);
        }
    }

    const deletePage = async () => {
        try {
            load();
            await deleteMenuPage(page.id);
            setPages(prev => ({
                ...prev,
                menu: prev.menu.filter(pg => pg.id !== page.id)
            }));
            toastAlert({ type: 'success' })
        } catch (error) {
            toastAlert({ type: 'error' })
        } finally {
            closeModal();
            focusFirstPage({ ignoreCurrentActive: true });
        }
        
    }

    useEffect(() => {
        if (page.background) {
            setBackground({
                id: page.background.id,
                url: page.background.link,
                file: null,
            })
        }
    }, [])

  return (
    <div>
        
        <DeleteModal 
            show={isModalOpen}
            onDeleteClick={deletePage}
            isDeleting={isDeleting}
            onCloseClick={() => setModal(false)}
        />

        <Row>
            <Col md={4}>
                <CustomLabel className='fs-16'>
                   Categories
                </CustomLabel>
                <CustomInput 
                    type="text"
                    placeholder={t('Search...')}
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                />
            </Col>
            <Col>
                <div className='d-flex justify-content-end gap-2'>

                    <CustomButton
                        type="button"
                        className="btn-success"
                        onClick={handleSave}
                        isLoading={isSaving}
                    >
                        Save
                    </CustomButton>

                    <CustomButton
                        type="button"
                        className="btn-dark"
                        onClick={() => openUpdateModal('update', page)}
                    >
                        <i className="ri-edit-line fs-16"></i>
                    </CustomButton>

                    <CustomButton
                        type="button"
                        className="btn-danger"
                        onClick={() => setModal(true)}
                    >
                        Delete
                    </CustomButton>
                </div>
            </Col>
        </Row>

        <div className='mt-3 d-flex flex-wrap gap-3'>
            {categories.filter(category => category.name.toLowerCase().includes(searchQuery.toLowerCase())).map(category => (
                <div 
                    key={category.id}
                    className={`border p-2 px-5 d-flex justify-content-center align-items-center cursor-pointer user-select-none ${page.items.some(cat => cat.id === category.id) ? 'bg-success text-white' : ''}`}
                    onClick={() => handleSelectCategory(category)}
                >
                    <span className='fs-15 fw-bold'>{category.name}</span>
                </div>
            ))}
        </div>

        <div className='mt-3 d-flex flex-column gap-3'>
            <div>
                <CustomLabel className='fs-16'>
                    Note
                </CustomLabel>
                <CustomTextArea 
                    value={page.note}
                    onChange={(e) => handleChange('note', e.target.value)}
                />    
            </div>

            <div>
                <CustomLabel className='fs-16'>
                    Price Type
                </CustomLabel>
                <select className='form-select' value={page.price_type} onChange={(e) => handleChange('price_type', e.target.value)}>
                    <CustomOption value="price">Price</CustomOption>
                    <CustomOption value="price_in">Price in</CustomOption>
                </select>  
            </div>

            <div className="form-check form-switch">
                <input 
                    checked={page.show_allergy}
                    onChange={(e) => handleChange('show_allergy', e.target.checked)}
                    className="form-check-input fs-16" 
                    type="checkbox" 
                    role="switch" 
                />
                <CustomLabel className="form-check-label" htmlFor="is_menu">Show Allergies</CustomLabel>
            </div>

            <div>
                <CustomLabel>Background Image</CustomLabel>
                <SelectFile
                    selectedFiles={background}
                    setSelectedFiles={setBackground}
                    type='image'
                />
            </div>

            <div>
                <CustomLabel>Images</CustomLabel>
                <MenuPageImages 
                    pageId={page.id}
                    images={page.images}
                    setPages={setPages}
                />
            </div>

        </div>

        <Droppable droppableId={`page-${page.id}`} type="category">
            {provided => (
                <div className='d-flex flex-column gap-3 dropzone p-3 mt-3' ref={provided.innerRef} {...provided.droppableProps}>
                    {!page.items.length &&
                         <div className='d-flex align-items-center justify-content-center'>
                                This page is currently empty
                        </div>
                    }
                    {page.items.map((item, index) =>  (
                        <DraggableCategory 
                            key={item.id}
                            index={index}
                            pageId={page.id}
                            category={item}
                            setData={setPages}
                        />
                    ))}
                    {provided.placeholder}
                </div>
            )}
        </Droppable>
    </div>
  )
}

export default MenuPage