import React from "react";
import classNames from "classnames";
import NoImage from "../../../assets/images/no-image.jpg";

const CouponProductItem = ({ img, title, selected, onClick }) => {
  return (
    <div
      className={classNames("cursor-pointer border d-flex flex-column", {
        "bg-success": selected,
      })}
      onClick={onClick}
      style={{
        width: 160,
        height: "auto",
      }}
    >
      <div className="team-cover">
        <img src={img || NoImage} alt="" className="img-fluid" />
      </div>

      <div className="d-flex align-items-end justify-content-center flex-1">
        <h6 className="text-center mb-0 py-2">{title}</h6>
      </div>
    </div>
  );
};

export default CouponProductItem;
