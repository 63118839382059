import React from "react";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import PageContainer from "../../../Components/Layout/PageContainer";
import LanguageForm from "../../../Components/Settings/Language/LanguageForm";
import { getPathname } from "../../../Layouts/LayoutMenuData";

const CreateLanguage = () => {
  return (
    <PageContainer>
      <BreadCrumb
        to={"settings/language"}
        pageTitle="Language"
        title="Create"
      />
      <LanguageForm type="Create" />
    </PageContainer>
  );
};

export default CreateLanguage;
