import React from "react";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import PageContainer from "../../../Components/Layout/PageContainer";
import UserForm from "../../../Components/Users/Users/Form";

const CreateUser = () => {
  return (
    <PageContainer>
      <BreadCrumb pageTitle="Users" title="Create" to="users" />
      <UserForm type="Create" />
    </PageContainer>
  );
};

export default CreateUser;
