import axiosInstance from "..";

// ! Footer

export const getFooterApi = () => {
  return axiosInstance.get("/settings/footer");
};

export const setFooterApi = (body) => {
  return axiosInstance.post("/settings/footer", body);
};

// ! Social Media

export const getSocialMediaApi = () => {
  return axiosInstance.get("/settings/sociel_media");
};

export const setSocialMediaApi = (body) => {
  return axiosInstance.post("/settings/sociel_media", body);
};

// ! Setup
export const getSetupApi = () => {
  return axiosInstance.get("/settings/setup");
};

export const setSetupApi = (body) => {
  return axiosInstance.post("/settings/setup", body);
};

// ! Contact
export const getContactApi = () => {
  return axiosInstance.get("/settings/contact");
};

export const setContactApi = (body) => {
  return axiosInstance.post("/settings/contact", body);
};

// ! Opening Hours
export const getOpeningHoursApi = () => {
  return axiosInstance.get("/settings/opening_hours");
};

export const setOpeningHoursApi = (body) => {
  return axiosInstance.post("/settings/opening_hours", body);
};

export const deleteOneOpeningHour = (id) => {
  return axiosInstance.delete(`/settings/opening_hours/${id}`);
};

// ! Seo

export const getSeoApi = () => {
  return axiosInstance.get("/settings/seo");
};

export const setSeoApi = (body) => {
  return axiosInstance.post("/settings/seo", body);
};

// ! App

export const getAppApi = () => {
  return axiosInstance.get("/settings/app");
};

export const setAppApi = (body) => {
  return axiosInstance.post("/settings/app", body);
};

// Files

export const deleteFileApi = (data) => {
  return axiosInstance.delete(`/settings/images`, {
    data
  })
}

export const getTimeZones = () => {
  return axiosInstance.get("/settings/timezones");
};

// ! Colors

export const getColorsApi = () => {
  return axiosInstance.get("/settings/colors");
};

export const setColorsApi = (body) => {
  return axiosInstance.post("/settings/colors", body);
};

// ! Global Settings

export const getGlobalSettingsApi = () => {
  return axiosInstance.get("/global_setting");
};

export const setGlobalSettingsApi = (body) => {
  return axiosInstance.post("/global_setting", body);
};
