import React from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import PageContainer from "../../../Components/Layout/PageContainer";
import { getPathname } from "../../../Layouts/LayoutMenuData";
import ControlForm from "../../../Components/Inventory/Control/Form";
import { IoArrowBackSharp } from "react-icons/io5";

const ProductsControl = () => {

  const { t } = useTranslation();

  const { type } = useParams();

  const cards = [
    {
        title: 'Control',
        link: getPathname(`inventory/control/control`)
    },
    {
        title: 'Default Daily',
        link: getPathname(`inventory/control/default-daily`)
    },
    {
        title: 'Add Quantity',
        link: getPathname(`inventory/control/add-quantity`)
    },
    {
        title: 'Waste Quantity',
        link: getPathname(`inventory/control/extract-quantity`)
    },
  ]

  return (
    <PageContainer>

       {!type &&
          <div className="d-flex flex-column justify-content-center align-items-center" style={{ height: '60vh' }}>
              <div className="w-100 text-center mb-3">
                  <h1 className="text-uppercase" style={{fontSize: '30px'}}>{t(`Control Type`)}:</h1>
              </div>

              <Row className="w-100 justify-content-center">
                  {cards.map((item, index) => (
                      <Col lg={4} key={index}>
                          <Link to={item.link}>
                              <Card
                                  className="cursor-pointer p-4 card-animate"
                              >
                                  <CardBody className="d-flex justify-content-center align-items-center">
                                          <div className="d-flex align-items-center">
                                              <div className="flex-grow-1 ms-3">
                                                  <p className="text-uppercase fw-bold text-muted mb-1" style={{fontSize: '20px'}}>{t(item.title)}</p>
                                              </div>
                                          </div>
                                  </CardBody>
                              </Card>
                          </Link>
                      </Col>
                  ))}
              </Row>
          </div>
       }

       {type && 
          <>
            <div className="d-flex justify-content-between">
                <Link to={getPathname('inventory/control')}>
                    <IoArrowBackSharp style={{ fontSize: '2rem' }} />
                </Link>
                <h4>
                    {t({
                        control: 'Control',
                        'default-daily': 'Default Daily',
                        'add-quantity': 'Add Quantity',
                        'extract-quantity': 'Waste Quantity',
                    }[type])}
                </h4>
            </div>

            <ControlForm 
              type={type}
            />
          </>

       }
    </PageContainer>
  );
};

export default ProductsControl;
