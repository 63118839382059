import moment from "moment";
import React from "react";
import ReactApexChart from "react-apexcharts";
import numeral from "numeral";

const RevenueCharts = ({ data, months, formatters }) => {

  var options = {
    chart: {
      height: 370,
      type: "line",
      toolbar: {
        show: false,
      },
    },
    stroke: {
      curve: "straight",
      dashArray: [0, 0, 8],
      width: [0, 2, 2.2],
    },
    fill: {
      opacity: [0.9, 0.1, 1],
    },
    markers: {
      size: [0, 0, 0],
      strokeWidth: 2,
      hover: {
        size: 4,
      },
    },
    xaxis: {
      categories: months.map(item => moment(item).format('MMM')),
      axisTicks: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
    },
    yaxis: {
      labels: {
        formatter: (value) => {
          return numeral(value).format('0a')
        }
      }
    },
    grid: {
      show: true,
      xaxis: {
        lines: {
          show: true,
        },
      },
      yaxis: {
        lines: {
          show: false,
        },
      },
      padding: {
        top: 0,
        right: -2,
        bottom: 15,
        left: 10,
      },
    },
    legend: {
      show: true,
      horizontalAlign: "center",
      offsetX: 0,
      offsetY: -5,
      markers: {
        width: 9,
        height: 9,
        radius: 6,
      },
      itemMargin: {
        horizontal: 10,
        vertical: 0,
      },
    },
    plotOptions: {
      bar: {
        columnWidth: "30%",
        barHeight: "70%",
      },
    },
    // colors: linechartcustomerColors,
    tooltip: {
      shared: true,
      y: formatters
    },
  };


  return (
    <React.Fragment>
      <ReactApexChart
        options={options}
        series={data}
        type="line"
        height="370"
        className="apex-charts"
      />
    </React.Fragment>
  );
};

export default RevenueCharts
