import React, { useState } from "react";
import Dropzone from "react-dropzone";
import { deleteFileApi } from "../../apis/Settings/webSettingsApi";
import generateUniqueID from "../../helpers/generateUniqueID";
import ImageItem from "./ImageItem";
import { Spinner } from "reactstrap";
import LoadingComponent from "./Loading";

const SelectFile = ({ selectedFiles, setSelectedFiles, max = 1, multiple, loading }) => {
  
  const [deleting, setDeleting] = useState(null);

  const handleFileChange = (files) => {
    if (multiple || max > 1) {
      const newFiles = files.map((file) => {
        return {
          id: generateUniqueID(),
          url: URL.createObjectURL(file),
          file,
          name: file.name,
          isNew: true,
        };
      });
      setSelectedFiles((prev) => [...prev, ...newFiles]);
    } else {
      setSelectedFiles({
        file: files[0],
        url: URL.createObjectURL(files[0]),
        name: files[0].name,
      });
    }
  };

  const removeFile = async (file) => {
    if (multiple || max > 1) {
      const existingFile = selectedFiles.find((fl) => fl.id === file.id);
      const isNew = existingFile.isNew;
      if (isNew) {
        setSelectedFiles((prev) => prev.filter((fl) => fl.id !== file.id));
      } else {
        try {
          setDeleting(file.id);
          await deleteFileApi({
            ids: [file.id],
          });
          setSelectedFiles((prev) => prev.filter((fl) => fl.id !== file.id));
          setDeleting(null);
        } catch (error) {
          setDeleting(null);
        }
      }
    } else {
      if (!selectedFiles.id) {
        setSelectedFiles({
          file: "",
          url: "",
          name: "",
        });
      } else {
        try {
          setDeleting(file.id);
          await deleteFileApi({
            ids: [file.id],
          });
          setSelectedFiles({
            file: "",
            url: "",
            name: "",
          });
          setDeleting(null);
        } catch (error) {
          setDeleting(null);
        }
      }
    }
  };

  if (loading) return <LoadingComponent loading={true} />

  return (
    <div>
      <Dropzone
        onDrop={(acceptedFiles) => {
          handleFileChange(acceptedFiles);
        }}
        accept={["image/*"]}
      >
        {({ getRootProps }) => (
          <div className="dropzone dz-clickable" {...getRootProps()}>
            <div className="dz-message needsclick">
              <div className="mb-3">
                <i className="text-muted ri-upload-cloud-2-fill display-6" />
              </div>
              {/* <h5>Drop files here or click to upload.</h5> */}
            </div>
          </div>
        )}
      </Dropzone>

      <div className="d-flex gap-2 justify-content-center flex-wrap mt-3">
        {[selectedFiles].flat().some((file) => file.url) &&
          [selectedFiles]
            .flat()
            .map((item, index) => (
              <ImageItem
                key={index}
                f={item}
                removeFile={removeFile}
                deleting={deleting}
                loading={loading}
              />
            ))}
      </div>
    </div>
  );
};

export default SelectFile;
