import React from "react";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import PagesMenuForm from "../../../Components/Settings/PagesMenu/Form";
import PageContainer from "../../../Components/Layout/PageContainer";
import { useFetchByLang } from "../../../hooks/useFetchByLang";

const CreatePagesMenu = () => {

  const {language, setLanguage} = useFetchByLang();

  return (
    <PageContainer>
      <BreadCrumb
        pageTitle="Pages Menu"
        title="Create"
        to="settings/pages-menu"
      />
      <PagesMenuForm 
        type="create" 
        language={language}
        setLanguage={setLanguage}
      />
    </PageContainer>
  );
};

export default CreatePagesMenu;
