import React, { useEffect, useState } from "react";
import { Card } from "reactstrap";
import DataTableContainer from "../../../Components/Common/DataTableContainer";
import CustomInput from "../../../Components/Custom/CustomInput";
import CustomOption from "../../../Components/Custom/CustomOption";
import CustomTableHeading from "../../../Components/Custom/CustomTableHeading";
import PageContainer from "../../../Components/Layout/PageContainer";
import Flatpickr from "react-flatpickr";
import useDebounceValue from "../../../hooks/useDebouneValue";
import { useTranslation } from "react-i18next";
import { useQueries } from "@tanstack/react-query";
import { getAllOrdersApi, getOrdersStatistics } from "../../../apis/Orders/ordersApi";
import { useBranches, useGetChannels, useGetDeliveryCompanies, useGetOrderStatus } from "../../../hooks/useQueryHelpers";
import OrderStatistics from "../../../Components/Orders/Orders/Statistics/Statistics";
import { formatDateTime } from "../../../helpers/timeFormat";
import moment from "moment";
import { objectFilter } from "../../../helpers/objectFilter";
import { useLocalStorage } from "../../../hooks/useLocalStorage";

const paymentStatuses = [
  {
    id: 1,
    name: 'Paid',
  },
  {
    id: 2,
    name: 'Not Paid',
  },
  {
    id: 3,
    name: 'Returned',
  },
  {
    id: 4,
    name: 'Cancelled',
  },
]

const paymentMethods = [
  {
    id: 1,
    name: "Cash",
  },
  {
    id: 2,
    name: "SumUp",
  },
  {
    id: 3,
    name: "Vipps",
  },
  {
    id: 4,
    name: "Stripe",
  },
  {
    id: 5,
    name: "Nets",
  },
  {
    id: 6,
    name: "Reepay",
  },
  {
    id: 7,
    name: "My Fatoorah",
  },
  {
    id: 8,
    name: "Paypal",
  },
];

const deliveryTypes = [
  {
    id: 1,
    name: "Takeaway",
  },
  {
    id: 2,
    name: "Delivery",
  },
  {
    id: 3,
    name: "Rest",
  },
]

const OrderList = () => {

  const { t } = useTranslation();

  const [orders, setOrders] = useState({
    list: [],
    total: null
  });

  const [statistics, setStatistics] = useState(null);

  const [page, setPage] = useState(1);

  const [searchQuery, setSearchQuery] = useState({
    code: '',
    user: '',
    phone: '',
    number: '',
  })

  const { status: orderStatus } = useGetOrderStatus();

  const { data: channelsData } = useGetChannels();

  const { data: deliveryCompaniesData } = useGetDeliveryCompanies();

  const channels = channelsData?.data?.data ?? [];

  const deliveryCompanies = deliveryCompaniesData?.data?.data ?? [];

  const { branches } = useBranches();

  const {values: {
      ordersFilter: localFilterValues
  }, setNewValues: setLocalFilterValues} = useLocalStorage({
      ordersFilter: {
        status: "",
        payment_status: "",
        payment_method: "",
        is_payment: "",
        delivery_type_id: "",
        delivery_company: "",
        channel: "",
        branche_id: "",
        page_size: 10,
        date: {
          from: moment(Date.now()).format("YYYY-MM-DD"),
          to: '',
        }
      }
  })

  const debouncedFilterData = useDebounceValue({ value: searchQuery });

  const navigateToPage = (page) => {
      setPage(page);
  };

  const setPageSize = (pageSize) => {
    setLocalFilterValues({
        ordersFilter: {
          page_size: pageSize,
        }
    })
  };

  const handleChange = (name, value) => {
    if (value === null || value === undefined) return;
    setLocalFilterValues({
        ordersFilter: {
          [name]: value,
        }
    })
  };

  const handleDateChange = (value) => {
    let from = moment(value[0]).format("YYYY-MM-DD");
    let to =  value[1] ? moment(value[1]).format("YYYY-MM-DD") : ''
    setLocalFilterValues({
        ordersFilter: {
          date: {
            from,
            to
          }
      }
    })
  }

  const handleTextSearch = (name, value) => {
    if (value === null || value === undefined) return;
    setSearchQuery(prev => ({
          ...prev,
          [name]: value,
      }
    ))
    setPage(1);
  }

  const [{ isLoading, isRefetching, refetch }, { refetch: refetchStatistics }] = useQueries({
    queries: [
      {
        queryKey: ['orders'],
        queryFn: () => getAllOrdersApi(objectFilter({...localFilterValues, ...searchQuery, page})),
        enabled: false,
        cachTime: 0,
        onSuccess({ data }) {
          setOrders({
            list: data.data,
            total: data.meta.total
          });
        }
      },
      {
        queryKey: ['orders-statistics'],
        queryFn: () => getOrdersStatistics(objectFilter({...localFilterValues, ...searchQuery, page})),
        enabled: false,
        cachTime: 0,
        onSuccess({ data: { data }}) {
          setStatistics(data);
        }
      },
    ]
  })
 

  useEffect(() => {
    refetch();
    refetchStatistics();
  }, [page, localFilterValues, debouncedFilterData]);

  const { delivery_type_id, delivery_company, channel, payment_status, payment_method, status, branche_id, date } = localFilterValues;

  const columns = [
    {
      name: (
        <CustomInput
          placeholder="Code"
          value={searchQuery.code}
          onChange={(e) => handleTextSearch("code", e.target.value)}
          data-filtered={!!searchQuery.code}
        />
      ),
      selector: (row) => `#${row.code}`,
    },
    {
      name: (
        <CustomInput
          placeholder="Number"
          value={searchQuery.number}
          onChange={(e) => handleTextSearch("number", e.target.value)}
          data-filtered={!!searchQuery.number}
        />
      ),
      selector: (row) => row.number ?? '-',
    },
    {
      name: (
        <CustomInput
          placeholder="User"
          value={searchQuery.user}
          onChange={(e) => handleTextSearch("user", e.target.value)}
          data-filtered={!!searchQuery.user}
        />
      ),
      selector: (row) => (
        <div data-tag="allowRowEvents">
          {row.user}
        </div>
      ),
    },
    {
      name: (
        <CustomInput
          placeholder="Phone"
          value={searchQuery.phone}
          onChange={(e) => handleTextSearch("phone", e.target.value)}
          data-filtered={!!searchQuery.phone}
        />
      ),
      selector: (row) => (
        <div data-tag="allowRowEvents">
          {row.phone ?? '-'}
        </div>
      ),
    },
    {
      name: (
        <select
          className="form-select"
          value={status}
          onChange={(e) => handleChange("status", e.target.value)}
          data-filtered={!!status}
        >
          <CustomOption value={""}>Status</CustomOption>
            {orderStatus.map((item) => (
            <CustomOption key={item.id} value={item.id}>
              {item.name}
            </CustomOption>
          ))}
        </select>
      ),
      selector: (row) => t(row.status),
    },
    {
      name: (
        <select
          className="form-select"
          value={delivery_type_id}
          onChange={(e) => handleChange("delivery_type_id", e.target.value)}
          data-filtered={!!delivery_type_id}
        >
          <CustomOption value={""}>Delivery Type</CustomOption>
            {deliveryTypes.map((deliveryType) => (
            <CustomOption key={deliveryType.id} value={deliveryType.id}>
              {deliveryType.name}
            </CustomOption>
          ))}
        </select>
      ),
      selector: (row) => row.delivery,
    },
    {
      name: (
        <select
          className="form-select"
          value={channel}
          onChange={(e) => handleChange("channel", e.target.value)}
          data-filtered={!!channel}
        >
          <CustomOption value={""}>Channel</CustomOption>
            {channels.map((channel) => (
            <CustomOption key={channel.id} value={channel.id}>
              {channel.name}
            </CustomOption>
          ))}
        </select>
      ),
      selector: (row) => row.channel ?? '-',
    },
    {
      name: (
        <select
          className="form-select"
          value={delivery_company}
          onChange={(e) => handleChange("delivery_company", e.target.value)}
          data-filtered={!!delivery_company}
        >
          <CustomOption value={""}>Delivery By</CustomOption>
            {deliveryCompanies.map((company) => (
            <CustomOption key={company.id} value={company.id}>
              {company.name}
            </CustomOption>
          ))}
        </select>
      ),
      selector: (row) => row.delivery_company ?? '-',
    },
    {
      name: (
        <select
          className="form-select"
          value={payment_status}
          onChange={(e) => handleChange("payment_status", e.target.value)}
          data-filtered={!!payment_status}
        >
          <CustomOption value={""}>Payment</CustomOption>
          {paymentStatuses.map(payment => (
            <CustomOption key={payment.id} value={payment.id}>
              {payment.name}
            </CustomOption>
          ))}
        </select>
      ),
      selector: (row) => row.payment?.name,
    },
    {
      name: (
        <select
          className="form-select"
          value={payment_method}
          onChange={(e) => handleChange("payment_method", e.target.value)}
          data-filtered={!!payment_method}
        >
          <CustomOption value={""}>Payment Method</CustomOption>
          {paymentMethods.map(method => (
            <CustomOption key={method.id} value={method.id}>
              {method.name}
            </CustomOption>
          ))}
        </select>
      ),
      selector: (row) => t(row.payment_method),
    },
    {
      name: (
        <div className="d-flex gap-2">
        
            <Flatpickr
              className="form-control"
              value={[date.from, date.to]}
              onChange={handleDateChange}
              placeholder="Date"
              options={{
                mode: "range",
              }}
              data-filtered={!!date.from || !!date.to}
            />
            {(!!date.from || !!date.to) && 
              <i className="ri-filter-3-fill align-self-center cursor-pointer" onClick={() => {
                setLocalFilterValues({
                    ordersFilter: {
                      date: {
                        from: '',
                        to: '',
                      }
                    }
                })
              }}></i>
            }

        </div>
      ),
      selector: (row) => formatDateTime(row.created_at),
      grow: 1.2,
    },
    {
      name: (
        <select
          className="form-select"
          value={branche_id}
          onChange={(e) => handleChange("branche_id", e.target.value)}
          data-filtered={!!branche_id}
        >
          <CustomOption value={""}>Branch</CustomOption>
          {branches.map(branch => (
            <CustomOption key={branch.id} value={branch.id}>
              {branch.name}
            </CustomOption>
          ))}
        </select>
      ),
      selector: (row) => row.branch?.name ?? '-',
    },
    {
      name: <CustomTableHeading>Total</CustomTableHeading>,
      selector: (row) => row.total,
    },
  ];

  return (
    <PageContainer isLoading={isLoading}>
      <div className="mb-4">
        <OrderStatistics 
          statistics={statistics}
        />
      </div>
      <Card>
        <DataTableContainer
          data={orders.list}
          columns={columns}
          paginationServer
          pageSize={localFilterValues.page_size}
          loading={isLoading || isRefetching}
          path="orders"
          navigateToPage={navigateToPage}
          setPageSize={setPageSize}
          paginationTotalRows={orders.total}
        />
      </Card>
    </PageContainer>
  );
};

export default OrderList;
