import React, { useEffect, useState } from 'react';
import { Col, Dropdown, DropdownMenu, DropdownToggle, Row } from 'reactstrap';
import SimpleBar from "simplebar-react";
import { useDispatch, useSelector } from 'react-redux';
import OrderNotification from './Types/OrderNotification';
import CustomButton from '../Custom/CustomButton';
import { useTranslation } from 'react-i18next';
import { getNotifications } from '../../apis/notificationsApi';
import { notificationActions } from '../../redux/slices/notificationsSlice';
import toastAlert from '../../helpers/toastAlert';
import MarkAsSeen from './MarkAsSeen';
import NotificationSound from './NotificationSound';

const NotificationDropdown = () => {

    const [isNotificationDropdown, setIsNotificationDropdown] = useState(false);

    const [newNotifs, setNewNotifs] = useState(0);

    const [loading, setLoading] = useState(false);

    const dispatch = useDispatch();

    const toggleNotificationDropdown = () => {
        setIsNotificationDropdown(!isNotificationDropdown);
    };

    const { list: notifications, new: newNotifications, total } = useSelector(state => state.notifications);

    const { t } = useTranslation();

    const getNextPage = async () => {
        const nextPage = (notifications?.length - (notifications?.length % 10)) / 10 + 1;
        setLoading(true);
        try {
            const { data: { data } } = await getNotifications({
                page_size: 10,
                page: Math.floor(nextPage),
            });
            dispatch(notificationActions.getNextPage({ data }));
        } catch (error) {
            toastAlert({ type: "error" })
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        setNewNotifs(newNotifications);
    }, [newNotifications])

    return (
        <React.Fragment>
            <Dropdown isOpen={isNotificationDropdown} toggle={toggleNotificationDropdown} className="topbar-head-dropdown ms-1 header-item">

                <DropdownToggle type="button" tag="button" className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle">
                    <i className='bx bx-bell fs-22'></i>
                    <span className="position-absolute topbar-badge fs-10 translate-middle badge rounded-pill bg-danger">{newNotifs}</span>
                </DropdownToggle>

                <DropdownMenu className="dropdown-menu-lg dropdown-menu-end p-0">
                    <div className="dropdown-head bg-primary bg-pattern rounded-top">
                        <div className="p-3">
                            <Row className="align-items-center">
                                <Col>
                                    <h6 className="m-0 fs-16 fw-semibold text-white">{t('Notifications')}</h6>
                                </Col>
                                <div className="d-flex gap-2 col-auto dropdown-tabs">
                                    <span className="badge badge-soft-light fs-13">{total} {t('Total')}</span>
                                    <span className="badge badge-soft-light fs-13">{newNotifs} {t('New')}</span>
                                </div>
                            </Row>
                        </div>
                    </div>

                    <SimpleBar style={{ maxHeight: "300px" }} className="pt-2">

                        <NotificationSound />

                        {
                            notifications?.some(item => !item.read) && <MarkAsSeen /> 
                        }

                        {notifications?.length ? notifications.map(notification => (
                            <OrderNotification
                                key={notification.id}
                                notification={notification}
                            />
                        ))
                            : 
                            <div
                                className='d-flex justify-content-center p-3'
                            >
                                {t('You have no notifications')}
                            </div>
                        }

                        {total > notifications?.length && 
                            <div className="my-3 text-center">
                                <CustomButton
                                    type="button"
                                    isLoading={loading}
                                    onClick={getNextPage}
                                    className="btn btn-soft-success waves-effect waves-light"
                                >
                                    {t("View More")}
                                    {" "}
                                    <i className="ri-arrow-right-line align-middle"></i>
                                </CustomButton>
                            </div>
                        }

                    </SimpleBar>
                </DropdownMenu>
            </Dropdown>
        </React.Fragment>
    );
};

export default NotificationDropdown;