import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Card } from "reactstrap";
import { isAllowed } from "../../../helpers/isAllowed";
import DataTableContainer from "../../Common/DataTableContainer";
import CustomTableHeading from "../../Custom/CustomTableHeading";
import { getPathname } from "../../../Layouts/LayoutMenuData";
import CustomInput from "../../Custom/CustomInput";
import CustomOption from "../../Custom/CustomOption";
import { useAdmins, useBranches, useGetInventoryOrdersStatuses } from "../../../hooks/useQueryHelpers";

const InventoryOrdersTable = ({
  data,
  selectItems,
  modal,
  setModal,
  onDeleteClick,
  isDeleting,
  navigateToPage,
  setPageSize,
  isLoading,
  code,
  setCode,
  filters,
  setFilters
}) => {

  const { user } = useSelector(state => state.admin);

  const { branches } = useBranches();

  const { data: statusesData } = useGetInventoryOrdersStatuses();

  const statuses = statusesData?.data ?? [];

  const { admins, refetch: refetchAdmins } = useAdmins({ page_size: 'all'});

  const handleFilter = (field, value) => {
    setFilters({
      inventoryOrdersFilter: {
        [field]: value
      }
    })
  }

  const columns = [
    {
      name: <CustomTableHeading>ID</CustomTableHeading>,
      selector: (row) => row.id,
    },
    {
      name: 
      <CustomInput 
        placeholder="Code"
        value={code ?? ''}
        onChange={(e) => setCode(e.target.value)}
        data-filtered={!!code}
      />,
      selector: (row) => row.code ?? '-',
    },
    {
      name: (
        <select
          className="form-select"
          value={filters.branche_id}
          onChange={(e) => handleFilter('branche_id', e.target.value)}
          data-filtered={!!filters.branche_id}
        >
          <CustomOption value="">Branch</CustomOption>
          {branches.map(branch => (
            <option key={branch.id} value={branch.id}>{branch.name}</option>
          ))}
        </select>
      ),
      selector: (row) => row.branch?.name ?? '-',
    },
    {
      name: (
        <select
          className="form-select"
          value={filters.admin_id}
          onChange={(e) => handleFilter('admin_id', e.target.value)}
          data-filtered={!!filters.admin_id}
        >
          <CustomOption value="">Admin</CustomOption>
          {admins.map(admin => (
            <option key={admin.id} value={admin.id}>{admin.name}</option>
          ))}
        </select>
      ),
      selector: (row) => row.admin?.name ?? '-',
    },
    {
      name: (
        <select
          className="form-select text-capitalize"
          value={filters.status}
          onChange={(e) => handleFilter('status', e.target.value)}
          data-filtered={!!filters.status}
        >
          <CustomOption value="">Status</CustomOption>
          {statuses.map(status => (
            <option key={status} value={status}>{status}</option>
          ))}
        </select>
      ),
      selector: (row) => <span className="text-capitalize">{row.status ?? '-'}</span>,
    },
  ];

  useEffect(() => {
    refetchAdmins();
  }, [])

  return (
    <Card>
      <DataTableContainer
        selectableRows={isAllowed(user)}
        data={data.list ?? []}
        paginationTotalRows={data.total}
        isDeleting={isDeleting}
        handleDelete={onDeleteClick}
        modal={modal}
        setPageSize={setPageSize}
        paginationServer
        loading={isLoading}
        setModal={setModal}
        selectItems={selectItems}
        navigateToPage={navigateToPage}
        columns={columns}
        path={getPathname("inventory/orders")}
      />
    </Card>
  );
};

export default InventoryOrdersTable;
