export const isoCountryList = [
    {
        name: 'Kuwait',
        code: 'KWD'
    },

    {
        name: 'Saudi Arabia',
        code: 'SAR'
    },

    {
        name: 'Bahrain',
        code: 'BHD'
    },

    {
        name: 'United Arab Emirates',
        code: 'AED'
    },

    {
        name: 'Qatar',
        code: 'QAR'
    },

    {
        name: 'Oman',
        code: 'OMR'
    },

    {
        name: 'Jordan',
        code: 'JOD'
    },

    {
        name: 'Egypt',
        code: 'EGP'
    },
]