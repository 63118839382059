import axiosInstance from "..";

export const getAllShelves = (params) => {
  return axiosInstance.get("/shelves", {
    params
  });
};

export const getOneShelf = (id) => {
  return axiosInstance.get(`/shelves/${id}`);
};

export const createShelf = (data) => {
  return axiosInstance.post(`/shelves`, data);
};

export const updateShelf = (id, data) => {
    return axiosInstance.post(`/shelves/${id}`, data);
};

export const deleteShelf = (data) => {
    return axiosInstance.delete(`/shelves`, {
        data
    });
};

