import classNames from "classnames";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Card,
  CardBody,
  CardHeader,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import OrderAdminControl from "./OrderAdminControl";
import OrderTrackings from "./OrderTrackings";

const OrderDetailsControl = ({ order, status, setOrder }) => {
  
  const [activeTab, setActiveTab] = useState(1);

  const toggleActiveTab = (tab) => {
    setActiveTab(tab);
  };

  const { t } = useTranslation();

  const TABS = [
    {
      name: "Admin control",
      children: (
        <CardBody>
          <OrderAdminControl
            order={order}
            status={status}
            setOrder={setOrder}
          />
        </CardBody>
      ),
    },
    {
      name: "History",
      children: <OrderTrackings trackings={order.tracking} />,
    },
  ];

  return (
    <Card className="order-details-control">
      <CardHeader>
        <Nav tabs className="nav-tabs-custom card-header-tabs border-bottom-0">
          {TABS.map((tab, index) => {
            const i = index + 1;
            return (
              <NavItem key={tab.name}>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classNames({ active: activeTab === i })}
                  onClick={() => {
                    toggleActiveTab(i);
                  }}
                >
                  {t(tab.name)}
                </NavLink>
              </NavItem>
            );
          })}
        </Nav>
      </CardHeader>
      <TabContent activeTab={activeTab} className="text-muted">
        {TABS.map((tab, index) => {
          const i = index + 1;

          return (
            <TabPane key={tab.name} tabId={i}>
              {tab.children}
            </TabPane>
          );
        })}
      </TabContent>
    </Card>
  );
};

export default OrderDetailsControl;