import { useQuery } from "@tanstack/react-query";
import React from "react";
import { CardBody, CardFooter, Col, Row } from "reactstrap";
import {
  getSocialMediaApi,
  setSocialMediaApi,
} from "../../../apis/Settings/webSettingsApi";
import extractError from "../../../helpers/extractError";
import { renderErrorAsString } from "../../../helpers/renderErrors";
import toastAlert from "../../../helpers/toastAlert";
import useActions from "../../../hooks/useActions";
import useCustomForm from "../../../hooks/useCustomForm";
import LoaderComponent from "../../Common/LoaderComponent";
import CustomButton from "../../Custom/CustomButton";
import CustomInput from "../../Custom/CustomInput";
import CustomLabel from "../../Custom/CustomLabel";
import WebSettingsBody from "./WebSettingsBody";
import WebSettingsFooter from "./WebSettingsFooter";

const inputs = [""];

const SocialMediaTab = () => {
  const { isLoading: isSubmitting, onLoading, onFinally } = useActions();

  const { values, handleChange, setValues } = useCustomForm({
    facebook: "",
    twitter: "",
    youtube: "",
    instagram: "",
    whatsapp: "",
    snapchat: "",
    telegram: "",
    tiktok: "",
  });

  const { isLoading } = useQuery(
    ["web-settings", "social-media"],
    getSocialMediaApi,
    {
      onSuccess(data) {
        setValues(data.data.data);
      },
    }
  );

  const onSubmit = async () => {
    onLoading();
    try {
      await setSocialMediaApi(values);
      toastAlert({ type: 'success' });
    } catch (error) {
      toastAlert({ type: 'error', message: renderErrorAsString(extractError(error))});
    } finally {
      onFinally();
    }
  };

  return (
    <LoaderComponent isLoading={isLoading}>
      <WebSettingsBody>
        {Object.entries(values).map(([prop, val]) => {
          return (
            <Col key={prop} md={6}>
              <CustomLabel>{`${prop.charAt(0).toUpperCase()}${prop.slice(1)}`}</CustomLabel>
              <CustomInput
                name={prop}
                value={val || ""}
                onChange={handleChange}
              />
            </Col>
          );
        })}
      </WebSettingsBody>
      <WebSettingsFooter isSubmitting={isSubmitting} onSubmit={onSubmit} />
    </LoaderComponent>
  );
};

export default SocialMediaTab;
