const sortFunc = (a, b) => {
    if (a.sort === null && b.sort === null) return 0;
    if (a.sort === null) return 1;
    if (b.sort === null) return -1;
    return a.sort - b.sort
}

export const sortFoodComponents = (list) => {
    return list.sort(sortFunc).map(component => ({
        ...component,
        items: [...component.items].sort(sortFunc)
        })
    )
}