import { useQuery } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react'
import { foodApi } from '../../../../../../apis/Food/api';
import { foodFormTabs } from '../../../../../../data/foodFormTabs';
import EntreesTable from './Table';

const Entrees = ({foodType, initialValues, setValue}) => {

  const [selectedCategory, setSelectedCategory] = useState(null);

  const [categories, setCategories] = useState(null);

  const [data, setData] = useState(null);

  const { refetch } = useQuery(['entrees'], () => foodApi.entress.getAll({
    lang: localStorage.getItem("I18N_LANGUAGE") || 'en'
  }), {
    cacheTime: 0,
    enabled: false,
    onSuccess({data: {data}}) {
      setCategories(data)
    }
  })

  useEffect(() => {
    if (categories) {
      setData(categories.map(category => ({
          category_id: category.id,
          name: category.name,
          entrees: category.foods?.map(item => {
            const initialVal = initialValues?.entries?.find(entry => entry.entry_id === item.id);
            if (initialValues?.type_id === foodType) return {
              id: item.id,
              name: item.name,
              selected: !!initialVal || false,
              default_item: initialVal?.default_item || false,
              has_sizes: !!item.has_sizes,
              ...(item.has_sizes ? {
                entrees_sizes: item.entrees_sizes?.map(size => {
                  const existingSize = initialVal?.entrees_sizes?.find(item => item.size_id === size.id);
                  return {
                    size_id: size.id,
                    size: size.size,
                    price: existingSize?.price ?? (size.price || 0),
                    selected: !!existingSize,
                    default_size: existingSize?.default_size ?? false
                  }
                })
              } : {
                price: initialVal?.price || null,
              }),
            }

            return {
              id: item.id,
              name: item.name,
              selected: false,
              default_item: false,
              has_sizes: !!item.has_sizes,
              ...(item.has_sizes ? {
                entrees_sizes: item.entrees_sizes?.map(size => {
                  return {
                    size_id: size.id,
                    size: size.size,
                    price: size.price || 0,
                    selected: false,
                    default_size: false
                  }
                })
              } : {
                price: 0,
              }),
            }
          })
        })
      ))
    }
  }, [categories, foodType, initialValues])

  useEffect(() => {
      if (data) {
        setValue('entries', data.reduce((accum, category) => {
          const filteredItems = category.entrees.filter(entree => {
            if (entree.has_sizes) return entree.selected && entree.entrees_sizes.some(size => size.selected);
            return entree.selected
          });
          return [...accum, ...filteredItems];
        }, []).map(item => ({
          entry_id: item.id,
          has_sizes: item.has_sizes,
          default_item: item.default_item,
          ...((item.has_sizes && item.entrees_sizes) ? 
          {
            entrees_sizes: item.entrees_sizes?.filter(entree_size => entree_size.selected).map(entree_size => {
              const { size, ...rest } = entree_size;
              return rest
            })
          }
          : 
            {
              price: item.price || '0'
            }
          )
        })))
      } else {
        setValue('entries', null)
      }
  }, [data])

  
  useEffect(() => {
    if (foodFormTabs.find(item => item.id === foodType)?.tabs?.some(tab => tab.value === 'entrees')) {
      refetch()
    } else {
      setValue('entries', null);
    }
  }, [foodType])

  useEffect(() => {
    if (categories?.length) {
      setSelectedCategory(categories[0].id);
    }
  }, [categories])

  return (

    <div>
      <div>
        <div className='selectable-tabs-container'>
          {categories && categories.map(category => (
              <div
                key={category.id} 
                className={`selectable-tab heading-tab ${selectedCategory === category.id ? 'selected-tab' : ''} py-1`}
                onClick={() => setSelectedCategory(category.id)}
              >
                {category.name}
              </div>
            ))}
        </div>
      </div>

      {data?.map(category => (
          <EntreesTable 
            key={category.category_id}
            categoryId={category.category_id}
            selectedCategory={selectedCategory}
            data={category.entrees}
            setData={setData}
          />
        ))
      }

  </div>
  )
}

export default Entrees