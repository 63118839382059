import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CardHeader, Modal } from "reactstrap";
import useCreateUpdateModal from "../../../../hooks/useCreateUpdateModal";
import useSelectItems from "../../../../hooks/useSelectItems";
import DataTableContainer from "../../../Common/DataTableContainer";
import CustomButton from "../../../Custom/CustomButton";
import CustomTableHeading from "../../../Custom/CustomTableHeading";
import SpecialDayModal from "./SpecialDayModal";
import generateUniqueID from "../../../../helpers/generateUniqueID";

const BranchSpecialDays = ({ data, setValue }) => {
  
  const { t } = useTranslation();

  const [specialDays, setSpecialDays] = useState([]);

  const { isModalOpen, toggleModal, onAddClick, onUpdateClick, initValues, type } = useCreateUpdateModal();

  const { getSelectedIds, selectItems } = useSelectItems();

  const onDelete = async () => {
    const ids = getSelectedIds();
    setSpecialDays((current) => current.filter((item) => !ids.includes(item.id)));
  };

  const columns = [
    {
      name: <CustomTableHeading>From</CustomTableHeading>,
      selector: (row) => row.date_from,
    },
    {
      name: <CustomTableHeading>To</CustomTableHeading>,
      selector: (row) => row.date_to,
    },
    {
      name: <CustomTableHeading>Start</CustomTableHeading>,
      selector: (row) => row.time_start,
    },
    {
      name: <CustomTableHeading>End</CustomTableHeading>,
      selector: (row) => row.time_end,
    },
    {
      name: <CustomTableHeading>Open</CustomTableHeading>,
      selector: (row) => t(row.open ? "Yes" : "No"),
    },
  ];

  useEffect(() => {
    setValue('dates', specialDays.map(day => {
      const { id, ...rest } = day;
      return rest;
    }))
  }, [specialDays])

  useEffect(() => {
    if (data) {
      setSpecialDays(data.dates.map(date => ({
        id: generateUniqueID(),
        ...date
      })) ?? [])
    }
  }, [data])

  return (
    <>
      <CardHeader>
        <div className="d-flex align-items-center justify-content-end">
          <div className="d-flex align-items-center gap-2">

            <CustomButton onClick={onAddClick} size="sm">
              Create
            </CustomButton>

            <CustomButton
              color="danger"
              size="sm"
              onClick={onDelete}
            >
              Delete
            </CustomButton>

          </div>
        </div>
      </CardHeader>

      <DataTableContainer
        columns={columns}
        selectableRows
        selectItems={selectItems}
        handleClick={(row) => { onUpdateClick(row) }}
        data={specialDays}
      />

     <Modal isOpen={isModalOpen} toggle={toggleModal} size="xl" centered>
        <SpecialDayModal 
            toggle={toggleModal}
            initValues={initValues}
            type={type}
            setData={setSpecialDays}
        />
     </Modal>

    </>
  );
};

export default BranchSpecialDays;
