import React, { useState } from 'react'
import { Col, Row } from 'reactstrap';
import CustomLabel from '../../Custom/CustomLabel';
import CustomInput from '../../Custom/CustomInput';
import { deleteMenuPage, updateMenuPage } from '../../../apis/Settings/menuApi';
import toastAlert from '../../../helpers/toastAlert';
import CustomButton from '../../Custom/CustomButton';
import useDeleteModal from '../../../hooks/useDeleteModal';
import DeleteModal from '../../Common/DeleteModal';

const VideoPage = ({ setPages, page, openUpdateModal, focusFirstPage }) => {

    const [isSaving, setIsSaving] = useState(false);

    const { isLoading: isDeleting, setModal, load, callback: closeModal, modal: isModalOpen } = useDeleteModal();

    const handleLinkChange = (value) => {
        setPages(prev => ({
            ...prev,
            video: prev.video.map(pg => {
                if (pg.id === page.id) return {
                    ...pg,
                    link: value
                }
                return pg
            })
        }))
    }

    const handleSave = async () => {
        try {
            setIsSaving(true);
            const requestBody = {
                page_id: page.id,
                name: page.name,
                type: page.type,
                link: page.link
            }
            const { data: { data: resData }} = await updateMenuPage({
                pages: [requestBody]
            })
            console.log('resData', resData);
            toastAlert({ type: 'success' })
        } catch (error) {
            toastAlert({ type: 'error' })
        } finally {
            setIsSaving(false);
        }
    }

    const deletePage = async () => {
        try {
            load(true);
            await deleteMenuPage(page.id);
            setPages(prev => ({
                ...prev,
                video: prev.video.filter(pg => pg.id !== page.id)
            }));
            toastAlert({ type: 'success' })
        } catch (error) {
            toastAlert({ type: 'error' })
        } finally {
            closeModal();
            focusFirstPage({ ignoreCurrentActive: true });
        }
        
    }

  return (
    <div>

        <DeleteModal 
            show={isModalOpen}
            onDeleteClick={deletePage}
            isDeleting={isDeleting}
            onCloseClick={() => setModal(false)}
        />

        <Row>
            <Col lg={12} className='d-flex justify-content-end'>
                <div className='d-flex justify-content-end gap-2'>

                    <CustomButton
                        type="button"
                        className="btn-success"
                        onClick={handleSave}
                        isLoading={isSaving}
                    >
                        Save
                    </CustomButton>

                    <CustomButton
                        type="button"
                        className="btn-dark"
                        onClick={() => openUpdateModal('update', page)}
                    >
                        <i className="ri-edit-line fs-16"></i>
                    </CustomButton>

                    <CustomButton
                        type="button"
                        className="btn-danger"
                        onClick={() => setModal(true)}
                    >
                        Delete
                    </CustomButton>
                </div>
            </Col>
        </Row>
        <Row>
            <Col md={4}>
                <CustomLabel className='fs-16'>
                    Video URL
                </CustomLabel>
                <CustomInput 
                    type="text"
                    value={page.link ?? ''}
                    onChange={(e) => handleLinkChange(e.target.value)}
                />
            </Col>
        </Row>
    </div>
  )
}

export default VideoPage