import { useQuery } from '@tanstack/react-query'
import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import BreadCrumb from '../../../Components/Common/BreadCrumb'
import PageContainer from '../../../Components/Layout/PageContainer'
import { inventoryApi } from '../../../apis/Inventory/api'
import InventoryOrderForm from '../../../Components/Inventory/Orders/Form'

const UpdateInventoryOrder = () => {

  const { id } = useParams();

  const [initialValues, setInitialValues] = useState(null);

  const { isLoading } = useQuery(['one-product'], () => inventoryApi.orders.getOne(id), {
    onSuccess({data: { data }}) {
      setInitialValues(data);
    }
  })

  return (
    <PageContainer isLoading={isLoading}>
      <BreadCrumb pageTitle="Orders" title="Update" to="inventory/orders"/>
      {initialValues &&
        <InventoryOrderForm 
          type="update"
          initialValues={initialValues}
        />
      }
    </PageContainer>
  )
}

export default UpdateInventoryOrder