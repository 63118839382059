import axiosInstance from "..";

export const getAllCouponsApi = () => {
  return axiosInstance.get("/coupons", {
    params: {
      page_size: "all",
    },
  });
};

export const getOneCouponApi = (id) => {
  return axiosInstance.get(`/coupons/${id}`);
};

export const createCouponApi = (body) => {
  return axiosInstance.post("/coupons", body);
};

export const updateCouponApi = (id, body) => {
  return axiosInstance.post(`/coupons/${id}`, body);
};

export const deleteCouponsApi = (ids) => {
  return axiosInstance.delete("/coupons", {
    data: {
      ids,
    },
  });
};
