import axios from "axios";

import API_URL from "../config/api";

const addAuth = (config) => {
  if (config.headers) {
    config.headers.Authorization = `Bearer ${localStorage.getItem("restaurant_dash")}`;
  }
  return config;
}

const errorBoundary = (error) => {
  return Promise.reject(error);
}


const axiosInstance = axios.create({
  baseURL: `${API_URL}/api/v1`,
});

export const axiosInstanceV2 = axios.create({
  baseURL: `${API_URL}/api/v2`,
});

axiosInstance.interceptors.request.use(addAuth, errorBoundary);

axiosInstanceV2.interceptors.request.use(addAuth, errorBoundary);

export default axiosInstance;

 
