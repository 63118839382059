import React, { useRef, useState } from 'react'
import GalleryImage from '../../Common/GalleryImage';
import { addImageToGallery } from '../../../apis/Settings/galleryApi';
import { Spinner } from 'reactstrap';
import toastAlert from '../../../helpers/toastAlert';

const GalleryImages = ({ initialValues }) => {

  const [images, setImages] = useState(initialValues.details.map(detail => ({
    id: detail.id,
    link: detail.image,
    active: detail.active
  })));

  const [isUploading, setIsUploading] = useState();

  const fileInputRef = useRef(null);

  const handleImageClick = () => {
    fileInputRef.current.click();
  };

  const uploadImage = async (e) => {
    const file = e.target.files[0];
    if (!file || isUploading) return;
    try {
      setIsUploading(true);
      const formData = new FormData();
      const blob = new Blob([file], { type: file.type });
      formData.append('image', blob);
      const { data: { data } } = await addImageToGallery(initialValues.id, formData);
      setImages(data.details.map(detail => ({
        id: detail.id,
        link: detail.image,
        active: detail.active
      })));
      toastAlert({ type:'success' })
    } catch (error) {
      toastAlert({ type:'error' })
    } finally {
      setIsUploading(false);
    }
  }

  return (
    <div className='d-flex gap-4 flex-wrap'>
       {images.map(image => (
        <div key={image.id} className='d-flex flex-column align-items-center gap-3'>
          <GalleryImage 
            image={image}
            setImages={setImages}
          />
        </div>
       ))}
        <div className={`image-input-container ${isUploading ? 'loading' : ''}`}>
          {isUploading && 
              <div className='loading-indicator'>
                  <Spinner />
              </div>
          }
              <div 
                  className='placeholder-container'
                  onClick={handleImageClick}
              >
                  <i className="ri-image-2-line"></i>
                  <i className="ri-add-line add-image-button"></i>
              </div>

              <input
                  type="file"
                  accept="image/*"
                  style={{ display: 'none' }}
                  ref={fileInputRef}
                  onChange={uploadImage}
              />
        </div>
    </div>
  )
}

export default GalleryImages