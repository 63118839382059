import { useQuery } from '@tanstack/react-query'
import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { foodApi } from '../../../apis/Food/api'
import BreadCrumb from '../../../Components/Common/BreadCrumb'
import PageContainer from '../../../Components/Layout/PageContainer'
import { useFetchByLang } from '../../../hooks/useFetchByLang'
import OfferForm from '../../../Components/Food/Offers/Form'

const UpdateOffer = () => {

  const { id } = useParams();

  const [initialValues, setInitialValues] = useState(null);

  const { isLoading, refetch } = useQuery(['one-offer'], () => foodApi.offers.getOne(id, language), {
    enabled: false,
    cacheTime: 0,
    onSuccess({data: {data}}) {
      setInitialValues(data);
    }
  })
  
  const { language, setLanguage } = useFetchByLang(refetch);

  return (
    <PageContainer isLoading={isLoading}>
      <BreadCrumb pageTitle="Offers" title="Update" to="food/offers"/>
      {initialValues &&
        <OfferForm 
          type="update"
          initialValues={initialValues}
          setInitialValues={setInitialValues}
          refetch={refetch}
          language={language}
          setLanguage={setLanguage}
        />
      }
    </PageContainer>
  )
}

export default UpdateOffer