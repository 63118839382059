import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

export const useFetchByLang = (refetch) => {

    const { activeLang } = useSelector((state) => state.language);

    const [language, setLanguage] = useState(null);

  useEffect(() => {
    if (language && refetch) {
      refetch();
    }
  }, [language])

  useEffect(() => {
    setLanguage(activeLang)
  }, [activeLang])

  return { language, setLanguage }

}