import React from 'react'
import { Draggable } from 'react-beautiful-dnd'
import { Card } from 'reactstrap'
import CustomLabel from '../../Custom/CustomLabel'
import CustomTextArea from '../../Custom/CustomTextArea'
import CustomInput from '../../Custom/CustomInput'
import CustomButton from '../../Custom/CustomButton'

const DraggableCategoryItem = ({ item, index, handleItemChange }) => {

  return (
    <Draggable draggableId={`category-item-${item.id}`} index={index}>
        {provided => {
            return (
                <div    
                    className='my-3'       
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                >
                     <Card className='category-item p-2'>

                        <div className='d-flex gap-2 align-items-center'>
                            <span>
                                <i className="ri-menu-line"></i>
                            </span>

                            {item.name}

                            <CustomButton style={{ marginLeft: 'auto' }} className={`d-flex gap-2 align-items-center ${item.show ? 'btn-soft-success' : 'btn-soft-danger'}`} onClick={() => handleItemChange(item.id, 'show', !item.show)}>
                                {item.show ? 

                                <>
                                    <i className="ri-eye-line"></i>
                                    <span>Visible</span>
                                </> : 

                                <>
                                    <i className="ri-eye-off-line"></i>
                                    <span>Hidden</span>
                                </>
                                }
                            </CustomButton>
                  
                        </div>

                       <div className='category-item-content'>
                            <div className='mt-3 p-3 d-flex flex-column gap-2'>

                                <div>
                                    <CustomLabel>
                                        Subtitle
                                    </CustomLabel>
                                    <CustomInput 
                                        value={item.subtitle ?? ''}
                                        onChange={(e) => handleItemChange(item.id, 'subtitle', e.target.value)}
                                    />
                                </div>

                                <div>
                                    <CustomLabel>Description</CustomLabel>
                                    <CustomTextArea
                                        type="text"
                                        value={item.description ?? ''}
                                        onChange={(e) => handleItemChange(item.id, 'description', e.target.value)}
                                    />
                                </div>

                                
                                <div>
                                    <CustomLabel>Price</CustomLabel>
                                    <CustomInput
                                        value={item.price ?? ''}
                                        onChange={(e) => handleItemChange(item.id, 'price', e.target.value)}
                                    />
                                </div>

                            </div>
                       </div>
                     </Card>
                </div>
            )
        }}
    </Draggable>
  )
}

export default DraggableCategoryItem