import React from 'react'
import BreadCrumb from '../../../Components/Common/BreadCrumb'
import SizesForm from '../../../Components/Food/Sizes/Form'
import PageContainer from '../../../Components/Layout/PageContainer'
import { useFetchByLang } from '../../../hooks/useFetchByLang'

const CreateSize = () => {

  const { language, setLanguage } = useFetchByLang();
    
  return (
    <PageContainer>
      <BreadCrumb pageTitle="Sizes" title="Create" to="food/sizes" />
      <SizesForm 
        type="create"
        language={language}
        setLanguage={setLanguage}
      />
    </PageContainer>
  )
}

export default CreateSize