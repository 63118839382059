import React from "react";
import { useTranslation } from "react-i18next";

const OrderDeliveryItem = ({ prop, value }) => {
  const { t } = useTranslation();
  return value ? (
    <p className="mb-0">
      {t(prop)} : <span className="text-muted">{value}</span>
    </p>
  ) : (
    <></>
  );
};

export default OrderDeliveryItem;
