export const FILTER_TYPES = {
  LT: "lt",
  GT: "gt",
  GTE: "gte",
  LTE: "lte",
  EQ: "eq",
  MATCH: "match",
  IN: "in",
};

const filters = {
  name: {
    key: "age",
    value: 20,
    type: FILTER_TYPES.LT,
  },
};

export const getValueByKey = (object, key) => {
  let splittedKeys = key.split(".");
  if (splittedKeys.length === 0) {
    return object[key];
  }

  let value = object[splittedKeys[0]];
  for (let i = 1; i < splittedKeys.length; i++) {
    const item = splittedKeys[i];
    value = value[item];
  }

  return value;
};

export default function useFilterData(data, filters = {}) {
  const items = [...data];

  return items.filter((item) => {
    return Object.keys(filters).every((filter) => {
      const value = getValueByKey(item, filters[filter].key);
      const filterValue = filters[filter].value;
      const type = filters[filter].type;

      if (
        filterValue === "" ||
        filterValue === null ||
        filterValue === undefined
      )
        return true;

      if (type === FILTER_TYPES.MATCH) {
        return value?.toLowerCase().includes(filterValue.toLowerCase());
      }

      if (type === FILTER_TYPES.EQ) {
        return value === filterValue;
      }

      if (type === FILTER_TYPES.LT) {
        return value < filterValue;
      }

      if (type === FILTER_TYPES.GT) {
        return filterValue < value;
      }

      if (type === FILTER_TYPES.GTE) {
        return filterValue <= value;
      }

      if (type === FILTER_TYPES.LTE) {
        return filterValue >= value;
      }

      if (type === FILTER_TYPES.IN) {
        return filterValue.includes(value);
      }

      return false;
    });
  });
}
