import { isAllOf } from '@reduxjs/toolkit'
import React from 'react'
import { useSelector } from 'react-redux'
import { isAllowed } from '../../../helpers/isAllowed'
import { getPathname } from '../../../Layouts/LayoutMenuData'
import DataTableContainer from '../../Common/DataTableContainer'
import CustomInput from '../../Custom/CustomInput'
import CustomTableHeading from '../../Custom/CustomTableHeading'

const SpiceLevelsTable = ({navigateToPage, pageSize, setPageSize, setSelectedItems, modal, setModal, isLoading, handleDelete, loading, name, setName}) => {

    const { spiceLevels } = useSelector(state => state.food);

    const {user} = useSelector(state => state.admin);

    const columns = [
        {
            name: <CustomTableHeading>ID</CustomTableHeading>,
            selector: row => row.id || '-',
            grow: -1,
        },

        {
            name: 
            <CustomInput 
                placeholder='Name' 
                type="text" 
                value={name || ''}
                onChange={(e) => setName(e.target.value)}
                data-filtered={!!name}
            />,
            selector: row => row.name || '-',
        },

        {
            name: <CustomTableHeading>Level</CustomTableHeading>,
            selector: row => row.level || '-',
        },
        
    ]


  return (
    <DataTableContainer 
        loading={loading}
        data={spiceLevels.list}
        columns={columns}
        paginationServer
        paginationTotalRows={spiceLevels.total && spiceLevels.total}
        path={getPathname('food/spice-levels')}
        navigateToPage={navigateToPage}
        pageSize={pageSize}
        setPageSize={setPageSize}
        selectableRows={isAllowed(user)}
        selectItems={setSelectedItems}
        modal={modal}
        setModal={setModal}
        isDeleting={isLoading}
        handleDelete={handleDelete}
    />
  )
}

export default SpiceLevelsTable