import { Draggable } from 'react-beautiful-dnd'
import React, { useState } from 'react'
import CustomLabel from '../../Custom/CustomLabel'
import CustomTextArea from '../../Custom/CustomTextArea'
import CustomInput from '../../Custom/CustomInput'
import { updateFoodMenuImage } from '../../../apis/Settings/menuApi'
import SelectFile from '../../Common/SelectFile'
import toastAlert from '../../../helpers/toastAlert'

const SliderItem = ({ isDragDisabled, item, handleChange, index }) => {

    const [uploadingImage, setUploadingImage] = useState(false);

    const uploadImage = (itemId, image) => {
        const formData = new FormData();
        const file = image.file;
        const blob = new Blob([file], { type: file.type });
        formData.append("image", blob, file.name);
        formData.append("is_image_transparent", 0);
        return updateFoodMenuImage(itemId, formData);
    }
    
    const handleImageChange = async (image) => {
        try {
            let imageData = {
                id: null,
                url: '',
            };
            if (image.file) {
                setUploadingImage(true);
                const { data: { data } } = await uploadImage(item.id, image);
                imageData = {
                    id: data.id,
                    url: data.link,
                };
            }
            handleChange(item.id, 'image', imageData);
        } catch (error) {
            toastAlert({ type: 'error' })
        } finally {
            setUploadingImage(false);
        }
    }

  return (
    <Draggable isDragDisabled={isDragDisabled} draggableId={`slider-item-${item.id}`} index={index} key={`slider-item-${item.id}`}>
        {provided => (
            <div    
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                className='p-3 border'
            >
                <div className='d-flex flex-column gap-2'>
                    
                    <div className="form-check form-switch">
                        <input 
                            className="form-check-input fs-16" 
                            type="checkbox" 
                            role="switch"
                            checked={item.active ?? false}
                            onChange={(e) => handleChange(item.id, 'active', e.target.checked)} 
                        />
                        <CustomLabel>{item.name}</CustomLabel>
                    </div>

                    <div>
                        <CustomLabel>Menu Image</CustomLabel>
                        <SelectFile
                            selectedFiles={item.image}
                            setSelectedFiles={(newVal) => handleImageChange(newVal)}
                            type='image'
                            loading={uploadingImage}
                        />
                    </div>

                    <div>
                        <CustomLabel>Subtitle</CustomLabel>
                        <CustomInput 
                            value={item.subtitle ?? ''}
                            onChange={(e) => handleChange(item.id, 'subtitle', e.target.value)}
                        />
                    </div>

                    <div>
                        <CustomLabel>Description</CustomLabel>
                        <CustomTextArea 
                            value={item.description ?? ''}
                            onChange={(e) => handleChange(item.id, 'description', e.target.value)}
                        />
                    </div>

                </div>
            </div>
        )}
    </Draggable>
  )
}

export default SliderItem