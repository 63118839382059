import React from 'react'
import BreadCrumb from '../../../Components/Common/BreadCrumb'
import PageContainer from '../../../Components/Layout/PageContainer'
import ProductUnitForm from '../../../Components/Inventory/ProductUnits/Form'

const CreateProductUnit = () => {
    
  return (
    <PageContainer>
      <BreadCrumb pageTitle="Units" title="Create" to="inventory/units"/>
      <ProductUnitForm 
        type="create"
      />
    </PageContainer>
  )
}

export default CreateProductUnit