import axiosInstance from "..";

const sortApis = {
  slider: (body) => {
    return axiosInstance.post("/slider/sort", body);
  },
  pages: (body) => {
    return axiosInstance.post("/pages/sort", body);
  },
  gallery: (body) => {
    return axiosInstance.post("/gallries/sort", body);
  },
};

export default sortApis;
