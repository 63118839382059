import React, { useEffect, useState } from 'react'
import { foodFormTabs } from '../../../../../../data/foodFormTabs';
import FoodDays from './Days/Days';
import FoodDates from './Dates/Dates';

const DateTime = ({ foodType, initialValues, setValue }) => {

  const [daysData, setDaysData] = useState(null);
  const [datesData, setDatesData] = useState(null);

  useEffect(() => {
    if (!foodFormTabs.find(item => item.id === foodType)?.tabs?.some(tab => tab.value === 'dateTime')) {
        setValue('date_time', null);
    } 
  }, [foodType])

  useEffect(() => {
    if (initialValues) {
        setDaysData(initialValues?.days);
        setDatesData(initialValues?.dates);
    }
  }, [initialValues])

  useEffect(() => {
    if (daysData) {
        setValue('days', daysData);
    } else {
        setValue('days', null);
    }
  }, [daysData])

  useEffect(() => {
    if (datesData) {
        setValue('dates', datesData);
    } else {
        setValue('dates', null);
    }
  }, [datesData])


  return (
    <div className='d-flex flex-column gap-3'>

        <div className='p-3 border'>
          <FoodDays 
              data={daysData}
              setData={setDaysData}
          />
        </div>

        <div className='p-3 border'>
          <FoodDates 
              data={datesData}
              setData={setDatesData}
          />
        </div>
    </div>
  )
}

export default DateTime