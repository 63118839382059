import React, { useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { Card, Col, Row } from 'reactstrap'
import BreadCrumb from '../../../Components/Common/BreadCrumb'
import PageContainer from '../../../Components/Layout/PageContainer'
import useDeleteModal from '../../../hooks/useDeleteModal'
import usePagination from '../../../hooks/usePagination'
import { getPathname } from '../../../Layouts/LayoutMenuData'
import debounce from "lodash.debounce";
import { objectFilter } from '../../../helpers/objectFilter'
import { useLocalStorage } from '../../../hooks/useLocalStorage'
import Restricted from '../../../Components/Guard/Restricted'
import CreateButton from '../../../Components/Common/Buttons/CreateButton'
import DeleteButton from '../../../Components/Common/Buttons/DeleteButton'
import { useGetTableServices } from '../../../hooks/useQueryHelpers'
import TableServicesTable from '../../../Components/Orders/TableServices/Table'
import { deleteTableService } from '../../../apis/Orders/tableServicesApi'

const TableServices = () => {

  const { currentPage, navigateToPage } = usePagination();

  const {selectedItems, setSelectedItems, load, isLoading, modal, setModal, callback} = useDeleteModal();

  const [name, setName] = useState(null);

  const {values: {
      tableServicesFilter: localFilterValues
  }, setNewValues: setLocalFilterValues} = useLocalStorage({
      tableServicesFilter: {
        page_size: 10
      }
  })
  
  const { tableServices, refetch, isLoading: loading, isRefetching: refetching } = useGetTableServices(objectFilter({
    page: currentPage,
    ...localFilterValues,
    name
  }));

  const setPageSize = (val) => {
    setLocalFilterValues({
      tableServicesFilter: {
          page_size: val
        }
    })
  }

  const handleDelete = async () => {
    load();
    const ids = selectedItems.map(item => item.id);
    await deleteTableService({
      ids
    })
    await refetch();
    callback();
  }

  const debounceSearch = useMemo(() => {
    return debounce(() => refetch(true), 200);
  }, [localFilterValues, currentPage, name]);

  useEffect(() => {
    if (name !== null) debounceSearch();
  }, [name])

  useEffect(() => {
    refetch();
  }, [localFilterValues, currentPage])

  return (
    <PageContainer>
        <BreadCrumb pageTitle="Orders" title="Table Services"/>

        <Row className='justify-content-between gy-3'>
          <Col md={3} xs={12} className="d-flex gap-2">

            <Link to={getPathname('orders/table-services/create')}>
              <CreateButton />
            </Link>

            <Restricted allowed={['super-admin', 'developer']}>
              <DeleteButton 
                  disabled={!selectedItems.length}
                  onClick={() => setModal(true)}
              />
            </Restricted>

          </Col>
        </Row>

        <Card className='mt-3'>
          <TableServicesTable 
              loading={loading || refetching}
              navigateToPage={navigateToPage}
              pageSize={localFilterValues.page_size}
              setPageSize={setPageSize}
              setSelectedItems={setSelectedItems}
              modal={modal}
              setModal={setModal}
              isLoading={isLoading}
              handleDelete={handleDelete}
              name={name}
              setName={setName}
              data={tableServices}
          />
        </Card>

    </PageContainer>
  )
}

export default TableServices