import { useQuery } from "@tanstack/react-query";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { foodApi } from "../../../apis/Food/api";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import CategoryForm from "../../../Components/Food/Categories/Form";
import PageContainer from "../../../Components/Layout/PageContainer";
import { useFetchByLang } from "../../../hooks/useFetchByLang";

const CreateCategory = () => {

  const { id } = useParams();

  const [initialValues, setInitialValues] = useState(null);

  const { isLoading, isRefetching, refetch } = useQuery(["one-category", id], () => foodApi.categories.getOne(id, language),
    {
      enabled: false,
      cacheTime: 0,
      onSuccess({ data: { data } }) {
        setInitialValues({
          ...data,
          seo_title: data.seo_title || "",
          seo_keywords: data.seo_keywords || "",
          seo_description: data.seo_description || "",
        });
      },
    }
  );

  const { language, setLanguage } = useFetchByLang(refetch);

  return (
    <PageContainer isLoading={isLoading || isRefetching}>
      <BreadCrumb pageTitle="Categories" title="Update" to="food/categories" />
      {initialValues && (
        <CategoryForm 
          type="update" 
          initialValues={initialValues} 
          language={language} 
          setLanguage={setLanguage}
        />
      )}
    </PageContainer>
  );
};

export default CreateCategory;
