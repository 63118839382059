import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "admin",
  initialState: {
    token: localStorage.getItem("restaurant_dash") || null,
    user: null,
  },
  reducers: {
    login: (state, { payload }) => {
      localStorage.setItem("restaurant_dash", payload.token);
      state.token = payload.token;
      state.user = payload.user;
    },
    getAdminData: (state, { payload }) => {
      state.user = payload;
    },
    logout: (state) => {
      localStorage.removeItem("restaurant_dash");
      state.token = null;
      state.user = null;
    },
  },
});

const adminReducer = slice.reducer;

export default adminReducer;

export const adminActions = slice.actions;
