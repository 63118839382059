import React from "react";
import DataTableContainer from "../../Common/DataTableContainer";
import StatusToggle from "../../Common/StatusToggle";
import CustomTableHeading from "../../Custom/CustomTableHeading";

const GalleriesTable = ({
  data,
  modal,
  setModal,
  isDeleting,
  handleDelete,
  selectItems,
}) => {
  
  const columns = [
    {
      name: <CustomTableHeading>Name</CustomTableHeading>,
      selector: (row) => row.name || "-",
    },

    {
      name: <CustomTableHeading>Active</CustomTableHeading>,
      selector: (row) => <StatusToggle type='galleries' id={row.id} field="active" status={row.active} />,
    },
  ];

  return (
    <DataTableContainer
      data={data}
      columns={columns}
      modal={modal}
      setModal={setModal}
      isDeleting={isDeleting}
      handleDelete={handleDelete}
      selectItems={selectItems}
      path="gallery"
      selectableRows
      noPagination
    />
  );
};

export default GalleriesTable;
