import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";

//redux
import { useSelector } from "react-redux";
import { useLocalStorage } from "../hooks/useLocalStorage";

const NonAuthLayout = ({ children }) => {

  const {values: {layoutModeTypes: localLayoutModeValues}} = useLocalStorage({
        layoutModeTypes: {
            theme: 'LIGHTMODE'
        }
    })
  useEffect(() => {

    if (localLayoutModeValues.theme === "DARKMODE") {
      document.body.setAttribute("data-layout-mode", "dark");
    } else {
      document.body.setAttribute("data-layout-mode", "light");
    }
    return () => {
      document.body.removeAttribute("data-layout-mode");
    };
  }, [localLayoutModeValues]);

  return <div>{children}</div>;
};

export default withRouter(NonAuthLayout);
