import React from 'react'
import BreadCrumb from '../../../Components/Common/BreadCrumb'
import PageContainer from '../../../Components/Layout/PageContainer'
import TableForm from '../../../Components/Orders/Tables/Form'

const CreateTable = () => {

  return (
    <PageContainer>
        <BreadCrumb pageTitle="Tables" title="Create" to="orders/tables"/>
        <TableForm 
          type="create"
        />
    </PageContainer>
  )
}

export default CreateTable