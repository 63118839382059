import React from "react";
import { ReactSortable } from "react-sortablejs";
import { Card, CardBody, CardFooter } from "reactstrap";
import toastAlert from "../../helpers/toastAlert";
import useActions from "../../hooks/useActions";
import CustomButton from "../Custom/CustomButton";
import sortApis from "../../apis/Sort/sortApi";

const SortableList = ({
  list,
  setList,
  mainTitle = "name",
  key,
  // isLoading,
  // onClick,
}) => {
  const { isLoading, onLoading, onFinally } = useActions();
  const onSubmit = async () => {
    onLoading();
    const sortedList = list.map((item, index) => {
      return {
        ...item,
        sort: index + 1,
      };
    });
    try {
      // await sortApis['gallery']({
      //   data: sortedList
      // })
      toastAlert({ type: 'success' });
    } catch (error) {
      toastAlert({ type: 'error' });
    } finally {
      onFinally();
    }
  };
  return (
    <Card>
      <CardBody>
        <ReactSortable list={list} setList={(data) => setList(data)}>
          {list.map((item) => {
            return (
              <div
                key={item.id}
                className="d-flex align-items-center gap-2 border px-1 py-1 my-1 cursor-pointer"
              >
                <i className="ri-list-ordered"></i>

                <span className="">{item[mainTitle]}</span>
              </div>
            );
          })}
        </ReactSortable>
      </CardBody>
      <CardFooter>
        <CustomButton color="success" isLoading={isLoading} onClick={onSubmit}>
          Save
        </CustomButton>
      </CardFooter>
    </Card>
  );
};

export default SortableList;
