import React from "react";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import PageContainer from "../../../Components/Layout/PageContainer";
import { useFetchByLang } from "../../../hooks/useFetchByLang";
import BranchForm from "../../../Components/Settings/Branches/Form";

const CreateBranch = () => {

  const {language, setLanguage} = useFetchByLang();

  return (
    <PageContainer>
      <BreadCrumb pageTitle="Branches" title="Create" to="settings/branches" />
      <BranchForm 
        type="create" 
        language={language}
        setLanguage={setLanguage}
      />
    </PageContainer>
  );
};

export default CreateBranch;
