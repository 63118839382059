import React from "react";
import CustomInput from "../../Custom/CustomInput";
import CustomLabel from "../../Custom/CustomLabel";
import CommonForm from "./CommonForm";

const AdminTab = ({ values, handleChange }) => {
  return (
    <CommonForm
      handleChange={handleChange}
      values={values}
      name="admin"
      checkboxName="admin_send_sms"
      inputName="admin_sms_text"
      keywordsName="admin_sms_keywords"
      afterChildren={
        <>
          <CustomLabel>Phone</CustomLabel>
          <CustomInput
            name="admin_mobile"
            value={values["admin_mobile"]}
            onChange={handleChange("admin")}
          />
        </>
      }
    />
  );
};

export default AdminTab;
