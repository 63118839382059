import React from "react";
import CommonForm from "./CommonForm";

const ActiveMobileTab = ({ values, handleChange }) => {
  return (
    <CommonForm
      handleChange={handleChange}
      values={values}
      name="activeMobile"
      hideCheckbox
      inputName="active_mobile_sms"
      keywordsName="active_mobile_keywords"
    />
  );
};

export default ActiveMobileTab;
