import React from 'react'
import DataTableContainer from '../../../../../../Common/DataTableContainer'
import CustomTableHeading from '../../../../../../Custom/CustomTableHeading'
import { days } from '../../../../../../../data/days'
import StatusIcon from '../../../../../../Common/StatusIcon'

const FoodDaysTable = ({ data, setSelectedItems, modal, setModal, handleDelete, openFormModal }) => {

    const columns = [

      {
        name: <CustomTableHeading>Day</CustomTableHeading>,
        selector: row => days.find(item => +item.id === +row.day_id)?.day,
      },

    
      {
        name: <CustomTableHeading>From</CustomTableHeading>,
        selector: row => row.start || '-',
      },

      {
        name: <CustomTableHeading>To</CustomTableHeading>,
        selector: row => row.end || '-',
      },

      {
        name: <CustomTableHeading>Active</CustomTableHeading>,
        selector: row => <StatusIcon status={row.active} />,
      },

    ]

  return (
    <DataTableContainer 
      data={data || []}
      columns={columns}
      selectableRows
      selectItems={setSelectedItems}
      modal={modal}
      setModal={setModal}
      handleDelete={handleDelete}
      handleClick={row => openFormModal('update', row)}
    />
  )
}

export default FoodDaysTable