import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { Card, Col, Row } from 'reactstrap'
import BreadCrumb from '../../../Components/Common/BreadCrumb'
import CustomButton from '../../../Components/Custom/CustomButton'
import CustomSearchInput from '../../../Components/Custom/CustomSearchInput'
import CategoriesTable from '../../../Components/Food/Categories/Table'
import PageContainer from '../../../Components/Layout/PageContainer'
import useDeleteModal from '../../../hooks/useDeleteModal'
import usePagination from '../../../hooks/usePagination'
import { getPathname } from '../../../Layouts/LayoutMenuData'
import { deleteFoodAction, getFoodDataAction } from '../../../redux/actions/foodActions'
import debounce from "lodash.debounce";
import { objectFilter } from '../../../helpers/objectFilter'
import { useLocalStorage } from '../../../hooks/useLocalStorage'
import Restricted from '../../../Components/Guard/Restricted'
import DeleteButton from '../../../Components/Common/Buttons/DeleteButton'
import CreateButton from '../../../Components/Common/Buttons/CreateButton'
import SortButton from '../../../Components/Common/Buttons/SortButton'
import useModal from '../../../hooks/useModal'
import AttachBranchesModal from '../../../Components/Food/AttachBranches'

const Categories = () => {

  const { currentPage, navigateToPage} = usePagination();

  const {selectedItems, setSelectedItems, load, isLoading, modal, setModal, callback, setIsLoading} = useDeleteModal();

  const { toggleModal: toggleBranchesModal, isModalOpen: isBranchesModalOpen } = useModal();

  const [loading, setLoading] = useState(true);

  const [name, setName] = useState(null);

  const dispatch = useDispatch();

  const {values: {
      categoriesFilter: localFilterValues,
  }, setNewValues: setLocalFilterValues} = useLocalStorage({
      categoriesFilter: {
        branche_id: '',
        page_size: 10
      }
  })

  const getCategories = (byName) => {
    if (byName) navigateToPage(1);
    setLoading(true);
    dispatch(getFoodDataAction({
      field: 'categories',
      params: objectFilter({
        page: byName ? 1 : currentPage,
        ...localFilterValues,
        name
      })
    }, {
      onSuccess() {
        setLoading(false);
      },
      onError(error) {
        console.log('error', error);
        setLoading(false);
      }
    }))
  }

  const setPageSize = (val) => {
    setLocalFilterValues({
        categoriesFilter: {
          page_size: val
        }
    })
  }


  const handleDelete = () => {
      load();
      const ids = selectedItems.map(item => item.id);
      dispatch(deleteFoodAction({
        field: 'categories',
        data: {
          ids
        }
      }, {
        onSuccess() {
          getCategories();
          callback();
        },
        onError() {
          setIsLoading(false);
        }
      }))
  }
  

  useEffect(() => {
    dispatch(getFoodDataAction({
      field: 'categories',
      params: {
        page: currentPage,
        page_size: 100
      }
    }))
  }, [dispatch])

  const debounceSearch = useMemo(() => {
    return debounce(() => getCategories(true), 200);
  }, [localFilterValues, currentPage, name]);

  useEffect(() => {
    if (name !== null) debounceSearch();
  }, [name])

  useEffect(() => {
    getCategories();
  }, [localFilterValues, currentPage])


  return (
    <PageContainer>
        <BreadCrumb pageTitle="Food" title="Categories"/>

        <div className='hstack gap-2 flex-wrap'>
            <Link to={getPathname('food/categories/create')}>
              <CreateButton />
            </Link>

            <Restricted allowed={['super-admin', 'developer']}>

              <AttachBranchesModal
                  isOpen={isBranchesModalOpen}
                  toggle={toggleBranchesModal}
                  selectedItems={selectedItems.map(item => item.id)}
                  setSelectedItems={setSelectedItems}
                  route='attachCats'
              />

              <CustomButton 
                className="btn-dark"
                onClick={toggleBranchesModal}
                disabled={!selectedItems.length}
              >
                Add Branches
              </CustomButton>

            </Restricted>

            <Restricted allowed={['super-admin', 'developer']}>
              <DeleteButton
                  disabled={!selectedItems.length}
                  onClick={() => setModal(true)}
              />
            </Restricted>

            <SortButton 
              to="food/categories/sort"
            />
        </div>

        <Card className='mt-3'>
          <CategoriesTable 
              loading={loading}
              navigateToPage={navigateToPage}
              pageSize={localFilterValues.page_size}
              setPageSize={setPageSize}
              setSelectedItems={setSelectedItems}
              modal={modal}
              setModal={setModal}
              isLoading={isLoading}
              filters={localFilterValues}
              setFilters={setLocalFilterValues}
              handleDelete={handleDelete}
              name={name}
              setName={setName}
          />
        </Card>

    </PageContainer>
  )
}

export default Categories