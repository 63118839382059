import React, { useEffect, useState } from 'react'
import CustomInput from '../../../../../Custom/CustomInput'
import { useTranslation } from 'react-i18next'

const ChangeAllInputs = ({ label, field, onChange }) => {

  const [value, setValue] = useState('');

  const handleChange = (val) => {
    setValue(val);
    onChange(field, val)
  }

  const { t } = useTranslation();

  useEffect(() => {
    setValue('');
  }, [label])

  return (
    <div className="input-group">
        <span className="input-group-text" id="basic-addon3">{t(label)}</span>

        <CustomInput 
          type="number" 
          value={value || ''}
          onChange={(e) => handleChange(e.target.value)}
        />

        <span 
          className="input-group-text cursor-pointer" 
          onClick={() => handleChange(null)}
        >
          <i className="ri-close-line fs-16"></i>
        </span>
    </div>
  )
}

export default ChangeAllInputs