import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { foodFormTabs } from '../../../../../../data/foodFormTabs';
import { isEmpty } from '../../../../../../helpers/objectFilter';
import { getFoodDataAction } from '../../../../../../redux/actions/foodActions';
import OptionsSelection from './Selection';

const OptionsAlergy = ({foodType, initialValues, setValue}) => {

  const dispatch = useDispatch();

  const [selectedItems, setSelectedItems] = useState({});

  const {
    options: {list: options}, 
    allergy: {list: allergy}, 
  } = useSelector(state => state.food);

  useEffect(() => {
    if (foodFormTabs.find(item => item.id === foodType)?.tabs?.some(tab => tab.value === 'optionsAllergy')) {
        dispatch(getFoodDataAction({
          field: 'options',
          params: {
            page_size: 'all'
          }
        }))
        dispatch(getFoodDataAction({
          field: 'allergy',
          params: {
            page_size: 'all'
          }
        }))
    } else {
      setValue('options', null);
      setValue('allergies', null);
    }
  }, [foodType])

  useEffect(() => {
    const items = [{label: 'options', items: options}, {label: 'allergies', items: allergy}];
    let data = {};
    items.forEach(item => {
      if (item.items.length) {
        data[item.label] = item.items.map(it => ({
          id: it.id,
          name: it.name,
          ...(it.icon ? {icon: it.icon} : {}),
          selected: (initialValues?.type_id === foodType) ? initialValues?.[item.label]?.some(it2 => it.id === it2.id) : false
        }))
      }
    })
    setSelectedItems(data);
  }, [options, allergy, initialValues])

  useEffect(() => {
      setValue('options', selectedItems.options?.filter(item => item.selected)?.map(item => item.id) || null);
      setValue('allergies', selectedItems.allergies?.filter(item => item.selected)?.map(item => item.id) || null);
  }, [selectedItems])

  return (
    <div>
      {!isEmpty(selectedItems) && 
        <OptionsSelection 
          selectedItems={selectedItems}
          setSelectedItems={setSelectedItems}
        />
      }
    </div>
  )
}

export default OptionsAlergy