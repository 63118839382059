import React from 'react'
import BreadCrumb from '../../../Components/Common/BreadCrumb'
import PageContainer from '../../../Components/Layout/PageContainer'
import ProductCategoryForm from '../../../Components/Inventory/ProductCategories/Form'

const CreateProductCategory = () => {
    
  return (
    <PageContainer>
      <BreadCrumb pageTitle="Categories" title="Create" to="inventory/categories"/>
      <ProductCategoryForm 
        type="create"
      />
    </PageContainer>
  )
}

export default CreateProductCategory