import React from "react";
import { Link } from "react-router-dom";
import { getPathname } from "../../../Layouts/LayoutMenuData";
import CustomButton from "../../Custom/CustomButton";

const SortButton = ({ to }) => {
  
  return (
    <Link to={getPathname(to)}>
      <CustomButton className="d-flex gap-1 align-items-center">
        <i className="ri-list-unordered fs-16"></i>
        Sort
      </CustomButton>
    </Link>
  );
};

export default SortButton;
