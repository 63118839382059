import axiosInstance from "..";

export const getAllTables = (params) => {
  return axiosInstance.get("/tables", {
    params
  });
};

export const getOneTable = (id) => {
  return axiosInstance.get(`/tables/${id}`);
};

export const createTable = (data) => {
  return axiosInstance.post(`/tables`, data);
};

export const updateTable = (id, data) => {
    return axiosInstance.post(`/tables/${id}`, data);
};

export const deleteTable = (data) => {
    return axiosInstance.delete(`/tables`, {
        data
    });
};

