import React, { useEffect, useState } from 'react';
import PhoneInputComponent from "react-phone-input-2";
import { useSelector } from 'react-redux';

const PhoneInput = (props) => {

    const { activeLang } = useSelector((state) => state.language);

    const [localization, setLocalization] = useState(null);

    const [key, setKey] = useState(0);

    const handleLocalization = async () => {
        try {
            const loc = await import(`react-phone-input-2/lang/${activeLang}.json`);
            setLocalization(loc.default);
        } catch (error) {
            setLocalization({
                ...PhoneInputComponent.defaultProps.localization,
                invalid_phone_number: 'Invalid phone number',
                invalid_country_code: 'Invalid country code'
            })
        }
    }

    useEffect(() => {
        handleLocalization();
    }, [activeLang])

    useEffect(() => {
        setKey(key + 1);
    }, [localization]);

  return (
    <>
        {localization ? 
        <PhoneInputComponent
            {...props}
            key={key}
            localization={localization}
        /> : <></>}
    </> 
  )
}

export default PhoneInput