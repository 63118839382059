import React, { useEffect, useMemo } from "react";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Card, Col, Row } from "reactstrap";
import { createFoodAction, getFoodDataAction, updateFoodAction } from "../../../redux/actions/foodActions";
import CustomButton from "../../Custom/CustomButton";
import CustomLabel from "../../Custom/CustomLabel";
import CustomInput from "../../Custom/CustomInput";
import CustomOption from "../../Custom/CustomOption";
import N_PRODUCT_X_FREE from "./Types/N_PRODUCT_X_FREE";
import useActions from "../../../hooks/useActions";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { objectFilter } from "../../../helpers/objectFilter";
import { getPathname } from "../../../Layouts/LayoutMenuData";
import Flatpickr from 'react-flatpickr'
import moment from "moment";

const OfferForm = ({ type, initialValues }) => {

  const { register, handleSubmit, watch, setValue, reset, control } = useForm();

  const dispatch = useDispatch();

  const history = useHistory();

  const selectedOfferType = watch('type');

  const { offerTypes: { list: offerTypes }} = useSelector((state) => state.food);

  const { isLoading, onLoading, onFinally, onError } = useActions();

  const submitForm = (data) => {
    const requestBody = objectFilter(data);
    onLoading();
    if (type === "create") {
      dispatch(
        createFoodAction(
          {
            field: "offers",
            data: requestBody,
            language: 'en'
          },
          {
            onSuccess() {
              onFinally();
              history.push(getPathname("food/offers"));
            },
            onError
          }
        )
      );
    } 
    else {
      dispatch(
        updateFoodAction(
          {
            field: "offers",
            id: initialValues.id,
            data,
          },
          {
            onSuccess() {
              onFinally();
            },
            onError,
          }
        )
      );
    }
  };

  const offerTypesComponents = useMemo(() => {
    return {
      N_PRODUCT_X_FREE: <N_PRODUCT_X_FREE offerType={selectedOfferType} setValue={setValue} initialValues={initialValues} />,
      N_PRODUCT_X_FREE_PROGRESS: <N_PRODUCT_X_FREE offerType={selectedOfferType} setValue={setValue} initialValues={initialValues} limitBuy={1} />,
    }
  }, [selectedOfferType])

  useEffect(() => {
    if (initialValues) {
      reset(initialValues);
    }
  }, [initialValues])

  useEffect(() => {
    dispatch(
      getFoodDataAction({
        field: "offerTypes",
      })
    );
  }, []);

  return (
      <div>
          <Card className="p-3">
            <Row className="gy-3">
                <Col lg={6}>
                  <CustomLabel>Title</CustomLabel>
                  <CustomInput 
                    {...register('name')}
                  />
                </Col>

                <Col lg={6}>
                    <CustomLabel>Type</CustomLabel>
                    <select className="form-select" {...register('type')}> 
                        <CustomOption>Select...</CustomOption>
                        {offerTypes.map(type => (
                          <CustomOption key={type} value={type}>{type}</CustomOption>
                        ))}
                    </select>
                </Col>

                <Col lg={6}>
                    <CustomLabel>Start Date</CustomLabel>
                    <Controller
                          name="start_at"
                          control={control}
                          render={({name, field: {onChange}}) => (
                              <Flatpickr
                                  name={name}
                                  className="form-control"
                                  autoFocus={false}
                                  options={{
                                      dateFormat: "d M, Y",
                                  }}
                                  value={moment(watch('start_at'))?.format("D MMM, YYYY") || ''}
                                  onChange={([date]) =>  onChange(moment(date).format("YYYY-MM-DD"))}
                              />
                          )}
                      />
                </Col>
                
                <Col lg={6}>
                    <CustomLabel>End Date</CustomLabel>
                    <Controller
                          name="end_at"
                          control={control}
                          render={({name, field: {onChange}}) => (
                              <Flatpickr
                                  name={name}
                                  className="form-control"
                                  autoFocus={false}
                                  options={{
                                      dateFormat: "d M, Y",
                                  }}
                                  value={moment(watch('end_at'))?.format("D MMM, YYYY") || ''}
                                  onChange={([date]) =>  onChange(moment(date).format("YYYY-MM-DD"))}
                              />
                          )}
                      />
                </Col>

                <Col lg={6}>
                  <div className="form-check form-switch">
                    <input
                      name="active"
                      className="form-check-input fs-16"
                      type="checkbox"
                      role="switch"
                      {...register("active")}
                    />
                    <CustomLabel>Active</CustomLabel>
                  </div>
                </Col>
                
            </Row>

            <div className="mt-3">
              {offerTypesComponents[selectedOfferType?.toUpperCase()]}
            </div>
                
            <div className="mt-3">
              <CustomButton 
                type='button'
                className="btn-success" 
                onClick={handleSubmit(submitForm)}
                isLoading={isLoading}
              >
                {type === "create" ? "Create" : "Update"}
              </CustomButton>
            </div>

          </Card>

      </div>
  );
};

export default OfferForm;
