import React from "react";
import DataTableContainer from "../../Common/DataTableContainer";
import StatusToggle from "../../Common/StatusToggle";
import CustomTableHeading from "../../Custom/CustomTableHeading";

const PagesMenuTable = ({
  data,
  selectItems,
  isDeleting,
  handleDelete,
  modal,
  setModal,
}) => {
  const columns = [
    {
      name: <CustomTableHeading>Title</CustomTableHeading>,
      selector: (row) => row.title,
    },

    {
      name: <CustomTableHeading>Active</CustomTableHeading>,
      selector: (row) => <StatusToggle type='pages' id={row.id} field="active" status={row.active} />,
    },

    {
      name: <CustomTableHeading>Top nav</CustomTableHeading>,
      selector: (row) => <StatusToggle type='pages' id={row.id} field="top_nav" status={row.top_nav}/>,
    },

    {
      name: <CustomTableHeading>Footer nav</CustomTableHeading>,
      selector: (row) => <StatusToggle type='pages' id={row.id} field="footer_nav" status={row.footer_nav} />,
    },
  ];

  return (
    <DataTableContainer
      selectableRows
      isDeleting={isDeleting}
      handleDelete={handleDelete}
      modal={modal}
      path="pages-menu"
      setModal={setModal}
      selectItems={selectItems}
      data={data}
      columns={columns}
      noPagination
    />
  );
};

export default PagesMenuTable;
