import React from 'react'
import BreadCrumb from '../../../Components/Common/BreadCrumb'
import OptionsForm from '../../../Components/Food/Options/Form'
import PageContainer from '../../../Components/Layout/PageContainer'
import { useFetchByLang } from '../../../hooks/useFetchByLang'

const CreateOption = () => {

  const { language, setLanguage } = useFetchByLang();
    
  return (
    <PageContainer>
      <BreadCrumb pageTitle="Options" title="Create" to="food/options"/>
      <OptionsForm 
        type="create"
        language={language}
        setLanguage={setLanguage}
      />
    </PageContainer>
  )
}

export default CreateOption