import axiosInstance from "..";

export const getAllOrdersApi = (params = {}) => {
  return axiosInstance.get("/dash/orders", {
    params
  });
};

export const getOneOrderApi = (id) => {
  return axiosInstance.get(`/dash/orders/${id}`);
};

export const updateOrderApi = (id, data) => {
  return axiosInstance.patch(`/dash/orders/${id}`, data);
};

export const updateOrderChannelApi = (id, data) => {
  return axiosInstance.post(`/dash/orders/updateChannel/${id}`, data);
};

export const updateOrderDeliveryCompanyApi = (id, data) => {
  return axiosInstance.post(`/dash/orders/updateDeliveryBy/${id}`, data);
};

export const sendInvoiceMailApi = (id) => {
  return axiosInstance.post(`/dash/orders/sendInvoice/${id}`);
};

export const getOrderStatusApi = () => {
  return axiosInstance.get("/order_settings/order_status");
};

export const getOrderDeliveryApi = () => {
  return axiosInstance.get("/order_settings/delivery_types");
};

export const getOrdersStatistics = (params = {}) => {
  return axiosInstance.get("/dash/orders/stats", {
    params
  });
};
