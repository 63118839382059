import React from 'react'
import { Col, Row } from 'reactstrap'
import CustomInput from '../../../../../Custom/CustomInput'
import CustomLabel from '../../../../../Custom/CustomLabel'

const ProductsSelection = ({category, selectedCategory, data, setData}) => {

    const handleChange = (itemId, field, value) => {
        setData(prev => prev.map(item => {
            if (item.category_id === category) return {
                ...item,
                products: item.products.map(prod => {
                    if (prod.id === itemId) return {
                        ...prod,
                        [field]: value,
                        ...((field === 'included' && !value) ? { is_fix: false } : {})
                    }
                    return prod
                })
            }

            return item
        }))
    }

    const changeCount = (e) => {
        setData(prev => prev.map(item => {
            if (item.category_id === category) return {
                ...item,
                count: e.target.value
            }
            return item
        }))
    }

  return (
    <React.Fragment>
        {selectedCategory === category ? 
           <div>
                <Row className='my-3'>
                    <Col md={4}>
                        <CustomLabel>Count</CustomLabel>
                        <CustomInput 
                            value={data.count}
                            onChange={changeCount}
                        />
                    </Col>
                </Row>
                <Row className='mt-2 gap-2'>
                    {data.products.map(item => (
                        <Col 
                            key={item.id} 
                            md={5} 
                            className="border p-2"
                        >
                                <div className="form-check form-check-primary">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id={`product-${item.id}`}
                                        checked={item.included ?? ''}
                                        onChange={e => handleChange(item.id, 'included', e.target.checked)}
                                    />
                                    <CustomLabel className="form-check-label" htmlFor={`product-${item.id}`}>{item.name}</CustomLabel>

                                </div>
                                
                                <div className="form-check form-switch">
                                    <input 
                                        className="form-check-input fs-16" 
                                        type="checkbox" 
                                        role="switch" 
                                        id={`product-fix-${item.id}`}
                                        checked={item.is_fix ?? false}
                                        onChange={e => {
                                            if (item.included) handleChange(item.id, 'is_fix', e.target.checked)
                                        }}
                                        disabled={!item.included}
                                    />
                                    <CustomLabel 
                                        className="form-check-label" 
                                        htmlFor={`product-fix-${item.id}`} 
                                    >
                                        Fixed
                                    </CustomLabel>
                                </div>
                        </Col>
                    ))}
                </Row>
           </div>
        : <></>}
    </React.Fragment>
  )
}

export default ProductsSelection