import React from 'react'
import { useSelector } from 'react-redux'
import { isAllowed } from '../../../helpers/isAllowed'
import { getPathname } from '../../../Layouts/LayoutMenuData'
import DataTableContainer from '../../Common/DataTableContainer'
import TableImage from '../../Common/TableImage'
import CustomInput from '../../Custom/CustomInput'
import CustomTableHeading from '../../Custom/CustomTableHeading'

const SizesTable = ({navigateToPage, pageSize, setPageSize, setSelectedItems, modal, setModal, isLoading, handleDelete, loading, name, setName}) => {

    const { sizes } = useSelector(state => state.food);

    const { user } = useSelector(state => state.admin);

    const columns = [
        {
            name: <CustomTableHeading>ID</CustomTableHeading>,
            selector: row => row.id || '-',
            grow: -1,
        },

        {
            selector: row =><TableImage src={row.icon} />,
        },

        {
            name: 
            <CustomInput 
                placeholder='Name' 
                type="text" 
                value={name || ''}
                onChange={(e) => setName(e.target.value)}
                data-filtered={!!name}
            />,
            selector: row => row.name || '-',
        },
        
        {
            name: <CustomTableHeading>Pieces</CustomTableHeading>,
            selector: row => row.pieces || '-',
        },

        {
            name: <CustomTableHeading>Weight</CustomTableHeading>,
            selector: row => row.weight || '-',
        },

        {
            name: <CustomTableHeading>Unit</CustomTableHeading>,
            selector: row => row.unit || '-',
        },

        {
            name: <CustomTableHeading>Type</CustomTableHeading>,
            selector: row => row.type || '-',
        },

        {
            name: <CustomTableHeading>Code</CustomTableHeading>,
            selector: row => row.code || '-',
        },

        {
            name: <CustomTableHeading>Class</CustomTableHeading>,
            selector: row => row.class || '-',
        },
    ]


  return (
    <DataTableContainer 
        data={sizes.list}
        columns={columns}
        paginationServer
        paginationTotalRows={sizes.total && sizes.total}
        path={getPathname('food/sizes')}
        navigateToPage={navigateToPage}
        pageSize={pageSize}
        setPageSize={setPageSize}
        selectableRows={isAllowed(user)}
        selectItems={setSelectedItems}
        modal={modal}
        setModal={setModal}
        isDeleting={isLoading}
        handleDelete={handleDelete}
        loading={loading}
    />
  )
}

export default SizesTable