import React, { useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Form,
  FormFeedback,
  Alert,
} from "reactstrap";
import ParticlesAuth from "./ParticlesAuth";
//redux
import { useDispatch, useSelector } from "react-redux";

import { withRouter, Link, useHistory } from "react-router-dom";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";
import CustomButton from "../../Components/Custom/CustomButton";
import { loginAction } from "../../redux/actions/adminActions";
import { renderErrorAsString } from "../../helpers/renderErrors";
import { getPathname } from "../../Layouts/LayoutMenuData";
import { useTranslation } from "react-i18next";
import CustomLabel from "../../Components/Custom/CustomLabel";
import CustomInput from "../../Components/Custom/CustomInput";
//Import config

const Login = () => {

  const dispatch = useDispatch();

  const { replace } = useHistory();

  const [error, setError] = useState(null);

  const { t } = useTranslation();

  const { setup } = useSelector(state => state.settings);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    // enableReinitialize: true,

    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: (values, helpers) => {
      setError(null);
      dispatch(
        loginAction(values, {
          onLoading() {
            helpers.setSubmitting(true);
          },
          onSuccess() {
            replace(getPathname("/"));
          },
          onError(error) {
            helpers.setSubmitting(false);
            setError(renderErrorAsString(error));
          },
        })
      );
    },
  });

  return (
    <React.Fragment>
      <ParticlesAuth>
        <div className="auth-page-content">
          <Container>
            <Row>
              <Col lg={12}>
                <div className="text-center text-white-50">
                  <div>
                    <Link to="/" className="d-inline-block auth-logo">
                      <img src={setup.logo} alt="" height="200" />
                    </Link>
                  </div>
                </div>
              </Col>
            </Row>

            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="mt-4">
                  <CardBody className="p-4">
                    <div className="text-center mt-2">
                      <h5 className="text-primary">{t('Welcome Back')} !</h5>
                      <p className="text-muted">
                        {t('Sign in to continue to')} {setup.name}
                      </p>
                    </div>

                    {error && <Alert color="danger">{t(error)}</Alert>}

                    <div className="p-2 mt-4">
                      <Form
                        onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                        action="#"
                      >
                        <div className="mb-3">
                          <CustomLabel htmlFor="email" className="form-label">
                            Email
                          </CustomLabel>
                          <CustomInput
                            name="email"
                            placeholder="Enter email"
                            type="text"
                            value={validation.values.email || ""}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            invalid={
                              validation.touched.email &&
                              validation.errors.email
                                ? true
                                : false
                            }
                          />
                          {validation.touched.email &&
                          validation.errors.email ? (
                            <FormFeedback type="invalid">
                              {validation.errors.email}
                            </FormFeedback>
                          ) : null}
                        </div>

                        <div className="mb-3">
                          <CustomLabel
                            className="form-label"
                            htmlFor="password-input"
                          >
                            Password
                          </CustomLabel>
                          <div className="position-relative auth-pass-inputgroup mb-3">
                            <CustomInput
                              name="password"
                              placeholder="Enter password"
                              type="password"
                              value={validation.values.password || ""}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              invalid={
                                validation.touched.password &&
                                validation.errors.password
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.password &&
                            validation.errors.password ? (
                              <FormFeedback type="invalid">
                                {validation.errors.password}
                              </FormFeedback>
                            ) : null}
                            {/* <button
                              className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted"
                              type="button"
                              id="password-addon"
                            >
                              <i className="ri-eye-fill align-middle"></i>
                            </button> */}
                          </div>
                        </div>

                        <div className="mt-4">
                          <CustomButton
                            color="success"
                            className="btn btn-success w-100"
                            type="button"
                            isLoading={validation.isSubmitting}
                            onClick={validation.handleSubmit}
                          >
                            Sign In
                          </CustomButton>
                        </div>
                      </Form>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </ParticlesAuth>
    </React.Fragment>
  );
};

export default withRouter(Login);
