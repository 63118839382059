import React, { useState } from "react";
import {
  Card,
  Nav,
  NavLink,
  NavItem,
  TabContent,
  TabPane,
  Row,
  Col,
} from "reactstrap";
import PageContainer from "../../Components/Layout/PageContainer";
import classnames from "classnames";
import { useTranslation } from "react-i18next";
import CustomLabel from "../../Components/Custom/CustomLabel";
import CustomInput from "../../Components/Custom/CustomInput";
import { useForm } from "react-hook-form";
import SumUp from "../../Components/Settings/Payment/Tabs/SumUp";
import Vipps from "../../Components/Settings/Payment/Tabs/Vipps";
import Stripe from "../../Components/Settings/Payment/Tabs/Stripe";
import Reepay from "../../Components/Settings/Payment/Tabs/Reepay";
import Cash from "../../Components/Settings/Payment/Tabs/Cash";
import MyFatoorah from "../../Components/Settings/Payment/Tabs/MyFatoorah";
import Nets from "../../Components/Settings/Payment/Tabs/Nets";
import { getCurrencies } from "../../apis/Settings/paymentApi";
import { useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import CustomButton from "../../Components/Custom/CustomButton";
import useActions from "../../hooks/useActions";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { useDispatch, useSelector } from "react-redux";
import { getPaymentSettingsAction, setPaymentSettingsAction } from "../../redux/actions/settingsActions";
import { isEmpty } from "../../helpers/objectFilter";
import Paypal from "../../Components/Settings/Payment/Tabs/Paypal";
import Fawry from "../../Components/Settings/Payment/Tabs/Fawry";

const TABS = [
  {
    id: 1,
    name: "Cash",
    component: Cash ,
  },
  {
    id: 2,
    name: "SumUp",
    component: SumUp,
  },
  {
    id: 3,
    name: "Vipps",
    component: Vipps,
  },
  {
    id: 4,
    name: "Stripe",
    component: Stripe,
  },
  {
    id: 5,
    name: "Nets",
    component: Nets,
  },
  {
    id: 6,
    name: "Reepay",
    component: Reepay,
  },
  {
    id: 7,
    name: "My Fatoorah",
    component: MyFatoorah,
  },
  {
    id: 8,
    name: "Paypal",
    component: Paypal,
  },
  {
    id: 12,
    name: "Fawry",
    component: Fawry,
  },
];

const Payment = () => {

  const [activeTab, setActiveTab] = useState(1);

  const {register, handleSubmit, setValue, reset} = useForm();

  const [currencies, setCurrencies] = useState(null);
  
  const [selectedCurrency, setSelectedCurrency] = useState(null);

  const [data, setData] = useState(null);

  const { isLoading, onLoading, onFinally } = useActions();

  const dispatch = useDispatch();

  const { paymentSettings } = useSelector(state => state.settings);

  useQuery(['currencies'], () => getCurrencies(), {
    onSuccess({data: {data}}) {
      setCurrencies(data);
    }
  })

  const { t } = useTranslation();

  const toggleActiveTab = (tab) => {
    setActiveTab(tab);
  };

  const submitForm = async (data) => {
      onLoading();
      dispatch(setPaymentSettingsAction({
        data: {
          ...data,
          datas: data.datas.map(item => {
            const {logo, ...rest} = item;
            return rest
          })
        },
        onSuccess() {
          onFinally();
        },
        onError() {
          onFinally();
        }
      }))
  }

  useEffect(() => {
    if (!isEmpty(paymentSettings)) {
      reset(paymentSettings);
      setData(paymentSettings.datas.map(item => {
        const { name, ...rest } = item;
        return rest
      }));
    }
  }, [paymentSettings, reset])

  useEffect(() => {
    setValue('datas', data);
  }, [data])

  useEffect(() => {
    if (currencies) {
      let currency;
      if (selectedCurrency) {
        currency = currencies.find(item => item.name === selectedCurrency);
      } else {
        currency = currencies[0];
      }
      setValue('settings.currency', currency?.name)
      setValue('settings.view_currency', currency?.symbol)
    }
  }, [currencies, selectedCurrency])

  useEffect(() => {
    if (currencies && paymentSettings?.settings?.currency) {
        setSelectedCurrency(paymentSettings?.settings?.currency)
    }
  }, [currencies, paymentSettings])


  useEffect(() => {
    dispatch(getPaymentSettingsAction())
  }, [dispatch])

  return (
    <PageContainer>
      <BreadCrumb pageTitle="Settings" title="Payment"/>
      <form onSubmit={handleSubmit(submitForm)}>
        <Card>
            <Row className="p-3 gy-3">

                <Col md={4}>
                    <CustomLabel>
                        Currency
                    </CustomLabel>
                    <select
                      className="form-select"
                      value={selectedCurrency || ''}
                      onChange={(e) => setSelectedCurrency(e.target.value)}
                    >
                        {currencies?.map(currency => (
                          <option key={currency.symbol} value={currency.name}>{currency.full_name}</option>
                        ))}
                    </select>
                </Col>

                <Col md={4}>
                    <CustomLabel>
                        Currency Code
                    </CustomLabel>
                    <CustomInput 
                        type="text"
                        {...register('settings.currency')}
                        disabled
                    />
                </Col>

                <Col md={4}>
                    <CustomLabel>
                        View currency
                    </CustomLabel>
                    <CustomInput 
                        type="text"
                        {...register('settings.view_currency')}
                        disabled
                    />
                </Col>

                <Col md={6}>
                    <CustomLabel>
                      Success Redirect URL
                    </CustomLabel>
                    <CustomInput 
                        type="text"
                        {...register('settings.redirect_url')}
                    />
                </Col>

                <Col md={6}>
                    <CustomLabel>
                      Failed Redirect URL
                    </CustomLabel>
                    <CustomInput 
                        type="text"
                        {...register('settings.redirect_url_cancel')}
                    />
                </Col>

                <Col md={6}>
                    <CustomLabel>
                      Checkout Redirect URL
                    </CustomLabel>
                    <CustomInput 
                        type="text"
                        {...register('settings.redirect_url_checkout')}
                    />
                </Col>

            </Row>

            <div>
              <Nav className="nav-tabs-custom card-header-tabs border-bottom-0 p-3">
                {TABS.map((tab) => {
                  return (
                    <NavItem key={tab.id}>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: activeTab === tab.id,
                        })}
                        onClick={() => {
                          toggleActiveTab(tab.id);
                        }}
                      >
                        {t(tab.name)}
                      </NavLink>
                    </NavItem>
                  );
                })}
              </Nav>

              {data ? 

                <TabContent activeTab={activeTab} className="p-3">
                  {TABS.map((tab) => {
                    return (
                      <TabPane key={tab.id} tabId={tab.id}>
                        <tab.component 
                          id={tab.id} 
                          data={data.find(item => item.id === tab.id)} setData={setData} 
                        />
                      </TabPane>
                    );
                  })}
                </TabContent>
              : 
              <></>
              }
            </div>
        </Card>
        <div>
          <CustomButton
            className="btn-success"
            isLoading={isLoading}
          >
            Save
          </CustomButton>
        </div>
      </form>
    </PageContainer>
  );
};

export default Payment;
