import React from 'react'
import errorImage from '../../assets/images/error-image.jpg'
import { useTranslation } from 'react-i18next'

const ErrorPage = () => {

  const { t } = useTranslation();

  return (
    <div className='error-page'>
        <div className='error-container'>
              <img src={errorImage} />
              <h3>{t('Oops, Something Went Wrong!')}</h3>
              <p>{t('We apologize for the inconvenience, please try refreshing the page or comeback later while we work on a fix.')}</p>
        </div>
    </div>
  )
}

export default ErrorPage