import axiosInstance from "..";

export const getAllBlackAndWhite = (params) => {
  return axiosInstance.get("/black_white_liste", {
    params
  });
};

export const getOneBlackAndWhite = (id) => {
  return axiosInstance.get(`/black_white_liste/${id}`);
};

export const createBlackAndWhite = (data) => {
  return axiosInstance.post(`/black_white_liste`, data);
};

export const updateBlackAndWhite = (id, data) => {
    return axiosInstance.post(`/black_white_liste/${id}`, data);
};

export const deleteBlackAndWhite = (data) => {
    return axiosInstance.delete(`/black_white_liste`, {
        data
    });
};

