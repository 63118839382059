import axiosInstance from ".";

export const getNotifications = (query) => {
    return axiosInstance.get('/notifications', {
        params: query
    });
}

export const setNotificationToRead = (id) => {
    return axiosInstance.get(`/notifications/${id}`);
}

export const markAllNotificationsAsSeen = () => {
    return axiosInstance.get('/notifications/seeAll');
}