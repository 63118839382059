import React from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Card, Col, Row } from "reactstrap";
import { errorMessage } from "../../../helpers/errorMessage";
import { setFormErrors } from "../../../helpers/setFormErrors";
import toastAlert from "../../../helpers/toastAlert";
import useActions from "../../../hooks/useActions";
import { getPathname } from "../../../Layouts/LayoutMenuData";
import {
  addLanguageAction,
  updateLanguageAction,
} from "../../../redux/actions/languageActions";
import CustomButton from "../../Custom/CustomButton";
import CustomInput from "../../Custom/CustomInput";
import CustomLabel from "../../Custom/CustomLabel";

const LanguageForm = ({ type, initValues }) => {
  
  const { isLoading: isSubmitting, onLoading, onFinally } = useActions();

  const dispatch = useDispatch();

  const { push } = useHistory();

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({
    defaultValues: type === "Update" ? initValues : {},
  });

  const onSubmit = async (data) => {
    onLoading();
    const body = {
      active: 1,
      name: data.name,
      code: data.code,
      country_code: data.country_code,
    }
    if (type === "Create") {
      dispatch(
        addLanguageAction(body, {
          onSuccess() {
            push(getPathname("settings/language"));
          },
          onError(error) {
            toastAlert({ type: 'error' });
            setFormErrors(error, setError);
          },
        })
      );
    } else {
      dispatch(
        updateLanguageAction(data.code, data, {
          onSuccess() {
            onFinally();
            toastAlert({ type: 'success' });
          },
          onError(error) {
            toastAlert({ type: 'error' });
            setFormErrors(error, setError);
          },
        })
      );
    }
  };

  return (
    <Card className="p-4">
      <Row className="g-3">
        <Col lg={6}>
          <div>
            <CustomLabel>
              Name
            </CustomLabel>
            <CustomInput
              {...register("name")}
              type="text"
              name="name"
              className="form-control"
            />
            {errorMessage(errors, "name")}
          </div>
        </Col>

        <Col lg={6}>
          <div>
            <CustomLabel>
              Code
            </CustomLabel>
            <CustomInput
              {...register("code")}
              type="text"
              name="code"
              className="form-control"
              disabled={type === "Update"}
            />
            {errorMessage(errors, "code")}
          </div>
        </Col>

        
        <Col lg={6}>
          <div>
            <CustomLabel>
              Country Code
            </CustomLabel>
            <CustomInput
              {...register("country_code")}
              type="text"
              name="country_code"
              className="form-control"
            />
            {errorMessage(errors, "country_code")}
          </div>
        </Col>

        {type === 'Update' ?
          <Col lg={12} className="d-flex flex-column gap-2">

              <div className="form-check form-switch">
                  <input {...register('default_dash')} className="form-check-input fs-16" type="checkbox" role="switch" id="default_dash" />
                  <CustomLabel className="form-check-label" htmlFor="default_dash">Dashboard Default</CustomLabel>
              </div>

              <div className="form-check form-switch">
                  <input {...register('default_app')} className="form-check-input fs-16" type="checkbox" role="switch" id="default_app" />
                  <CustomLabel className="form-check-label" htmlFor="default_app">App Default</CustomLabel>
              </div>

              <div className="form-check form-switch">
                  <input {...register('default_web')} className="form-check-input fs-16" type="checkbox" role="switch" id="default_web" />
                  <CustomLabel className="form-check-label" htmlFor="default_web">Web Page Default</CustomLabel>
              </div>

              <div className="form-check form-switch">
                  <input {...register('default_pos')} className="form-check-input fs-16" type="checkbox" role="switch" id="default_pos" />
                  <CustomLabel className="form-check-label" htmlFor="default_pos">POS Default</CustomLabel>
              </div>

          </Col>
        : <></>}

      </Row>
      <div className="hstack gap-2 justify-content-end">
        <CustomButton
          type="submit"
          className="btn btn-success"
          id="add-btn"
          isLoading={isSubmitting}
          onClick={handleSubmit(onSubmit)}
        >
          Save
        </CustomButton>
      </div>
    </Card>
  );
};

export default LanguageForm;
