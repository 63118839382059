import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { Card, Col, Row } from 'reactstrap'
import { formatExample } from '../../../helpers/formatExample'
import toastAlert from '../../../helpers/toastAlert'
import useActions from '../../../hooks/useActions'
import { getPathname } from '../../../Layouts/LayoutMenuData'
import CustomButton from '../../Custom/CustomButton'
import CustomInput from '../../Custom/CustomInput'
import CustomLabel from '../../Custom/CustomLabel'
import CustomTextArea from '../../Custom/CustomTextArea'
import { createRoom, updateRoom } from '../../../apis/Orders/roomsApi'
import { useBranches } from '../../../hooks/useQueryHelpers'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import CustomOption from '../../Custom/CustomOption'

const RoomForm = ({type, initialValues = null}) => {

    const { register, handleSubmit, reset, setValue } = useForm();

    const {isLoading, onLoading, onFinally, onError} = useActions();

    const history = useHistory();

    const { branches } = useBranches();

    const locationState = useLocation().state;

    const submitForm = async (data) => {
        onLoading();
        try {
            if (type === 'create') {
                await createRoom(data);
                toastAlert({ type: 'success' });
                history.push(getPathname('orders/rooms'))
            } else {
                await updateRoom(initialValues?.id, data);
                toastAlert({ type: 'success' });
            }
        } catch (error) {
            toastAlert({ type: 'error' });
            onError();
        } finally {
            onFinally();
        }
        
    }

    useEffect(() => {
        if (branches && initialValues?.branche_id) {
            setValue('branche_id', initialValues.branche_id);
        }
    }, [initialValues, branches])

    useEffect(() => {
        if (locationState?.branche_id && branches.length) {
            setValue('branche_id', locationState?.branche_id);
        }
    }, [locationState?.branche_id, branches])


    useEffect(() => {
        if (initialValues) {
            reset(initialValues);
        }
    }, [initialValues])

  return (

    <form onSubmit={handleSubmit(submitForm)}>
        <Row>
            <Col lg={12}>
                <Card className='p-3'>

                    <Row className='gy-4'>

                        <Col lg={6}>
                            <CustomLabel>
                                Name
                            </CustomLabel>
                            <CustomInput 
                                type="text"
                                {...register('name')}
                                placeholder={formatExample("Room 4-3-2")}
                            />
                        </Col>

                        <Col lg={6}>
                            <CustomLabel>
                                Branches
                            </CustomLabel>
                            <select 
                                {...register('branche_id')}
                                className='form-select'
                            >
                                <CustomOption value=''>Select...</CustomOption>
                                {branches?.map(branch => (
                                    <option key={branch.id} value={branch.id}>{branch.name}</option>
                                ))}
                            </select>
                        </Col>

                        <Col lg={12}>
                            <CustomLabel>
                                Note
                            </CustomLabel>
                            <CustomTextArea 
                                type="number"
                                {...register('note')}
                            />
                        </Col>

                        <Col lg={12}>
                            <div className='d-flex gap-4'>
                                <div className="form-check form-switch" style={{width: 'max-content'}}>
                                    <input {...register('active')} className="form-check-input fs-16" type="checkbox" role="switch" id="active" />
                                    <CustomLabel className="form-check-label" htmlFor="active">Active</CustomLabel>
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <Row className='mt-3'>
                        <Col>
                            <CustomButton
                                className="btn-success text-capitalize"
                                isLoading={isLoading}
                            >
                                {type}
                            </CustomButton>
                          
                        </Col>
                    </Row>
                </Card>
            </Col>
        </Row>
    </form>
  )
}

export default RoomForm