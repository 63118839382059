import React from "react";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import SortableList from "../../../Components/Common/SortableList";
import PageContainer from "../../../Components/Layout/PageContainer";
import { useSlider } from "../../../hooks/useQueryHelpers";

const SortHomeSliders = () => {
  const { isLoading, sliders, setSliders } = useSlider();
  return (
    <PageContainer isLoading={isLoading}>
      <BreadCrumb
        pageTitle="Home Sliders"
        title="Sort"
        to="settings/home-sliders"
      />
      <SortableList list={sliders} setList={setSliders} mainTitle="title" />
    </PageContainer>
  );
};

export default SortHomeSliders;
