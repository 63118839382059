import { useQuery } from '@tanstack/react-query';
import React, { useState } from 'react'
import { reportsApi } from '../../apis/Reports/api';
import BreadCrumb from '../../Components/Common/BreadCrumb';
import PageContainer from '../../Components/Layout/PageContainer'
import ReportsSettingsForm from '../../Components/Reports/Settings/Form';

const ReportsSettings = () => {

  const [initialValues, setInitialValues] = useState(null);

  const { isLoading, isRefetching } = useQuery(['reports-settings'], () => reportsApi.settings.get(), {
    onSuccess({data: { data }}) {
      setInitialValues(data);
    }
  })

  return (
    <PageContainer isLoading={ isLoading || isRefetching }>
        <BreadCrumb 
            pageTitle="Reports"
            title="Settings"
        />
        {initialValues && 
            <ReportsSettingsForm 
                initialValues={initialValues}
            />
        }
    </PageContainer>
  )
}

export default ReportsSettings