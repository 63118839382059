import { objectFilter } from "../../objectFilter";

export const reportRqBody = (data) => {

    const { delivery_type, payment, platform: platforms, ...rest } = data;

    const delivery_type_id = Object.entries(delivery_type).filter(([key, val]) => val).map(([key, val]) => +key);
    const payment_method_id = Object.entries(payment).filter(([key, val]) => val).map(([key, val]) => +key)
    const platform = Object.entries(platforms).filter(([key, val]) => val).map(([key, val]) => key)

    return objectFilter({
        report_type: data.report_type,
        delivery_type_id,
        payment_method_id,
        platform,
        ...Object.fromEntries(Object.entries(rest).map(([key, value]) => {
            if (Array.isArray(value)) return [key, value.map(value => {
                if (typeof value === 'object') return value.value
                return value
            })]
            return [key, value]
        }))
    })
}