import axiosInstance from "..";

export const inventoryApi = {

  inventories: {
    getAll: (query) => {
      return axiosInstance.get("/inventories", {
        params: query,
      });
    },

    getOne: (id, lang = 'en') => {
      return axiosInstance.get(`/inventories/${id}?lang=${lang}`);
    },

    create: (data, lang = 'en') => {
      return axiosInstance.post(`/inventories?lang=${lang}`, data);
    },

    update: (id, data, lang = 'en') => {
      return axiosInstance.post(`/inventories/${id}?lang=${lang}`, data);
    },

    delete: (data) => {
      return axiosInstance.delete("/inventories", {
        data,
      });
    },
  },
    
  products: {
    getAll: (query) => {
      return axiosInstance.get("/products", {
        params: query,
      });
    },

    getOne: (id, lang = 'en') => {
      return axiosInstance.get(`/products/${id}?lang=${lang}`);
    },

    getTypes: () => {
      return axiosInstance.get('/products/types');
    },

    create: (data, lang = 'en') => {
      return axiosInstance.post(`/products?lang=${lang}`, data);
    },

    update: (id, data, lang = 'en') => {
      return axiosInstance.post(`/products/${id}?lang=${lang}`, data);
    },

    control: (type, data) => {
        return axiosInstance.post(`/products/${type}`, data);
    },

    delete: (data) => {
      return axiosInstance.delete("/products", {
        data,
      });
    },
  },

  units: {
    getAll: (query) => {
      return axiosInstance.get("/product_units", {
        params: query,
      });
    },

    getOne: (id, lang = 'en') => {
      return axiosInstance.get(`/product_units/${id}?lang=${lang}`);
    },

    create: (data) => {
      return axiosInstance.post(`/product_units`, data);
    },

    update: (id, data, lang) => {
      return axiosInstance.post(`/product_units/${id}?lang=${lang}`, data);
    },

    delete: (data) => {
      return axiosInstance.delete("/product_units", {
        data,
      });
    },
  },

  getProductMovements: () => {
    return axiosInstance.get("/product_categories");
  },

  category: {
    getAll: (query) => {
      return axiosInstance.get("/product_categories", {
        params: query,
      });
    },

    getOne: (id, lang = 'en') => {
      return axiosInstance.get(`/product_categories/${id}?lang=${lang}`);
    },

    create: (data) => {
      return axiosInstance.post(`/product_categories`, data);
    },

    update: (id, data, lang) => {
      return axiosInstance.post(`/product_categories/${id}?lang=${lang}`, data);
    },

    delete: (data) => {
      return axiosInstance.delete("/product_categories", {
        data,
      });
    },
  },

  orders: {
    getAll: (query) => {
      return axiosInstance.get("/stock_orders", {
        params: query,
      });
    },

    getOne: (id) => {
      return axiosInstance.get(`/stock_orders/${id}`);
    },

    create: (data) => {
      return axiosInstance.post(`/stock_orders/store`, data);
    },

    update: (id, data) => {
      return axiosInstance.post(`/stock_orders/update/${id}`, data);
    },

    delete: (data) => {
      return axiosInstance.delete("/stock_orders", {
        data,
      });
    },

    getStatuses: () => {
      return axiosInstance.get("/stock_orders/statuses");
    },

    getDailyProducts: (params) => {
      return axiosInstance.get("/stock_orders/daily_products", {
        params
      });
    },

    confirm: (id, data) => {
      return axiosInstance.post(`/stock_orders/confirmOrder/${id}`, data);
    },

    confirmRecieving: (id, data) => {
      return axiosInstance.post(`/stock_orders/confirmReceiving/${id}`, data);
    },

    exportPdf: (id) => {
      return axiosInstance.get(`/stock_orders/export/${id}`);
    },
  },

};
