import React, { useEffect } from 'react'
import CustomLabel from '../../../../../Custom/CustomLabel'
import { Col, Row } from 'reactstrap'

const ComponentToggles = ({register, setValue, watch}) => {

    useEffect(() => {
      return () => {
        setValue('max_active', null);
        setValue('max_compo', null);
        setValue('free_before_max', null);
      }
    }, [])

    const isMaxComp = watch('max_active');

    useEffect(() => {
        if (!isMaxComp) {
            setValue('max_compo', null);
        }
    }, [isMaxComp])
    
  return (
    <Row className='mb-3'>
        <Col lg={12} className='d-flex flex-column gap-3'>

            <div className='hstack gap-2'>
                <div className="form-check form-switch">
                    <input  {...register('max_active')} className="form-check-input fs-16" type="checkbox" role="switch" id="half_max_price" />
                    <CustomLabel className="form-check-label" htmlFor="half_max_price">Activate Max Components</CustomLabel>
                </div>

                {isMaxComp ? 
                    <div className="form-check form-switch">
                        <input  {...register('free_before_max')} className="form-check-input fs-16" type="checkbox" role="switch" id="free_before_max" />
                        <CustomLabel className="form-check-label" htmlFor="free_before_max">Free Before Max Components</CustomLabel>
                    </div>
                : <></>}
            </div>

        </Col>
    </Row>
  )
}

export default ComponentToggles