import React from "react";
import { useTranslation } from "react-i18next";
import { useNotifications } from "../../Wrappers/NotificationWrapper";
import { formatDateTime } from "../../../helpers/timeFormat";

const OrderNotification = ({ notification }) => {

  const { t } = useTranslation();

  const { handleNotificationClick } = useNotifications();

  return (
    <div className="d-flex cursor-pointer notification-item dropdown-item" onClick={() => handleNotificationClick(notification)}>

      <div className="avatar-xs me-3">
        <span className="avatar-title bg-soft-info text-info rounded-circle fs-16">
          <i className="bx bx-badge-check"></i>
        </span>
      </div>
      
      <div className="flex-1">

        <h6 className="mt-0 mb-1 fs-13 fw-semibold">
            {t(notification.title)}
        </h6>

        <div className="fs-13 text-muted">
          <p className="mb-1">{notification.description}</p>
        </div>

        <p className="mb-0 fs-11 fw-medium text-uppercase text-muted">
          <span>
            <i className="mdi mdi-clock-outline"></i>{" "}
            {formatDateTime(notification.created_at)}
          </span>
        </p>
        
      </div>

      {!notification.read && 
        <span className="text-danger fs-12">New</span>
      }

    </div>
  );
};

export default OrderNotification;
