import { useState } from "react";

export default function useDeleteModal() {
    
    const [selectedItems, setSelectedItems] = useState([]);
    const [modal, setModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState(null)

    const load = () => setIsLoading(true)
    const stopLoad = () => setIsLoading(false)

    const callback = () => {
        stopLoad()
        setModal(false)
        setSelectedItems([])
    }

    const onError = (error) => {
        stopLoad()
        setError(error)
    }

    return {
        callback, selectedItems, modal, isLoading, onError, setSelectedItems, setModal, load, error, setIsLoading
    }

}