import React from "react";
import { Row, Col } from "reactstrap";
import CustomInput from "../../../Custom/CustomInput";
import CustomLabel from "../../../Custom/CustomLabel";

const BranchContacts = ({ register }) => {

  return (
    <Row className="gy-3">
      
      <Col md={6}>
        <CustomLabel>Email</CustomLabel>
        <CustomInput
          {...register('contact.email')}
        />
      </Col>

      <Col md={6}>
        <CustomLabel>Phone</CustomLabel>
        <CustomInput
          {...register('contact.phone')}
        />
      </Col>

      <Col md={6}>
        <CustomLabel>Address</CustomLabel>
        <CustomInput
          {...register('contact.address')}
        />
      </Col>

      <Col md={6}>
        <CustomLabel>Latitude, Longitude</CustomLabel>
        <CustomInput
          {...register('contact.lat_long')}
        />
      </Col>

    </Row>
  );
};

export default BranchContacts;
