import React from 'react'
import { Card } from 'reactstrap';
import PageContainer from '../../../Components/Layout/PageContainer';
import CustomButton from '../../../Components/Custom/CustomButton';
import { downloadPdf } from '../../../helpers/pdfHelper';
import BreadCrumb from '../../../Components/Common/BreadCrumb';

const DisplayReport = ({ data, setData }) => {

  return (
    <PageContainer>
      <BreadCrumb
        pageTitle="Reports"
        title="Details"
      />

      <Card className="p-3">
        <i 
          className="ri-arrow-left-line cursor-pointer"
          style={{ fontSize: '2rem' }}
          onClick={() => setData(null)}
        ></i>
        {!!data && 
          <div 
            dangerouslySetInnerHTML={{
              __html: data
            }}
          />
        }

        <div>
          <CustomButton
            type="button"
            className="btn-success"
            onClick={() => downloadPdf(data)}
          >
            Export
          </CustomButton>
        </div>
      </Card>
    </PageContainer>
  )
  
}

export default DisplayReport