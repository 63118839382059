import React from 'react'
import { foodApi } from '../../../../apis/Food/api';
import { useQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'reactstrap';
import FoodSelection from './FoodSelection/FoodSelection';

const N_PRODUCT_X_FREE = ({ initialValues, offerType, setValue, limitBuy }) => {

  const { data } = useQuery(['foods'], () => foodApi.menu.getAllMinimized({
    page_size: 'all'
  }))

  const foods = data?.data?.data;

  const { t } = useTranslation();

  return (
    <div className='mt-3 border p-3'>

        <div className='d-flex justify-content-center'>
            <h5>{offerType}</h5>
        </div>

        <Row className='mt-1 px-2 gx-3'>

          <Col lg={6} className='border p-3'>
            <h5 className='text-center mb-3'>{t('Products to buy')}</h5>
            <FoodSelection 
              foods={foods}
              field='items_buy'
              setValue={setValue}
              initialValues={initialValues}
              limitBuy={limitBuy}
              offerType={offerType}
            />
          </Col>

          <Col lg={6} className='border p-3'>
            <h5 className='text-center mb-3'>{t('Products to get')}</h5>
            <FoodSelection 
              foods={foods}
              field='items_gift'
              setValue={setValue}
              initialValues={initialValues}
              offerType={offerType}
            />
          </Col>

        </Row>
    </div>
  )
}

export default N_PRODUCT_X_FREE