import React, { useState } from 'react'
import Select from 'react-select'
import { useGetProducts } from '../../hooks/useQueryHelpers';
import { useTranslation } from 'react-i18next';
import toastAlert from '../../helpers/toastAlert';
import { inventoryApi } from '../../apis/Inventory/api';

const SearchProduct = ({ isDisabled, onChange, value, menuPortalTarget }) => {

    const [loadingProduct, setLoadingProduct] = useState(false);

    const { t } = useTranslation();

    const { products: { list: products }, isLoading: loadingProducts } = useGetProducts({
        page: 1,
        page_size: 'all',
        minimized: 1
    })

    const onSelect = async (productId) => {
        try {
          setLoadingProduct(true);
          const { data: { data: product }} = await inventoryApi.products.getOne(productId);
          if (typeof onChange === 'function') onChange(product);
        } catch (error) {
          toastAlert({ type: 'error' })
        } finally {
          setLoadingProduct(false);
        }
    }

    return (
        <Select 
            value={value ?? null}
            options={products?.map(product => ({
                value: product.id,
                label: product.name
            }))}
            placeholder={t("Search Product")}
            isLoading={loadingProduct || loadingProducts}
            isDisabled={loadingProduct || loadingProducts || isDisabled}
            onChange={(e) => onSelect(e.value)}
            menuPortalTarget={menuPortalTarget}
        />
    )
}

export default SearchProduct