import React, { useEffect, useRef, useState } from 'react'
import { useGetProducts } from '../../../hooks/useQueryHelpers';
import usePagination from '../../../hooks/usePagination';
import { useLocalStorage } from '../../../hooks/useLocalStorage';
import { objectFilter } from '../../../helpers/objectFilter';
import ProductControlTable from './Table';
import CustomLabel from '../../Custom/CustomLabel';
import { Col, Row } from 'reactstrap';
import CustomOption from '../../Custom/CustomOption';
import { getAllBranchesApi } from '../../../apis/Settings/branchesApi';
import { useQuery } from '@tanstack/react-query';

const ControlForm = ({ type, }) => {

  const { currentPage, navigateToPage } = usePagination();

  const timeout = useRef(null);

  const { data } = useQuery(["branches"], () => getAllBranchesApi({ page_size: 'all' }));

  const branches = data?.data?.data;
  
  const [name, setName] = useState(null);

  const [code, setCode] = useState(null);
  
  const {values: { [`${type}Filter`]: localFilterValues }, setNewValues: setLocalFilterValues} = useLocalStorage({
    [`${type}Filter`]: {
      category_id: '',
      branche_id: '',
      daily: '',
      page_size: 10
    }
  })

  const { isLoading, products, isRefetching, refetch } = useGetProducts(objectFilter({
    page: currentPage,
    name,
    code,
    ...localFilterValues,
  }), {
    enabled: false,
    cacheTime: 0
  });

  const setPageSize = (val) => {
    setLocalFilterValues({
      [`${type}Filter`]: {
          page_size: val
        }
    })
  }
  useEffect(() => {
    if (Array.isArray(branches) && branches?.length && !localFilterValues.branche_id) setLocalFilterValues({
      [`${type}Filter`]: {
        branche_id: branches[0].id
      }
    })
  }, [branches])

  const debounceSearch = () => {
    clearTimeout(timeout.current);
    timeout.current = setTimeout(() => refetch(), 500);
  }

  useEffect(() => {
    if (Array.isArray(branches)) {
      if (branches.length) {
        if (localFilterValues.branche_id && !isRefetching) refetch();
      } else {
        refetch();
      }
    }
  }, [localFilterValues, currentPage]);

  useEffect(() => {
    if (Array.isArray(branches) && (name !== null || code !== null)) {
      if (branches.length) {
        if (localFilterValues.branche_id && !isRefetching) debounceSearch();
      } else {
        debounceSearch();
      }
    }
  }, [name, code])

  return (
      <Row className="mt-3">

        <Col lg={3}>
          <CustomLabel>Branch</CustomLabel>
          <select
            className="form-select"
            value={localFilterValues.branche_id}
            onChange={(e) => setLocalFilterValues({
              [`${type}Filter`]: {
                branche_id: e.target.value
              }
            })}
          >
            {branches?.map(branch => (
              <option id={branch.id} value={branch.id}>{branch.name}</option>
            ))}
          </select>
        </Col>

        <Col lg={12}>
          <ProductControlTable 
              data={products}
              setPageSize={setPageSize}
              navigateToPage={navigateToPage}
              refetch={refetch}
              code={code}
              setCode={setCode}
              name={name}
              setName={setName}
              filters={localFilterValues}
              setFilters={setLocalFilterValues}
              isLoading={isLoading || isRefetching}
              type={type}
          />
        </Col>
      </Row>
  )
}

export default ControlForm