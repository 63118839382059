import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Card, Col, Row } from 'reactstrap'
import BreadCrumb from '../../../Components/Common/BreadCrumb'
import VatTable from '../../../Components/Food/Vat/Table'
import PageContainer from '../../../Components/Layout/PageContainer'
import useDeleteModal from '../../../hooks/useDeleteModal'
import usePagination from '../../../hooks/usePagination'
import { deleteFoodAction, getFoodDataAction } from '../../../redux/actions/foodActions'
import debounce from "lodash.debounce";
import { objectFilter } from '../../../helpers/objectFilter'
import { useLocalStorage } from '../../../hooks/useLocalStorage'
import Restricted from '../../../Components/Guard/Restricted'
import CreateButton from '../../../Components/Common/Buttons/CreateButton'
import DeleteButton from '../../../Components/Common/Buttons/DeleteButton'
import useFormModal from '../../../hooks/useFormModal'
import VatModal from '../../../Components/Food/Vat/Create/Modal'

const Vat = () => {

  const { currentPage, navigateToPage } = usePagination();

  const {selectedItems, setSelectedItems, load, isLoading, modal, setModal, callback, setIsLoading} = useDeleteModal();

  const {isOpen: modalOpened, toggleModal, type,  openModal, initialValues} = useFormModal();

  const [name, setName] = useState(null);
  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch();

  const {values: {
      vatFilter: localFilterValues
  }, setNewValues: setLocalFilterValues} = useLocalStorage({
      vatFilter: {
        page_size: 10
      }
  })

  const getVat = (byName) => {
    if (byName) navigateToPage(1);
    setLoading(true);
    dispatch(getFoodDataAction({
      field: 'vat',
      params: objectFilter({
        page: byName ? 1 : currentPage,
        ...localFilterValues,
        name
      })
    }, {
      onSuccess() {
        setLoading(false);
      },
      onError(error) {
        setLoading(false);
      }
    }))
  }

  const setPageSize = (val) => {
    setLocalFilterValues({
        vatFilter: {
          page_size: val
        }
    })
  }

  const handleDelete = () => {
    load();
    const ids = selectedItems.map(item => item.id);
    dispatch(deleteFoodAction({
      field: 'vat',
      data: {
        ids
      }
    }, {
      onSuccess() {
        getVat();
        callback();
      },
      onError() {
        setIsLoading(false);
      }
    }))
  }

  const debounceSearch = useMemo(() => {
    return debounce(() => getVat(true), 200);
  }, [localFilterValues, currentPage, name]);

  useEffect(() => {
    if (name !== null) debounceSearch();
  }, [name])

  useEffect(() => {
    getVat();
  }, [localFilterValues, currentPage])

  return (
    <PageContainer>
        <BreadCrumb 
          pageTitle="Food" 
          title="VAT"
        />

        <Row className='justify-content-between gy-3'>
        
        <Col md={3} xs={12} className="d-flex gap-2">

          <CreateButton 
            onClick={() => openModal("create")}
          />

          <Restricted allowed={['super-admin', 'developer']}>
            <DeleteButton
                disabled={!selectedItems.length}
                onClick={() => setModal(true)}
            />
          </Restricted>
        </Col>

        <VatModal 
            type={type}
            isOpen={modalOpened}
            toggleModal={toggleModal}
            initialValues={initialValues}
        />

      </Row>

      <Card className='mt-3'>
        <VatTable 
            loading={loading}
            navigateToPage={navigateToPage}
            pageSize={localFilterValues.page_size}
            setPageSize={setPageSize}
            setSelectedItems={setSelectedItems}
            modal={modal}
            setModal={setModal}
            isLoading={isLoading}
            handleDelete={handleDelete}
            name={name}
            setName={setName}
            openFormModal={openModal}
        />
      </Card>

    </PageContainer>
  )
}

export default Vat