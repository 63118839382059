import { useState } from "react";

export const useFormModal = () => {
    
    const [isOpen, setIsOpen] = useState(false);
    const [type, setType] = useState('create');

    const openModal = () => {
        setIsOpen(true)
    }

    const closeModal = () => {
        setIsOpen(false)
    }

    const toggleModal = () => {
        setIsOpen((current) => !current)
    }

    const openCreateModal = (cb) => {
        if (cb) {
            cb();
        }
        setType('create')
        openModal();
    }

    const openUpdateModal = (cb) => {
        if (cb) {
            cb();
        }
        setType('update');
        openModal();
    }

    return {
        type, isOpen, openModal, toggleModal, closeModal, openCreateModal, openUpdateModal
    }

}