import React, { useEffect } from 'react'
import CustomInput from '../../../Custom/CustomInput';
import CustomLabel from '../../../Custom/CustomLabel';
import { formatExample } from '../../../../helpers/formatExample';
import CustomOption from '../../../Custom/CustomOption';
import { errorMessage } from '../../../../helpers/errorMessage';

const CouponDiscount = ({initialValues, register, setValue, discount, setDiscount, errors}) => {

    useEffect(() => {
        if (discount === 'discount_rate') {
            setValue('qr_amount', null);
        } else if (discount === 'qr_amount') {
            setValue('discount_rate', null);
        }
    }, [discount])

    useEffect(() => {
        if (initialValues) {
            if (initialValues?.discount_rate) {
                setDiscount("discount_rate")
            } else {
                setDiscount("qr_amount")
            }
        } else {
            setDiscount('discount_rate')
        }
    }, [initialValues])

    if (!discount) return null

  return (
    <>
        <CustomLabel>Discount</CustomLabel>
        <div className="d-flex gap-2">

            <CustomInput
                {...register(discount)}
                type="number"
                max="100"
                placeholder={formatExample("10")}
            />

            <select
                className="form-select"
                onChange={(e) => setDiscount(e.target.value)}
                value={discount}
            >

                <CustomOption
                    value="discount_rate"
                >
                    Rate
                </CustomOption>

                <CustomOption
                    value="qr_amount"
                >
                    Amount
                </CustomOption>

            </select>
        </div>
        {errorMessage(errors, discount)}
    </>
  )
}

export default CouponDiscount