import React from 'react'
import defaultAvatar from '../../assets/images/users/default.jpg';

const UserAvatar = ({image}) => {

  return (
    <img data-tag="allowRowEvents" src={image || defaultAvatar} alt="" className="avatar-xs rounded-circle me-2" />
  )
}

export default UserAvatar