import { useState } from "react";

export default function usePagination() {
  
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const navigateToPage = (page) => {
    setCurrentPage(page);
  };

  const changePageSize = (pageSize) => {
    setPageSize(pageSize);
  };

  return {
    currentPage,
    setCurrentPage,
    navigateToPage,
    pageSize,
    setPageSize,
    changePageSize,
  };
}
