import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import CustomButton from "../../Custom/CustomButton";
import DayTimeRow from "./DayTimeRow";

const OrderCloseDays = ({ openingHours, setOpeningHours, forBranch }) => {
  
  const { t } = useTranslation();

  const addNewTime = (openingHourID) => {
    setOpeningHours((current) => {
      return current.map((item) => {
        if (item.id === openingHourID) {
          return {
            ...item,
            times: [
              ...item.times,
              {
                id: Math.random(),
                isNew: true,
                start: "00:00",
                end: "00:00",
              },
            ],
          };
        }
        return item;
      });
    });
  };

  const handleChange = ({ id, key, value }) => {
    setOpeningHours((current) => {
      return current.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            [key]: value,
          };
        }
        return item;
      });
    });
  };

  const handleTimeChange = ({ id, timeID, key, value }) => {
    setOpeningHours((current) => {
      return current.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            times: item.times.map((time) => {
              if (time.id === timeID) {
                return {
                  ...time,
                  [key]: value,
                };
              }
              return time;
            }),
          };
        }
        return item;
      });
    });
  };

  return (
    <table className="table table-nowrap align-middle table-borderless mb-0">
      <thead className="border-top">
        <tr>
          <th scope="col">{t("Day")}</th>
          <th scope="col">{t("Open")}</th>
          <th scope="col">{t("Start")}</th>
          <th scope="col">{t("End")}</th>
          <th scope="col" className="">
            {t("Actions")}
          </th>
        </tr>
      </thead>
      <tbody>
        {openingHours.map((openingHour) => {
          const hasTime = openingHour.times.length >= 1;
          return (
            <Fragment key={openingHour.id}>
              <tr>
                <td>{openingHour.day?.name}</td>
                <td>
                  <div className="form-check form-switch">
                    <input
                      name="active"
                      className="form-check-input fs-16"
                      type="checkbox"
                      role="switch"
                      checked={openingHour.open}
                      onChange={(e) =>
                        handleChange({
                          id: openingHour.id,
                          key: "open",
                          value: e.target.checked,
                        })
                      }
                    />
                  </div>
                </td>

                {hasTime ? (
                  openingHour.times.slice(0, 1).map((time) => {
                    return (
                      <Fragment key={time.id}>
                        <DayTimeRow
                          handleTimeChange={handleTimeChange}
                          setOpeningHours={setOpeningHours}
                          openingHour={openingHour}
                          time={time}
                          forBranch={forBranch}
                        />
                      </Fragment>
                    );
                  })
                ) : (
                  <td colSpan={2} style={{}}>
                    <CustomButton
                      style={{
                        display: "block",
                        width: "100%",
                      }}
                      size="sm"
                      onClick={() => addNewTime(openingHour.id)}
                    >
                      Add
                    </CustomButton>
                  </td>
                )}
              </tr>
              {openingHour.times.slice(1).map((time) => {
                return (
                  <tr key={time.id}>
                    <td colSpan={2}></td>
                    <DayTimeRow
                      handleTimeChange={handleTimeChange}
                      setOpeningHours={setOpeningHours}
                      openingHour={openingHour}
                      time={time}
                      forBranch={forBranch}
                    />
                  </tr>
                );
              })}

              {!!hasTime && (
                <tr
                  style={{  borderBottom: "1px solid #e6e6e6" }}
                >
                  <td></td>
                  <td></td>
                  <td
                    colSpan={2}
                    style={{
                      paddingTop: 0,
                    }}
                  >
                    <div>
                      <CustomButton
                        style={{
                          display: "block",
                          width: "100%",
                        }}
                        size="sm"
                        onClick={() => addNewTime(openingHour.id)}
                      >
                        Add
                      </CustomButton>
                    </div>
                  </td>
                  <td></td>
                </tr>
              )}

            </Fragment>
          );
        })}
      </tbody>
    </table>
  );
};

export default OrderCloseDays;
