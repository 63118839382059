import React from "react";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import PageContainer from "../../../Components/Layout/PageContainer";
import GroupsForm from "../../../Components/Users/Groups/Form";

const CreateGroup = () => {
  return (
    <PageContainer>
      <BreadCrumb pageTitle="Users" title="Create" />
      <GroupsForm type="Create" />
    </PageContainer>
  );
};

export default CreateGroup;
