import React from 'react'
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import PageContainer from '../../../Components/Layout/PageContainer'
import { useGetReportTypes } from '../../../hooks/useQueryHelpers';
import { Card } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { getPathname } from '../../../Layouts/LayoutMenuData';

const Reports = () => {

  const { isLoading, data } = useGetReportTypes();

  const { t } = useTranslation();

  const reportTypes = data?.data?.data;

  return (
    <PageContainer isLoading={isLoading || !reportTypes}>
        <BreadCrumb 
            pageTitle="Reports"
            title="Reports"
        />
        {!!reportTypes && 
          <Card className="d-flex flex-column gap-4 p-4">
            {Object.keys(reportTypes).map((reportTypeKey, index) => {
              const types = reportTypes[reportTypeKey];
              return (
                <div key={index}>
                  <h3>{t(reportTypeKey)}</h3>
                  <div 
                    className='mt-4'
                    style={{
                      display: 'grid',
                      gridTemplateColumns: 'repeat(3, 1fr)',
                      gap: '1rem'
                    }}
                  >
                    {types?.map((type, typeIndex) => (
                      <Link key={typeIndex} to={getPathname(`reports/${type.value}`)}>
                        <div 
                          className="p-3 d-flex justify-content-between align-items-center gap-2 border" 
                        >
                          <span className='fs-18'>{type.name}</span>
                          <i class="ri-arrow-right-line fs-20"></i>
                        </div>
                      </Link>
                    ))}
                  </div>
                </div>
              )
            })}
          </Card>
        }
    </PageContainer>
  )
}

export default Reports