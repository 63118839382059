import React from 'react'
import { useTranslation } from 'react-i18next'
import { Row, Col } from 'reactstrap'
import CustomLabel from '../../Custom/CustomLabel';

const ClientDeliveryInfo = ({ register, ...props }) => {

    const { t } = useTranslation();

  return (
    <Row {...props}>
        <div>{t('CLient Delivery Information')}</div>
        <Col md={4}>
            <div className="form-check form-switch">
                <input
                    name="active"
                    className="form-check-input fs-16"
                    type="checkbox"
                    role="switch"
                    {...register("show_note")}
                />
                <CustomLabel>Show Note</CustomLabel>
            </div>
        </Col>

        <Col md={4}>
            <div className="form-check form-switch">
                <input
                    name="active"
                    className="form-check-input fs-16"
                    type="checkbox"
                    role="switch"
                    {...register("show_block")}
                />
                <CustomLabel>Show Block</CustomLabel>
            </div>
        </Col>

        <Col md={4}>
            <div className="form-check form-switch">
                <input
                    name="active"
                    className="form-check-input fs-16"
                    type="checkbox"
                    role="switch"
                    {...register("show_floor")}
                />
                <CustomLabel>Show Floor</CustomLabel>
            </div>
        </Col>

        <Col md={4}>
            <div className="form-check form-switch">
                <input
                    name="active"
                    className="form-check-input fs-16"
                    type="checkbox"
                    role="switch"
                    {...register("show_apartment")}
                />
                <CustomLabel>Show Apartement</CustomLabel>
            </div>
        </Col>

    </Row>
  )
}

export default ClientDeliveryInfo