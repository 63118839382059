import { useQuery } from "@tanstack/react-query";
import React, { useState } from "react";
import { useParams } from "react-router";
import { Col, Row } from "reactstrap";
import { getOneOrderApi } from "../../../apis/Orders/ordersApi";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import PageContainer from "../../../Components/Layout/PageContainer";
import OrderDetailsControl from "../../../Components/Orders/Orders/OrderDetailsControl";
import OrderDetailsInfo from "../../../Components/Orders/Orders/OrderDetailsInfo";
import OrderDetailsItems from "../../../Components/Orders/Orders/OrderDetailsItems";
import { useGetOrderStatus } from "../../../hooks/useQueryHelpers";

const OrderDetails = () => {
  
  const { id } = useParams();

  const [order, setOrder] = useState(null);

  const { status } = useGetOrderStatus();

  const { isLoading, isRefetching } = useQuery(["orders", id], () => getOneOrderApi(id), {
    cacheTime: 0,
    onSuccess(data) {
      setOrder(data.data.data);
    },
  });

  return (
    <PageContainer isLoading={isLoading || isRefetching}>
      {order ? (
        <>
          <BreadCrumb title="Details" pageTitle="Orders" to="orders" />
          <Row>
            <Col xl={9}>
              <OrderDetailsItems order={order} />
            </Col>
            <Col xl={3}>
              <OrderDetailsInfo order={order} />
            </Col>
            <Col>
              <OrderDetailsControl
                status={status}
                order={order}
                setOrder={setOrder}
              />
            </Col>
          </Row>
        </>
      ) : (
        <></>
      )}
    </PageContainer>
  );
};

export default OrderDetails;
