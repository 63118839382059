import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

//import Components
import LanguageDropdown from "../Components/Common/LanguageDropdown";
import ProfileDropdown from "../Components/Common/ProfileDropdown";
import LightDark from "../Components/Common/LightDark";
import { useSelector } from "react-redux";
import { useLocalStorage } from "../hooks/useLocalStorage";
import Navbar from "./Navbar";
import NotificationDropdown from "../Components/Notfications/NotificationDropdown";

const Header = ({ onChangeLayoutMode, layoutModeType, headerClass }) => {

  const { setup } = useSelector((state) => state.settings);

  const location = useLocation();

  const toogleMenuBtn = () => {
    var windowSize = document.documentElement.clientWidth;

    if (windowSize > 767)
      document.querySelector(".hamburger-icon").classList.toggle("open");

    //For collapse horizontal menu
    if (document.documentElement.getAttribute("data-layout") === "horizontal") {
      document.body.classList.contains("menu")
        ? document.body.classList.remove("menu")
        : document.body.classList.add("menu");
    }

    //For collapse vertical menu
    if (document.documentElement.getAttribute("data-layout") === "vertical") {
      if (windowSize < 1025 && windowSize > 767) {
        document.body.classList.remove("vertical-sidebar-enable");
        document.documentElement.getAttribute("data-sidebar-size") === "sm"
          ? document.documentElement.setAttribute("data-sidebar-size", "")
          : document.documentElement.setAttribute("data-sidebar-size", "sm");
      } else if (windowSize > 1025) {
        document.body.classList.remove("vertical-sidebar-enable");
        document.documentElement.getAttribute("data-sidebar-size") === "lg"
          ? document.documentElement.setAttribute("data-sidebar-size", "sm")
          : document.documentElement.setAttribute("data-sidebar-size", "lg");
      } else if (windowSize <= 767) {
        document.body.classList.add("vertical-sidebar-enable");
        document.documentElement.setAttribute("data-sidebar-size", "lg");
      }
    }

    //Two column menu
    if (document.documentElement.getAttribute("data-layout") === "twocolumn") {
      document.body.classList.contains("twocolumn-panel")
        ? document.body.classList.remove("twocolumn-panel")
        : document.body.classList.add("twocolumn-panel");
    }
  };


  const {setNewValues: setLocalLayoutModeValues} = useLocalStorage({
    layoutModeTypes: {
        theme: 'LIGHTMODE'
    }
  })

  useEffect(() => {
    if (document.body.classList.contains('menu')) document.body.classList.remove('menu');
  }, [location.pathname])

  return (
    <React.Fragment>
      <header id="page-topbar" className={headerClass}>
        <div className="layout-width">
          <div className="navbar-header">
            <div className="d-flex">
              <div className="navbar-brand-box horizontal-logo">
                <Link to="/panel" className="logo">
                  <span>
                    <img src={setup.logo} alt="" height="35" />
                  </span>
                </Link>
              </div>

              <button
                onClick={toogleMenuBtn}
                type="button"
                className="btn btn-sm px-3 fs-16 header-item vertical-menu-btn topnav-hamburger"
                id="topnav-hamburger-icon"
              >
                <span className="hamburger-icon">
                  <span></span>
                  <span></span>
                  <span></span>
                </span>
              </button>
            </div>

            <Navbar />

            <div className="d-flex align-items-center">

              <LanguageDropdown />

              <NotificationDropdown />

              {/* Dark/Light Mode set */}
              <LightDark
                  layoutMode={layoutModeType}
                  onChangeLayoutMode={onChangeLayoutMode}
                  setLocalLayoutModeValues={setLocalLayoutModeValues}
              />

              {/* ProfileDropdown */}
              <ProfileDropdown />
            </div>
          </div>
        </div>
      </header>
    </React.Fragment>
  );
};

export default Header;
