import React from 'react'
import BreadCrumb from '../../../Components/Common/BreadCrumb'
import CategoryForm from '../../../Components/Food/Categories/Form'
import PageContainer from '../../../Components/Layout/PageContainer'
import { useFetchByLang } from '../../../hooks/useFetchByLang'

const CreateCategory = () => {

  const { language, setLanguage } = useFetchByLang();

  return (
    <PageContainer>
        <BreadCrumb pageTitle="Categories" title="Create" to="food/categories"/>
        <CategoryForm
          type="create"
          language={language}
          setLanguage={setLanguage}
        />
    </PageContainer>
  )
}

export default CreateCategory