import React from "react";
import { useSelector } from "react-redux";
import { Card } from "reactstrap";
import { isAllowed } from "../../../helpers/isAllowed";
import DataTableContainer from "../../Common/DataTableContainer";
import CustomTableHeading from "../../Custom/CustomTableHeading";
import StatusToggle from "../../Common/StatusToggle";
import { getPathname } from "../../../Layouts/LayoutMenuData";

const ProductUnitsTable = ({
  data,
  selectItems,
  modal,
  setModal,
  onDeleteClick,
  isDeleting,
  navigateToPage,
  setPageSize,
  isRefetching,
}) => {

  const { user } = useSelector(state => state.admin);

  const columns = [
    {
      name: <CustomTableHeading>ID</CustomTableHeading>,
      selector: (row) => row.id,
    },
    {
      name: <CustomTableHeading>Name</CustomTableHeading>,
      selector: (row) => row.name,
    },
    {
      name: <CustomTableHeading>Is weight</CustomTableHeading>,
      selector: (row) => <StatusToggle type="product_units" id={row.id} field="is_weight" status={row.is_weight} />,
    },

  ];
  
  return (
    <Card>
      <DataTableContainer
        selectableRows={isAllowed(user)}
        data={data.list}
        paginationTotalRows={data.total}
        isDeleting={isDeleting}
        handleDelete={onDeleteClick}
        modal={modal}
        setPageSize={setPageSize}
        paginationServer
        loading={isRefetching}
        setModal={setModal}
        selectItems={selectItems}
        navigateToPage={navigateToPage}
        columns={columns}
        path={getPathname("inventory/units")}
      />
    </Card>
  );
};

export default ProductUnitsTable;
