import React, { useState } from "react";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import GalleryForm from "../../../Components/Settings/Gallery/Form";
import PageContainer from "../../../Components/Layout/PageContainer";
import { useQuery } from "@tanstack/react-query";
import { getOneGalleryApi } from "../../../apis/Settings/galleryApi";
import { useParams } from "react-router-dom";
import { useFetchByLang } from "../../../hooks/useFetchByLang";

const UpdateGallery = () => {
  const { id } = useParams();

  const [initValue, setInitValue] = useState(null);

  const { refetch, isLoading, isRefetching } = useQuery(["gallery", id], () => getOneGalleryApi(id, language), {
    enabled: false,
    cacheTime: 0,
    onSuccess(data) {
      const { id, name, active, details, file } = data.data.data;
      const body = {
        id,
        name: name || "",
        active: !!active,
        details,
        file,
      };
      setInitValue(body);
    },
  });

  const { language, setLanguage } = useFetchByLang(refetch);

  return (
    <PageContainer isLoading={isLoading || isRefetching}>
      <BreadCrumb pageTitle="Gallery" title="Update" to="settings/gallery" />
      {initValue && 
        <GalleryForm 
          type="update" 
          initialValues={initValue} 
          language={language}
          setLanguage={setLanguage}
        />
      }
    </PageContainer>
  );
};

export default UpdateGallery;
