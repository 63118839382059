import React from "react";
import { Col, Row } from "reactstrap";
import CustomInput from "../../Custom/CustomInput";
import CustomLabel from "../../Custom/CustomLabel";
import CustomOption from "../../Custom/CustomOption";
import { getSmsServicesApi } from "../../../apis/Orders/smsSettingsApis";
import { useQuery } from '@tanstack/react-query'
import PaySms from "./PaySms";

const SmsSettingsTab = ({ value, handleChange }) => {

  const { data } = useQuery(['sms-services'], getSmsServicesApi);

  const smsServices = data?.data?.data;

  return (
    <Row className="g-3">
      <Col lg={6}>
        <CustomLabel>Name</CustomLabel>
        <CustomInput 
          name='sms_sender' 
          onChange={handleChange('settings')} 
          value={value.sms_sender} 
        />
      </Col>

      <Col lg={6}>
        <CustomLabel>SMS Service</CustomLabel>
        <select 
          name="sms_service_id" 
          className="form-select" 
          onChange={handleChange('settings')} 
          value={value.sms_service_id} 
        >
            <CustomOption value=''>Select</CustomOption>
            {smsServices?.map((service) => (
              <option key={service.id} value={service.id}>{service.name}</option>
            ))}
        </select>
      </Col>

      <Col lg={6}>
        <CustomLabel>Key</CustomLabel>
        <CustomInput 
          name='sms_service_key' 
          onChange={handleChange('settings')} 
          value={value.sms_service_key} 
        />
      </Col>

      <Col lg={6}>
        <CustomLabel>Username</CustomLabel>
        <CustomInput 
          name='sms_service_username' 
          onChange={handleChange('settings')} 
          value={value.sms_service_username} 
        />
      </Col>

      <Col lg={6}>
        <CustomLabel>Password</CustomLabel>
        <CustomInput 
          name='sms_service_password' 
          onChange={handleChange('settings')} 
          value={value.sms_service_password} 
        />
      </Col>

      <Col lg={12}>
          <PaySms smsServices={smsServices} />
      </Col>
    </Row>
  );
};

export default SmsSettingsTab;
