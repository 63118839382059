import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Redirect, Route } from "react-router-dom";
import { getPathname } from "../Layouts/LayoutMenuData";

const ProtectedRoute = ({ component: Component, allowed, ...rest }) => {
  
  const { token, user } = useSelector((state) => state.admin);

  if (!token) return <Redirect to="/login" />;

  const isAllowed = allowed?.length ? allowed?.includes(user?.role?.[0]) : true;

  if (!isAllowed && user?.role?.[0] === 'warehouse') return <Redirect to={getPathname('inventory/orders')} />

  if (!isAllowed) return <Redirect to={getPathname()} />

  return (
    <Route
      {...rest}
      render={(props) => {
        return <Component {...props} />;
      }}
    />
  );
};

export default ProtectedRoute;
