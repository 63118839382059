import React from "react";
import DataTableContainer from "../../Common/DataTableContainer";
import CustomTableHeading from "../../Custom/CustomTableHeading";
import { getPathname } from "../../../Layouts/LayoutMenuData";
import { useComplaintTypes } from "../../../hooks/useQueryHelpers";
import CustomOption from "../../Custom/CustomOption";

const ComplaintsTable = ({
  data,
  isLoading,
  complaintType,
  setComplaintType,
  complaintStatus,
  setComplaintStatus
}) => {

  const { data: complaints } = useComplaintTypes();

  const complaintTypes = complaints?.data?.data ?? [];
  
  const columns = [
    {
      name: <CustomTableHeading>ID</CustomTableHeading>,
      selector: (row) => row.id || "-",
    },
    {
      name: <CustomTableHeading>User</CustomTableHeading>,
      selector: (row) => row.user_name || "-",
    },
    {
      name: <CustomTableHeading>Admin</CustomTableHeading>,
      selector: (row) => row.admin_name || "-",
    },
    {
      name: (
        <select
          className="form-select"
          value={complaintType}
          onChange={e => setComplaintType(e.target.value)}
        >
          <CustomOption value=''>Type</CustomOption>
          {complaintTypes.map(type => (
            <option key={type} value={type}>{type}</option>
          ))}
        </select>
      ),
      selector: (row) => row.type || "-",
    },
    {
      name: (
        <select
          className="form-select text-capitalize"
          value={complaintStatus}
          onChange={e => setComplaintStatus(e.target.value)}
        >
          <CustomOption value=''>Status</CustomOption>
          {['opened', 'closed'].map(status => (
            <option key={status} value={status}>{status}</option>
          ))}
        </select>
      ),
      selector: (row) => <span className="text-capitalize">{row.status || '-'}</span>,
    },
  ];

  return (
    <DataTableContainer
      data={data}
      columns={columns}
      path={getPathname("settings/complaints")}
      loading={isLoading}
      noPagination
    />
  );
};

export default ComplaintsTable;
