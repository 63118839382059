import React, { useEffect, useRef, useState } from 'react'
import { foodApi } from '../../apis/Food/api';
import toastAlert from '../../helpers/toastAlert';
import { deleteFileApi } from '../../apis/Settings/webSettingsApi';
import { Spinner } from 'reactstrap';
import CustomLabel from '../Custom/CustomLabel';

const ImageInput = ({ bread_id, item, setData }) => {

  const [image, setImage] = useState({
    url: '',
    file: null,
    is_transparent: null,
    fileId: null
  });

  const [loading ,setLoading] = useState(false);

  const fileInputRef = useRef(null);

  const setDataFile = (file) => {
    setData(prev => prev.map(bread => {
      if (bread.id === bread_id) return {
        ...bread,
        prices: bread.prices.map(priceSize => {
          if (priceSize.id === item.id) return {
            ...priceSize,
            file
          }
          return priceSize
        })
      }
      return bread
    }))
  } 

  const removeDataFile = () => {
    setData(prev => prev.map(bread => {
      if (bread.id === bread_id) return {
        ...bread,
        prices: bread.prices.map(priceSize => {
          if (priceSize.id === item.id) return {
            ...priceSize,
            file: null
          }
          return priceSize
        })
      }
      return bread
    }))
  } 

  const uploadImage = async () => {
    if (!image.file || !item.price_id || loading) return;
    try {
      setLoading(true);
      const file = image.file;
      const formData = new FormData();
      const blob = new Blob([file], { type: file.type });
      formData.append('image', blob);
      const { data: { data } } = await foodApi.menu.priceSizeImage.update(item.price_id, formData);
      setDataFile(data.file);
      toastAlert({ type:'success' })
    } catch (error) {
      toastAlert({ type:'error' })
    } finally {
      setLoading(false);
    }
  }

  const changeImageTransparency = async () => {
    if (!item.price_id || loading) return;
    try {
      const formData = new FormData();
      formData.append('is_image_transparent', image.is_transparent === 1 ? 0 : 1);
      const { data: { data } } = await foodApi.menu.priceSizeImage.update(item.price_id, formData);
      setDataFile(data.file);
      toastAlert({ type:'success' })
    } catch (error) {
      console.log('error', error);
      toastAlert({ type:'error' })
    } 
  }

  const removeImage = async () => {
    if (loading) return;
    try {
      setLoading(true);
      if (image.fileId) {
        await deleteFileApi({ ids: [image.fileId] });
        toastAlert({ type: 'success' })
      }
      removeDataFile();
    } catch (error) {
      toastAlert({ type: 'error' })
    } finally {
      setLoading(false);
    }
  }

  const handleImageClick = () => {
    if (item.selected) fileInputRef.current.click();
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setImage({
      url: URL.createObjectURL(selectedFile),
      file: selectedFile,
      fileId: null
    });
  };

  useEffect(() => {
    if (item.file) {
      setImage({
        url: item.file?.link,
        fileId: item.file.id,
        is_transparent: item.file.is_transparent,
        file: null
      })
    } else {
      setImage({
        url: '',
        file: null,
        is_transparent: null,
        fileId: null
      })
    }
  }, [item])

  useEffect(() => {
    if (image.file) uploadImage();
  }, [image.file])

  return (
   <div className='d-flex flex-column gap-3 align-items-center'>
     <div 
        className={`image-input-container ${image.url ? 'has-image' : ''} ${loading ? 'loading' : ''}`}
        style={{
          pointerEvents: item.selected && item.price_id ? 'all' : 'none'
        }}
    >
        <>
        {loading && 
          <div className='loading-indicator'>
            <Spinner />
          </div>}

          {!image.url ? 
                <div 
                  className='placeholder-container'
                  onClick={handleImageClick}
                >
                    <i className="ri-image-2-line"></i>
                    <i className="ri-add-line add-image-button"></i>
                </div>
            : 
              <div className='image-display-container'>
                <img src={image.url} alt={`size ${item.name} image`} />
              </div>
            }

            <input
              type="file"
              accept="image/*"
              style={{ display: 'none' }}
              ref={fileInputRef}
              onChange={handleFileChange}
            />

          {image.url && 
            <i 
              className="ri-subtract-line remove-image-button"
              onClick={removeImage}
            ></i>
          }
        </>
    </div>

    {image?.is_transparent !== null &&
        <div className="form-check form-switch">
            <input 
              className="form-check-input fs-16" 
              checked={image.is_transparent} 
              type="checkbox" 
              role="switch" 
              id={`is_file_${image.fileId}_transparent`} 
              onChange={changeImageTransparency}
            />
            <CustomLabel className="form-check-label" htmlFor={`is_file_${image.fileId}_transparent`}>Is Transparent</CustomLabel>
        </div>
    }
   </div>
  )
}

export default ImageInput