import classNames from "classnames";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { CardFooter, Col, Row } from "reactstrap";
import { updateOrderApi } from "../../../apis/Orders/ordersApi";
import generateInterval from "../../../data/generateInterval";
import toastAlert from "../../../helpers/toastAlert";
import useActions from "../../../hooks/useActions";
import CustomButton from "../../Custom/CustomButton";
import CustomLabel from "../../Custom/CustomLabel";
import CustomTextArea from "../../Custom/CustomTextArea";

const OrderAdminControl = ({ order, status, setOrder }) => {

  const { t } = useTranslation();

  const { isLoading, onLoading, onFinally } = useActions();

  const [errors, setErrors] = useState({
    delivery_time: null,
    admin_note: null,
    status_id: null
  })

  const times = generateInterval({});

  const [data, setData] = useState({
    delivery_time: order.delivery_time || "",
    admin_note: order.admin_note || "",
    status_id: order.current_status?.id,
  });

  const handleChange = (prop, val) => {

    setData((current) => {
      return {
        ...current,
        [prop]: val,
      };
    });

    if (errors[prop]) {
      setErrors({
        ...errors,
        [prop]: null
      })
    }
  };

  const validate = (callback) => {
    if (data.status_id === 2 || status.find(item => item.id === data.status_id)?.name === 'Accepted') {
      if (!data.delivery_time) return setErrors({
        ...errors,
        delivery_time: 'Delivery time is required'
      })
    }
    setErrors({
      delivery_time: null,
      admin_note: null,
      status_id: null
    })
    return callback();
  }

  const onSubmit = async () => {
    onLoading();
    try {
      const { data: response } = await updateOrderApi(order.id, data);
      setOrder(response.data);
      toastAlert({ type: 'success' });
    } catch (error) {
      toastAlert({ type: 'error' });
    } finally {
      onFinally();
    }
  };

  return (
    <Row className="gy-4">
      <Col xs={12}>
        <CustomLabel>Status</CustomLabel>
        <div className="selectable-tabs-container">
          {status.map((state) => (
            <div
              key={state.id}
              className={classNames("selectable-tab py-1", {"selected-tab": state.id === data.status_id})}
              onClick={() => handleChange("status_id", state.id)}
            >
              {t(state.name)}
            </div>
          ))}
        </div>
      </Col>
      <Col md={6}>
        <CustomLabel>{t("Delivery time")}</CustomLabel>
        <select
          className="form-select"
          value={data.delivery_time}
          onChange={(e) => handleChange("delivery_time", e.target.value)}
        >
          <option value=""></option>
          {times.map((time) => {
            return (
              <option key={time} value={time}>
                {time > 9 ? time : `0${time}`}
              </option>
            );
          })}
        </select>
        {errors.delivery_time && <span className="text-danger">{errors.delivery_time}</span>}
      </Col>
      <Col xs={12}>
        <CustomLabel>{t("Admin note")}</CustomLabel>
        <CustomTextArea
          value={data.admin_note}
          onChange={(e) => handleChange("admin_note", e.target.value)}
          rows={2}
        />
      </Col>
      <CardFooter
        style={{
          paddingBottom: 0,
        }}
      >
        <CustomButton onClick={() => validate(onSubmit)} isLoading={isLoading} color="success">
          Save
        </CustomButton>
      </CardFooter>
    </Row>
  );
};

export default OrderAdminControl;
