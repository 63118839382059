import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";
import toastAlert from "../../../helpers/toastAlert";
import { getPathname } from "../../../Layouts/LayoutMenuData";
import { deleteLanguageAction } from "../../../redux/actions/languageActions";
import CoverSpinner from "../../Common/CoverSpinner";
import DataTableContainer from "../../Common/DataTableContainer";
import useSelectMultiple from "../../../hooks/useSelectMultiple";
import StatusToggle from "../../Common/StatusToggle";

const LanguagesTable = ({ data }) => {

  const { t } = useTranslation();

  const {modal: deleteModal, setModal: setDeleteModal, isDeleting, load, callback} = useSelectMultiple();

  const [langToDelete, setLangToDelete] = useState(null);

  const dispatch = useDispatch();

  const handleDelete = () => {
    load();
    dispatch(
      deleteLanguageAction(langToDelete, {
        onSuccess() {
          callback();
          setLangToDelete(null);
          toastAlert({ type: 'success' });
        },
        onError() {
          toastAlert({ type: 'error' });
        },
      })
    );
  };

  const columns = [
    {
      name: <span className="font-weight-bold fs-13">{t("ID")}</span>,
      selector: (row, i) => i + 1,
      grow: -1,
    },
    {
      name: <span className="font-weight-bold fs-13">{t("Name")}</span>,
      selector: (row) => row.name || "-",
    },
    {
      name: <span className="font-weight-bold fs-13">{t("Code")}</span>,
      selector: (row) =>
        row.code ? <span className="text-uppercase">{row.code}</span> : "-",
    },
    {
      name: <span className="font-weight-bold fs-13">{t("Active")}</span>,
      selector: row => <StatusToggle type='language' id={row.id} field="active" status={row.active} />,
    },
    {
      name: <span className="font-weight-bold fs-13 ">{t("Actions")}</span>,
      selector: (row) => (
        <div className="d-flex align-items-center gap-3 mx-auto">
          <Link 
            to={{pathname: getPathname(`settings/language/keys/${row.code}`), state: {name: row.name}}}
          >
            <Button className="rounded-pill  btn-soft-primary">
              <i className="ri-translate"></i>
            </Button>
          </Link>

          <Link 
            to={getPathname(`settings/language/update/${row.code}`)}
          >
            <Button className="rounded-pill btn-soft-success">
              <i className="ri-file-edit-line"></i>
            </Button>
          </Link>
          {row.code !== "en" && (
            <>
              <Button
                onClick={() => {
                  setDeleteModal(true);
                  setLangToDelete(row.code);
                }}
                className="rounded-pill  btn-soft-danger"
              >
                <i className="ri-delete-bin-line"></i>
              </Button>
            </>
          )}
        </div>
      ),
    },
  ];

  return (
    <React.Fragment>
      <DataTableContainer 
        data={data} 
        columns={columns} 
        modal={deleteModal}
        setModal={setDeleteModal}
        isDeleting={isDeleting}
        handleDelete={handleDelete}
      />
    </React.Fragment>
  );
};

export default LanguagesTable;
