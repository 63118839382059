import * as yup from "yup";

const adminSchema = yup.object().shape({
  name: yup.string().required("Required"),
  username: yup.string().required("Required"),
  email: yup
    .string()
    .required("Required")
    .email("Please provide a valid email"),
  role: yup.string().required("Required"),
});

export default adminSchema;
