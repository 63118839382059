import React from "react";

const TogglePassword = ({ visible, setVisible }) => {
  const toggle = () => {
    setVisible((current) => !current);
  };

  return (
    <button type="button" className="btn btn-primary btn-sm" onClick={toggle}>
      {visible ? (
        <i className="ri-eye-line cursor-pointer fs-15"></i>
      ) : (
        <i className="ri-eye-off-line cursor-pointer fs-15"></i>
      )}
    </button>
  );
};

export default TogglePassword;
