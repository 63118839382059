import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { Card, Col, Row } from 'reactstrap'
import BreadCrumb from '../../../Components/Common/BreadCrumb'
import CustomButton from '../../../Components/Custom/CustomButton'
import CustomSearchInput from '../../../Components/Custom/CustomSearchInput'
import DonenessTable from '../../../Components/Food/Doneness/Table'
import PageContainer from '../../../Components/Layout/PageContainer'
import useDeleteModal from '../../../hooks/useDeleteModal'
import usePagination from '../../../hooks/usePagination'
import { getPathname } from '../../../Layouts/LayoutMenuData'
import { deleteFoodAction, getFoodDataAction } from '../../../redux/actions/foodActions'
import debounce from "lodash.debounce";
import { objectFilter } from '../../../helpers/objectFilter'
import { useLocalStorage } from '../../../hooks/useLocalStorage'
import Restricted from '../../../Components/Guard/Restricted'
import CreateButton from '../../../Components/Common/Buttons/CreateButton'
import DeleteButton from '../../../Components/Common/Buttons/DeleteButton'

const Doneness = () => {

  const { currentPage, navigateToPage } = usePagination();

  const {selectedItems, setSelectedItems, load, isLoading, modal, setModal, callback, setIsLoading} = useDeleteModal();

  const [name, setName] = useState(null);
  const [loading, setLoading] = useState(true);


  const dispatch = useDispatch();

  const {values: {
      donenessFilter: localFilterValues
  }, setNewValues: setLocalFilterValues} = useLocalStorage({
      donenessFilter: {
        page_size: 10
      }
  })

  const getDoneness = (byName) => {
    if (byName) navigateToPage(1);
    setLoading(true);
    dispatch(getFoodDataAction({
      field: 'doneness',
      params: objectFilter({
        page: byName ? 1 : currentPage,
        ...localFilterValues,
        name
      })
    }, {
      onSuccess() {
        setLoading(false);
      },
      onError(error) {
        console.log('error', error);
        setLoading(false);
      }
    }))
  }

  const setPageSize = (val) => {
    setLocalFilterValues({
        donenessFilter: {
          page_size: val
        }
    })
  }


  const handleDelete = () => {
    load();
    const ids = selectedItems.map(item => item.id);
    dispatch(deleteFoodAction({
      field: 'doneness',
      data: {
        ids
      }
    }, {
      onSuccess() {
        getDoneness();
        callback();
      },
      onError() {
        setIsLoading(false);
      }
    }))
  }

  const debounceSearch = useMemo(() => {
    return debounce(() => getDoneness(true), 200);
  }, [localFilterValues, currentPage, name]);

  useEffect(() => {
    if (name !== null) debounceSearch();
  }, [name])

  useEffect(() => {
    getDoneness();
  }, [localFilterValues, currentPage])

  return (
    <PageContainer>

      <BreadCrumb pageTitle="Food" title="Doneness"/>

      <Row className='justify-content-between gy-3'>
        
          <Col md={3} xs={12} className="d-flex gap-2">

            <Link to={getPathname('food/doneness/create')}>
              <CreateButton />
            </Link>
            <Restricted allowed={['super-admin', 'developer']}>
              <DeleteButton
                  disabled={!selectedItems.length}
                  onClick={() => setModal(true)}
              />
            </Restricted>

          </Col>
      </Row>

      <Card className='mt-3'>
        <DonenessTable 
            loading={loading}
            navigateToPage={navigateToPage}
            pageSize={localFilterValues.page_size}
            setPageSize={setPageSize}
            setSelectedItems={setSelectedItems}
            modal={modal}
            setModal={setModal}
            isLoading={isLoading}
            handleDelete={handleDelete}
            name={name}
            setName={setName}
        />
      </Card>

    </PageContainer>
  )
}

export default Doneness