import React from "react";
import { Col, Row } from "reactstrap";
import CustomInput from "../../Custom/CustomInput";
import CustomLabel from "../../Custom/CustomLabel";
import PageTitle from "../../Page/PageTitle";

const DiscountTab = ({ register }) => {

  return (
    <div className="d-flex flex-column gap-2">

      <Row className="gy-3">

        <Col md={3}>
          <PageTitle>Student discount</PageTitle>
          <div className="form-check form-switch">
            <input
              name="active"
              className="form-check-input fs-16"
              type="checkbox"
              role="switch"
              {...register("discount_student")}
            />
            <CustomLabel>Active</CustomLabel>
          </div>
        </Col>

        <Col md={3}>
          <CustomLabel>Amount</CustomLabel>
          <CustomInput type="number" {...register("discount_student_amount")} />
        </Col>

        <Col md={3}>
          <CustomLabel>Min Amount</CustomLabel>
          <CustomInput type="number" {...register("discount_student_min_amount")} />
        </Col>

        <Col md={3}>
          <CustomLabel>Rate</CustomLabel>
          <CustomInput type="number" {...register("discount_student_rate")} />
        </Col>

      </Row>

      <div>

        <PageTitle>Web discount amount</PageTitle>

        <div className="hstack gap-5 flex-wrap">

          <div>
            <div className="form-check form-switch">
              <input
                name="active"
                className="form-check-input fs-16"
                type="checkbox"
                role="switch"
                {...register("discount_web")}
              />
              <CustomLabel>Active</CustomLabel>
            </div>
          </div>
          
          <div>
            <div className="form-check form-switch">
              <input
                name="active"
                className="form-check-input fs-16"
                type="checkbox"
                role="switch"
                {...register("discount_web_guest")}
              />
              <CustomLabel>Guest</CustomLabel>
            </div>
          </div>

          <div>
            <div className="form-check form-switch">
              <input
                name="active"
                className="form-check-input fs-16"
                type="checkbox"
                role="switch"
                {...register("discount_web_first_order")}
              />
              <CustomLabel>First order</CustomLabel>
            </div>
          </div>

          <div>
            <CustomLabel>Amount</CustomLabel>
            <CustomInput type="number" {...register("discount_web_amount")} />
          </div>

          <div>
            <CustomLabel>Min Amount</CustomLabel>
            <CustomInput type="number" {...register("discount_web_min_amount")} />
          </div>
          
          <div>
            <CustomLabel>Rate</CustomLabel>
            <CustomInput type="number" {...register("discount_web_rate")} />
          </div>

        </div>

      </div>

      <div>

        <PageTitle>App discount</PageTitle>

        <div className="hstack gap-5 flex-wrap">

          <div>
            <div className="form-check form-switch">
              <input
                name="active"
                className="form-check-input fs-16"
                type="checkbox"
                role="switch"
                {...register("discount_app")}
              />
              <CustomLabel>Active</CustomLabel>
            </div>
          </div>

          <div>
            <div className="form-check form-switch">
              <input
                name="active"
                className="form-check-input fs-16"
                type="checkbox"
                role="switch"
                {...register("discount_app_guest")}
              />
              <CustomLabel>Guest</CustomLabel>
            </div>
          </div>
          
          <div>
            <div className="form-check form-switch">
              <input
                name="active"
                className="form-check-input fs-16"
                type="checkbox"
                role="switch"
                {...register("discount_app_first_order")}
              />
              <CustomLabel>First order</CustomLabel>
            </div>
          </div>

          <div>
            <CustomLabel>Amount</CustomLabel>
            <CustomInput type="number" {...register("discount_app_amount")} />
          </div>

          <div>
            <CustomLabel>Min Amount</CustomLabel>
            <CustomInput type="number" {...register("discount_app_min_amount")} />
          </div>

          <div>
            <CustomLabel>Rate</CustomLabel>
            <CustomInput type="number" {...register("discount_app_rate")} />
          </div>
        </div>

      </div>

    </div>
  );
};

export default DiscountTab;
