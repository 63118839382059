import React, { Component } from 'react';
import ErrorPage from '../Layout/ErrorPage';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state to display the error page
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      // Render your error page component here
      return <ErrorPage />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;