import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Card, Col, Modal, ModalHeader, Row } from "reactstrap";
import CreateButton from "../../../Components/Common/Buttons/CreateButton";
import DeleteButton from "../../../Components/Common/Buttons/DeleteButton";
import DataTableContainer from "../../../Components/Common/DataTableContainer";
import UserAvatar from "../../../Components/Common/UserAvatar";
import CustomInput from "../../../Components/Custom/CustomInput";
import CustomTableHeading from "../../../Components/Custom/CustomTableHeading";
import Restricted from "../../../Components/Guard/Restricted";
import PageContainer from "../../../Components/Layout/PageContainer";
import AdminFormModal from "../../../Components/Settings/Admins/AdminFormModal";
import { isAllowed } from "../../../helpers/isAllowed";
import useActions from "../../../hooks/useActions";
import useCreateUpdateModal from "../../../hooks/useCreateUpdateModal";
import useModal from "../../../hooks/useModal";
import { useAdmins, useBranches, useRoles } from "../../../hooks/useQueryHelpers";
import useSelectItems from "../../../hooks/useSelectItems";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import CustomOption from "../../../Components/Custom/CustomOption";
import { useLocalStorage } from "../../../hooks/useLocalStorage";
import usePagination from "../../../hooks/usePagination";
import StatusToggle from '../../../Components/Common/StatusToggle'

const Admins = () => {
  const { t } = useTranslation();

  const {
    isLoading: isDeleting,
    onLoading: onDeleting,
    onFinally: onDeletingFinally,
  } = useActions();

  const { getSelectedIds, selectItems, isDisable } = useSelectItems();

  const { currentPage, navigateToPage } = usePagination();

  const { user } = useSelector((state) => state.admin);

  const timeout = useRef(null);

  const { isModalOpen, setIsModalOpen, openModal, closeModal } = useModal();

  const [query, setQuery] = useState('');

  const [userName, setUserName] = useState('');

  const { values: { adminsFilter: localFilterValues }, setNewValues: setLocalFilterValues } = useLocalStorage({
    adminsFilter: {
      branche_id: '',
      is_pos: '',
      is_kitchen: '',
      is_cs: '',
      is_dash: '',
      is_delivery: '',
      is_client_screen: '',
      page_size: 10
    }
  })

  const { admins, createNewAdmin, updateAdmin, deleteAdmins, isLoading, isRefetching, refetch, totalAdmins } = useAdmins({ ...localFilterValues, page: currentPage }, { username: userName, name: query });

  const { branches } = useBranches();

  const { roles } = useRoles();

  const {
    isModalOpen: isOpen,
    onAddClick,
    onUpdateClick,
    initValues,
    type,
    toggleModal,
  } = useCreateUpdateModal();

  const onDeleteSubmit = () => {
    onDeleting();
    const ids = getSelectedIds();
    deleteAdmins(ids, {
      onFinally: onDeletingFinally,
      onSuccess() {
        selectItems([]);
        closeModal();
      },
    });
  };
  
  const handleFilter = (field, value) => {
    setLocalFilterValues({
        adminsFilter: {
            [field]: value
        }
    })
  }

  
  const setPageSize = (val) => {
    setLocalFilterValues({
        adminsFilter: {
          page_size: val
        }
    })
  }

  useEffect(() => {
    refetch();
  }, [localFilterValues])

  useEffect(() => {
    timeout.current = setTimeout(() => refetch(), 1000);
    return () => clearTimeout(timeout.current);
  }, [query, userName])


  const columns = [
    {
      name: <CustomTableHeading>ID</CustomTableHeading>,
      selector: (row) => row.id || "-",
    },
    {
      name: (
        <CustomInput
          placeholder="Name"
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          data-filtered={!!query}
        />
      ),
      selector: (row) => (
        <div data-tag="allowRowEvents">
          <UserAvatar image={row.image} />
          {row.name}
        </div>
      ),
    },

    {
      name: (
        <CustomInput
          placeholder="Username"
          value={userName}
          onChange={(e) => setUserName(e.target.value)}
          data-filtered={!!userName}
        />
      ),
      selector: (row) => row.username,
    },

    {
      name: <CustomTableHeading>Email</CustomTableHeading>,
      selector: (row) => row.email,
    },

    {
      name: <CustomTableHeading>Role</CustomTableHeading>,
      selector: (row) => row.role.join(","),
    },

    {
      name:      
      <select
          className='form-select'
          value={localFilterValues.branche_id}
          onChange={(e) => handleFilter('branche_id', e.target.value)}
          data-filtered={!!localFilterValues.branche_id}
      >
          <CustomOption value=''>Branch</CustomOption>
          {branches.map(type => (
              <CustomOption key={type.id} value={type.id}>{type.name}</CustomOption>
          ))}
      </select>,
      selector: row => row.branch || '-',
    },

    {
      name:      
      <select
          className='form-select'
          value={localFilterValues.is_pos}
          onChange={(e) => handleFilter('is_pos', e.target.value)}
          data-filtered={!!localFilterValues.is_pos}
      >
          <CustomOption value=''>POS</CustomOption>
          {[{ label: "Is POS", value: 1 }, { label: "Not POS", value: 0}].map(pos => (
            <CustomOption id={pos.value} value={pos.value}>{pos.label}</CustomOption>
          ))}
      </select>,
      selector: row => <StatusToggle type='admins' id={row.id} field="is_pos" status={row.is_pos} />,
    },

    {
      name:      
      <select
          className='form-select'
          value={localFilterValues.is_dash}
          onChange={(e) => handleFilter('is_dash', e.target.value)}
          data-filtered={!!localFilterValues.is_dash}
      >
          <CustomOption value=''>Dashboard</CustomOption>
          {[{ label: "Is Dashboard", value: 1 }, { label: "Not Dashboard", value: 0}].map(dashboard => (
            <CustomOption id={dashboard.value} value={dashboard.value}>{dashboard.label}</CustomOption>
          ))}
      </select>,
      selector: row => <StatusToggle type='admins' id={row.id} field="is_dash" status={row.is_dash} />,
    },
    
    {
      name:      
      <select
          className='form-select'
          value={localFilterValues.is_kitchen}
          onChange={(e) => handleFilter('is_kitchen', e.target.value)}
          data-filtered={!!localFilterValues.is_kitchen}
      >
          <CustomOption value=''>Kitchen</CustomOption>
          {[{ label: "Is Kitehen", value: 1 }, { label: "Not Kitchen", value: 0}].map(kitchen => (
            <CustomOption id={kitchen.value} value={kitchen.value}>{kitchen.label}</CustomOption>
          ))}
      </select>,
      selector: row => <StatusToggle type='admins' id={row.id} field="is_kitchen" status={row.is_kitchen} />,
    },

    {
      name:      
      <select
          className='form-select'
          value={localFilterValues.is_cs}
          onChange={(e) => handleFilter('is_cs', e.target.value)}
          data-filtered={!!localFilterValues.is_cs}
      >
          <CustomOption value=''>Call Center</CustomOption>
          {[{ label: "Is Call Center", value: 1 }, { label: "Not Call Center", value: 0}].map(callCenter => (
            <CustomOption id={callCenter.value} value={callCenter.value}>{callCenter.label}</CustomOption>
          ))}
      </select>,
      selector: row => <StatusToggle type='admins' id={row.id} field="is_cs" status={row.is_cs} />,
    },

    {
      name:      
      <select
          className='form-select'
          value={localFilterValues.is_client_screen}
          onChange={(e) => handleFilter('is_client_screen', e.target.value)}
          data-filtered={!!localFilterValues.is_client_screen}
      >
          <CustomOption value=''>Client Screen</CustomOption>
          {[{ label: "Is Client Screen", value: 1 }, { label: "Not Client Screen", value: 0}].map(clientScreen => (
            <CustomOption id={clientScreen.value} value={clientScreen.value}>{clientScreen.label}</CustomOption>
          ))}
      </select>,
      selector: row => <StatusToggle type='admins' id={row.id} field="is_client_screen" status={row.is_client_screen} />,
    },

    {
      name:      
      <select
          className='form-select'
          value={localFilterValues.is_delivery}
          onChange={(e) => handleFilter('is_delivery', e.target.value)}
          data-filtered={!!localFilterValues.is_delivery}
      >
          <CustomOption value=''>Delivery</CustomOption>
          {[{ label: "Is Delivery", value: 1 }, { label: "Not Delivery", value: 0}].map(delivery => (
            <CustomOption id={delivery.value} value={delivery.value}>{delivery.label}</CustomOption>
          ))}
      </select>,
       selector: row => <StatusToggle type='admins' id={row.id} field="is_delivery" status={row.is_delivery} />,
    },
  ];

  return (
    <PageContainer>
      <BreadCrumb pageTitle="Settings" title="Admins"/>
      <Row className="gy-4">
        <Col xs={12}>
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex gap-2">
              <CreateButton onClick={onAddClick} />

              <Restricted allowed={["super-admin", "developer"]}>
                <DeleteButton disabled={isDisable} onClick={openModal} />
              </Restricted>
            </div>
          </div>
        </Col>
        <Col xs={12}>
          <Card>
            <DataTableContainer
              columns={columns}
              data={admins}
              handleClick={(row) =>
                onUpdateClick({
                  ...row,
                  role: row.role?.[0] || "",
                })
              }
              modal={isModalOpen}
              setModal={setIsModalOpen}
              selectableRows={isAllowed(user)}
              isDeleting={isDeleting}
              selectItems={selectItems}
              handleDelete={onDeleteSubmit}
              loading={isLoading || isRefetching}
              setPageSize={setPageSize}
              navigateToPage={navigateToPage}
              paginationTotalRows={totalAdmins}
            />
          </Card>
        </Col>
      </Row>
      <Modal centered isOpen={isOpen} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>{t("Admin form")}</ModalHeader>
        <AdminFormModal
          type={type}
          initialValues={initValues}
          roles={roles}
          createNewAdmin={createNewAdmin}
          toggleModal={toggleModal}
          updateAdmin={updateAdmin}
          branches={branches}
        />
      </Modal>
    </PageContainer>
  );
};

export default Admins;
