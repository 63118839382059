import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";

import defaultAvatar from "../../assets/images/users/default.jpg";
import { adminActions } from "../../redux/slices/adminSlice";
import { useTranslation } from "react-i18next";

const ProfileDropdown = () => {

  const dispatch = useDispatch();
  
  const { user } = useSelector((state) => state.admin);

  const { t } = useTranslation();

  const [isProfileDropdown, setIsProfileDropdown] = useState(false);

  const toggleProfileDropdown = () => {
    setIsProfileDropdown(!isProfileDropdown);
  };
  
  return (
    <React.Fragment>
      <Dropdown
        isOpen={isProfileDropdown}
        toggle={toggleProfileDropdown}
        className="ms-sm-3 header-item topbar-user"
      >
        <DropdownToggle tag="button" type="button" className="btn">
          <span className="d-flex align-items-center">
            <img
              className="rounded-circle header-profile-user"
              src={user?.image || defaultAvatar}
              alt="Header Avatar"
            />
            <span className="text-start ms-xl-2 d-xl">
              <span className="d-none d-xl-inline-block ms-1 fw-medium user-name-text">
                {user?.name}
              </span>
              <span className="d-none d-xl-block ms-1 fs-12 text-muted user-name-sub-text">
                {user?.role?.[0]}
              </span>
            </span>
          </span>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <h6 className="dropdown-header">{t('Welcome')} {user?.name}!</h6>

          <DropdownItem onClick={() => dispatch(adminActions.logout())}>
            <i className="mdi mdi-logout text-muted fs-16 align-middle me-1"></i>{" "}
            <span className="align-middle" data-key="t-logout">
              {t('Logout')}
            </span>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default ProfileDropdown;
