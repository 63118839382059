import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { isAllowed } from '../../../helpers/isAllowed'
import { getPathname } from '../../../Layouts/LayoutMenuData'
import DataTableContainer from '../../Common/DataTableContainer'
import CustomInput from '../../Custom/CustomInput'
import CustomOption from '../../Custom/CustomOption'
import CustomTableHeading from '../../Custom/CustomTableHeading'
import { getFoodDataAction } from '../../../redux/actions/foodActions'
import { useDispatch } from 'react-redux'
import StatusToggle from '../../Common/StatusToggle'

const OffersTable = ({navigateToPage, pageSize, setPageSize, setSelectedItems, modal, setModal, isLoading, handleDelete, loading, filters, setFilters, name, setName }) => {

    const { offers, offerTypes: { list: offerTypes } } = useSelector(state => state.food);

    const { user } = useSelector(state => state.admin);

    const dispatch = useDispatch();

    const handleFilter = (field, value) => {
        setFilters({
            offersFilter: {
                [field]: value
            }
        })
    }

    const columns = [

        {
            name: <CustomTableHeading>ID</CustomTableHeading>,
            selector: row => row.id || '-',
        },

        {
            name: 
            <CustomInput
                type="text"
                className="form-control"
                placeholder="Name"
                value={name || ''}
                onChange={(e) => setName(e.target.value)}
                data-filtered={!!name}
            />,
            selector: row => <span title={row.name} data-tag="allowRowEvents" >{row.name}</span> || '-',
        },
        
        {
            name:
                <select
                    className='form-select'
                    value={filters.type_id}
                    onChange={(e) => handleFilter('type_id', e.target.value)}
                    data-filtered={!!filters.type_id}
                >
                <CustomOption value=''>Type</CustomOption>
                {offerTypes.map(offerType => (
                    <CustomOption key={offerType} value={offerType}>{offerType}</CustomOption>
                ))}
            </select>,
            selector: row => row.type || '-',
        },
        {
            name: <CustomTableHeading>Active</CustomTableHeading>,
            selector: row => <StatusToggle type='offers' id={row.id} field="active" status={row.active} />,
        },

    ]

    useEffect(() => {
        dispatch(getFoodDataAction({
            field: 'offerTypes',
        }))
    }, [dispatch])

  return (
    <DataTableContainer 
        data={offers.list}
        columns={columns}
        paginationServer
        navigateToPage={navigateToPage}
        paginationTotalRows={offers.total && offers.total}
        path={getPathname('food/offers')}
        pageSize={pageSize}
        setPageSize={setPageSize}
        selectableRows={isAllowed(user)}
        selectItems={setSelectedItems}
        modal={modal}
        setModal={setModal}
        isDeleting={isLoading}
        loading={loading}
        handleDelete={handleDelete}
    />
  )
}

export default OffersTable