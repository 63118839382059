import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getFoodDataAction } from '../../../../../../redux/actions/foodActions';
import { Col, Row } from 'reactstrap';
import CustomLabel from '../../../../../Custom/CustomLabel';
import CustomOption from '../../../../../Custom/CustomOption';

const DefaultBreadSize = ({ initialValues, setValue, watch }) => {

    const dispatch = useDispatch();

    const [default_bread_id, default_size_id] = watch(['default_bread_id', 'default_size_id']);

    const { sizes: { list: sizes }, doughAndBread: { list: doughAndBread } } = useSelector(state => state.food);

    useEffect(() => {
          dispatch(getFoodDataAction({
            field: 'sizes',
            params: {
              type_id: 1,
              page_size: 1000,
            }
          }))
          dispatch(getFoodDataAction({
            field: 'doughAndBread',
            params: {
              type_id: 1,
              page_size: 1000,
            }
          }))
    }, [])

    useEffect(() => {
      if (initialValues) {
        setValue('default_bread_id', initialValues.default_bread_id || null)
        setValue('default_size_id', initialValues.default_size_id || null)
      } 
      
    }, [initialValues])

  return (
    <Row className='mt-3'>
        <Col>
            <CustomLabel>Default Bread</CustomLabel>
            <select
                className='form-select'
                value={default_bread_id || ''}
                onChange={(e) => setValue('default_bread_id', e.target.value)}
            >
                <CustomOption value="">Select</CustomOption>
                {doughAndBread.map(item => (
                    <option key={item.id} value={item.id}>{item.name}</option>
                ))}
            </select>
        </Col>

        <Col>
            <CustomLabel translatable>Default Size</CustomLabel>
            <select
                className='form-select'
                value={default_size_id || ''}
                onChange={(e) => setValue('default_size_id', e.target.value)}
            >
                <CustomOption value="">Select</CustomOption>
                {sizes.map(item => (
                    <option key={item.id} value={item.id}>{item.name}</option>
                ))}
            </select>
        </Col>
    </Row>
  )
}

export default DefaultBreadSize