import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Card, Col, Row } from 'reactstrap'
import { formatExample } from '../../../helpers/formatExample'
import { sizeRqBody } from '../../../helpers/requests/Food/sizeRqBody'
import useActions from '../../../hooks/useActions'
import { getPathname } from '../../../Layouts/LayoutMenuData'
import { createFoodAction, getFoodDataAction, updateFoodAction } from '../../../redux/actions/foodActions'
import SelectFile from '../../Common/SelectFile'
import CustomButton from '../../Custom/CustomButton'
import CustomInput from '../../Custom/CustomInput'
import CustomLabel from '../../Custom/CustomLabel'
import CustomOption from '../../Custom/CustomOption'
import CustomTextArea from '../../Custom/CustomTextArea'
import SelectLanguage from '../../Common/SelectLanguage'

const DoughAndBreadForm = ({type, initialValues = null, language, setLanguage}) => {

    const {register, handleSubmit, reset, setValue} = useForm();
    
    const [icon, setIcon] = useState({
        url: '',
        name: '',
        file: null
    })

    const dispatch = useDispatch();

    const history = useHistory();

    const {isLoading, onLoading, onFinally, onError} = useActions();

    const { foodTypes: {list: foodTypes} } = useSelector(state => state.food);
    
    const submitForm = (data) => {
        const requestBody = sizeRqBody(data);
        onLoading();
        const formData = new FormData();
        Object.keys(requestBody).forEach(key => {
            formData.append(key, data[key]);
        })
        const file = icon.file

        if (file) {
            const blob = new Blob([file], {type: file.type})
            formData.append('icon', blob, file.name);
        }
        if (type === 'create') {
            dispatch(createFoodAction({
                field: 'doughAndBread',
                data: formData,
                language
            }, {
            onSuccess() {
                onFinally();
                history.push(getPathname('food/dough-bread'))
            },
            onError
            }))
        } else {
            dispatch(updateFoodAction({
                field: 'doughAndBread',
                id: initialValues.id,
                data: formData,
                language
            }, {
                onSuccess() {
                    onFinally();
                    setIcon({
                        name: icon.name,
                        url: icon.url,
                        file: null
                    })
                },
                onError
            }))
        }
    
    }
    

    useEffect(() => {
        dispatch(getFoodDataAction({
            field: 'foodTypes'
        }))
    }, [])

    useEffect(() => {
        if (initialValues) {
            reset((() => {
                const {file, icon, ...rest} = initialValues;
                return rest
            })())

            if (initialValues?.icon) {
                setIcon({
                    id: initialValues.file?.id,
                    url: initialValues.icon,
                    file: null,
                    name: initialValues.file?.name
                })
            }
            
        }
    }, [initialValues])

    return (
    <form onSubmit={handleSubmit(submitForm)}>
        <Row>
            <Col lg={3}>
                <Card className='p-3'>
                    <CustomLabel>
                        Icon
                    </CustomLabel>
                    <SelectFile
                        type='image'
                        max={1}
                        display
                        selectedFiles={icon}
                        setSelectedFiles={setIcon}
                        initialValues={initialValues}
                    />
                </Card>
            </Col>
            <Col lg={9}>

                <Card className='p-3'>
                     {type === 'update' && 
                        <div className='d-flex justify-content-end'>
                            <SelectLanguage
                                language={language}
                                onChange={lang => setLanguage(lang)}
                            /> 
                        </div>
                    }
                    <Row className='gy-3'>
                        <Col md={6}>
                            <CustomLabel>
                                Type
                            </CustomLabel>
                            <select 
                                {...register('type_id')}
                                className="form-select"
                            >
                                {foodTypes.map(type => (
                                    <CustomOption key={type.id} value={type.id}>{type.name}</CustomOption>
                                ))}
                            </select>
                        </Col>

                        <Col md={6}>
                            <CustomLabel translatable>
                                Name
                            </CustomLabel>
                            <CustomInput 
                                type="text"
                                {...register('name')}
                                placeholder={formatExample("Baguette")}
                            />
                        </Col>

                        <Col md={6}>
                            <CustomLabel translatable>
                                Description
                            </CustomLabel>
                            <CustomTextArea 
                                {...register('desc')}
                                placeholder={formatExample("Baguettes are long French bread with a hard outer crust while remaining soft and chewy inside.")}
                            />
                        </Col>

                        <Col md={6}>
                            <CustomLabel translatable>
                                Note
                            </CustomLabel>
                            <CustomTextArea 
                                {...register('note')}
                                placeholder={formatExample("Baguettes are long French bread with a hard outer crust while remaining soft and chewy inside.")}
                            />
                        </Col>
                    </Row>

                    <Row className='mt-3 justify-content-end'>
                        <div>
                            <CustomButton
                                className="btn-success"
                                isLoading={isLoading}
                            >
                                {type === 'create' ? 'Create' : 'Update'}
                            </CustomButton>
                        </div>
                    </Row>
                </Card>

            </Col>
        </Row>
    </form>
      )
}

export default DoughAndBreadForm