import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { Card, Col, Row } from "reactstrap";
import { addNewSliderApi, updateSliderApi } from "../../../apis/Settings/sliderApi";
import { formatExample } from "../../../helpers/formatExample";
import formatFormData from "../../../helpers/formatFormData";
import toastAlert from "../../../helpers/toastAlert";
import useActions from "../../../hooks/useActions";
import { getPathname } from "../../../Layouts/LayoutMenuData";
import CustomButton from "../../Custom/CustomButton";
import CustomInput from "../../Custom/CustomInput";
import CustomLabel from "../../Custom/CustomLabel";
import CustomOption from "../../Custom/CustomOption";
import CreateSliderTabs from "./Create/Tabs";
import SelectLanguage from "../../Common/SelectLanguage";

const HomeSliderForm = ({ type, initialValues = null, language, setLanguage }) => {

  const { register, handleSubmit, setValue, reset } = useForm({
    defaultValues: {
      type: 'order'
    }
  });

  const { isLoading, onLoading, onFinally } = useActions();

  const { push } = useHistory();

  const [media, setMedia] = useState({
    url: "",
    file: null,
  });

  const submitForm = async (data) => {
    onLoading();
    let body = { ...data };
    if (media.file) {
      body.file = media.file;
    }
    const formData = formatFormData(body);
    try {
      if (type === "create") {
        await addNewSliderApi(formData, language);
        push(getPathname("settings/home-sliders"));
      } else {
        const { data } = await updateSliderApi(initialValues.id, formData, language);
        if (data?.data?.file) {
          setMedia({
            ...data.data.file,
            url: data.data.file.link,
          });
        }
        toastAlert({ type: 'success' });
      }
    } catch (error) {
      toastAlert({ type: 'error' });
    } finally {
      onFinally();
    }
  };

  useEffect(() => {
    if (initialValues) {
      reset((() => {
          const { id, file, ...rest } = initialValues;
          reset(rest);
          if (file) {
            setMedia({ ...file, url: file.link })
          }
      })())
    } 
  }, [initialValues])

  return (
    <Card className="p-3">
      <form onSubmit={handleSubmit(submitForm)}>
        
         {type === 'update' && 
            <div className='d-flex justify-content-end'>
                <SelectLanguage
                  language={language}
                  onChange={lang => setLanguage(lang)}
                /> 
            </div>
          }
        <Row className="gy-4 mt-1">
          <Col md={6}>
            <CustomLabel>Area</CustomLabel>
            <select className="form-select" {...register("area")}>
              <CustomOption value="top">Top</CustomOption>
              <CustomOption value="bottom">Bottom</CustomOption>
            </select>
          </Col>

          <Col md={6}>
            <CustomLabel translatable>Title</CustomLabel>
            <CustomInput 
              type="text" {...register("title")} 
              placeholder={formatExample("SUPER OFFER")}
            />
          </Col>

          <Col md={6}>
            <CustomLabel translatable>Sup Title</CustomLabel>
            <CustomInput 
              type="text" 
              {...register("sup_title")} 
              placeholder={formatExample("30% off of everything !!")}
            />
          </Col>

          <Col md={6}>
            <CustomLabel translatable>Sub Title</CustomLabel>
            <CustomInput
              type="text" 
              {...register("sub_title")} 
              placeholder={formatExample("Hurry up and order now.")}
             />
          </Col>

          
          <Col md={6}>
              <CustomLabel className="form-check" htmlFor="active">
                Type
              </CustomLabel>
              <select 
                className="form-select"
                {...register('type')}
              >
                  <CustomOption value="order">Order</CustomOption>
                  <CustomOption value="landing">Landing</CustomOption>
              </select>
          </Col>

          <Col md={6} className="d-flex align-items-end">
            <div className="form-check form-switch">
              <input
                {...register("active")}
                className="form-check-input fs-16"
                type="checkbox"
                role="switch"
                id="active"
              />
              <CustomLabel className="form-check-label" htmlFor="active">
                Active
              </CustomLabel>
            </div>
          </Col>
          
          <Col md={6}>
            <CustomLabel translatable>Order Button Link</CustomLabel>
            <CustomInput 
              {...register("button_order_link")} 
            />
          </Col>

          <Col md={6} className="d-flex align-items-end">
            <div className="form-check form-switch">
              <input
                {...register("button_order")}
                className="form-check-input fs-16"
                type="checkbox"
                role="switch"
                id="button_order"
              />
              <CustomLabel className="form-check-label" htmlFor="button_order">
                Show Order Button
              </CustomLabel>
            </div>
          </Col>
          
          <Col md={6}>
            <CustomLabel translatable>Reserve Button Link</CustomLabel>
            <CustomInput 
              {...register("button_reserve_link")} 
            />
          </Col>

          <Col md={6} className="d-flex align-items-end">
            <div className="form-check form-switch">
              <input
                {...register("button_reserve")}
                className="form-check-input fs-16"
                type="checkbox"
                role="switch"
                id="button_reserve"
              />
              <CustomLabel className="form-check-label" htmlFor="button_reserve">
                Show Reserve Button
              </CustomLabel>
            </div>
          </Col>

        </Row>

        <CreateSliderTabs
          media={media}
          setMedia={setMedia}
          register={register}
          setValue={setValue}
        />

        <Row className="mt-5">
          <Col>
            <CustomButton isLoading={isLoading} className="btn-success">
              {type === "create" ? "Create" : "Update"}
            </CustomButton>
          </Col>
        </Row>
      </form>
    </Card>
  );
};

export default HomeSliderForm;
