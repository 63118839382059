import React, { useEffect, useState } from 'react'
import FoodProductsTable from './FoodProductsTable';
import SearchProduct from '../../../../../Common/SearchProduct';
import { objectFilter } from '../../../../../../helpers/objectFilter';

const InventoryWithoutCombos = ({ initialValues, foodType, setValue, watch }) => {

    const [products, setProducts] = useState([]);
  
    const populateProducts = () => {
        setProducts(initialValues?.recipes?.filter(recipe => !recipe.entree_size_id && !recipe.bread_id && !recipe.size_id).map(recipe => ({
            id: recipe.product?.id,
            name: recipe.product?.name,
            image: recipe.product?.image,
            qty: recipe.qty,
            item: recipe.component_item ? {
                id: recipe.component_item.id,
                name: recipe.component_item.name,
            } : null
        })) ?? [])
    } 
  
    const setProductsToForm = () => {
        setValue('recipes', products.map(product => objectFilter({
          product_id: product.id,
          qty: product.qty,
          component_item_id: product.item?.id
        })))
    }

    const onProductSelect = (product) => {
        if (!products.find(prod => prod.id === product.id)) {
            setProducts(prev => [...prev, {
                id: product.id,
                name: product.name,
                image: product.image ?? product.menu_image ?? '',
                qty: 0,
                item: null
            }])
        }
    }
    
    useEffect(() => {
        populateProducts();
    }, [foodType, initialValues])
  
    useEffect(() => {
        setProductsToForm();
    }, [products])

  return (
    <div>
        <div>
            <SearchProduct 
                onChange={onProductSelect}
            />
        </div>
        <FoodProductsTable 
            foodType={foodType}
            products={products}
            setProducts={setProducts}
            watch={watch}
            withCombos={false}
        />
    </div>
  )
}

export default InventoryWithoutCombos