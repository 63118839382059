import React from 'react'
import { Spinner } from 'reactstrap'

const LoadingComponent = ({ children, loading, styles = {} }) => {

  return (
    <>
        {loading ? 
            <div
                style={{
                    height: "100%",
                    minHeight: '120px',
                    display: "flex",
                    width: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                    paddingBlock: '1rem',
                    ...styles
                }}
            >
                <Spinner />
        </div> 
        : children}
    </>
  )
}

export default LoadingComponent