import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "webSettings",
  initialState: {
    footer: {
      footer: "",
      copyright: "",
    },
    setup: {
      name: "",
      link: "",
      logo: null,
      fav_icon: null,
    },
    reports: {
      reports: {}, 
      settings: {}
    },
    paymentSettings: {},
    keysSettings: {}
  },
  reducers: {
    setFooter: (state, action) => {
      state.footer = action.payload;
    },
    setSetup: (state, action) => {
      state.setup = action.payload;
    },
    setReports: (state, {payload}) => {
      state.reports.reports = payload;
    },
    setReportsSettings: (state, {payload}) => {
      state.reports.settings = payload;
    },
    setPaymentSettings: (state, { payload }) => {
      state.paymentSettings = payload
    },
    setKeysSettings: (state, { payload }) => {
      state.keysSettings = payload
    }
  },
});

export const settingsActions = slice.actions;

export default slice.reducer;
