import * as yup from "yup";

const specialDaySchema = yup.object().shape({
  date_from: yup.string().required("Required"),
  date_to: yup.string().required("Required"),
  time_start: yup.string().required("Required"),
  time_end: yup.string().required("Required"),
});

export default specialDaySchema;
