import { createContext, useContext, useEffect, useRef, useState } from 'react';
import Pusher from 'pusher-js';
import { notificationActions } from '../../redux/slices/notificationsSlice';
import toastAlert from '../../helpers/toastAlert';
import { useDispatch, useSelector } from 'react-redux';
import { getNotificationsAction, readNotificationAction } from '../../redux/actions/notificationsActions';
import { useHistory } from 'react-router-dom';
import { getPathname } from '../../Layouts/LayoutMenuData';
import { useTranslation } from 'react-i18next';
import { getKeysSettingsActions } from '../../redux/actions/settingsActions';
import { isEmpty } from '../../helpers/objectFilter';
import { triggerSound } from '../../helpers/triggerSound';

const NotificationContext = createContext();

export const useNotifications = () => {
  return useContext(NotificationContext);
}

const NotificationWrapper = ({ children }) => {

  const dispatch = useDispatch();

  const { t } = useTranslation();

  const { admin: { token: accessToken }, notifications: { new: newNotifications, sounds } } = useSelector(state => state);

  const history = useHistory();

  const [loading ,setLoading] = useState(false);

  const pusherRef = useRef();
  
  const channelRef = useRef();

  const notificationInterval = useRef(null);

  const [pusherConfig, setPusherConfig] = useState(null);

  const { keysSettings } = useSelector(state => state.settings);

  const handleNotificationClick = (notification) => {
    if (loading) return;
    if (!notification.read) {
        setLoading(true);
        dispatch(readNotificationAction({
            id: notification.id,
            onSuccess() {
              history.push(getPathname(`orders/update/${notification.type_id}`));
              setLoading(false);
            },
        }))
    } else {
        history.push(getPathname(`orders/update/${notification.type_id}`));
    }
  }

  const setNotificationInterval = () => {
    clearInterval(notificationInterval.current);
    notificationInterval.current = setInterval(() => {
      triggerSound('notification');
    }, 1000)
  }

  const disconnectPusher = () => {
    channelRef?.current?.unbind();
    pusherRef?.current?.disconnect();
    pusherRef.current = null;
    channelRef.current = null;
  }

  useEffect(() => {
    if (accessToken && pusherConfig) {

      disconnectPusher();

      if (!pusherRef.current) {

        const pusher = new Pusher(pusherConfig?.app_key, {
          cluster: pusherConfig?.cluster,
          forceTLS: true,
          encrypted: true,
        });

        pusherRef.current = pusher;

      }
  
      if (!channelRef.current) {
        const channel = pusherRef.current.subscribe("restaurent-dashboard");
        channelRef.current = channel;
      }
  
      if (channelRef.current) {

        channelRef.current.bind("user-order-created", (data) => {
          const notification = data.message;
          if (notification.status === null || notification.status === undefined || notification.status === 1) {
            setNotificationInterval();
            dispatch(notificationActions.addNotification(notification));
            toastAlert({
              type: 'success',
              message: `${t(notification.title)} - ${notification.description || ''}`,
              autoClose: 9000,
              onClick: () => handleNotificationClick(notification),
              ...(sounds.newNotifications ? { sound: 'notification' } : {})
            });
          }
        });
        
        channelRef.current.bind("notification-seen", () => {
          dispatch(notificationActions.markAllAsSeen());
        });

      }

    } else {
      disconnectPusher();
    }
  }, [accessToken, pusherConfig, sounds]);

  useEffect(() => {
    if (accessToken) {
      dispatch(getNotificationsAction());
      dispatch(getKeysSettingsActions());
    }
  }, [accessToken]);

  useEffect(() => {
    if (!isEmpty(keysSettings) && pusherConfig === null) {
      setPusherConfig({
        app_key: keysSettings.pusher_dashboard?.app_key ?? 'eu',
        cluster: keysSettings.pusher_dashboard?.app_cluster || ''
      }) 
    }
  }, [keysSettings])

  useEffect(() => {
    if (accessToken && newNotifications > 0 && sounds.unseenNotifications) {
      if (!notificationInterval.current) setNotificationInterval();
    } else {
      clearInterval(notificationInterval.current);
      notificationInterval.current = null;
    }
  }, [accessToken, newNotifications, sounds])

  return (
    <NotificationContext.Provider value={{ handleNotificationClick }}>
      {children}
    </NotificationContext.Provider>
  )
};

export default NotificationWrapper;