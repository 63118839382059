import React from "react";
import { Col, Row } from "reactstrap";
import CustomLabel from "../../Custom/CustomLabel";
import CustomTextArea from "../../Custom/CustomTextArea";

const CommonForm = ({
  afterChildren,
  checkboxName,
  name,
  inputName,
  values,
  keywordsName,
  handleChange,
  hideCheckbox = false,
}) => {
  return (
    <Row className="gy-3">
      {!hideCheckbox && (
        <Col xs={12}>
          <div className="form-check form-switch">
            <input
              className="form-check-input fs-16"
              type="checkbox"
              role="switch"
              name={checkboxName}
              checked={values[checkboxName]}
              onChange={handleChange(name)}
            />
            <CustomLabel className="form-check-label">Send</CustomLabel>
          </div>
        </Col>
      )}

      <Col xs={12}>
        <CustomLabel>Text</CustomLabel>
        <CustomTextArea
          onChange={handleChange(name)}
          rows={2}
          name={inputName}
          value={values[inputName]}
        />
        <span>
          {(values[keywordsName] || []).map((word) => `{${word}}`).join(",")}
        </span>
      </Col>

      <Col xs={12}>{afterChildren}</Col>
    </Row>
  );
};

export default CommonForm;

/**<div className="form-check form-switch mb-2">
                        <input {...register('active')} className="form-check-input fs-16" type="checkbox" role="switch" id="active" />
                        <CustomLabel className="form-check-label" htmlFor="active">Active</CustomLabel>
                    </div> */
