import React, { useState } from 'react'
import toastAlert from '../../helpers/toastAlert';
import { Spinner } from 'reactstrap';
import { deleteImageFromGallery } from '../../apis/Settings/galleryApi';
import StatusToggle from './StatusToggle';

const GalleryImage = ({ image, setImages }) => {

  const [isDeleting ,setIsDeleting] = useState(false);

  const removeImage = async () => {
    if (isDeleting) return;
    try {
      setIsDeleting(true);
      if (image.id) {
        await deleteImageFromGallery(image.id);
        toastAlert({ type: 'success' })
      }
      setImages(prev => prev.filter(img => img.id !== image.id));
    } catch (error) {
      toastAlert({ type: 'error' })
    } finally {
      setIsDeleting(false);
    }
  }

  return (
     <div className={`image-input-container ${image.link ? 'has-image' : ''} ${isDeleting ? 'loading' : ''}`}>
      {isDeleting && 
        <div className='loading-indicator'>
          <Spinner />
        </div>}

        <div className='image-display-container'>
          <img src={image.link} />
        </div>
        
        {image.link && 
          <i 
            className="ri-subtract-line remove-image-button"
            onClick={removeImage}
          ></i>
        }
        <StatusToggle 
          type='gallery_detail'
          id={image.id}
          field='active'
          status={image.active}
        />
    </div>
  )
}

export default GalleryImage