import React from "react";
import { Link } from "react-router-dom";
import { getPathname } from "../../../Layouts/LayoutMenuData";
import OrderItemComponents from "./OrderItemComponents";
import OrderItemFoodComponent from "./OrderItemFoodComponent";
import blackIcon from "../../../assets/images/orders/black-icon.svg";
import greenIcon from "../../../assets/images/orders/green-icon.svg";
import redIcon from "../../../assets/images/orders/red-icon.svg";
import multiIcon from "../../../assets/images/orders/multi-icon.svg";
import halvesIcon from "../../../assets/images/orders/halves-icon.svg";
import OrderGreenComponents from "./OrderGreenComponents";
import OrderItemEntrees from "./OrderItemEntrees";

const OrderProduct = ({ item }) => {

  const {
    food,
    bread,
    size,
    qty,
    price_tax,
    discount,
    final_price,
    total,
    entrees,
    halves,
    black,
    green,
    red,
    product_multi,
    refund,
  } = item;

  return (
    <React.Fragment>
      <tr
        className={`order-item ${refund ? "refund" : ""}`}
        style={{
          borderBottom: "1px solid #e6e6e6",
        }}
      >
        <td>
          <div className="d-flex">
            <div className="flex-shrink-0 avatar-md bg-light rounded p-1">
              <img
                src={food?.image}
                alt=""
                className="d-block"
                style={{
                  objectFit: "contain",
                  height: "100%",
                  width: "100%",
                }}
              />
            </div>
            <div className="flex-grow-1 ms-2">
              <h5
                className="fs-16"
                style={{
                  ...(refund ? { textDecoration: "line-through" } : {}),
                }}
              >
                <Link
                  className="link-primary"
                  to={getPathname(`food/menu/update/${food?.id}`)}
                >
                  {food?.name}{" "}
                  {size && bread ? `(${size.name} ${bread.name})` : ""}
                </Link>
              </h5>

              {halves?.length > 0 ? (
                <>
                  {halves.map((half, idx) => {
                    return (
                      <OrderItemFoodComponent
                        imgStyle={{
                          ...(idx === 1 ? { transform: "rotate(180deg)" } : {}),
                        }}
                        key={idx}
                        icon={halvesIcon}
                        type="half"
                        list={[half]}
                      />
                    );
                  })}
                </>
              ) : (
                <></>
              )}

              {product_multi?.length > 0 && (
                <>
                  {product_multi.map((item, idx) => (
                    <OrderItemFoodComponent
                      key={idx}
                      icon={multiIcon}
                      type="multi"
                      list={[item]}
                    />
                  ))}
                </>
              )}
              <OrderItemFoodComponent
                icon={blackIcon}
                type="black"
                list={black}
              />
              <OrderGreenComponents green={green} greenIcon={greenIcon} />

              <OrderItemFoodComponent
                icon={redIcon}
                type="red"
                list={red}
                color="danger"
              />

              <OrderItemEntrees list={entrees} />
              {/* <OrderItemComponents list={entrees} title="Entrees" /> */}
            </div>
          </div>
        </td>
        <td>{qty}</td>
        <td>{price_tax}</td>
        <td>{discount}</td>
        <td>{final_price}</td>
        <td className="fw-medium text-end">{total}</td>
      </tr>
    </React.Fragment>
  );
};

export default OrderProduct;
