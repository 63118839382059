import React from 'react'
import SetupActions from '../SetupActions'
import BootstrapTable from '../../Common/BootstrapTable';
import CustomButton from '../../Custom/CustomButton';
import useFormModal from '../../../hooks/useFormModal';
import LanguageModal from './Modal';
import { useSelector } from 'react-redux';

const LanguagesSetup = ({ id, next, skip }) => {

  const { languages } = useSelector((state) => state.language);

  const { isOpen: modalOpened, toggleModal, type, openModal, initialValues } = useFormModal();

  const columns = [
    {
      name: 'Name',
      selector: row => row.name,
    },

    {
      name: 'Code',
      selector: row => row.code.toUpperCase()
    }
  ]

  return (
    <div>
      
      <LanguageModal 
          type={type}
          isOpen={modalOpened}
          toggleModal={toggleModal}
          initialValues={initialValues}
      />

      <div className='hstack gap-2'>
          <CustomButton 
            className="btn-success"
            onClick={() => openModal("create")}
          >
            Create
          </CustomButton>
      </div>

      <div className='mt-3'>
        <BootstrapTable 
          columns={columns}
          data={languages}
          clickableRows
          onRowClick={(row) => openModal('update', row)}
        />
      </div>

      <SetupActions id={id} submit={() => next(id)} skip={skip} />
    </div>
  )
}

export default LanguagesSetup