import React from 'react'
import BreadCrumb from '../../../Components/Common/BreadCrumb'
import DonenessForm from '../../../Components/Food/Doneness/Form'
import PageContainer from '../../../Components/Layout/PageContainer'
import { useFetchByLang } from '../../../hooks/useFetchByLang'

const CreateDoneness = () => {
  
  const { language, setLanguage } = useFetchByLang();
    
  return (
    <PageContainer>
      <BreadCrumb pageTitle="Doneness" title="Create" to="food/doneness"/>
      <DonenessForm 
        type="create"
        language={language}
        setLanguage={setLanguage}
      />
    </PageContainer>
  )
}

export default CreateDoneness