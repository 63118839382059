import React, { useState } from "react";
import { Card, Col, Row } from "reactstrap";
import ListHeader from "../../../Components/Common/ListHeader";
import PageContainer from "../../../Components/Layout/PageContainer";
import toastAlert from "../../../helpers/toastAlert";
import useActions from "../../../hooks/useActions";
import useFilterData, { FILTER_TYPES } from "../../../hooks/useFilterData";
import useModal from "../../../hooks/useModal";
import { useBranches } from "../../../hooks/useQueryHelpers";
import useSelectItems from "../../../hooks/useSelectItems";
import BranchesTable from "../../../Components/Settings/Branches/Table";
import { deleteBranchesApi } from "../../../apis/Settings/branchesApi";

const Branches = () => {

  const { isLoading, branches, deleteBranches } = useBranches();

  const { isLoading: isDeleting, onLoading: onDeleting, onFinally: onFinallyDeleting } = useActions();

  const [query, setQuery] = useState("");

  const { selectItems, getSelectedIds, isDisable } = useSelectItems();

  const { isModalOpen, setIsModalOpen, openModal, closeModal } = useModal();

  const handleDelete = async () => {
    onDeleting();
    const ids = getSelectedIds();
    try {
      await deleteBranchesApi(ids);
      selectItems([]);
      closeModal();
      deleteBranches(ids);
    } catch (error) {
      toastAlert({ type: 'error' });
    } finally {
      onFinallyDeleting();
    }
  };

  const list = useFilterData(branches, {
    name: {
      key: "name",
      type: FILTER_TYPES.MATCH,
      value: query,
    },
  });

  return (
    <PageContainer isLoading={isLoading}>
      <Row className="gy-4">
        <Col xs={12}>
          <ListHeader
            isDisable={isDisable}
            openModal={openModal}
            pathname="settings/branches/create"
            query={query}
            setQuery={setQuery}
          />
        </Col>
        <Col xs={12}>
          <Card>
            <BranchesTable
              data={list}
              modal={isModalOpen}
              setModal={setIsModalOpen}
              selectItems={selectItems}
              handleDelete={handleDelete}
              isDeleting={isDeleting}
              isLoading={isLoading}
            />
          </Card>
        </Col>
      </Row>
    </PageContainer>
  );
};

export default Branches;
