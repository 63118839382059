import React from 'react'
import CustomLabel from '../../Custom/CustomLabel'
import { Draggable } from 'react-beautiful-dnd';

const DraggableSize = ({ size, bread, category, pageId, index, handleSizeChange }) => {

  return (
    <Draggable draggableId={`page-${pageId}-category-${category.id}-bread-${bread.id}-size-${size.id}`} index={index} type={`page-${pageId}-category-${category.id}-bread-${bread.id}-size`}>
        {provided => (
            <div    
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
            >
                <div key={index} className='p-3 border form-check form-switch'>
                    <span>
                        <i className="ri-menu-line"></i>
                    </span>

                    <input
                        className="form-check-input"
                        style={{ marginLeft: 0 }}
                        type="checkbox"
                        role='switch'
                        checked={size.active ?? false}
                        onChange={(e) => handleSizeChange(bread.id, size.id, e.target.checked)}
                    />

                    <div className='d-flex gap-2'>
                        <CustomLabel
                            className="d-flex gap-2"
                            >
                            {size.icon && 
                                <img 
                                src={size.icon} 
                                alt={size.icon} 
                                style={{
                                    height: '20px',
                                }} 
                                />
                            }
                            {size.name}
                        </CustomLabel>
                    </div>
                </div>
            </div>
        )}
    </Draggable>
     
  )
}

export default DraggableSize