import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import { sendInvoiceMailApi } from "../../../apis/Orders/ordersApi";
import toastAlert from "../../../helpers/toastAlert";
import useActions from "../../../hooks/useActions";
import CustomButton from "../../Custom/CustomButton";
import OrderHeadingItem from "./OrderHeadingItem";
import OrderPriceItem from "./OrderPriceItem";
import OrderProduct from "./OrderProduct";
import { formatDateTime } from "../../../helpers/timeFormat";
import { Link } from "react-router-dom";
import { getPathname } from "../../../Layouts/LayoutMenuData";

const OrderDetailsItems = ({ order }) => {

  const { t } = useTranslation();

  const { isLoading, onLoading, onFinally } = useActions();
  
  const { id } = useParams();

  const sendInvoice = async () => {
    try {
      onLoading();
      await sendInvoiceMailApi(id);
      toastAlert({ type: 'success' });
    } catch (error) {
      toastAlert({ type: 'error' });
    } finally {
      onFinally();
    }
  };

  return (
    <>
      <Card>
        <CardHeader>
          <div className="d-flex align-items-center justify-content-between gap-2 flex-wrap">
            <div className="d-flex gap-1">
              <OrderHeadingItem value={`#${order.code}`} />
              <OrderHeadingItem value={formatDateTime(order.created_at)}
              />
              <OrderHeadingItem value={`${order.platform}`} />
              {order.is_preorder && <OrderHeadingItem value={`Pre-Order`} />}
            </div>

            <div className="d-flex gap-2 flex-shrink-0">
              {
                order.current_status?.id === 6 && (
                  <Link to={getPathname(`orders/track/${order.id}`)}>
                    <CustomButton
                      type="button"
                      className="btn btn-success btn-sm"
                    >
                      <i className="ri-map-pin-line align-middle me-1"></i>{" "}
                      {t("Track")}
                    </CustomButton>
                  </Link>
                )
              }

              <CustomButton
                onClick={sendInvoice}
                isLoading={isLoading}
                className="btn btn-success btn-sm"
              >
                <i className="ri-mail-send-fill align-middle me-1"></i>{" "}
                {t("Send invoice by email")}
              </CustomButton>
            </div>
          </div>
        </CardHeader>
        <CardBody>
          <div className="table-responsive table-card">
            <table className="table table-nowrap align-middle table-borderless mb-0">
              <thead className="table-light text-muted">
                <tr>
                  <th scope="col">{t("Item")}</th>
                  <th scope="col">{t("Qty")}</th>
                  <th scope="col">{t("Price")}</th>
                  <th scope="col">{t("Discount")}</th>
                  <th scope="col">{t("Final price")}</th>
                  <th scope="col" className="text-end">
                    {t("Total")}
                  </th>
                </tr>
              </thead>
              <tbody>
                {order.items.map((item, key) => (
                  <OrderProduct item={item} idx={key} key={key} />
                ))}
              </tbody>
            </table>
          </div>
        </CardBody>
      </Card>
      <Row className="justify-content-end">
        <Col md={6} xxl={4}>
          <Card className="">
            <table>
              <tbody>
                <OrderPriceItem
                  prop="Delivery price"
                  value={order.delivery_final_price}
                />

                {order.coupon ? (
                  <OrderPriceItem
                    prop={`Apply coupon (${order.coupon.code}) ${
                      order.coupon.discount_rate
                        ? `${order.coupon.discount_rate}%`
                        : order.coupon.qr_amount
                    }`}
                    value={order.discount}
                  />
                ) : (
                  <OrderPriceItem prop={"Discount"} value={order.discount} />
                )}

                <OrderPriceItem prop="Sub total" value={order.sub_total} />

                {order.taxes.map((tax) => {
                  return (
                    <OrderPriceItem
                      key={tax.taxe_name}
                      prop={`${t("Vat")} ${tax.taxe_rate}%`}
                      value={tax.value}
                    />
                  );
                })}

                <tr className="d-flex justify-content-between px-2 py-3  bg-primary text-white">
                  <th scope="row">{t("Total")}</th>
                  <th className="text-end">{order.total}</th>
                </tr>
              </tbody>
            </table>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default OrderDetailsItems;
