import React, { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import { useGetUsers } from "../../../hooks/useQueryHelpers";
import CustomLabel from "../../Custom/CustomLabel";
import CustomOption from "../../Custom/CustomOption";
import ReactSelect from "react-select";
import useDebounceValue from "../../../hooks/useDebouneValue";

const CouponUsersTab = ({ register, initialValues, setValue, watch}) => {

  const [name, setName] = useState("");

  const query = useDebounceValue({ value: name });

  const [selectedUsers, setSelectedUsers] = useState(null);

  const { isLoading, refetch, users } = useGetUsers({
    currentPage: 1,
    pageSize: 100,
    name: query,
  });

  const onSelectUsers = (items) => {
    setSelectedUsers(items);
  };

  const all_users = watch('users');

  useEffect(() => {
    if (initialValues && users?.list?.length) {
      setSelectedUsers(users?.list?.filter(user => initialValues?.selected_users?.includes(user.id)).map(user => ({
        label: user.name,
        value: user.id
      })));
    }
  }, [initialValues, users])

  useEffect(() => {
    setValue('selected_users', selectedUsers?.map(user => user.value));
  }, [selectedUsers])

  useEffect(() => {
    refetch();
  }, [query]);

  return (
    <Row className="gy-3">
      
      <Col md={6}>
        <CustomLabel>Apply to</CustomLabel>
        <select
          className="form-select"
          {...register("users")}
        >
          <CustomOption value={0}>All users</CustomOption>
          <CustomOption value={1}>Selected users</CustomOption>
          <CustomOption value={2}>New users</CustomOption>
        </select>
      </Col>

      {+all_users !== 0 &&
        <Col md={6}>
          <CustomLabel>Select users</CustomLabel>
          <ReactSelect
            isMulti
            value={selectedUsers}
            onInputChange={(value) => {
              setName(value);
            }}
            onChange={(items) => {
              onSelectUsers(items);
            }}
            options={users.list.map((user) => {
              return {
                label: user.name,
                value: user.id,
              };
            })}
          />
        </Col>
      }
    </Row>
  );
};

export default CouponUsersTab;
