import React, { useState, useMemo, Fragment } from "react";
import {
  Card,
  CardBody,
  Col,
  CardHeader,
  Row,
  Modal,
  ModalHeader,
  Form,
  ModalBody,
  Input,
  ModalFooter,
  Table,
} from "reactstrap";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
// import { getAllWords, createWord, updateWord, deleteWord } from "../../../apis/settingsApis/languageApi";
// import LanguageKey from "../../../Components/Settings/Language/LanguageKey";
import CoverSpinner from "../../../Components/Common/CoverSpinner";
import toastAlert from "../../../helpers/toastAlert";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import CustomButton from "../../../Components/Custom/CustomButton";
import CustomInput from "../../../Components/Custom/CustomInput";
import CustomLabel from "../../../Components/Custom/CustomLabel";
import PageContainer from "../../../Components/Layout/PageContainer";
import LanguageKey from "../../../Components/Settings/Language/LanguageKey";
import {
  addNewKeyApi,
  deleteKeyApi,
  getAllKeysApi,
  updateKeyApi,
} from "../../../apis/Settings/languageApi";
import { renderErrorAsString } from "../../../helpers/renderErrors";
import extractError from "../../../helpers/extractError";

const handleSearch = (data, query) => {
  const copied = { ...data };
  if (!query) return data;
  return Object.fromEntries(
    Object.entries(copied).filter(([key]) => {
      return key.toLowerCase().includes(query) || copied[key].toLowerCase().includes(query)
    })
  );
};

const LanguageKeys = () => {

  const { code, whatFor } = useParams();

  const { t } = useTranslation();

  const [newWord, setNewWord] = useState({
    key: "",
    value: "",
  });

  const [modal, setModal] = useState(false);

  const [words, setWords] = useState({});
  const [searchValue, setSearchValue] = useState("");
  const searchedValues = handleSearch(words, searchValue);
  const copiedWords = useRef({});

  const [isActing, setIsActing] = useState(false);
  const [isAdding, setIsAdding] = useState(false);

  const toggle = () => {
    setModal((current) => !current);
  };

  const handleChange = (e) => {
    setNewWord((current) => {
      return {
        ...current,
        [e.target.name]: e.target.value,
      };
    });
  };

  // Column
  const columns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
        filterable: true,
      },
      {
        Header: "Word",
        accessor: "key",
        filterable: true,
      },

      {
        Header: "Translation",
        accessor: "value",
        filterable: true,
        Cell: (cellprops) => {
          return (
            <Input
              name="home-slider-name"
              id="home-slider-name-field"
              className="form-control"
              placeholder="Enter Page Title"
              type="text"
              validate={{
                required: { value: true },
              }}
            />
          );
        },
      },
      {
        Header: "Actions",
        accessor: "actions",
      },
    ],
    []
  );

  const onAddKey = async () => {
    setIsAdding(true);
    const { key, value } = newWord;
    try {
      await addNewKeyApi(whatFor, code, { key, value });
      setWords((current) => {
        return {
          ...current,
          [key]: value,
        };
      });
      toggle();
      setNewWord({ key: "", value: "" });
      toastAlert({ type: 'success' });
    } catch (error) {
      toastAlert({ type: 'error', message: renderErrorAsString(extractError(error))});
    } finally {
      setIsAdding(false);
    }
  };

  const onEditKey = async (key, value) => {
    setIsActing(true);
    try {
      await updateKeyApi(whatFor, code, { key, value });
      setWords((current) => {
        const copied = { ...current };
        copied[key] = value;
        return copied;
      });
      toastAlert({ type: 'success' });
    } catch (error) {
      toastAlert({ type: 'error' });
    } finally {
      setIsActing(false);
    }
  };

  const onDeleteKey = async (key) => {
    setIsActing(true);
    try {
      await deleteKeyApi(whatFor, key);
      setWords((current) => {
        const copied = { ...current };

        delete copied[key];

        return copied;
      });
      delete copiedWords.current[key];
      toastAlert({ type: 'success' });
    } catch (error) {
      toastAlert({ type: 'error' });
    } finally {
      setIsActing(false);
    }
  };

  const { isLoading } = useQuery(["languages", "keys", whatFor], () => getAllKeysApi(whatFor),
    {
      onSuccess({data: {data}}) {
        if (whatFor === 'lang_pos') {
          setWords(data[code]);
        } else {
          setWords(Object.fromEntries(Object.entries(data).map(([key, val]) => {
            return [key, val[code]]
          })));
        }
      },
    }
  );

  return (
    <PageContainer isLoading={isLoading}>
      <BreadCrumb
        pageTitle="Language"
        title="Words"
        to={"settings/language"}
      />
      <Row>
        <Col lg={12}>
          <Card id="language-translation">
            <CardHeader className="card-header border-0 d-flex align-items-center justify-between">
              <h5 className="card-title mb-0 flex-grow-1">{t("Words")}</h5>
              <CustomButton className="btn btn-info" onClick={toggle}>
                Add New Word
              </CustomButton>
            </CardHeader>
            <CardBody className="pt-0">
              <div className="w-100 mb-5 d-flex gap-2">
                <CustomInput
                  onChange={(e) => {
                    setSearchValue(e.target.value.toLocaleLowerCase());
                  }}
                  value={searchValue}
                  id="search-bar-0"
                  type="text"
                  placeholder="Search"
                  className="form-control w-100"
                />
              </div>

              <div className="table-responsive language-detail-table table-card mb-1">
                <Table hover className="align-middle table-nowrap">
                  <thead className="table-light text-muted">
                    <tr>
                      {columns.map((column, index) => {
                        return <th key={index}>{t(column.Header)}</th>;
                      })}
                    </tr>
                  </thead>

                  <tbody>
                    <Fragment>
                      {Object.entries(searchedValues).map(
                        ([key, val], index) => {
                          return (
                            <LanguageKey
                              key={key}
                              index={index}
                              word={{ key, value: val }}
                              onDeleteKey={() => onDeleteKey(key)}
                              onEditKey={onEditKey}
                            />
                          );
                        }
                      )}
                    </Fragment>
                  </tbody>
                </Table>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Modal id="showModal" isOpen={modal} toggle={toggle} centered>
        <ModalHeader className="bg-light p-3" toggle={toggle}>
          {t("Add Word")}
        </ModalHeader>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            // validation.handleSubmit();
            return false;
          }}
        >
          <ModalBody>
            <Input type="hidden" id="id-field" />
            <Row className="g-3">
              <Col lg={6}>
                <CustomLabel htmlFor="word-key-field" className="form-label">
                  Word
                </CustomLabel>
                <CustomInput
                  name="key"
                  id="word-key-field"
                  className="form-control"
                  placeholder="Enter word key"
                  type="text"
                  onChange={handleChange}
                  value={newWord.key}
                  validate={{
                    required: { value: true },
                  }}
                />
              </Col>
              <Col lg={6}>
                <CustomLabel htmlFor="word-value-field" className="form-label">
                  Translation
                </CustomLabel>

                <CustomInput
                  name="value"
                  id="word-value-field"
                  className="form-control"
                  placeholder="Enter word value"
                  type="text"
                  onChange={handleChange}
                  value={newWord.value}
                  validate={{
                    required: { value: true },
                  }}
                />
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <div className="hstack gap-2 justify-content-end">
              <CustomButton
                type="button"
                className="btn btn-light"
                onClick={() => {
                  setModal(false);
                }}
              >
                Close
              </CustomButton>
              <CustomButton
                isLoading={isAdding}
                onClick={onAddKey}
                type="button"
                className="btn btn-success"
                id="add-btn"
              >
                Add
              </CustomButton>
            </div>
          </ModalFooter>
        </Form>
      </Modal>
      <CoverSpinner loading={isActing} />
    </PageContainer>
  );
};

export default LanguageKeys;
