import React from 'react'
import Select from 'react-select'

const SearchComponentItem = ({ onChange, value, items, ...props }) => {

    return (
        <Select 
            value={value ?? null}
            options={items.map(item => ({
                value: item.id,
                label: item.name
            }))}
            onChange={onChange}
            menuPortalTarget={document.body}
            {...props}
        />
    )
}

export default SearchComponentItem