import { useQuery } from "@tanstack/react-query";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Col, Row } from "reactstrap";
import {
  getSetupApi,
  getTimeZones,
  setSetupApi,
} from "../../../apis/Settings/webSettingsApi";
import formatFormData from "../../../helpers/formatFormData";
import toastAlert from "../../../helpers/toastAlert";
import useActions from "../../../hooks/useActions";
import useCustomForm from "../../../hooks/useCustomForm";
import useStateFile from "../../../hooks/useStateFile";
import { settingsActions } from "../../../redux/slices/settingsSlice";
import LoaderComponent from "../../Common/LoaderComponent";
import SelectFile from "../../Common/SelectFile";
import CustomInput from "../../Custom/CustomInput";
import CustomLabel from "../../Custom/CustomLabel";
import SetupActions from "../../Setup/SetupActions";
import CustomOption from "../../Custom/CustomOption";
import { validateValues } from "../../../helpers/validateValues";

const GeneralSetup = ({ id, next, skip }) => {

  const { values, handleChange, setValues } = useCustomForm({
      name: "",
      google_analitics: "",
      google_rank: "",
      time_zone: "",
    });

  const [timeZones, setTimeZones] = useState(null);

  const dispatch = useDispatch();

  const [errors, setErrors] = useState({
    name: null,
    logo: null,
    fav_icon: null
  })

  const { file: logo, setFile: setLogo } = useStateFile();

  const { file: footer_logo, setFile: setFooterLogo } = useStateFile();

  const { file: fav_icon, setFile: setFavIcon } = useStateFile();

  const { file: product, setFile: setProduct } = useStateFile();

  const { file: coupon, setFile: setCoupon } = useStateFile();

  const { onLoading, isLoading: isSubmitting, onFinally } = useActions();

  const { isLoading } = useQuery(["web-settings", "setup"], getSetupApi, {
    onSuccess(data) {
      const {
        name,
        logo,
        google_analitics,
        google_rank,
        footer_logo,
        fav_icon,
        product,
        coupon,
        time_zone,
      } = data.data.data;
      dispatch(
        settingsActions.setSetup({
          name,
          logo: logo?.link,
          fav_icon: fav_icon?.link,
        })
      );
      setValues({ name, google_analitics: google_analitics || "", time_zone, google_rank: google_rank || ""});
      setLogo({ ...logo, url: logo?.link });
      setFooterLogo({ ...footer_logo, url: footer_logo?.link });
      setFavIcon({ ...fav_icon, url: fav_icon?.link });
      setProduct({ ...product, url: product?.link });
      setCoupon({ ...coupon, url: coupon?.link });
    },
  });

  useQuery(["timezones"], getTimeZones, {
    onSuccess({data: { data }}) {
      setTimeZones(data);
    },
  });

  const onSubmit = async () => {

    const { isError } = validateValues({
      name: values.name,
      logo: logo.file || logo.url,
      fav_icon: fav_icon.file || fav_icon.url

    }, setErrors)

    if (isError) return

    onLoading();

    let files = {};

    const listFiles = [
      { name: "logo", file: logo },
      { name: "footer_logo", file: footer_logo },
      { name: "fav_icon", file: fav_icon },
      { name: "product", file: product },
      { name: "coupon", file: coupon },
    ];

    listFiles.forEach((file) => {
      if (file.file.file) {
        files[file.name] = file.file.file;
      }
    });

    const { ...rest } = values;

    const body = {
      ...rest,
      ...files,
    };

    const formData = formatFormData(body);

    try {
      const { data } = await setSetupApi(formData);
      const {
        name,
        logo,
        google_analitics,
        google_rank,
        time_zone,
        footer_logo,
        fav_icon,
        product,
        coupon,
      } = data.data;
      setValues({ name, google_analitics: google_analitics || "", time_zone, google_rank: google_rank || "" });
      setLogo({ ...logo, url: logo?.link });
      setFooterLogo({ ...footer_logo, url: footer_logo?.link });
      setFavIcon({ ...fav_icon, url: fav_icon?.link });
      setProduct({ ...product, url: product?.link });
      setCoupon({ ...coupon, url: coupon?.link });

      dispatch(
        settingsActions.setSetup({
          name,
          logo: logo?.link,
          fav_icon: fav_icon?.link,
        })
      );
      next(id);
    } catch (error) {
      toastAlert({ type: 'error' });
    } finally {
      onFinally();
    }
  };


  return (
    <LoaderComponent isLoading={isLoading}>

        <Row className="gy-2">

          <Col md={6}>
            <CustomLabel>Website Name</CustomLabel>
            <CustomInput
              name="name"
              value={values.name}
              onChange={handleChange}
            />
            {errors['name'] && <span className="text-danger">{errors['name']}</span>}
          </Col>

          <Col md={6}>
            <CustomLabel>Timezone</CustomLabel>
            <select
              className="form-select"
              name="time_zone"
              value={values.time_zone}
              onChange={handleChange}
            >
              <CustomOption value="">None</CustomOption>
              {timeZones?.map((timezone) => {
                return (
                  <option key={timezone.id} value={timezone.name}>
                    {timezone.name}
                  </option>
                );
              })}
            </select>
          </Col>

          <Col md={6}>
            <CustomLabel>Google Analitics</CustomLabel>
            <CustomInput
              name="google_analitics"
              value={values.google_analitics}
              onChange={handleChange}
            />
          </Col>

          <Col md={6}>
            <CustomLabel>Google Rank</CustomLabel>
            <CustomInput
              name="google_rank"
              value={values.google_rank}
              onChange={handleChange}
            />
          </Col>

        </Row>

        <Row className="mt-3 gy-4">

          <Col>
            <CustomLabel>Logo</CustomLabel>
            <SelectFile
              type="image"
              max={1}
              display
              selectedFiles={logo}
              setSelectedFiles={setLogo}
            />
            {errors['logo'] && <span className="text-danger">{errors['logo']}</span>}
          </Col>

          <Col>
            <CustomLabel>Footer logo</CustomLabel>
            <SelectFile
              type="image"
              max={1}
              display
              selectedFiles={footer_logo}
              setSelectedFiles={setFooterLogo}
            />
          </Col>

          <Col>
            <CustomLabel>Fav icon</CustomLabel>
            <SelectFile
              type="image"
              max={1}
              display
              selectedFiles={fav_icon}
              setSelectedFiles={setFavIcon}
            />
            {errors['fav_icon'] && <span className="text-danger">{errors['fav_icon']}</span>}
          </Col>

          <Col>
            <CustomLabel>Product</CustomLabel>
            <SelectFile
              type="image"
              max={1}
              display
              selectedFiles={product}
              setSelectedFiles={setProduct}
            />
          </Col>

          <Col>
            <CustomLabel>Coupon</CustomLabel>
            <SelectFile
              type="image"
              max={1}
              display
              selectedFiles={coupon}
              setSelectedFiles={setCoupon}
            />
          </Col>

        </Row>

      <SetupActions id={id} submit={onSubmit} skip={skip} isLoading={isSubmitting} />

    </LoaderComponent>
  );
};

export default GeneralSetup;
