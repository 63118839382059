import React from 'react'
import CustomLabel from '../../../../Custom/CustomLabel';
import CustomInput from '../../../../Custom/CustomInput';
import DeleteButton from '../../../../Common/Buttons/DeleteButton';
import { useTranslation } from 'react-i18next';

const OfferFoodItem = ({ food, handleChange, removeFood }) => {

    const { t } = useTranslation();

  return (
    <div className='border p-2'>

        <div className='d-flex align-items-center gap-3'>

            <div className='fs-13 fw-bold'>

                <div className='pb-2'>
                    {food.name}
                </div>

                <CustomInput
                    type="number"
                    placeholder='Quantity'
                    value={food.qty}
                    onChange={(e) => handleChange(food.id, 'qty', e.target.value)}
                />
            </div>

            <DeleteButton 
                style={{ marginLeft: 'auto' }}
                onClick={() => removeFood(food.id)}
            />

        </div>

        {food.prices.length > 0 &&
            <div className='mt-2 border p-3'>
                <h6>{t('Bread')}</h6>
                <div className='d-flex gap-2'>
                    {food.prices?.map(bread => (
                        <div key={bread.bread_id} className='border p-3'>

                            <div className="form-check">
                                    <input 
                                        value={bread.bread_id} 
                                        type="radio" 
                                        id={`food-${food.id}-bread-${bread.bread_id}`} 
                                        name={`food-${food.id}-bread_id`}
                                        className='form-check-input'
                                        onChange={(e) => handleChange(food.id, 'bread_id', e.target.value)}
                                        checked={+food.bread_id === bread.bread_id}
                                    />
                                    <CustomLabel className="form-check-label" htmlFor={`food-${food.id}-bread-${bread.bread_id}`}>{bread.bread_name}</CustomLabel>
                            </div>

                            <div className='mx-4'>
                                {bread.sizes.map(size => (
                                    <div key={size.size_id} className='form-check'>
                                        <input 
                                            className='form-check-input'
                                            type="radio" 
                                            id={`food-${food.id}-bread-${bread.bread_id}-size-${size.size_id}`} 
                                            value={size.size_id} 
                                            name={`food-${food.id}-bread-${bread.bread_id}-size_id`} 
                                            onChange={+food.bread_id === bread.bread_id ? (e) => handleChange(food.id, 'size_id', e.target.value) : null}
                                            checked={+food.bread_id === bread.bread_id && +food.size_id === size.size_id}
                                            disabled={+food.bread_id !== bread.bread_id}
                                        />
                                        <CustomLabel className="form-check-label" htmlFor={`food-${food.id}-bread-${bread.bread_id}-size-${size.size_id}`}>{size.size_name}</CustomLabel>
                                    </div>
                                ))}
                            </div>

                        </div>
                    ))}
                </div>
            </div>
        }

        {food.sizes?.length > 0 &&
            <div className='mt-3 border p-3'>
                <h6>{t('Size')}</h6>
                <div className='d-flex gap-2'>
                    {food.sizes?.map(size => (
                        <div key={size.id} className="form-check">
                            <input 
                                value={size.id} 
                                type="radio" 
                                id={`food-${food.id}-size-${size.id}`} 
                                name={`food-${food.id}-entree_size_id`} 
                                className='form-check-input'
                                onChange={(e) => handleChange(food.id, 'entree_size_id', e.target.value)}
                                checked={+food.entree_size_id === size.id}
                            />
                            <CustomLabel className="form-check-label" htmlFor={`food-${food.id}-size-${size.id}`}>{size.size}</CustomLabel>
                        </div>
                    ))}
                </div>
            </div>
        }
    </div>
  )
}

export default OfferFoodItem