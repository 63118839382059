import React, { useState } from 'react'
import CustomButton from '../../Custom/CustomButton'
import toastAlert from '../../../helpers/toastAlert';
import { foodApi } from '../../../apis/Food/api';

const CloneButton = ({ refetch, selectedItems }) => {

    const [isLoading, setIsLoading] = useState(false);

    const handleClone = async () => {
        try {
            setIsLoading(true);
            const ids = selectedItems.map(item => item.id);
            await foodApi.menu.clone({ ids });
            refetch();
            toastAlert({ type: 'success' })
        } catch(error) {
            toastAlert({ type: 'error' })
        } finally {
            setIsLoading(false);
        }
    }

  return (
    <CustomButton
        className={`btn-success d-flex gap-1 align-items-center`}
        isDisabled={!selectedItems.length}
        onClick={handleClone}
        isLoading={isLoading}
    >
        <i className="ri-file-copy-line fs-16"></i>
        Clone
    </CustomButton>
  )
}

export default CloneButton