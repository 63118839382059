import React from "react";
import CustomInput from "../../../../../Custom/CustomInput";
import { useTranslation } from "react-i18next";
import BootstrapTable from "../../../../../Common/BootstrapTable";
import CustomButton from "../../../../../Custom/CustomButton";
import { FaTrashAlt } from "react-icons/fa";
import SearchComponentItem from "../../../../../Common/SearchComponentItem";

const FoodProductsTable = ({
  foodType,
  products,
  setProducts,
  selectedCombos,
  watch,
  withCombos
}) => {

  const { t } = useTranslation();

  const componentItems = (watch('components') ?? []).reduce((accum, val) => {
    return [...accum, ...val.items.filter(item => item.basic !== 2)]
  }, [])

  const handleInputChange = (productId, field, value) => {
    if (withCombos && selectedCombos.length) {
      setProducts((prev) => prev.map((product) => {
        if (product.id === productId && selectedCombos.includes(product.combo_id)) {
          return {
            ...product,
            [field]: value
          }
        }
        return product;
      }))
    } else {
      setProducts((prev) => prev.map((product) => {
        if (product.id === productId) {
          return {
            ...product,
            [field]: value
          }
        }
        return product;
      }))
    }
  };

  const handleDelete = (productId) => {
    if (withCombos && selectedCombos.length) {
      setProducts((prev) => prev.filter((product) => product.id === productId ? product.combo_id !== selectedCombos[selectedCombos.length - 1] : true ));
    } else {
      setProducts((prev) => prev.filter((product) => product.id !== productId));
    }
  }

  const baseColumns = [

      {
        name: ' ',
        selector: row => (
          <img 
            src={row.image} 
            at={row.name} 
            style={{
              width: '70px',
            }}
          />
        ),
        style: { width: '10%' }
      },

      {
        name: t('Product'),
        selector: row => row.name,
        style: { width: '30%' } 
      },

      {
        name: t('Component Item'),
        selector: row => (
          <SearchComponentItem 
              onChange={(items) => handleInputChange(row.id, 'items', items.map(item => ({ id: item.value, name: item.label })))}
              value={row.items?.map(item => ({
                label: item.name,
                value: item.id
              })) ?? null}
              isMulti
              items={componentItems}
          />
        ),
        condition: componentItems?.length
      },
      
      {
        name: t('Quantity'),
        selector: row => (
          <div className="d-flex gap-2 align-items-center">
            <CustomInput 
              value={row.qty}
              onChange={(e) => handleInputChange(row.id, 'qty', e.target.value)}
            />
            {row.unit}
          </div>
        )    
        ,
      },
      
      {
        name: t('Extra Quantity'),
        selector: row => (
          <div className="d-flex gap-2 align-items-center">
            <CustomInput 
              value={row.extra_qty}
              onChange={(e) => handleInputChange(row.id, 'extra_qty', e.target.value)}
              disabled={foodType === 8}
            />
            {row.unit}
          </div>
        ),
        condition: foodType !== 8
      },
      {
        name: ' ',
        style: {
          width: '70px',
        },
        selector: row => (
          <CustomButton
            className="btn btn-danger"
            type="button"
            onClick={() => handleDelete(row.id)}
          >
              <FaTrashAlt />
          </CustomButton>
        ) 
      }
  ]

  const visibleColumns = baseColumns.filter(column => column.condition !== undefined ? column.condition : true).map(column => {
    const { condition, ...rest } = column;
    return rest
  })

  const visibleProducts = (withCombos && selectedCombos.length) ? products.filter(product => product.combo_id === selectedCombos[selectedCombos.length - 1]) : products;

  return (
    <BootstrapTable 
      data={visibleProducts}
      columns={visibleColumns}
      style={{ marginTop: '1rem', tableLayout: 'fixed', borderCollapse: 'collapse' }}
    />
  );
};

export default FoodProductsTable;