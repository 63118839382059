import React from 'react'
import { Col, Row } from 'reactstrap';
import CustomLabel from '../../Custom/CustomLabel';
import CustomInput from '../../Custom/CustomInput';

const Env = ({ register }) => {

  return (

    <Row className="gy-3 p-3">

        <Col lg={6}>
            <CustomLabel>
                Name
            </CustomLabel>
            <CustomInput
                {...register(`env.name`)}
                type="text"
            />
        </Col>

    </Row>
  )
}

export default Env