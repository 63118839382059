import React from "react";
import { Switch, Route } from "react-router-dom";

//Layouts
import NonAuthLayout from "../Layouts/NonAuthLayout";
import VerticalLayout from "../Layouts/index";
//routes
import { authProtectedRoutes, publicRoutes, setupRoutes } from "./allRoutes";
import ProtectedRoute from "./ProtectedRoute";

const Index = () => {

  const availableSetupRoutes = setupRoutes.map((r) => r.path);
  
  const availablePublicRoutesPaths = publicRoutes.map((r) => r.path);

  const availableAuthRoutesPath = authProtectedRoutes.map((r) => r.path);

  return (
    <React.Fragment>
      
      <Switch>

        <Route path={availableSetupRoutes}>
          <NonAuthLayout>
            <Switch>
              {setupRoutes.map((route, idx) => (
                <ProtectedRoute
                  key={idx}
                  path={route.path}
                  allowed={route.allowed}
                  component={route.component}
                  exact={true}
                />
              ))}
            </Switch>
          </NonAuthLayout>
        </Route>

        <Route path={availablePublicRoutesPaths}>
          <NonAuthLayout>
            <Switch>
              {publicRoutes.map((route, idx) => (
                <Route
                  path={route.path}
                  component={route.component}
                  key={idx}
                  exact={true}
                />
              ))}
            </Switch>
          </NonAuthLayout>
        </Route>

        <Route path={availableAuthRoutesPath}>
          {/* <AuthProtected> */}
          <VerticalLayout>
            <Switch>
              {authProtectedRoutes.map((route, idx) => (
                <ProtectedRoute
                  path={route.path}
                  component={route.component}
                  allowed={route.allowed}
                  key={idx}
                  exact={true}
                />
              ))}
            </Switch>
          </VerticalLayout>
          {/* </AuthProtected> */}
        </Route>
      </Switch>
    </React.Fragment>
  );
};

export default Index;
