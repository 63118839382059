import React, { useEffect } from "react";
import { ReactSortable } from "react-sortablejs";
import { Card, CardBody } from "reactstrap";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import SortableList from "../../../Components/Common/SortableList";
import PageContainer from "../../../Components/Layout/PageContainer";
import { useGallery } from "../../../hooks/useQueryHelpers";

const SortGalleries = () => {
  const { gallery, setGallery, refetch, isLoading } = useGallery();

  // console.log(gallery);

  useEffect(() => {
    // const timeout = setTimeout(() => {
    //   refetch();
    // }, 0);
    // return () => clearTimeout(timeout);
  }, []);

  return (
    <PageContainer isLoading={isLoading}>
      <BreadCrumb pageTitle="Gallery" title="Sort" to="settings/gallery" />
      <SortableList list={gallery} setList={setGallery} />
    </PageContainer>
  );
};

export default SortGalleries;
