import React from "react";
import { Spinner } from "reactstrap";

const LoaderComponent = ({ isLoading, children }) => {
  return isLoading ? (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: 20,
        margin: "20px 0",
      }}
    >
      <Spinner />
    </div>
  ) : (
    children
  );
};

export default LoaderComponent;
