import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Input,
  Row,
} from "reactstrap";

import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import CustomLabel from "../../../Components/Custom/CustomLabel";
import CustomInput from "../../../Components/Custom/CustomInput";
import CustomButton from "../../../Components/Custom/CustomButton";
import { useTranslation } from "react-i18next";
import PageContainer from "../../../Components/Layout/PageContainer";
import LanguagesTable from "../../../Components/Settings/Language/LanguageTable";
import { getPathname } from "../../../Layouts/LayoutMenuData";
import { languageActions } from "../../../redux/slices/languageSlice";
import { getAllLanguagesApi } from "../../../apis/Settings/languageApi";
import { useQuery } from '@tanstack/react-query'
import { useDispatch } from "react-redux";

const handleSearch = (data, query) => {
  const copied = [...data];
  if (!query) return data;
  return copied.filter((item) => item.name.toLowerCase().includes(query));
};

const Language = () => {

  const [searchQuery, setSearchQuery] = useState("");

  const { t } = useTranslation();

  const dispatch = useDispatch();

  const { languages } = useSelector((state) => state.language);

  const { isLoading, isRefetching } = useQuery(["languages"], () => getAllLanguagesApi({ page_size: 'all' }),
  {
    onSuccess({data: { data }}) {
      dispatch(languageActions.getLanguages(data));
    },
  }
);

  const searchedData = handleSearch(languages, searchQuery);

  return (
    <PageContainer isLoading={isLoading || isRefetching}>
      <Card>
        <CardBody>
          <form>
            <Input type="hidden" id="id-field" />
            <Row className="g-3">
              <Col lg={6}>
                <div className="mb-3">
                  <CustomLabel
                    htmlFor="choices-single-default"
                    className="form-label text-muted"
                  >
                    Search
                  </CustomLabel>
                  <CustomInput
                    className="form-control"
                    placeholder="English..."
                    value={searchQuery}
                    onChange={(e) =>
                      setSearchQuery(e.target.value.toLowerCase())
                    }
                  />
                </div>
              </Col>
              <Col
                lg={6}
                className="d-flex align-items-end justify-content-end"
              >
                <div className="mb-3">
                  <Link to={getPathname("settings/language/create")}>
                    <CustomButton className="btn btn-success">Add</CustomButton>
                  </Link>
                </div>
              </Col>
            </Row>
          </form>
        </CardBody>
      </Card>

      <Card>
        <CardHeader>{t("Languages")}</CardHeader>
        <CardBody>
          <div>
            <LanguagesTable data={searchedData} />
          </div>
        </CardBody>
      </Card>
    </PageContainer>
  );
};

export default Language;
