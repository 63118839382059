import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { Card, Col, Row } from "reactstrap";
import useActions from "../../../hooks/useActions";
import CustomButton from "../../Custom/CustomButton";
import CustomInput from "../../Custom/CustomInput";
import CustomLabel from "../../Custom/CustomLabel";
import { useGetProductCategories, useGetProductUnits } from "../../../hooks/useQueryHelpers";
import SelectFile from "../../Common/SelectFile";
import { inventoryApi } from "../../../apis/Inventory/api";
import toastAlert from "../../../helpers/toastAlert";
import formatFormData from "../../../helpers/formatFormData";
import CustomOption from "../../Custom/CustomOption";
import { objectFilter } from "../../../helpers/objectFilter";
import { getPathname } from "../../../Layouts/LayoutMenuData";
import SelectLanguage from "../../Common/SelectLanguage";
import { useQuery } from "@tanstack/react-query";

const ProductForm = ({ type, initialValues, language, setLanguage }) => {

  const { register, handleSubmit, reset, setValue } = useForm();

  const [image, setImage] = useState({
    url: "",
    file: null,
  });

  const history = useHistory();

  const { isLoading, onLoading, onFinally } = useActions();

  const { units } = useGetProductUnits({
    page_size: 'all'
  })

  const { categories } = useGetProductCategories({
    page_size: 'all'
  })

  const { data } = useQuery(['product-types'], inventoryApi.products.getTypes);

  const productTypes = data?.data ?? [];

  const submitForm = async (data) => {
    onLoading();
    try {
        const formData = formatFormData(objectFilter({ ...data, image: image.file }));
        if (type === "create") {
            await inventoryApi.products.create(formData, language);
            toastAlert({ type: 'success' })
            history.push(getPathname('inventory/products'))
        } else {
            const { data: { data }} = await inventoryApi.products.update(initialValues?.id, formData, language);
            toastAlert({ type: 'success' })
            if (image.file) {
                setImage({
                    id: data.file?.id ?? '',
                    url: data.file?.link ?? '',
                    file: null
                })
            }
        }
    } catch (error) {
        toastAlert({ type: 'error' })
    } finally {
        onFinally();
    }
  };

  useEffect(() => {
    if (initialValues) {

      reset(
        (() => {
          const { id, file, image, unit, category, ready, total_qty, ...rest } = initialValues;
          return rest;
        })()
      );

      if (initialValues.unit) {
        setValue('unit_id', initialValues.unit.id);
      }

      if (initialValues.category) {
        setValue('category_id', initialValues.category.id);
      }

      if (initialValues.file) {
        setImage({
            id: initialValues.file.id,
            url: initialValues.file.link,
            file: null
        })
      }

    }
  }, [initialValues, units.list, categories.list, productTypes]);

  return (
    <Row>
        <Col lg={3}>
            <Card className='p-3'>
                <CustomLabel>Image</CustomLabel>
                <SelectFile
                    selectedFiles={image}
                    setSelectedFiles={setImage}
                    type='image'
                />
            </Card>
        </Col>

        <Col lg={9}>
            <Card className="p-3">
                <Row className="gy-3">

                    <Col lg={12}>
                        {type === 'update' && 
                            <SelectLanguage
                                language={language}
                                onChange={lang => setLanguage(lang)}
                            /> 
                        }
                    </Col>

                    <Col md={6}>
                        <CustomLabel translatable>Name</CustomLabel>
                        <CustomInput
                            type="text"
                            {...register("name")}
                        />
                    </Col>

                    <Col md={6}>
                        <CustomLabel>Unit</CustomLabel>
                        <select
                            className="form-select"
                            {...register('unit_id')}
                        >
                            <CustomOption value="">Select...</CustomOption>
                            {units.list.map(unit => (
                                <option key={unit.id} value={unit.id}>{unit.name}</option>
                            ))}
                        </select>
                    </Col>

                    <Col md={6}>
                        <CustomLabel>Category</CustomLabel>
                        <select
                            className="form-select"
                            {...register('category_id')}
                        >
                            <CustomOption value="">Select...</CustomOption>
                            {categories.list.map(category => (
                                <option key={category.id} value={category.id}>{category.name}</option>
                            ))}
                        </select>
                    </Col>

                    <Col md={6}>
                        <CustomLabel>Type</CustomLabel>
                        <select
                            className="form-select"
                            {...register('type')}
                        >
                            <CustomOption value="">Select...</CustomOption>
                            {productTypes.map(productType => (
                                <option key={productType} value={productType}>{productType}</option>
                            ))}
                        </select>
                    </Col>
                    
                    <Col md={6}>
                        <CustomLabel>Weight Before</CustomLabel>
                        <CustomInput
                            type="number"
                            {...register('weight_before')}
                        />
                    </Col>
                    
                    <Col md={6}>
                        <CustomLabel>Weight After</CustomLabel>
                        <CustomInput
                            type="number"
                            {...register('weight_after')}
                        />
                    </Col>

                    {
                        type ==='update' && 
                        <Col md={6}>
                            <CustomLabel>Total Quantity</CustomLabel>
                            <CustomInput
                                type="text"
                                disabled
                                value={initialValues?.total_qty ?? ''}
                            />
                        </Col>
                    }
                    
                    <Col md={6} className="d-flex align-items-end">
                        <div className="form-check form-switch">
                            <input {...register('daily')} className="form-check-input fs-16" type="checkbox" role="switch" id="daily" />
                            <CustomLabel className="form-check-label" htmlFor="daily">Daily</CustomLabel>
                        </div>
                    </Col>

                </Row>
    
            </Card>

            <div className="mb-3 inner-submit-button">
            <CustomButton 
                className="btn-success" 
                isLoading={isLoading}
                onClick={handleSubmit(submitForm)}
            >
                {type === "create" ? "Create" : "Update"}
            </CustomButton>
            </div>
        </Col>
    </Row>
  );
};

export default ProductForm;
