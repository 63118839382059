import React, { useEffect, useState } from 'react'
import { Droppable } from 'react-beautiful-dnd'
import { useQuery } from '@tanstack/react-query';
import { foodApi } from '../../../apis/Food/api';
import CustomLabel from '../../Custom/CustomLabel';
import LoadingComponent from '../../Common/Loading';
import CustomInput from '../../Custom/CustomInput';
import { Col, Row } from 'reactstrap';
import CustomButton from '../../Custom/CustomButton';
import { addPageImage, deleteMenuPage, updateMenuPage } from '../../../apis/Settings/menuApi';
import toastAlert from '../../../helpers/toastAlert';
import useDeleteModal from '../../../hooks/useDeleteModal';
import DeleteModal from '../../Common/DeleteModal';
import SelectFile from '../../Common/SelectFile';
import SliderItem from './SliderItem';

const SliderPage = ({ setPages, page, openUpdateModal, focusFirstPage }) => {

    const [background, setBackground] = useState({
        id: null,
        file: null,
        url: null,
    })

    const [searchQuery, setSearchQuery] = useState('');

    const [isSaving, setIsSaving] = useState(false);

    const { isLoading: isDeleting, setModal, load, callback: closeModal, modal: isModalOpen } = useDeleteModal();

    const [foods, setFoods] = useState([]);

    const handleChange = async (itemId, field, value) => {
        setPages(prev => ({
            ...prev,
            slider: prev.slider.map(pg => {
                if (pg.id === page.id) return {
                    ...pg,
                    sliders: pg.sliders.map(item => {
                        if (item.id === itemId) return {
                            ...item,
                            [field]: value
                        }
                        return item
                    })
                }
                return pg
            })
        }))
    }

    const { isLoading, isRefetching } = useQuery(['slider-food'], () => foodApi.menu.getAll({
        is_slider: true,
        page_size: 'all'
    }), {
        onSuccess({ data: { data }}) {
            setFoods(data);
        }
    })

    const handleSave = async () => {
        try {
            setIsSaving(true);
            const requestBody = {
                page_id: page.id,
                name: page.name,
                type: page.type,
                sliders: page.sliders.filter(item => item.active).map((item, index) => ({
                    food_id: item.id,
                    sort: index + 1,
                    subtitle: item.subtitle,
                    description: item.description
                }))
            }

            await updateMenuPage({
                pages: [requestBody]
            })

            if (background.file) {
                const formData = new FormData();
                const file = background.file;
                const blob = new Blob([file], { type: file.type });
                formData.append("image", blob, file.name);
                const { data: { data } } = await addPageImage(page.id, formData);
                setBackground({
                    id: data.id,
                    file: null,
                    url: data.link
                })
            }

            toastAlert({ type: 'success' })
        } catch (error) {
            toastAlert({ type: 'error' })
        } finally {
            setIsSaving(false);
        }
    }

    const deletePage = async () => {
        try {
            load();
            await deleteMenuPage(page.id);
            setPages(prev => ({
                ...prev,
                slider: prev.slider.filter(pg => pg.id !== page.id)
            }));
            toastAlert({ type: 'success' })
        } catch (error) {
            toastAlert({ type: 'error' })
        } finally {
            closeModal(false);
            focusFirstPage({ ignoreCurrentActive: true });
        }
    }

    useEffect(() => {
        if (foods) {
            setPages(prev => ({
                ...prev,
                slider: prev.slider.map(pg => {
                    if (pg.id === page.id) {
                        if (pg.sliders?.length) {
                            return {
                                ...pg,
                                sliders: [...pg.sliders, 
                                    ...foods.filter(food => !pg.sliders.find(slider => slider.id === food.id)).map(food => {
                                        return {
                                            id: food.id,
                                            name: food.name ?? '',
                                            subtitle: '',
                                            description: '',
                                            image: {
                                                id: food.file_menu?.id,
                                                url: food.file_menu?.link ?? '',
                                            },
                                            active: false
                                        }
                                    })
                                ]
                            }
                        }
                        else {
                            return {
                                ...pg,
                                sliders: foods.map(food => ({
                                    id: food.id,
                                    name: food.name ?? '',
                                    subtitle: '',
                                    description: '',
                                    image: {
                                        id: food.file_menu?.id,
                                        url: food.file_menu?.link ?? '',
                                    },
                                    active: true
                                }))
                            }
                        }
                    }
                    return pg
                })
            }))
        }
    }, [foods])


    useEffect(() => {
        if (page.background) {
            setBackground({
                id: page.background.id,
                url: page.background.link,
                file: null,
            })
        }
    }, [])

  return (
    <div>
        <DeleteModal 
            show={isModalOpen}
            onDeleteClick={deletePage}
            isDeleting={isDeleting}
            onCloseClick={() => setModal(false)}
        />
        
        <Row className='gy-3'>

            <Col lg={6}>
                <CustomLabel>
                    Search Foods
                </CustomLabel>
                <CustomInput 
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                />
            </Col>

            <Col className='d-flex align-items-end justify-content-end'>
                <div className='d-flex justify-content-end gap-2'>

                    <CustomButton
                        type="button"
                        className="btn-success"
                        onClick={handleSave}
                        isLoading={isSaving}
                    >
                        Save
                    </CustomButton>

                    <CustomButton
                        type="button"
                        className="btn-dark"
                        onClick={() => openUpdateModal('update', page)}
                    >
                        <i className="ri-edit-line fs-16"></i>
                    </CustomButton>

                    <CustomButton
                        type="button"
                        className="btn-danger"
                        onClick={() => setModal(true)}
                    >
                        Delete
                    </CustomButton>
                </div>
            </Col>

            <Col lg={12}>
                <CustomLabel>Background Image</CustomLabel>
                <SelectFile 
                    selectedFiles={background}
                    setSelectedFiles={setBackground}
                    type='image'
                />
            </Col>

        </Row>

        <LoadingComponent loading={isLoading || isRefetching || foods === null || (foods.length > 0 && page.sliders.length === 0)}>
            <Droppable droppableId={`page-${page.id}`} type={`slider-${page.id}`}>
                {provided => (
                    <div className='d-flex flex-column gap-3 dropzone p-3 mt-3' ref={provided.innerRef} {...provided.droppableProps}>
                        {page.sliders?.filter(item => item.name?.toLowerCase().includes(searchQuery.toLowerCase()))?.length ? page.sliders?.filter(item => item.name?.toLowerCase().includes(searchQuery.toLowerCase())).map((item, index) =>  (
                            <SliderItem 
                                key={item.id}
                                isDragDisabled={!!searchQuery.length}
                                item={item}
                                index={index}
                                handleChange={handleChange}
                            />
                        )) :
                            <div className='d-flex align-items-center justify-content-center'>
                                No Items found
                            </div>
                        }
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </LoadingComponent>
    </div>
  )
}

export default SliderPage