import { useQuery } from '@tanstack/react-query'
import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import BreadCrumb from '../../../Components/Common/BreadCrumb'
import PageContainer from '../../../Components/Layout/PageContainer'
import RoomForm from '../../../Components/Orders/Rooms/Form'
import { getOneRoom } from '../../../apis/Orders/roomsApi'

const UpdateRoom = () => {

  const {id} = useParams();

  const [initialValues, setInitialValues] = useState(null);

  const { isLoading, isRefetching } = useQuery(['one-room'], () => getOneRoom(id), {
    onSuccess({data: { data }}) {
      setInitialValues(data);
    }
  })

  return (
    <PageContainer isLoading={isLoading || isRefetching}>
        <BreadCrumb pageTitle="Rooms" title="Update" to="orders/rooms"/>
        {initialValues && 
          <RoomForm
            type="update"
            initialValues={initialValues}
          />
        } 
    </PageContainer>
  )
}

export default UpdateRoom