import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { getPathname } from "../../Layouts/LayoutMenuData";

const BreadCrumb = ({ title, pageTitle, to }) => {
  
  const { t } = useTranslation();

  return (
    <React.Fragment>
        <div className="page-title-box d-sm-flex align-items-center justify-content-end">
          <div className="page-title-right">
            <ol className="breadcrumb m-0">
              <li className="breadcrumb-item">
                {to ? 
                    <Link to={`${getPathname(to)}`}>{t(pageTitle)}</Link>
                  :
                    <span>{t(pageTitle)}</span>
                }
              </li>
              <li className="breadcrumb-item active text-capitalize">
                {t(title)}
              </li>
            </ol>
          </div>
        </div>
    </React.Fragment>
  );
};

export default BreadCrumb;
