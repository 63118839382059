import axiosInstance from "..";

export const getSmsSettingsApi = () => {
  return axiosInstance.get("/sms_settings");
};

export const setSmsSettingsApi = (body) => {
  return axiosInstance.post("/sms_settings", body);
};

export const getSmsServicesApi = () => {
  return axiosInstance.get("/smsservice");
};

export const getSmsHistoryApi = () => {
  return axiosInstance.get("/smsservice/history");
};

export const paySmsServiceApi = (id, data) => {
  return axiosInstance.post(`/smsservice/pay/${id}`, data);
};