import React from "react";
import { Spinner } from "reactstrap";

const ImageItem = ({ f, removeFile, deleting, loading }) => {

  return (
    <div className={`file-preview-container ${loading ? 'loading' : ''}`}>
        {loading && <Spinner className="loading-spinner" />}
        <div className="file-title-container">
            <div className="faded-overlay"></div>
            <div className="file-info-container">
              {deleting === f.id ? <div>
                <Spinner />
              </div> : <div className="remove-file" onClick={() => removeFile(f)}>
                <i className="ri-close-line"></i>
              </div>}
              {f.name ? 
                <span className="file-title">
                  {f.name || 'Image'}
                </span>
             : <></>}
            </div>
        </div>
        <div className="file-preview">
            <img 
              src={f.url}
              alt={f.url}
            />
        </div>
    </div>
  );
};

export default ImageItem;
