import axiosInstance from "..";

export const getOnlineSettingsApi = (lang) => {
  return axiosInstance.get(`/order_online_seetings?lang=${lang}`);
};

export const setOnlineSettingsApi = (body, lang) => {
  return axiosInstance.post(`/order_online_seetings?lang=${lang}`, body);
};

export const setCloseImage = (body) => {
  return axiosInstance.post(`/order_online_seetings/setCloseImage`, body);
};

export const addSpecialTimeApi = (body) => {
  return axiosInstance.post("/order_online_seetings/special_times", body);
};

export const updateSpecialTimeApi = (id, body) => {
  return axiosInstance.patch(
    `/order_online_seetings/special_times/${id}`,
    body
  );
};

export const deleteOpeningHourTimeApi = (id) => {
  return axiosInstance.delete(`/order_online_seetings/time/${id}`);
};

export const deleteSpecialTimeApi = (ids = []) => {
  return axiosInstance.delete("/order_online_seetings/special_times", {
    data: {
      ids,
    },
  });
};
