import React from 'react'
import { Col, Row } from 'reactstrap'
import CustomInput from '../../../../../Custom/CustomInput'
import CustomButton from '../../../../../Custom/CustomButton'
import generateUniqueID from '../../../../../../helpers/generateUniqueID'
import { useForm } from 'react-hook-form'

const SizesList = ({data, setData}) => {

  const {register, handleSubmit, reset} = useForm();

  const handleChange = ({itemId, field, value}) => {
    setData(prev => prev.map(item => {
        if (item.id === itemId) return {
              ...item,
              [field]: value
        }
        return item
    }))
  }

  const removeSize = (sizeId) => {
    setData(prev => prev.filter(size => size.id !== sizeId))
  }

  const addSize = (sizeData) => {

    setData([
      ...(data || []),
      {
        id: generateUniqueID(),
        ...sizeData,
        isNew: true
      }
    ])

    reset({
      size: '',
      price: '',
      price_in: '',
    })
  }

  return (
    <div>
        <Row className="gx-1 my-2 gy-3">

          <Col lg={3} md={5} sm={4}>
            <CustomInput 
                placeholder="Size"
                {...register('size')}
              />
          </Col>

          <Col lg={5} md={7} sm={8} className='d-flex gap-1'>
              <CustomInput 
                type="number"
                placeholder="Price"
                {...register('price')}
              />

              <CustomInput 
                type="number"
                placeholder="Price In"
                {...register('price_in')}
              />

              <CustomButton
                  className="btn-success btn-sm d-flex gap-1 align-items-center mx-2"
                  onClick={handleSubmit(addSize)}
              >
                  <i className="ri-add-line fs-16"></i>
              </CustomButton>
          </Col>

        </Row>
        
        {data?.map(item => (
            <Row key={item.id} className="gx-1 my-2 p-3 border gy-3">
              <Col lg={3} md={5} sm={6}>
                <CustomInput 
                    placeholder="Size"
                    value={item.size ?? ''}
                    onChange={e => handleChange({itemId: item.id, field: 'size', value: e.target.value})}
                  />
              </Col>

              <Col lg={5} md={7} sm={6} className='d-flex gap-1'>
                  <CustomInput 
                    type="number"
                    placeholder="Price"
                    value={item.price ?? ''}
                    onChange={e => handleChange({itemId: item.id, field: 'price', value: e.target.value})}
                  />

                  <CustomInput 
                    type="number"
                    placeholder="Price In"
                    value={item.price_in ?? ''}
                    onChange={e => handleChange({itemId: item.id, field: 'price_in', value: e.target.value})}
                  />

                  <CustomButton
                      className="btn-danger btn-sm d-flex gap-1 align-items-center mx-2"
                      onClick={() => removeSize(item.id)}
                  >
                      <i className="ri-delete-bin-4-line fs-16"></i>
                  </CustomButton>
              </Col>
            </Row>
        ))}
        
    </div>
  )
}

export default SizesList