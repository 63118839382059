import React from 'react'
import CustomButton from '../../Custom/CustomButton'
import { useTranslation } from 'react-i18next'

const Finish = ({ finish }) => {

    const { t } = useTranslation();

  return (
    <div className=' d-flex justify-content-center'>
        <div className='d-flex flex-column align-items-center gap-3 mt-5'>

            <h1>{t('Setup Completed')}</h1>

            <CustomButton 
                type="button" 
                className="btn btn-success"
                onClick={finish}
            >
                Advance to dashboard
            </CustomButton>

        </div>
    </div>
  )
}

export default Finish