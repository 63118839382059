import React, { useEffect, useState } from 'react'
import { Row, Col } from 'reactstrap'
import CustomLabel from '../../Custom/CustomLabel';
import CustomOption from '../../Custom/CustomOption';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import CustomButton from '../../Custom/CustomButton';
import CustomInput from '../../Custom/CustomInput';
import { paySmsServiceApi } from '../../../apis/Orders/smsSettingsApis';
import toastAlert from '../../../helpers/toastAlert';
import PasswordModal from './PasswordModal';
import useModal from '../../../hooks/useModal';

const PaySms = ({ smsServices }) => {

    const { register, handleSubmit, setValue, reset } = useForm();

    const [isSubmitting, setIsSubmitting] = useState(false);

    const { isModalOpen, toggleModal } = useModal();

    const { t } = useTranslation();

    const submitForm = async (data) => {
        setIsSubmitting(true);
        const { sms_service, ...rest } = data;
        try {
           await paySmsServiceApi(sms_service, rest); 
           toastAlert({ type: 'success' })
           onModalClose();
        } catch (error) {
            toastAlert({ type: 'error' })
        } finally {
            setIsSubmitting(false);
        }
    }

    const onModalClose = () => {
        toggleModal();
        reset({
            sms_service: '',
            password: '',
            amount: '',
            count: '',
            sms_rate: '',
        })
    }

  return (
    <Row className='border p-3 gy-3'>
        <h4>{t('Payment')}</h4>

        <PasswordModal 
            isOpen={isModalOpen}
            toggle={toggleModal}
            isSubmitting={isSubmitting}
            onSubmit={handleSubmit(submitForm)}
            register={register}
        />

        <Col lg={6}>
            <CustomLabel>SMS Service</CustomLabel>
            <select 
                className="form-select" 
                {...register('sms_service')}
            >
                <CustomOption value=''>Select</CustomOption>
                {smsServices?.map((service) => (
                    <option key={service.id} value={service.id}>{service.name}</option>
                ))}
            </select>
        </Col>

        <Col lg={6}>
            <CustomLabel>SMS Rate</CustomLabel>
            <CustomInput 
                {...register('sms_rate')}
            />
        </Col>

        <Col lg={6}>
            <CustomLabel>Count</CustomLabel>
            <CustomInput 
                {...register('count')}
            />
        </Col>

        <Col lg={6}>
            <CustomLabel>Amount</CustomLabel>
            <CustomInput
                {...register('amount')}
            />
        </Col>

        <Col lg={12}>
            <CustomButton
                type="button"
                className="btn-success"
                onClick={toggleModal}
            >
                Pay
            </CustomButton>
        </Col>

    </Row>
  )
}

export default PaySms