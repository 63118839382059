import React from "react";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import SortableList from "../../../Components/Common/SortableList";
import PageContainer from "../../../Components/Layout/PageContainer";
import { usePagesMenu } from "../../../hooks/useQueryHelpers";

const SortPagesMenus = () => {
  const { isLoading, pagesMenu, setPagesMenu } = usePagesMenu();

  return (
    <PageContainer isLoading={isLoading}>
      <BreadCrumb
        pageTitle="Pages Menu"
        title="Sort"
        to="settings/pages-menu"
      />
      <SortableList list={pagesMenu} setList={setPagesMenu} mainTitle="title" />
    </PageContainer>
  );
};

export default SortPagesMenus;
