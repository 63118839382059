import React, { useEffect, useState } from 'react'
import PageContainer from '../Components/Layout/PageContainer'
import { Card, CardBody, CardHeader, Col, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap'
import classnames from 'classnames'
import GeneralSetup from '../Components/Setup/General/General'
import ContactsSetup from '../Components/Setup/Contacts/Contacts'
import OpeningHoursSetup from '../Components/Setup/OpeningHours/OpeningHours'
import SeoSetup from '../Components/Setup/Seo/Seo'
import OnlineOrderSettingsSetup from '../Components/Setup/OnlineOrderSettings/OnlineOrderSettings'
import DeliverySettingsSetup from '../Components/Setup/DeliverySettings/DeliverySettings'
import PaymentSetup from '../Components/Setup/Payment/Payment'
import BranchesSetup from '../Components/Setup/Branches/Branches'
import HomeSlidersSetup from '../Components/Setup/HomeSliders/HomeSliders'
import LanguageSetup from '../Components/Setup/Language/Language'
import ReportsSettingsSetup from '../Components/Setup/ReportsSettings/ReportsSettings'
import DeliveryZonesSetup from '../Components/Setup/DeliveryZones/DeliveryZones'
import Finish from '../Components/Setup/Finish/Finish'
import { useHistory } from 'react-router-dom'
import { getDashboardSetup, setDashboardSetup } from '../apis/Settings/setupApi'
import { useTranslation } from 'react-i18next'
import { getPathname } from '../Layouts/LayoutMenuData'
import { useQuery } from '@tanstack/react-query'

const steps = [

    {
        step: 1,
        label: 'Setup',
        component: GeneralSetup,
        skippable: false,
    },

    {
        step: 2,
        label: 'Contact Information',
        component: ContactsSetup,
        skippable: false,
    },

    {
        step: 3,
        label: 'Opening hours',
        component: OpeningHoursSetup,
        skippable: false,
    },

    {
        step: 4,
        label: 'Seo',
        component: SeoSetup,
        skippable: true,
    },

    {
        step: 5,
        label: 'Online Order Settings',
        component: OnlineOrderSettingsSetup,
        skippable: true,
    },

    {
        step: 6,
        label: 'Delivery Settings',
        component: DeliverySettingsSetup,
        skippable: true,
    },

    {
        step: 7,
        label: 'Delivery Zones',
        component: DeliveryZonesSetup,
        skippable: true,
    },

    {
        step: 8,
        label: 'Payment ',
        component: PaymentSetup,
        skippable: true,
    },

    
    {
        step: 9,
        label: 'Branches ',
        component: BranchesSetup,
        skippable: true,
    },
    
    {
        step: 10,
        label: 'Home Sliders ',
        component: HomeSlidersSetup,
        skippable: true,
    },

    {
        step: 11,
        label: 'Language',
        component: LanguageSetup,
        skippable: true,
    },

    {
        step: 12,
        label: 'Report Settings',
        component: ReportsSettingsSetup,
        skippable: false,
    },

    {
        step: 13,
        label: 'Finish',
        component: Finish,
    },
]

const Setup = () => {


    const [setupProgress, setSetupProgress] = useState(null);

    const { t } = useTranslation(); 

    const history = useHistory();

    useQuery(['dashboard-setup'], getDashboardSetup, {
        onSuccess({data: { data }}) {
            setSetupProgress({
                completed: data.completed,
                steps: steps.map(( step, index ) => {
                    const initialStep = data.steps.find(item => item.step === step.step);
                    return {
                        step: step.step,
                        current: initialStep ? initialStep.current : index === 0,
                        completed: initialStep ? !!initialStep.completed : false,
                        skipped: initialStep ? !!initialStep.skipped : false,
                    }
                })
            })
        }
      })

    const changeCurrentStep = (id) => {
        setSetupProgress(prev => ({
            ...prev,
            steps: prev.steps.map(step => ({
                ...step,
                current: step.step === id
            }))
        }))
    }

    const next = async (stepId) => {
        const requestBody = {
            ...setupProgress,
            steps: setupProgress.steps.map(step => {
                if (step.step === stepId) return {
                    ...step,
                    completed: true,
                    skipped: false,
                    current: false
                }
                return {
                    ...step,
                    current: (setupProgress.steps.find(item => item.step > stepId && !item.completed)?.step || stepId + 1) === step.step
                }
            })
        }
        try {
            await setDashboardSetup(requestBody);
            setSetupProgress(requestBody);
        } catch (error) {
            console.log('error', error);
        }
    }

    const skip = async (stepId) => {
        const requestBody = {
            ...setupProgress,
            steps: setupProgress.steps.map(step => {
                if (step.step === stepId) return {
                    ...step,
                    completed: false,
                    skipped: true,
                    current: false
                }
                return {
                    ...step,
                    current: (setupProgress.steps.find(item => item.step > stepId && !item.completed)?.step || stepId + 1) === step.step
                }
            })
        }
        try {
            await setDashboardSetup(requestBody);
            setSetupProgress(requestBody);
        } catch (error) {
            console.log('error', error);
        }
    }

    const finish = async () => {
        const requestBody = {
            completed: true,
            steps: setupProgress.steps.map((step, index) => {
                if (index === setupProgress.steps.length - 1) return {
                    ...step,
                    skipped: false,
                    completed: true,
                    current: true
                }
                return step
            })
        }
        await setDashboardSetup(requestBody);
        history.push(getPathname());
    }

  return (
    <div style={{
            width: '85vw',
            marginInline: 'auto'
        }}
    >
        <PageContainer className="mt-5" isLoading={setupProgress === null}>

            <Card>

                <CardHeader className='fs-20'>
                    Finish setting up before you advance
                </CardHeader>

                <CardBody>

                    <div className="vertical-navs-step">
                        <Row className='gy-3'>
                            <Col lg={3}>
                                <Nav
                                    className="flex-column custom-nav nav-pills"
                                >
                                    {setupProgress?.steps?.map((step, index) => (
                                        <NavItem key={step.step}>
                                            <NavLink
                                                href="#"
                                                className={
                                                    (classnames({
                                                        active: step.current,
                                                        done: step.completed,
                                                    }))
                                                }
                                                onClick={() => { 
                                                    if (step.completed || step.skipped || setupProgress.steps[index - 1]?.completed || setupProgress.steps[index - 1]?.skipped) {
                                                        changeCurrentStep(step.step);
                                                    }
                                                }}
                                            >
                                                <span className="step-title me-2">
                                                    <i className="ri-close-circle-fill step-icon me-2"></i>
                                                    Step {step.step}
                                                </span>

                                                {steps.find(item => item.step === step.step)?.label}
                                                {step.skipped && 
                                                    <span className='ms-2'>({t('skipped')})</span>
                                                }
                                            </NavLink>
                                        </NavItem>
                                    ))}
                                </Nav>
                            </Col>

                            <Col lg={9}>
                                <TabContent activeTab={setupProgress?.steps?.find(item => item.current)?.step}>
                                    {steps.map((step, index) => (
                                        <TabPane key={step.step} tabId={step.step}>
                                            <h3
                                                className='fs-20 border-bottom pb-3'
                                            >
                                                {step.label}
                                            </h3>
                                            <div className='p-2'>
                                                <step.component 
                                                    id={step.step}
                                                    next={next} 
                                                    skip={(step.skippable && !setupProgress?.steps?.find(item => item.step === step.step)?.completed) && skip}
                                                    finish={index === steps?.length - 1 && finish}
                                                />
                                            </div>
                                        </TabPane>
                                    ))}
                                </TabContent>
                            </Col>

                        </Row>

                    </div>
                </CardBody>
            </Card>
        </PageContainer>
    </div>
  )
}

export default Setup