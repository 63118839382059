import { useQuery } from "@tanstack/react-query";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { getOneLanguageApi } from "../../../apis/Settings/languageApi";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import PageContainer from "../../../Components/Layout/PageContainer";
import LanguageForm from "../../../Components/Settings/Language/LanguageForm";

const UpdateLanguage = () => {

  const [initValue, setInitValue] = useState(null);

  const { code } = useParams();

  const {isLoading, isRefetching} = useQuery(["languages", code], () => getOneLanguageApi(code), {
    onSuccess(data) {
      const lang = data.data.data;
      setInitValue(lang);
    },
  });

  return (
    <PageContainer isLoading={isLoading || isRefetching}>
      <BreadCrumb
        to={"settings/language"}
        pageTitle="Language"
        title="Update"
      />
      {initValue && <LanguageForm type="Update" initValues={initValue} />}
    </PageContainer>
  );
};

export default UpdateLanguage;
