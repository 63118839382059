import { configureStore } from "@reduxjs/toolkit";
import adminReducer from "./slices/adminSlice";
import layoutReducer from "./slices/layoutSlice";
import foodReducer from "./slices/foodSlice";
import langaugeReducer from "./slices/languageSlice";
import settingsReducer from "./slices/settingsSlice";
import notificationsReducer from "./slices/notificationsSlice";

const store = configureStore({
  reducer: {
    settings: settingsReducer,
    admin: adminReducer,
    layout: layoutReducer,
    food: foodReducer,
    language: langaugeReducer,
    notifications: notificationsReducer
  },
});

export default store;
