import React from "react";
import {
  Card,
  CardBody,
  Col,
  Row,
} from "reactstrap";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import PageContainer from "../../../Components/Layout/PageContainer";
import { getPathname } from "../../../Layouts/LayoutMenuData";

const SelectLanguage = () => {

  const { t } = useTranslation();

  const { code } = useParams();

  const cards = [
    {
        title: 'Dashboard',
        icon: 'ri-dashboard-line',
        link: getPathname(`settings/language/keys/${code}/dash`)
    },
    {
        title: 'App',
        icon: 'ri-cellphone-line',
        link: getPathname(`settings/language/keys/${code}/app`)
    },
    {
        title: 'Web Page',
        icon: 'ri-article-line',
        link: getPathname(`settings/language/keys/${code}/web`)
    },
    {
        title: 'POS',
        icon: 'ri-bank-card-line',
        link: getPathname(`settings/language/keys/${code}/lang_pos`)
    },
]

  return (
    <PageContainer>
       <div className="d-flex flex-column justify-content-center align-items-center" style={{height: '60vh'}}>

            <div className="w-100 text-center mb-3">
                <h1 className="text-uppercase" style={{fontSize: '30px'}}>{t(`Translate in`)}:</h1>
            </div>

            <Row className="w-100 justify-content-center">
                {cards.map((item, index) => (
                    <Col lg={4} key={index}>
                        <Link to={item.link}>
                            <Card
                                className="cursor-pointer p-4 card-animate"
                            >
                                <CardBody className="d-flex justify-content-center align-items-center">
                                        <div className="d-flex align-items-center">
                                            <div className="avatar-md flex-shrink-0">
                                                <span className="avatar-title bg-light text-primary rounded-circle fs-2">
                                                    <i className={"align-middle " + item.icon}></i>
                                                </span>
                                            </div>
                                            <div className="flex-grow-1 ms-3">
                                                <p className="text-uppercase fw-bold text-muted mb-1" style={{fontSize: '20px'}}>{t(item.title)}</p>
                                            </div>
                                        </div>
                                </CardBody>
                            </Card>
                        </Link>
                    </Col>
                ))}
            </Row>
       </div>
    </PageContainer>
  );
};

export default SelectLanguage;
