import React from "react";
import { Breadcrumb } from "reactstrap";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import PageContainer from "../../../Components/Layout/PageContainer";
import CouponForm from "../../../Components/Orders/Coupons/CouponForm";

const CreateCoupon = () => {
  return (
    <PageContainer>
      <BreadCrumb title="Create" pageTitle="Coupons" to={"orders/coupons"} />
      <CouponForm type="Create" />
    </PageContainer>
  );
};

export default CreateCoupon;
