import { useTranslation } from "react-i18next";
import { NavLink } from "reactstrap";

const CustomNavLink = ({ children, ...rest }) => {
  
  const { t } = useTranslation();

  return (
    <NavLink {...rest}>
      {typeof children === "string" ? t(children) : children}
    </NavLink>
  );
};

export default CustomNavLink;
