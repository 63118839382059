import React from 'react'
import { useTranslation } from 'react-i18next'
import CustomInput from '../../Custom/CustomInput';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import CustomButton from '../../Custom/CustomButton';

const PasswordModal = ({ isOpen, toggle, register, onSubmit, isSubmitting }) => {

    const { t } = useTranslation();

  return (
    <Modal
        isOpen={isOpen}
        toggle={toggle}
        centered
    >
        <ModalHeader>
            {(t('Enter Password'))}
        </ModalHeader>

        <ModalBody>
            <CustomInput 
                type="password"
                {...register('password')}
            />
        </ModalBody>

        <ModalFooter>
            <CustomButton
                isLoading={isSubmitting}
                type='button'
                onClick={onSubmit}
            >
                Save
            </CustomButton>
        </ModalFooter>

    </Modal>
  )
}

export default PasswordModal