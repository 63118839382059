import React, { useEffect, useState } from 'react'
import CustomLabel from '../../Custom/CustomLabel';
import CustomInput from '../../Custom/CustomInput';
import generateUniqueID from '../../../helpers/generateUniqueID';
import CustomButton from '../../Custom/CustomButton';
import { Col, Row } from 'reactstrap';

const ReportSettingsEmails = ({ initialValues, setValue }) => {

    const [emails, setEmails] = useState(null);

    const handleChange = (id, value) => {
        setEmails(prev => prev.map(email => {
            if (email.id === id) return {
                ...email,
                email: value
            }
            return email
        }))
    }

    const addEmail = () => {
        setEmails([...(emails ?? []), {
            id: generateUniqueID(),
            email: ''
        }])
    }

    const deleteEmail = (id) => {
        setEmails(prev => prev.filter(email => email.id !== id));
    }

    useEffect(() => {
        if (initialValues?.emails && Array.isArray(initialValues?.emails)) setEmails(initialValues.emails.map(email => ({
            id: generateUniqueID(),
            email
        })));
    }, [initialValues])

    useEffect(() => {
        setValue('emails', emails?.map(email => email.email) || null);
    }, [emails])

  return (
    <div className='border p-3'>
        <div className='border-bottom p-1 pb-3'>

            <CustomLabel className='fw-bold fs-20'>
                Emails
            </CustomLabel>
            
            <CustomButton 
                onClick={addEmail} 
                className="btn-success"
            >
                Add Email
                <i className="ri-add-line"></i>
            </CustomButton>
            
        </div>

        <Row className='my-3 gy-4'>
            {emails?.map(email => (
                <Col lg={7} className='d-flex gap-2' key={email.id}>
                    <CustomInput 
                        value={email.email}
                        onChange={(e) => handleChange(email.id, e.target.value)}
                    />
                    <CustomButton
                        className="btn-danger"
                        onClick={() => deleteEmail(email.id)}
                    >
                        <i className="ri-close-line"></i>
                    </CustomButton>
                </Col>
            ))}
        </Row>

    </div>
  )
}

export default ReportSettingsEmails