import axiosInstance from "..";

export const getAllSlidersApi = (query) => {
  return axiosInstance.get(`/sliders`, {
    params: {
      lang: localStorage.getItem("I18N_LANGUAGE") || 'en',
      ...query
    }
  });
};

export const getOneSliderApi = (id, lang) => {
  return axiosInstance.get(`/sliders/${id}?lang=${lang}`);
};

export const addNewSliderApi = (body, lang) => {
  return axiosInstance.post(`/sliders?lang=${lang}`, body);
};

export const updateSliderApi = (id, body, lang) => {
  return axiosInstance.post(`/sliders/${id}?lang=${lang}`, body);
};

export const deleteSlidersApi = (ids) => {
  return axiosInstance.delete("/sliders", {
    data: {
      ids,
    },
  });
};
