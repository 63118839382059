import React from 'react'
import { Col, Row, Table } from 'reactstrap'
import CustomInput from '../../../../../Custom/CustomInput'
import CustomLabel from '../../../../../Custom/CustomLabel'
import Checkbox from "react-three-state-checkbox";
import { useTranslation } from 'react-i18next';
import SizesTable from './SizesTable';

const EntreesTable = ({categoryId, selectedCategory, data, setData}) => {

    const { t } = useTranslation();

    const handleSelectAll = () => {
        setData(prev => prev.map(category => {
            if (category.category_id === categoryId) return {
                ...category,
                entrees: category.entrees.map(entree => ({
                    ...entree,
                    selected: !category.entrees.every(entree => entree.selected),
                    default_item: category.entrees.every(entree => entree.selected) ? false : entree.default_item
                }))
            }
            return category
        }))
    }

    const handleChange = (itemId, field, value) => {
        setData(prev => prev.map(category => {
            if (category.category_id === categoryId) return {
                ...category,
                entrees: category.entrees.map(item => {
                    if (item.id === itemId) return {
                        ...item,
                        [field]: value,
                        ...(field === 'selected' ? 
                            {
                                default_item: value ? item.default_item : false,
                                ...(item.has_sizes ? {
                                    entrees_sizes: item.entrees_sizes.map(size => ({
                                        ...size,
                                        selected: value ? size.selected : false,
                                        default_size: value ? size.default_size : false
                                    }))
                                } : {})
                            }
                        : {})
                    }
        
                    return item
                })
    
            }
            return category
        }))
    }

    const handleCheckChange = (value) => {
        setData(prev => prev.map(category => {
            if (category.category_id === categoryId) return {
                ...category,
                entrees: category.entrees.map(item => ({
                    ...item,
                    default_item: item.default_item ? false : +item.id === +value
                }))
            }
            return category
        }))
    }


    if (selectedCategory !== categoryId) return null

    return (

        <Row className="gy-1">

            <Col lg={12}>

                <Table
                    striped
                    bordered
                    responsive
                    variant="dark"
                    className="mt-3 components-table"
                >
                    <thead>
                        <tr>
                            <th>
                                <Checkbox
                                    className="form-check-input"
                                    checked={data?.every((item) => item.selected)}
                                    indeterminate={data.some((item) => item.selected) && !data.every((item) => item.selected) }
                                    onChange={handleSelectAll}
                                />
                            </th>

                            <th>{t("Name")}</th>

                            <th style={{width: '5%'}}>
                                {t("Default")}
                            </th>

                            <th>{t("Price")}</th>

                        </tr>
                    </thead>

                    <tbody>
                        {data?.map((item) => (
                            
                            <tr key={item.id}>

                                <td>
                                    <input
                                        type="checkbox"
                                        id={`entry-${item.id}`}
                                        className="form-check-input"
                                        checked={item.selected}
                                        onChange={e => handleChange(item.id, 'selected', e.target.checked)}
                                    />
                                </td>

                                <td>
                                    <CustomLabel className="form-check-label" htmlFor={`entry-${item.id}`}>{item.name}</CustomLabel>
                                </td>

                                <td>
                                    <div className="form-check form-switch">
                                        <input 
                                            className="form-check-input fs-16" 
                                            type="checkbox" 
                                            role="switch" 
                                            id="takeaway" 
                                            checked={item.selected && item.default_item}
                                            onChange={() => {
                                                if (item.selected) handleCheckChange(item.id)
                                            }}
                                            disabled={!item.selected}
                                        />
                                    </div>
                                </td>
                                
                                <td>
                                    
                                    {item.has_sizes ? 
                                    
                                        <SizesTable 
                                            item={item}
                                            categoryId={categoryId}
                                            setData={setData}
                                        />
                                    
                                        : 

                                        <CustomInput
                                            value={item.price || ''}
                                            onChange={e => handleChange(item.id, 'price', e.target.value)}
                                            disabled={!item.selected}
                                        />
                                    }
                                </td>
                            </tr>
                        ))}
                    </tbody>

                </Table>

            </Col>
        </Row>  
    )
}

export default EntreesTable