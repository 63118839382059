import React from 'react'
import { useSelector } from 'react-redux';

const Restricted = ({ allowed, children, render = <></> }) => {

    const { user } = useSelector(state => state.admin);

    const isAllowed = allowed?.length ? allowed?.includes(user?.role[0]) : true;

    if (isAllowed) {
        return children
    }

    return render
}

export default Restricted