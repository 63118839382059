import { useQuery } from "@tanstack/react-query";
import React from "react";
import { useParams } from "react-router-dom";
import { getOneGroupApi } from "../../../apis/users/groupsApis";
import { getOneUserApi } from "../../../apis/users/usersApi";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import PageContainer from "../../../Components/Layout/PageContainer";
import GroupsForm from "../../../Components/Users/Groups/Form";
import UserForm from "../../../Components/Users/Users/Form";

const UpdateGroup = () => {
  const { id } = useParams();

  const { data, isLoading } = useQuery(["groups", id], () =>
    getOneGroupApi(id)
  );

  return (
    <PageContainer isLoading={isLoading}>
      <BreadCrumb pageTitle="Groups" title="Update" />
      {data && <GroupsForm type="Update" initValue={data.data.data} />}
    </PageContainer>
  );
};

export default UpdateGroup;
