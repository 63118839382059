import React from "react";
import { useSelector } from "react-redux";
import { Card } from "reactstrap";
import { isAllowed } from "../../../helpers/isAllowed";
import DataTableContainer from "../../Common/DataTableContainer";
import CustomTableHeading from "../../Custom/CustomTableHeading";
import TableImage from "../../Common/TableImage";
import { getPathname } from "../../../Layouts/LayoutMenuData";
import CustomInput from "../../Custom/CustomInput";
import CustomOption from "../../Custom/CustomOption";
import { useGetProductCategories } from "../../../hooks/useQueryHelpers";
import StatusToggle from "../../Common/StatusToggle";

const ProductsTable = ({
  data,
  selectItems,
  modal,
  setModal,
  onDeleteClick,
  isDeleting,
  navigateToPage,
  setPageSize,
  isLoading,
  name,
  setName,
  code,
  setCode,
  filters,
  setFilters
}) => {

  const { user } = useSelector(state => state.admin);

  const { categories: { list: categories } } = useGetProductCategories({ page_size: 'all' })

  const handleFilter = (field, value) => {
    setFilters({
      productsFilter: {
        [field]: value
      }
    })
  }

  const columns = [
    {
      name: <CustomTableHeading>ID</CustomTableHeading>,
      selector: (row) => row.id,
    },
    {
      selector: row => <TableImage src={row.image} />,
    },
    {
      name: 
      <CustomInput 
        placeholder="Name"
        value={name ?? ''}
        onChange={(e) => setName(e.target.value)}
        data-filtered={!!name}
      />,
      selector: (row) => row.name ?? '-',
    },
    {
      name: 
      <CustomInput 
        placeholder="Code"
        value={code ?? ''}
        onChange={(e) => setCode(e.target.value)}
        data-filtered={!!code}
      />,
      selector: (row) => row.code ?? '-',
    },
    {
      name: <CustomTableHeading>Unit</CustomTableHeading>,
      selector: (row) => row.unit?.name ?? '-',
    },
    {
      name: (
        <select
          className="form-select"
          value={filters.category_id}
          onChange={(e) => handleFilter('category_id', e.target.value)}
          data-filtered={!!filters.category_id}
        >
          <CustomOption value="">Category</CustomOption>
          {categories.map(category => (
            <option key={category.id} value={category.id}>{category.name}</option>
          ))}
        </select>
      ),
      selector: (row) => row.category?.name ?? '-',
    },
    {
      name: (
        <select
          className="form-select"
          value={filters.daily}
          onChange={(e) => handleFilter('daily', e.target.value)}
          data-filtered={!!filters.daily}
        >
          <CustomOption value="">Daily</CustomOption>
          {[{ label: 'Is Daily', value: 1 }, { label: 'Not Daily', value: 0 }].map((daily, index) => (
            <CustomOption key={index} value={daily.value}>{daily.label}</CustomOption>
          ))}
        </select>
      ),
      selector: (row) => <StatusToggle type="products" id={row.id} field='daily' status={row.daily} />,
    },
    {
      name: <CustomTableHeading>Total Quantity</CustomTableHeading>,
      selector: (row) => row.total_qty ?? '-',
    },
  ];

  return (
    <Card>
      <DataTableContainer
        selectableRows={isAllowed(user)}
        data={data.list ?? []}
        paginationTotalRows={data.total}
        isDeleting={isDeleting}
        handleDelete={onDeleteClick}
        modal={modal}
        setPageSize={setPageSize}
        paginationServer
        loading={isLoading}
        setModal={setModal}
        selectItems={selectItems}
        navigateToPage={navigateToPage}
        columns={columns}
        path={getPathname("inventory/products")}
      />
    </Card>
  );
};

export default ProductsTable;
