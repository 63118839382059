import React, { useEffect, useState } from 'react'
import toastAlert from '../../helpers/toastAlert';
import { toggleStatus } from '../../apis/general';

const StatusToggle = ({ type, id, field, status }) => {

  const [checked, setChecked] = useState(null);

  const toggleFunc = async (e) => {
    const status = e.target.checked;
    try {
      const requestBody = {
        type,
        id,
        [field]: status
      }
      await toggleStatus(requestBody);
      setChecked(status);
    } catch (error) {
      toastAlert({ type: 'error' });
    }
  }

  useEffect(() => {
    if (status !== null || status !== undefined) {
      setChecked(status);
    }
  }, [status])

  return (
    <div className='status-icon'>
      {checked !== null && 
          <div className="form-check form-switch">
            <input 
              className="form-check-input fs-16" 
              type="checkbox" 
              role="switch" 
              checked={checked} 
              onChange={toggleFunc} 
            />
          </div>
      }
    </div>
  )
}

export default StatusToggle