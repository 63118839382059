import React, { useEffect, useState } from 'react'
import { foodFormTabs } from '../../../../../../data/foodFormTabs';
import SizesList from './List';
import { Col, Row } from 'reactstrap';
import CustomLabel from '../../../../../Custom/CustomLabel';

const Sizes = ({initialValues, foodType, register, setValue, watch}) => {

  const [data, setData] = useState(null);

  const hasSizes = watch('has_sizes');

  useEffect(() => {
    if (!foodFormTabs.find(item => item.id === foodType)?.tabs?.some(tab => tab.value === 'sizes')) {
        setValue('has_sizes', null);
        setValue('sizes', null);
    } 
  }, [foodType])

  useEffect(() => {
    if (initialValues) {
        setData(initialValues?.entrees_sizes);
    }
  }, [initialValues])

  useEffect(() => {
    if (data && hasSizes) {
      setValue('entrees_sizes', data.map(item => {
        const { isNew, id, size, price, price_in } = item;
        return {
          ...(!isNew ? { id } : {}),
          size,
          price,
          price_in,
        }
      }));
    } else {
        setValue('entrees_sizes', null);
    }
  }, [data, hasSizes])

  return (
    <div className='d-flex flex-column gap-1'>
      <Row>
        <Col lg={6}>
            <div className="form-check form-switch">
                <input {...register('has_sizes')} className="form-check-input fs-16" type="checkbox" role="switch" id="has_sizes" />
                <CustomLabel className="form-check-label" htmlFor="has_sizes">Has Sizes</CustomLabel>
            </div>
        </Col>
      </Row>
      {!!hasSizes && 
        <SizesList 
          data={data}
          setData={setData}
        />
      }
    </div>
  )
}

export default Sizes