import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Card, Col, Row } from 'reactstrap'
import { formatExample } from '../../../helpers/formatExample'
import useActions from '../../../hooks/useActions'
import { getPathname } from '../../../Layouts/LayoutMenuData'
import { createFoodAction, updateFoodAction } from '../../../redux/actions/foodActions'
import SelectFile from '../../Common/SelectFile'
import CustomButton from '../../Custom/CustomButton'
import CustomInput from '../../Custom/CustomInput'
import CustomLabel from '../../Custom/CustomLabel'
import SelectLanguage from '../../Common/SelectLanguage'

const AllergyForm = ({type, initialValues = null, language, setLanguage}) => {

    const {register, handleSubmit, setValue, reset} = useForm();
    
    const [icon, setIcon] = useState({
        url: '',
        name: '',
        file: null
    })
    
    const {isLoading, onLoading, onFinally, onError} = useActions();

    const dispatch = useDispatch();

    const history = useHistory();

    const submitForm = (data) => {
        onLoading();
        const formData = new FormData();
        Object.keys(data).forEach(key => {
            formData.append(key, data[key]);
        })
        const file = icon.file
        if (file) {
            const blob = new Blob([file], {type: file.type})
            formData.append('icon', blob, file.name);
        }
        if (type === 'create') {
            dispatch(createFoodAction({
                field: 'allergy',
                data: formData,
                language
            }, {
            onSuccess() {
                onFinally();
                history.push(getPathname('food/allergy'))
            },
            onError
            }))
        } else {
            dispatch(updateFoodAction({
                field: 'allergy',
                id: initialValues.id,
                data: formData,
                language
            }, {
                onSuccess() {
                    onFinally();
                    setIcon({
                        name: icon.name,
                        url: icon.url,
                        file: null
                    })
                },
                onError
            }))
        }
        
    }

    useEffect(() => {
        if (initialValues) {
            reset(initialValues)
            if (initialValues?.icon) {
                setIcon({
                    id: initialValues.file?.id,
                    url: initialValues.icon,
                    file: null,
                    name: initialValues.file?.name
                })
            }
        }
    }, [initialValues])

  return (

    <form onSubmit={handleSubmit(submitForm)}>
        <Row>
            <Col lg={3}>
                <Card className='p-3'>
                    <CustomLabel>
                        Icon
                    </CustomLabel>
                    <SelectFile
                        type='image'
                        selectedFiles={icon}
                        setSelectedFiles={setIcon}
                        initialValues={initialValues}
                    />
                </Card>
            </Col>

            <Col lg={9}>

                <Card className='p-3'>

                    {type === 'update' && 
                        <div className='d-flex justify-content-end'>
                            <SelectLanguage
                            language={language}
                            onChange={lang => setLanguage(lang)}
                            /> 
                        </div>
                    }

                    <Row className='gy-4'>

                        <Col md={6}>
                            <CustomLabel>
                                Code
                            </CustomLabel>
                            <CustomInput 
                                type="text"
                                {...register('code')}
                                placeholder={formatExample("G")}                       
                            />
                        </Col>

                        <Col md={6}>
                            <CustomLabel translatable>
                                Name
                            </CustomLabel>
                            <CustomInput 
                                type="text"
                                {...register('name')}
                                placeholder={formatExample("Gluten")}
                            />
                        </Col>
                    </Row>

                    <Row className='mt-3'>
                        <Col>
                            <CustomButton
                                className="btn-success"
                                isLoading={isLoading}
                            >
                                {type === 'create' ? 'Create' : 'Update'}
                            </CustomButton>
                        </Col>
                    </Row>
                </Card>

            </Col>
        </Row>
    </form>
  )
}

export default AllergyForm