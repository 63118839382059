import React from "react";
import { useTranslation } from "react-i18next";

const OrderPriceItem = ({ prop, value, className = "" }) => {
  const { t } = useTranslation();

  return value && value !== "0" ? (
    <tr className={`d-flex justify-content-between px-2 py-3 ${className}`}>
      <td>{t(prop)}</td>
      <td className="text-end">{value}</td>
    </tr>
  ) : (
    <></>
  );
};

export default OrderPriceItem;
