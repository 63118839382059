import axiosInstance from "..";

export const getAllRooms = (params) => {
  return axiosInstance.get("/rooms", {
    params
  });
};

export const getOneRoom = (id) => {
  return axiosInstance.get(`/rooms/${id}`);
};

export const createRoom = (data) => {
  return axiosInstance.post(`/rooms`, data);
};

export const updateRoom = (id, data) => {
    return axiosInstance.post(`/rooms/${id}`, data);
};

export const deleteRoom = (data) => {
    return axiosInstance.delete(`/rooms`, {
        data
    });
};

