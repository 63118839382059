import React from "react";
import { useTranslation } from "react-i18next";
import { CardTitle } from "reactstrap";

const CustomCardTitle = ({ children, ...rest }) => {
  
  const {t} = useTranslation();

  return <CardTitle {...rest}>{typeof children === 'string' ? t(children) : children}</CardTitle>;
};

export default CustomCardTitle;
