import { useEffect, useState } from 'react'
import React from 'react'
import { Col, Label, Row } from 'reactstrap'
import CustomInput from '../../../Custom/CustomInput'
import CustomLabel from '../../../Custom/CustomLabel'
import { toBase64 } from '../../../../helpers/toBase64'
import { t } from 'i18next'
import VippsWebhook from './VippsWebhook'

const Vipps = ({data, setData}) => {

    const [image, setImage] = useState({
        url: data?.logo,
        file: null
    })

    const handleChange = (e) => {
      const field = e.target.name;
      const value = e.target.value;
      setData(prev => prev.map(item => {
        if (item.id === data.id) return {
          ...item,
          [field]: value
        }
        return item
      }))
  }
  
  const handleCheckChange = (e) => {
      const field = e.target.name;
      const value = e.target.checked;
      setData(prev => prev.map(item => {
          if (item.id === data.id) return {
          ...item,
          [field]: value
          }
          return item
      }))
  }

  const handleFileChange = async () => {
    const url = await toBase64(image.file);
    setData(prev => prev.map(item => {
        if (item.id === data.id) return {
        ...item,
        logo: url
        }
        return item
    }))
  }   

  useEffect(() => {
      if (image.file) {
          handleFileChange();
      }
  }, [image])

  return (
    <React.Fragment>

          <div className="hstack gap-3">
                <div className="form-check form-switch">
                    <input name="status" checked={data.status} onChange={handleCheckChange} className="form-check-input fs-16" type="checkbox" role="switch" id="active-vipps" />
                    <CustomLabel className="form-check-label" htmlFor="active-vipps">Active</CustomLabel>
                </div>

                <div className="form-check form-switch">
                    <input name="status_test_mode" checked={data.status_test_mode} onChange={handleCheckChange} className="form-check-input fs-16" type="checkbox" role="switch" id="active-vipps-test-mode" />
                    <CustomLabel className="form-check-label" htmlFor="active-vipps-test-mode">Test Mode</CustomLabel>
                </div>

                <div className="form-check form-switch">
                    <input name="guest" checked={data.guest} onChange={handleCheckChange} className="form-check-input fs-16" type="checkbox" role="switch" id="vipps-guest" />
                    <CustomLabel className="form-check-label" htmlFor="vipps-guest">Guest</CustomLabel>
                </div>

                <div className="form-check form-switch">
                    <input name="shop" checked={data.shop} onChange={handleCheckChange} className="form-check-input fs-16" type="checkbox" role="switch" id="vipps-shop" />
                    <CustomLabel className="form-check-label" htmlFor="vipps-shop">Shop</CustomLabel>
                </div>

                <div className="form-check form-switch">
                    <input name="pos" checked={data.pos} onChange={handleCheckChange} className="form-check-input fs-16" type="checkbox" role="switch" id="vipps-pos" />
                    <CustomLabel className="form-check-label" htmlFor="vipps-pos">POS</CustomLabel>
                </div>

                <div className="form-check form-switch">
                    <input name="cs" checked={data.cs} onChange={handleCheckChange} className="form-check-input fs-16" type="checkbox" role="switch" id="vipps-cs" />
                    <CustomLabel className="form-check-label" htmlFor="vipps-cs">Call Center</CustomLabel>
                </div>

          </div>

            <div>
              
                <CustomLabel className="fs-20 my-3 border-bottom">Vipps</CustomLabel>

                <Row className='gy-3'>

                    <Col lg={6}>
                      <CustomLabel>Client ID</CustomLabel>
                      <CustomInput 
                          type="text"
                          name="client_id"
                          value={data.client_id}
                          onChange={handleChange}
                      />
                    </Col>

                    <Col lg={6}>
                      <CustomLabel>Client Secret</CustomLabel>
                      <CustomInput 
                          type="text"
                          name="client_secret"
                          value={data.client_secret}
                          onChange={handleChange}
                      />
                    </Col>

                    <Col lg={6}>
                      <CustomLabel>Merchant Number</CustomLabel>
                      <CustomInput 
                          type="text"
                          name="merchant_code"
                          value={data.merchant_code}
                          onChange={handleChange}
                      />
                    </Col>

                    <Col lg={6}>
                      <Label>{t('Subscription Key')} ({t('Primary')}) </Label>
                      <CustomInput 
                          type="text"
                          name="subscription_key_primary"
                          value={data.subscription_key_primary}
                          onChange={handleChange}
                      />
                    </Col>

                    <Col lg={6}>
                      <Label>{t('Subscription Key')} ({t('Secondary')}) </Label>
                      <CustomInput 
                          type="text"
                          name="subscription_key_secondary"
                          value={data.subscription_key_secondary}
                          onChange={handleChange}
                      />
                    </Col>

                </Row>

            </div>

            <div>
                <CustomLabel className="fs-20 my-3 border-bottom">Test Mode</CustomLabel>

                <Row className='gy-3'>
                    <Col lg={6}>
                      <CustomLabel>Client ID</CustomLabel>
                      <CustomInput 
                          type="text"
                          name="client_id_test"
                          value={data.client_id_test}
                          onChange={handleChange}
                      />
                    </Col>

                    <Col lg={6}>
                      <CustomLabel>Client Secret</CustomLabel>
                      <CustomInput 
                          type="text"
                          name="client_secret_test"
                          value={data.client_secret_test}
                          onChange={handleChange}
                      />
                    </Col>

                    <Col lg={6}>
                      <CustomLabel>Merchant Number</CustomLabel>
                      <CustomInput 
                          type="text"
                          name="merchant_code_test"
                          value={data.merchant_code_test}
                          onChange={handleChange}
                      />
                    </Col>

                    <Col lg={6}>
                      <Label>{t('Subscription Key')} ({t('Primary')}) </Label>
                      <CustomInput 
                          type="text"
                          name="subscription_key_primary_test"
                          value={data.subscription_key_primary_test}
                          onChange={handleChange}
                      />
                    </Col>

                    <Col lg={6}>
                      <Label>{t('Subscription Key')} ({t('Secondary')}) </Label>
                      <CustomInput 
                          type="text"
                          name="subscription_key_secondary_test"
                          value={data.subscription_key_secondary_test}
                          onChange={handleChange}
                      />
                    </Col>
                </Row>

            </div>

            <div>
                <CustomLabel className="fs-20 my-3 border-bottom">Webhook</CustomLabel>
                <VippsWebhook />
            </div>

    </React.Fragment>

  )
}

export default Vipps