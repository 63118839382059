import { useQuery } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Card, CardBody, CardFooter, Col, Row } from "reactstrap";
import {
  getDeliverySettingsApi,
  setDeliverySettingsApi,
} from "../../apis/Orders/deliverySettingsApis";
import CustomButton from "../../Components/Custom/CustomButton";
import CustomInput from "../../Components/Custom/CustomInput";
import CustomLabel from "../../Components/Custom/CustomLabel";
import CustomTextArea from "../../Components/Custom/CustomTextArea";
import PageContainer from "../../Components/Layout/PageContainer";
import toastAlert from "../../helpers/toastAlert";
import useActions from "../../hooks/useActions";
import DeliveryZones from "../../Components/Orders/DeliverySettings/Zones/Zones";
import { useFetchByLang } from "../../hooks/useFetchByLang";
import SelectLanguage from "../../Components/Common/SelectLanguage";
import ClientDeliveryInfo from "../../Components/Orders/DeliverySettings/ClientDeliveryInfo";

const DeliverySettings = () => {

  const { isLoading: isSubmitting, onLoading, onFinally } = useActions();

  const [initialValues, setInitialValues] = useState(null);

  const { register, reset, watch, handleSubmit } = useForm({
    defaultValues: {
      min_order_keywords: [],
    },
  });

  const { refetch, isLoading, isRefetching } = useQuery(["order-delivery"], () => getDeliverySettingsApi(language), {
    enabled: false,
    cacheTime: 0,
    onSuccess({data: { data }}) {
      const { updated_at, ...rest } = data;
      setInitialValues(rest);
    },
  });

  const { language, setLanguage } = useFetchByLang(refetch);

  const [shopLocation, setShopLocation] = useState(null);
  const [mapsApiKey, setMapsApiKey] = useState(null);

  const keywords = watch("min_order_keywords");

  const onSubmit = async (data) => {
    onLoading();
    try {
      const { data: { data: resData } } = await setDeliverySettingsApi(data, language);
      setInitialValues(resData);
      toastAlert({ type: 'success' });
    } catch (error) {
      toastAlert({ type: 'error' });
    } finally {
      onFinally();
    }
  };

  useEffect(() => {
    if (initialValues) {
      reset(initialValues);
      if (initialValues.shop_location) {
          const coords = initialValues.shop_location.split(',');
          if (coords?.length === 2) setShopLocation({ lat: +coords[0], lng: +coords[1] })
      }

      if (initialValues.google_api_key) {
        setMapsApiKey(initialValues.google_api_key)
      }
    }
  }, [initialValues])

  return (
    <PageContainer isLoading={isLoading || isRefetching || initialValues === null}>
      <Card>
        <CardBody>
          <div className='d-flex justify-content-end'>
              <SelectLanguage
                language={language}
                onChange={lang => setLanguage(lang)}
              /> 
          </div>
          <Row className="gy-2">
            <Col md={4}>
              <div className="form-check form-switch">
                <input
                  name="active"
                  className="form-check-input fs-16"
                  type="checkbox"
                  role="switch"
                  {...register("takeaway")}
                />
                <CustomLabel>Open takeaway</CustomLabel>
              </div>
            </Col>
            <Col md={4}>
              <div className="form-check form-switch">
                <input
                  name="active"
                  className="form-check-input fs-16"
                  type="checkbox"
                  role="switch"
                  {...register("delivery")}
                />
                <CustomLabel>Open delivery</CustomLabel>
              </div>
            </Col>
            <Col md={4}>
              <div className="form-check form-switch">
                <input
                  name="active"
                  className="form-check-input fs-16"
                  type="checkbox"
                  role="switch"
                  {...register("user_phone_confirmation")}
                />
                <CustomLabel>Verification Phone</CustomLabel>
              </div>
            </Col>

            <Col md={4}>
              <div className="form-check form-switch">
                <input
                  name="active"
                  className="form-check-input fs-16"
                  type="checkbox"
                  role="switch"
                  {...register("choose_delivery")}
                />
                <CustomLabel>Choose delivery man</CustomLabel>
              </div>
            </Col>

            <Col xs={12}>
              <CustomLabel translatable>Close alert</CustomLabel>
              <CustomTextArea rows={3} {...register("close_alert")} />
            </Col>
            {/* <PageTitle>Distance settings</PageTitle> */}
            <Col md={6}>
              <CustomLabel>Google maps API key</CustomLabel>
              <CustomInput {...register("google_api_key")} />
            </Col>
            <Col md={6}>
              <CustomLabel>Shop location</CustomLabel>
              <CustomInput {...register("shop_location")} />
            </Col>
            <Col xs={12}>
              <CustomLabel>Max distance alert</CustomLabel>
              <CustomTextArea rows={3} {...register("max_distance_alert")} />
            </Col>

            <Col xs={12}>
              <CustomLabel translatable>Alert text</CustomLabel>
              <CustomTextArea rows={3} {...register("min_order_alert")} />
              <span>
                {(keywords || []).map((word) => `{${word}}`).join(",")}
              </span>
            </Col>
          </Row>

          <ClientDeliveryInfo 
            register={register}
            className="mt-3 g-3"
          />

        </CardBody>
        <CardFooter>
          <CustomButton
            isLoading={isSubmitting}
            onClick={handleSubmit(onSubmit)}
            color="success"
          >
            Save
          </CustomButton>
        </CardFooter>
      </Card>
      <DeliveryZones 
        shopLocation={shopLocation}
        mapsApiKey={mapsApiKey}
      />
    </PageContainer>
  );
};

export default DeliverySettings;
