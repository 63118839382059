import React from "react";
import PageContainer from "../../../Components/Layout/PageContainer";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import PrinterForm from "../../../Components/Food/Printers/Form";
import { useFetchByLang } from "../../../hooks/useFetchByLang";

const CreatePrinter = () => {

  const { language, setLanguage } = useFetchByLang();

  return (
    <PageContainer>
      <BreadCrumb title="Create" pageTitle="Printers" to={"food/printers"} />
      <PrinterForm 
         type="create" 
         language={language}
         setLanguage={setLanguage}
      />
    </PageContainer>
  );
};

export default CreatePrinter;
