import {
  addNewLanguageApi,
  deleteLangaugeApi,
  updateLanguageApi,
} from "../../apis/Settings/languageApi";
import extractError from "../../helpers/extractError";
import { languageActions } from "../slices/languageSlice";

export const addLanguageAction = (body, actions) => async (dispatch) => {
  try {
    const { data } = await addNewLanguageApi(body);
    dispatch(languageActions.addNewLang(data.data));
    actions.onSuccess();
  } catch (errors) {
    actions.onError(extractError(errors));
  }
};

export const updateLanguageAction =
  (code, body, actions) => async (dispatch) => {
    try {
      const { data } = await updateLanguageApi(code, body);
      dispatch(languageActions.updateLang(data.data));
      actions.onSuccess(data.data);
    } catch (errors) {
      actions.onError(extractError(errors));
    }
  };

export const deleteLanguageAction = (code, actions) => async (dispatch) => {
  try {
    await deleteLangaugeApi(code);
    dispatch(languageActions.deleteLang(code));
    actions.onSuccess();
  } catch (errors) {
    actions.onError(extractError(errors));
  }
};
