import React from 'react'

const TableImage = ({src}) => {

  return (
    <div 
      style={{
        width: '100px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        // background: 'red'
      }}
      data-tag="allowRowEvents"
    >
      {
        src ? <img data-tag="allowRowEvents" src={src} alt={src} style={{height: '30px', borderRadius: '.4rem'}} /> : '-'
      }
    </div>
  )
}

export default TableImage