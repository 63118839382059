import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { formatParagraph } from '../../../helpers/formatParagraph'
import { isAllowed } from '../../../helpers/isAllowed'
import { getPathname } from '../../../Layouts/LayoutMenuData'
import { getFoodDataAction } from '../../../redux/actions/foodActions'
import DataTableContainer from '../../Common/DataTableContainer'
import StatusToggle from '../../Common/StatusToggle'
import TableImage from '../../Common/TableImage'
import CustomInput from '../../Custom/CustomInput'
import CustomOption from '../../Custom/CustomOption'
import CustomTableHeading from '../../Custom/CustomTableHeading'
import { useBranches } from '../../../hooks/useQueryHelpers'

const FoodMenuTable = ({navigateToPage, pageSize, setPageSize, setSelectedItems, modal, setModal, isLoading, handleDelete, loading, filters, setFilters, name, setName, number, setNumber}) => {

    const { menu } = useSelector(state => state.food);

    const { user } = useSelector(state => state.admin);

    const { foodTypes: { list: foodTypes }, categories: { list: categories }} = useSelector(state => state.food);

    const { branches } = useBranches();

    const dispatch = useDispatch();

    const handleFilter = (field, value) => {
        setFilters({
            foodsFilter: {
                [field]: value
            }
        })
    }

    const columns = [

        {
            name: <CustomTableHeading>ID</CustomTableHeading>,
            selector: row => row.id || '-',
            id: 'food-id-column',
        },

        {
            selector: row => <TableImage src={row.image} />,
            id: 'food-image-column'
        },

        {
            name: 
            <CustomInput
                type="text"
                className="form-control"
                placeholder="Name"
                value={name || ''}
                onChange={(e) => setName(e.target.value)}
                data-filtered={!!name}
            />,
            selector: row => <span title={row.name} data-tag="allowRowEvents" >{row.name}</span> || '-',
        },

        {
            name:      
            <CustomInput
                className="form-control"
                type="number"
                placeholder="Number"
                value={number || ''}
                onChange={(e) => setNumber(e.target.value)}
                data-filtered={!!number}
            />,
            selector: row => row.number || '-',
        },
        
        {
            name:      
            <select
                className='form-select'
                value={filters.type_id}
                onChange={(e) => handleFilter('type_id', e.target.value)}
                data-filtered={!!filters.type_id}
            >
                <CustomOption value=''>Type</CustomOption>
                {foodTypes.map(type => (
                    <CustomOption key={type.id} value={type.id}>{type.name}</CustomOption>
                ))}
            </select>,
            selector: row => row.type || '-',
        },
        
        {
            name:
                <select
                    className='form-select'
                    value={filters.category_id}
                    onChange={(e) => handleFilter('category_id', e.target.value)}
                    data-filtered={!!filters.category_id}
                >
                <CustomOption value=''>Category</CustomOption>
                {categories.map(category => (
                    <CustomOption key={category.id} value={category.id}>{category.name}</CustomOption>
                ))}
            </select>,
            selector: row => row.categories?.length ? <span data-tag="allowRowEvents" title={formatParagraph(row.categories?.map(cat => cat?.name))}>{formatParagraph(row.categories?.map(cat => cat?.name))}</span>  : '-',
        },

        {
            name:
                <select
                    className='form-select'
                    value={filters.branche_id}
                    onChange={(e) => handleFilter('branche_id', e.target.value)}
                    data-filtered={!!filters.branche_id}
                >
                <CustomOption value=''>Branch</CustomOption>
                {branches.map(branch => (
                    <CustomOption key={branch.id} value={branch.id}>{branch.name}</CustomOption>
                ))}
            </select>,
            selector: row => row.branches?.length ? <span data-tag="allowRowEvents" title={formatParagraph(row.branches?.map(branch => branch?.name))}>{formatParagraph(row.branches?.map(branch => branch?.name))}</span>  : '-',
        },

        {
            name: 
            <select
                className='form-select'
                value={filters.active}
                onChange={(e) => handleFilter('active', e.target.value)}
                data-filtered={!!filters.active}
            >
                <CustomOption value=''>Active</CustomOption>
                <CustomOption value='1'>Yes</CustomOption>
                <CustomOption value='0'>No</CustomOption>
            </select>,
            selector: row => <StatusToggle type='foods' id={row.id} field="active" status={row.active} />,
        },

        {
            name:          
            <select
                className='form-select'
                value={filters.takeaway}
                onChange={(e) => handleFilter('takeaway', e.target.value)}
                data-filtered={!!filters.takeaway}
            >
                <CustomOption value=''>Takeaway</CustomOption>
                <CustomOption value='1'>Yes</CustomOption>
                <CustomOption value='0'>No</CustomOption>
            </select>,
            selector: row => <StatusToggle type='foods' id={row.id} field="takeaway" status={row.takeaway} />
        },

        {
            name:            
            <select
            className='form-select'
            value={filters.restaurent}
            onChange={(e) => handleFilter('restaurent', e.target.value)}
            data-filtered={!!filters.restaurent}
        >
            <CustomOption value=''>Restaurant</CustomOption>
            <CustomOption value='1'>Yes</CustomOption>
            <CustomOption value='0'>No</CustomOption>
        </select>,
            selector: row => <StatusToggle type='foods' id={row.id} field="restaurent" status={row.restaurent} />, 
        },

    ]

    useEffect(() => {
        dispatch(getFoodDataAction({
            field: 'foodTypes',
            params: {
                page_size: 'all'
            }
        }))
        dispatch(getFoodDataAction({
            field: 'categories',
            params: {
                page_size: 'all'
            }
        }))
    }, [dispatch])

  return (
    <DataTableContainer 
        data={menu.list}
        columns={columns}
        paginationServer
        navigateToPage={navigateToPage}
        paginationTotalRows={menu.total && menu.total}
        path={getPathname('food/menu')}
        pageSize={pageSize}
        setPageSize={setPageSize}
        selectableRows={isAllowed(user)}
        selectItems={setSelectedItems}
        modal={modal}
        setModal={setModal}
        isDeleting={isLoading}
        loading={loading}
        handleDelete={handleDelete}
    />
  )
}

export default FoodMenuTable