import { useQuery } from '@tanstack/react-query'
import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import BreadCrumb from '../../../Components/Common/BreadCrumb'
import PageContainer from '../../../Components/Layout/PageContainer'
import TableServiceForm from '../../../Components/Orders/TableServices/Form'
import { getOneTableService } from '../../../apis/Orders/tableServicesApi'

const UpdateTableService = () => {

  const {id} = useParams();

  const [initialValues, setInitialValues] = useState(null);

  const { isLoading, isRefetching } = useQuery(['one-table-service'], () => getOneTableService(id), {
    onSuccess({data: { data }}) {
      setInitialValues(data);
    }
  })

  return (
    <PageContainer isLoading={isLoading || isRefetching}>
        <BreadCrumb pageTitle="Table Services" title="Update" to="orders/table-services"/>
        {initialValues && 
          <TableServiceForm
            type="update"
            initialValues={initialValues}
          />
        } 
    </PageContainer>
  )
}

export default UpdateTableService