import { objectFilter } from "../../objectFilter"

export const menuPageRqBody = (page) => {
    return objectFilter({
        page_id: page.id,
        name: page.name,
        type: page.type,
        link: page.link,
        sliders: page.sliders,
        note: page.note,
        show_allergy: page.show_allergy,
        price_type: page.price_type,
        categories: page.items.map((category, index) => ({
            category_id: category.id,
            subtitle: category.subtitle,
            description: category.description,
            sort: index + 1,
            layout_type: category.layout_type,
            show_comp: category.show_comp,
            show_allergy: category.show_allergy,
            breads: category.breads.filter(bread => bread.sizes.some(size => size.active)).map(bread => ({
                bread_id: bread.id,
                sizes: bread.sizes.filter(size => size.active).map(size => size.id)
            })),
            foods: category.children.map((foodItem, index) => ({
                food_id: foodItem.id,
                subtitle: foodItem.subtitle,
                sort: index + 1,
                description: foodItem.description,
                price: foodItem.price,
                show: foodItem.show,
            }))
        }))
    })
}