import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import OfferFoodItem from './FoodItem';
import generateUniqueID from '../../../../../helpers/generateUniqueID';
import { foodApi } from '../../../../../apis/Food/api';
import { useTranslation } from 'react-i18next';

const FoodSelection = ({ offerType, field, setValue, foods, initialValues, limitBuy }) => {

    const [selectedFoods, setSelectedFoods] = useState([]);

    const [fetchingFood, setFetchingFood] = useState(false);

    const { t } = useTranslation();

    const addFood = async (foodId) => {
        if (selectedFoods.length >= limitBuy) return
        try {
            setFetchingFood(true);
            const { data: { data: food }} = await foodApi.menu.getOne(foodId);
            if (food) {
                const { id, entrees_sizes, prices } = food;
                setSelectedFoods(prev => [...prev, {
                    id: generateUniqueID(),
                    food_id: id,
                    name: food.name,
                    qty: '',
                    bread_id: '',
                    size_id: '',
                    entree_size_id: '',
                    prices: prices,
                    sizes: entrees_sizes,
                }])
            }
        } catch (error) {
            console.log('error', error);
        } finally {
            setFetchingFood(false);
        }
    }

    const removeFood = (id) => {
        setSelectedFoods(prev => prev.filter(food => food.id !== id));
    }

    const handleChange = (id, field, value) => {
        setSelectedFoods(prev => prev.map(food => {
            if (id === food.id) {
                return {
                    ...food,
                    [field]: +value,
                    ...(field === 'bread_id' ? { size_id: ''} : {})
                }
            }
            return food
        }))
    }

    useEffect(() => {
        setValue(field, selectedFoods.map((food) => {
            const { id, sizes, prices, name, ...rest } = food;
            return {
                ...rest
            }
        }))
    }, [selectedFoods])

    useEffect(() => {
        const dbFoods = initialValues?.[field];
        if (dbFoods) {
            setSelectedFoods(dbFoods.map(food => ({
                id: food.id,
                food_id: food.food_id,
                name: food.food.name,
                qty: food.qty,
                bread_id: food.bread_id,
                size_id: food.size_id,
                entree_size_id: food.entree_size_id,
                prices: food.food.prices,
                sizes: food.food.entrees_sizes,
            })))
        }
    }, [initialValues])

    useEffect(() => {
        if (offerType && selectedFoods.length > limitBuy) {
            setSelectedFoods(prev => prev.slice(0, limitBuy))
        }  
    }, [offerType, selectedFoods])

  return (
    <div>
        <Select 
            options={foods?.map(food => ({
                value: food.id,
                label: food.name
            }))}
            value={{
                label: fetchingFood ? t('Loading product...') : t('Select'),
                value: '',
            }}
            isLoading={!foods || fetchingFood}
            isDisabled={!foods || fetchingFood || selectedFoods.length >= limitBuy}
            menuPortalTarget={document.body}
            onChange={({ value }) => addFood(value)}
        />

        <div className='mt-3 d-flex flex-column gap-3'>
            {selectedFoods.map(food => (
                <OfferFoodItem 
                    key={food.id}
                    food={food}
                    handleChange={handleChange}
                    removeFood={removeFood}
                />
            ))}
        </div>

    </div>
  )
}

export default FoodSelection