import React, { useEffect, useState } from 'react'
import Nestable from 'react-nestable';
import CustomButton from '../../../../../../Custom/CustomButton';
import { sortFoodComponents } from '../../../../../../../helpers/sortFoodComponents';
import toastAlert from '../../../../../../../helpers/toastAlert';

const SortComponentsForm = ({ components, setComponents }) => {

    const [sortedList, setSortedList] = useState(null);

    const confirmChange = (dragChange) => {
        const dragItem = dragChange.dragItem;
        const currentParent = sortedList.find(item => item.nestLevel === (dragItem.nestLevel - 1) && item.children.some(child => child.id === dragItem.id));
        const destinationParent = dragChange.destinationParent;
        return destinationParent?.id === currentParent?.id
    }

    const saveChanges = () => {
        
        let newList = components.map(component => {
            const sort = sortedList.findIndex(item => item.component_id === component.component_id) + 1;
            if (sort) return {
                ...component,
                sort,
                items: component.items.map(item => {
                    const sort = sortedList.find(sortedItem => sortedItem.children.some(child => child.component_id === item.id))?.children?.findIndex(child => child.component_id === item.id) + 1;
                    if (sort) return {
                        ...item,
                        sort
                    }
                    return {
                        ...item,
                        sort: null
                    }
                })
            }
            return {
                ...component,
                sort: null
            }
        })

        let sortedNewList = sortFoodComponents(newList);
        setComponents(sortedNewList);
        toastAlert({ type: 'success' })
    }

    const setInitialList = () => {
        setSortedList(
            [...components]
            .sort((a, b) => a.sort - b.sort)
            .filter(component => component.items.some(item => item.selected))
            .map(component => ({
                id: `nested-1-${component.component_id}`,
                component_id: component.component_id,
                name: component.name,
                image: component.icon || null,
                class: 'nested-1',
                nestLevel: 1,
                children: 
                    [...component.items]
                    .sort((a, b) => a.sort - b.sort)
                    .filter(item => item.selected)
                    .map(item => ({
                        id: `nested-2-${item.id}`,
                        component_id: item.id,
                        name: item.name,
                        image: item.image || null,
                        class: 'nested-2',
                        nestLevel: 2
                    }))
                })
            )
        )
    }

    useEffect(() => {
        if (components) {
            setInitialList();
        }
    }, [components])

  return (
    <div>
        <div className='px-3 pb-2 w-100 d-flex justify-content-end align-items-center border-bottom'>
            <div className='hstack gap-2'>
                <CustomButton
                    type="button"
                    className="btn-danger"
                    onClick={setInitialList}
                >
                    Cancel
                </CustomButton>
                <CustomButton
                    type="button"
                    className="btn-success"
                    onClick={saveChanges}
                >
                    Save
                </CustomButton>
            </div>
        </div>

        <div className='p-3'>
            {sortedList && 
                <Nestable
                    items={sortedList}
                    renderItem={({item}) => {
                        return (
                            <div className="item-title-container">
                                {item.image && <img src={item.image} alt={item.image} />}
                                <span>{item.name}</span>
                            </div>
                        )
                    }}
                    
                    onChange={({ items }) => {
                        setSortedList(items);
                    }}
                    maxDepth={2}
                    confirmChange={confirmChange}
                />
            }
        </div>
    </div>
  )
}

export default SortComponentsForm