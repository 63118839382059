import React, { useEffect } from 'react'
import PageContainer from '../../Components/Layout/PageContainer'
import { Card, CardBody, CardHeader } from 'reactstrap'
import grapesjs from 'grapesjs'
import gjsPresetWebpage from 'grapesjs-preset-webpage';
import gjsBlocksBasic from 'grapesjs-blocks-basic';

const MenuBuilder = () => {

  useEffect(() => {
    const editor = grapesjs.init({
      container: '#gjs',
      plugins: [gjsPresetWebpage, gjsBlocksBasic],
      projectData: {

      },
      pluginsOpts: {
        gjsPresetWebpage: {},
        gjsBlocksBasic: {}
      }
    });
  }, []);

  return (
    <PageContainer>
        <Card>
            <CardHeader>
                Menu Builder
            </CardHeader>
            
            <CardBody>
              <div id="gjs">
              </div>
            </CardBody>
        </Card>
    </PageContainer>
  )
}

export default MenuBuilder