import axiosInstance from "..";

export const getAllCompltaintsApi = (params) => {
  return axiosInstance.get('/complains', {
    params
  });
};

export const getComplaintTypes = () => {
    return axiosInstance.get('/complains/types');
};

export const getOneComplaintApi = (id) => {
  return axiosInstance.get(`/complains/${id}`);
};

export const updateComplaintApi = (id, data) => {
  return axiosInstance.post(`/complains/${id}`, data);
};