import React, { useEffect } from "react";
import { getSmsHistoryApi } from "../../../apis/Orders/smsSettingsApis";
import { useQuery } from '@tanstack/react-query'
import DataTableContainer from "../../Common/DataTableContainer";
import { formatDateTime } from "../../../helpers/timeFormat";

const SmsHistoryTab = ({ isActive }) => {

  const { data, isLoading, isRefetching, refetch } = useQuery(['sms-history'], getSmsHistoryApi);

  const smsHistory = data?.data?.data;

  const columns = [
    {
      name: 'Service',
      selector: (row) => row.sms_service?.name ?? '-',
    },
    {
      name: 'Amount',
      selector: (row) => row.amount ?? '-',
    },
    {
      name: 'Count',
      selector: (row) => row.count ?? '-',
    },
    {
      name: 'Date',
      selector: (row) => formatDateTime(row.created_at) ?? '-',
    },
  ]

  useEffect(() => {
    if (isActive) {
      refetch();
    }
  }, [isActive])

  return (
    <DataTableContainer 
      data={smsHistory ?? []}
      columns={columns}
      loading={isLoading || isRefetching}
      noPagination
    />
  );
};

export default SmsHistoryTab;
