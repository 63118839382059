import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import PageContainer from '../../../Components/Layout/PageContainer';
import { useQueries } from '@tanstack/react-query';
import { getOneOrderApi } from '../../../apis/Orders/ordersApi';
import TrackOrderMap from '../../../Components/Orders/Orders/Track/Map';
import { getDeliverySettingsApi } from '../../../apis/Orders/deliverySettingsApis';
import { Card, CardBody, CardHeader } from 'reactstrap';
import { useTranslation } from 'react-i18next';

const TrackOrder = () => {

    const { t } = useTranslation();

    const { id } = useParams();

    const [order, setOrder] = useState(null);

    const [deliverySettings, setDeliverySettings] = useState(null);

    const [{ isLoading, isRefetching }, { isLoading: isLoading2, isRefetching: isRefetching2 }] = useQueries({
        queries: [
          {
            queryKey: ['orders', id],
            queryFn: () => getOneOrderApi(id),
            onSuccess({ data: { data } }) {
                setOrder(data)
            }
          },
          {
            queryKey: ['delivery-settings'],
            queryFn: () => getDeliverySettingsApi(),
            onSuccess({ data: { data } }) {
                setDeliverySettings(data);
            }
          },
    
        ],
    });

  return (
    <PageContainer isLoading={isLoading || isRefetching || isLoading2 || isRefetching2}>
        {!!order && !!deliverySettings &&
            <Card className='my-4'>

                <CardHeader className='text-center'>
                  <h2>{t('Tracking Order')} #{order.id} {t('Delivery')} </h2>
                </CardHeader>

                <CardBody className='text-center'>
                  {order.current_status?.id === 6 ?
                    <TrackOrderMap 
                        order={order}
                        shopLocation={deliverySettings.shop_location}
                        apiKey={deliverySettings.google_api_key}
                    /> 
                    :
                    <h5 className='text-danger'>{t('Tracking is not available for this order')}</h5>
                  }
                </CardBody>
            </Card>
        }
    </PageContainer>
  )
}

export default TrackOrder