import React, { useEffect, useState } from 'react'
import { Col, Modal, ModalBody, Row } from 'reactstrap'
import CustomLabel from '../../Custom/CustomLabel'
import CustomInput from '../../Custom/CustomInput'
import { useForm } from 'react-hook-form'
import CustomOption from '../../Custom/CustomOption'
import CustomButton from '../../Custom/CustomButton'
import toastAlert from '../../../helpers/toastAlert'
import menuPageSchema, { menuPageUpdateSchema } from '../../../schemas/menuPageSchema'
import { yupResolver } from '@hookform/resolvers/yup'
import { errorMessage } from '../../../helpers/errorMessage'
import { createMenupage, updateMenuPage } from '../../../apis/Settings/menuApi'
import { menuPageRqBody } from '../../../helpers/requests/Settings/MenuPage'

const pageTypes = ['Menu', 'Video', 'Slider']

const PageModal = ({ type, initialValues, isOpen, toggleModal, setPages, setActivePage }) => {

    const { register, handleSubmit, reset, formState: { errors } } = useForm({
        resolver: type === 'update' ?  yupResolver(menuPageUpdateSchema) :  yupResolver(menuPageSchema)
    });

    const [isSubmitting, setIsSubmitting] = useState(false);

    const submitForm = async (data) => {
        try {
            setIsSubmitting(true);
            if (type === 'update') {
                const requestBody = menuPageRqBody(initialValues);
                await updateMenuPage({
                    pages: [{ ...requestBody, name: data.name }]
                });
                setPages(prev => ({
                    ...prev,
                    [initialValues.type.toLowerCase()]: prev[initialValues.type.toLowerCase()].map(page => {
                        if (page.id === requestBody.page_id) return {
                            ...page,
                            name: data.name
                        }
                        return page
                    })
                }))
                toastAlert({ type: 'success' });
                toggleModal();
            } else {
                const {data: { data: resData }} = await createMenupage({
                    ...data,
                    type: data.type.toLowerCase()
                });
                setPages(prev => ({
                    ...prev,
                    [data.type.toLowerCase()]: [...prev[data.type.toLowerCase()], {
                        id:  resData.id,
                        name: resData.name,
                        type: resData.type,
                        link: resData.link,
                        images: [],
                        items: []
                    }]
                }))
                setActivePage(resData.id);
                toastAlert({ type: 'success' });
                toggleModal();
            }
        } catch (error) {
            toastAlert({ type: 'error' });
        } finally {
            setIsSubmitting(false);
        }
    }

    const clearForm = () => {
        reset({
            name: '',
            type: ''
        })
    }

    useEffect(() => {
        if (initialValues) reset({
            name: initialValues.name
        })
    }, [initialValues])

  return (
    <Modal
        centered 
        isOpen={isOpen}
        toggle={toggleModal}
        onClosed={clearForm}
    >
        <ModalBody>
            <form onSubmit={handleSubmit(submitForm)}>
                <Row className='gy-3'>
                    <Col lg={12}>
                        <CustomLabel>
                            Name
                        </CustomLabel>
                        <CustomInput 
                            type="text"
                            {...register('name')}
                        />
                        {errorMessage(errors, 'name')}
                    </Col>

                    {type !== 'update' &&
                        <Col lg={12}>
                            <CustomLabel>
                                Page Type
                            </CustomLabel>
                            <select 
                                className='form-select'
                                {...register('type')}
                            >
                                <CustomOption value="">
                                    Select
                                </CustomOption>
                                {pageTypes.map((type, index) => (
                                    <CustomOption value={type} key={index}>
                                        {type}
                                    </CustomOption>
                                ))}
                            </select>
                            {errorMessage(errors, 'type')}
                        </Col>
                    }
                </Row>

                <div className='d-flex justify-content-end gap-3 mt-3'>
                    <CustomButton
                        type="button"
                        className="btn-danger"
                        onClick={toggleModal}
                    >
                        Cancel
                    </CustomButton>

                    <CustomButton
                        type="submit"
                        className="btn-success"
                        isLoading={isSubmitting}
                    >
                        Save
                    </CustomButton>
                </div>

            </form>
        </ModalBody>
    </Modal>
  )
}

export default PageModal