import React, { useEffect, useRef, useState } from "react";
import { Col, Row } from "reactstrap";
import PageContainer from "../../../Components/Layout/PageContainer";
import { useGetInventoryOrders } from "../../../hooks/useQueryHelpers";
import useSelectItems from "../../../hooks/useSelectItems";
import useModal from "../../../hooks/useModal";
import useActions from "../../../hooks/useActions";
import usePagination from "../../../hooks/usePagination";
import ListHeader from "../../../Components/Common/ListHeader";
import { inventoryApi } from "../../../apis/Inventory/api";
import { useLocalStorage } from "../../../hooks/useLocalStorage";
import { objectFilter } from "../../../helpers/objectFilter";
import { useQuery } from "@tanstack/react-query";
import InventoryOrdersTable from "../../../Components/Inventory/Orders/Table";

const InventoryOrders = () => {
  
  const { isLoading: isDeleting, onLoading: onDeleting, onError, onFinally } = useActions();

  const { isDisable, selectedItems, selectItems, getSelectedIds } = useSelectItems();
  
  const { isModalOpen, setIsModalOpen, openModal, closeModal } = useModal();

  const { currentPage, navigateToPage } = usePagination();

  const timeout = useRef(null);

  const { data } = useQuery(["branches"], () => getAllBranchesApi({ page_size: 'all' }));

  const [code, setCode] = useState(null);
  
  const {values: { inventoryOrdersFilter: localFilterValues }, setNewValues: setLocalFilterValues} = useLocalStorage({
    inventoryOrdersFilter: {
      category_id: '',
      branche_id: '',
      admin_id: '',
      date: '',
      status: '',
      page_size: 10
    }
  })

  const { isLoading, orders, deleteOrders, isRefetching, refetch } = useGetInventoryOrders(objectFilter({
    page: currentPage,
    name,
    code,
    ...localFilterValues,
  }), {
    enabled: false,
    cacheTime: 0
  });

  const setPageSize = (val) => {
    setLocalFilterValues({
      inventoryOrdersFilter: {
          page_size: val
        }
    })
  }

  const onDeleteClick = async () => {
    onDeleting();
    try {
      const ids = getSelectedIds();
      await inventoryApi.orders.delete({ ids });
      closeModal();
      deleteOrders(ids);
      refetch();
      selectItems([]);
    } catch (error) {
      onError();
    } finally {
      onFinally();
    }
  };
  const debounceSearch = () => {
    clearTimeout(timeout.current);
    timeout.current = setTimeout(() => refetch(), 500);
  }

  useEffect(() => {
    refetch();
  }, [localFilterValues, currentPage]);

  useEffect(() => {
    if (name !== null || code !== null) debounceSearch();
  }, [name, code])

  return (
    <PageContainer>
      <Row className="gy-4">
        <Col xs={12}>
          <ListHeader
            isDisable={isDisable}
            openModal={openModal}
            pathname="inventory/orders/create"
          />
        </Col>
        <Col xs={12}>
          <InventoryOrdersTable
            isLoading={isLoading || isRefetching}
            data={orders}
            selectItems={selectItems}
            modal={isModalOpen}
            selectedItems={selectedItems}
            setModal={setIsModalOpen}
            onDeleteClick={onDeleteClick}
            isDeleting={isDeleting}
            setPageSize={setPageSize}
            navigateToPage={navigateToPage}
            refetch={refetch}
            code={code}
            setCode={setCode}
            filters={localFilterValues}
            setFilters={setLocalFilterValues}
          />
        </Col>
      </Row>
    </PageContainer>
  );
};

export default InventoryOrders;
