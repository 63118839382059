import { objectFilter } from "../../objectFilter"

export const foodRqBody = (data) => {
    const { image, ...rest } = data;
    const dataAfter = objectFilter({
        ...Object.fromEntries(Object.entries(rest).map(([key, val]) => {
            if (typeof val === 'boolean') return [key, val ? 1 : 0]
            return [key, val]
        })),
    });
    return dataAfter
}