import React, { useState } from 'react'
import DataTableContainer from '../../Common/DataTableContainer';
import CustomTableHeading from '../../Custom/CustomTableHeading';
import CustomInput from '../../Custom/CustomInput';
import CustomButton from '../../Custom/CustomButton';
import { FaRegTrashAlt } from "react-icons/fa";
import { MdOutlineStarOutline, MdOutlineStarPurple500 } from "react-icons/md";
import { toggleStatus } from '../../../apis/general';
import toastAlert from '../../../helpers/toastAlert';

const OrderProductsTable = ({ status, changingOrder, confirmingRecieving, confirmingOrder, products, setProducts }) => {

    const [filters, setFilters] = useState({
        code: '',
        name: '',
    }
)
    const handleFilterChange = (field, value) => {
        setFilters(prev => ({
            ...prev,
            [field]: value
        }))
    }

    const handleProductChange = (productId, field, value) => {
        setProducts(prev => prev.map(product => {
            if (product.id === productId) return {
                ...product,
                [field]: value
            }
            return product
        }))
    }

    const handleDelete = (id) => {
        setProducts(prev => prev.filter(product => product.id !== id));
    }

    const setFavourite = async (productId, isFavourite) => {
        try {
            await toggleStatus({
                type: 'products',
                id: productId,
                daily: isFavourite
            })
            handleProductChange(productId, 'daily', isFavourite)
        } catch (error) {
            toastAlert({ type: 'error' })
        }
    }

    const baseColumns = [
        {
            name: <CustomTableHeading>id</CustomTableHeading>,
            selector: row => row.id
        },
        {
            name: (
                <CustomInput 
                    placeholder="Code"
                    value={filters.code}
                    onChange={(e) => handleFilterChange('code', e.target.value)}
                />
            ),
            selector: row => row.code ?? '-'
        },
        {
            name: (
                <CustomInput 
                    placeholder="Name"
                    value={filters.name}
                    onChange={(e) => handleFilterChange('name', e.target.value)}
                />
            ),
            selector: row => row.name ?? '-'
        },
    ]

    const changeOrderColumns = [
        {
            name: <CustomTableHeading>Inventory</CustomTableHeading>,
            selector: row => row.inventory ?? '-'
        },
        {
            name: <CustomTableHeading>Sold</CustomTableHeading>,
            selector: row => row.sold ?? '-'
        },
        {
            name: <CustomTableHeading>Remaining</CustomTableHeading>,
            selector: row => row.remaining ?? '-'
        },
        {
            name: <CustomTableHeading>Order</CustomTableHeading>,
            grow: 2,
            selector: row => (
                <div className='d-flex align-items-center gap-2'>
                    <CustomInput
                        step="any" 
                        value={row.new_qty}
                        onChange={(e) => handleProductChange(row.id, 'new_qty', e.target.value)}
                    />
                    <span>{row.unit.name}</span>
                </div>
            )
        },
        {
            selector: row => (
                <div className='d-flex gap-3 align-items-center'>
                    {row.daily ? 
                        <MdOutlineStarPurple500 fill='#e09824' fontSize="2rem" className='cursor-pointer' onClick={() => setFavourite(row.id, false)} />  : 
                        <MdOutlineStarOutline fontSize="2rem" className='cursor-pointer' onClick={() => setFavourite(row.id, true)} /> 
                    }
                    <CustomButton
                        className="btn-danger"
                        type="button"
                        onClick={() => handleDelete(row.id)}
                    >
                        <FaRegTrashAlt />
                    </CustomButton>
                </div>
            )
        },
    ]

    const confirmOrderColumns = [
        {
            name: <CustomTableHeading>Remaining</CustomTableHeading>,
            selector: row => row.remaining ?? '-'
        },
        {
            name: <CustomTableHeading>Branch Order</CustomTableHeading>,
            selector: row => row.qty ?? '-'
        },
        {
            name: <CustomTableHeading>Inventory Order</CustomTableHeading>,
            grow: 2,
            selector: row => (
                <div className='d-flex align-items-center gap-2'>
                    <CustomInput
                        step="any" 
                        value={row.new_qty}
                        onChange={(e) => handleProductChange(row.id, 'new_qty', e.target.value)}
                    />
                    <span>{row.unit.name}</span>
                </div>
            )
        },
        {
            selector: row => ( 
                <CustomButton
                    className="btn-danger"
                    type="button"
                    onClick={() => handleDelete(row.id)}
                >
                    <FaRegTrashAlt />
                </CustomButton>
            )
        },
    ]

    
    const confirmRecievingColumns = [
        {
            name: <CustomTableHeading>Remaining</CustomTableHeading>,
            selector: row => row.remaining ?? '-'
        },
        {
            name: <CustomTableHeading>Branch Order</CustomTableHeading>,
            selector: row => row.qty ?? '-'
        },
        {
            name: <CustomTableHeading>Inventory Order</CustomTableHeading>,
            selector: row => row.qty_confirmed ?? '-'
        },
        {
            name: <CustomTableHeading>Recieved Order</CustomTableHeading>,
            grow: 2,
            selector: row => (
                <div className='d-flex align-items-center gap-2'>
                    <CustomInput
                        step="any" 
                        value={status === 'received' ? row.qty_received : row.new_qty}
                        onChange={(e) => handleProductChange(row.id, 'new_qty', e.target.value)}
                        disabled={!confirmingRecieving}
                    />
                    <span>{row.unit.name}</span>
                </div>
            )
        },
    ]

    const columns = [...baseColumns, ...(changingOrder ? changeOrderColumns : confirmingOrder ? confirmOrderColumns : confirmRecievingColumns)]

  return (
    <DataTableContainer 
        data={products.filter(product => Object.keys(filters).every(filterKey => {
            if (!filters[filterKey]) return true;
            return product[filterKey]?.includes(filters[filterKey])
        }))}
        columns={columns}
        noPagination
    />
  )
}

export default OrderProductsTable