import { useQuery } from "@tanstack/react-query";
import React from "react";
import { CardBody, CardFooter, Col, Row } from "reactstrap";
import { getSeoApi, setSeoApi } from "../../../apis/Settings/webSettingsApi";
import toastAlert from "../../../helpers/toastAlert";
import useActions from "../../../hooks/useActions";
import useCustomForm from "../../../hooks/useCustomForm";
import LoaderComponent from "../../Common/LoaderComponent";
import CustomButton from "../../Custom/CustomButton";
import CustomInput from "../../Custom/CustomInput";
import CustomLabel from "../../Custom/CustomLabel";
import CustomTextArea from "../../Custom/CustomTextArea";
import WebSettingsBody from "./WebSettingsBody";
import WebSettingsFooter from "./WebSettingsFooter";

const SeoTab = () => {
  const { values, handleChange, setValues } = useCustomForm({
    title: "",
    keywords: "",
    description: "",
  });

  const { isLoading: isSubmitting, onLoading, onFinally } = useActions();

  const { isLoading } = useQuery(["web-settings", "seo"], getSeoApi, {
    onSuccess(data) {
      setValues(data.data.data);
    },
  });

  const onSubmit = async () => {
    onLoading();
    try {
      await setSeoApi(values);
      toastAlert({ type: 'success' });
    } catch (error) {
      toastAlert({ type: 'error' });
    } finally {
      onFinally();
    }
  };

  return (
    <LoaderComponent isLoading={isLoading}>
      <WebSettingsBody>
        <Col md={6}>
          <CustomLabel>Title</CustomLabel>
          <CustomInput
            name="title"
            value={values.title}
            onChange={handleChange}
          />
        </Col>
        <Col md={6}>
          <CustomLabel>Keywords</CustomLabel>
          <CustomInput
            name="keywords"
            value={values.keywords}
            onChange={handleChange}
          />
        </Col>
        <Col xs={12}>
          <CustomLabel>Description</CustomLabel>
          <CustomTextArea
            rows={3}
            name="description"
            value={values.description}
            onChange={handleChange}
          />
        </Col>
      </WebSettingsBody>
      <WebSettingsFooter isSubmitting={isSubmitting} onSubmit={onSubmit} />
    </LoaderComponent>
  );
};

export default SeoTab;
