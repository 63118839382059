import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Card, CardBody, CardHeader } from "reactstrap";
import { getPathname } from "../../../Layouts/LayoutMenuData";
import OrderDeliveryItem from "./OrderDeliveryItem";
import OrderPaymentDetails from "./OrderPaymentDetails";
import { useBranches, useGetChannels, useGetDeliveryCompanies } from "../../../hooks/useQueryHelpers";
import toastAlert from "../../../helpers/toastAlert";
import CustomOption from "../../Custom/CustomOption";
import { updateOrderApi, updateOrderChannelApi, updateOrderDeliveryCompanyApi } from "../../../apis/Orders/ordersApi";

const OrderDetailsInfo = ({ order }) => {

  const { t } = useTranslation();

  const { id: userID, email, phone, phone_temp, name, username } = order.user;

  const [branch, setBranch] = useState(null);

  const [channel, setChannel] = useState(null);

  const [deliveryCompany, setDeliveryCompany] = useState(null);

  const { branches } = useBranches({ status: true });

  const { data: channelsData } = useGetChannels();

  const channels = channelsData?.data?.data;

  const { data: deliveryCompaniesData } = useGetDeliveryCompanies();
  
  const deliveryCompanies = deliveryCompaniesData?.data?.data

  const onSelectChange = async (type, newId) => {
    try {
      if (type === 'branch') {
        await updateOrderApi(order.id, {
          branche_id: newId
        });
        setBranch(newId);
      } else if (type === 'channel') {
        await updateOrderChannelApi(order.id, {
          channel_id: newId
        });
        setChannel(newId);
      } else if (type === 'deliveryCompany') {
        await updateOrderDeliveryCompanyApi(order.id, {
          delivery_company_id: newId
        });
        setDeliveryCompany(newId);
      }
      toastAlert({ type: 'success' });
    } catch (error) {
      toastAlert({ type: 'error' });
    }
  }

  useEffect(() => {
    if (order?.branche) {
      setBranch(order?.branche?.id)
    } 
    if (order?.channel) {
      setChannel(order?.channel?.id)
    }
    if (order?.delivery_company) {
      setDeliveryCompany(order?.delivery_company?.id)
    }
  }, [order])

  return (
    <>
      <Card>
        <CardHeader>
          <div className="d-flex">
            <h5 className="card-title flex-grow-1 mb-0">
              <i className="mdi mdi-truck-fast-outline align-middle me-1 text-muted"></i>
              {t("Delivery")}
            </h5>
          </div>
        </CardHeader>
        <CardBody>
          <div className="">
            <h5 className="fs-16 mt-2 text-center">{order.delivery_type}</h5>
            {order.delivery_type === "Takeaway" ? (
              <></>
            ) : (
              <>
                <OrderDeliveryItem
                  prop={"Address"}
                  value={order.address?.address}
                />
                <OrderDeliveryItem
                  prop={"Delivery at"}
                  value={order.delivered_at}
                />
                {order.delivery_type?.toLowerCase() === 'restaurant' &&
                    <OrderDeliveryItem
                     prop={"Table"}
                     value={order.table?.name}
                   />
                }
              </>
            )}
          </div>
        </CardBody>
      </Card>

      {
        order.delivery_type === 'Delivery' && order.delivery_man &&

          <Card>
            <CardHeader>
              <div className="d-flex">
                <h5 className="card-title flex-grow-1 mb-0">
                  <i className="mdi mdi-truck-fast-outline align-middle me-1 text-muted"></i>
                  {t("Delivery Agent")}
                </h5>
              </div>
            </CardHeader>
            <CardBody>
              <div className="">
                <h5 className="fs-16 mt-2 text-center">{order.delivery_man?.name}</h5>
              </div>
            </CardBody>
        </Card>
      }
      
      <Card>
        <CardHeader>
          <div className="d-flex">
            <h5 className="card-title flex-grow-1 mb-0">
              {t("Branch")}
            </h5>
          </div>
        </CardHeader>

        <CardBody>
          <select
            className="form-select"
            value={branch || ''}
            onChange={(e) => onSelectChange('branch', e.target.value)}
          >
            <CustomOption value="">None</CustomOption>
              {branches?.map(branch => (
                <option key={branch.id} value={branch.id}>
                  {branch.name}
                </option>
              ))}
          </select>
        </CardBody>
      </Card>

            
      <Card>
        <CardHeader>
          <div className="d-flex">
            <h5 className="card-title flex-grow-1 mb-0">
              {t("Channel")}
            </h5>
          </div>
        </CardHeader>

        <CardBody>
          <select
            className="form-select"
            value={channel || ''}
            onChange={(e) => onSelectChange('channel', e.target.value)}
          >
            <CustomOption value="">None</CustomOption>
              {channels?.map(channel => (
                <option key={channel.id} value={channel.id}>
                  {channel.name}
                </option>
              ))}
          </select>
        </CardBody>
      </Card>

            
      <Card>
        <CardHeader>
          <div className="d-flex">
            <h5 className="card-title flex-grow-1 mb-0">
              {t("Delivery Company")}
            </h5>
          </div>
        </CardHeader>

        <CardBody>
          <select
            className="form-select"
            value={deliveryCompany || ''}
            onChange={(e) => onSelectChange('deliveryCompany', e.target.value)}
          >
            <CustomOption value="">None</CustomOption>
              {deliveryCompanies?.map(deliveryCompany => (
                <option key={deliveryCompany.id} value={deliveryCompany.id}>
                  {deliveryCompany.name}
                </option>
              ))}
          </select>
        </CardBody>
      </Card>

      <Card>
        <CardHeader>
          <div className="d-flex">
            <h5 className="card-title flex-grow-1 mb-0">
              {t("Customer Details")}
            </h5>
            <div className="flex-shrink-0">
              <Link
                to={getPathname(`users/update/${userID}`)}
                className="link-secondary"
              >
                {t("View Profile")}
              </Link>
            </div>
          </div>
        </CardHeader>
        <CardBody>
          <ul className="list-unstyled mb-0 vstack gap-3">
            <li>
              <div className="flex-grow-1">
                <h6 className="fs-15 mb-1">{name}</h6>
                <p className="text-muted mb-0">{username}</p>
              </div>
            </li>
            {email && (
              <li>
                <i className="ri-mail-line me-2 align-middle text-muted fs-16"></i>
                {email}
              </li>
            )}

            {(phone || phone_temp) && (
              <li>
                <i className="ri-phone-line me-2 align-middle text-muted fs-16"></i>
                {phone || phone_temp}
              </li>
            )}
          </ul>
        </CardBody>
      </Card>

      <Card>
        <CardHeader>
          <h5 className="card-title mb-0">
            <i className="ri-secure-payment-line align-bottom me-1 text-muted"></i>{" "}
            {t("Payment Details")}
          </h5>
        </CardHeader>
        <CardBody>
          <OrderPaymentDetails
            prop="Payment status"
            value={order.payment?.name}
          />
          <OrderPaymentDetails
            prop="Payment method"
            value={order.payment_method?.name}
          />
        </CardBody>
      </Card>
    </>
  );
};

export default OrderDetailsInfo;
