import { toast } from 'react-toastify';
import i18n from "../i18n"
import { triggerSound } from './triggerSound';

const messages = {
    success: 'Operation was successful',
    error: 'Something went wrong'
}

export default async function toastAlert ({ type , message, sound, ...rest }) {
    const theme = 'colored';
    if (sound) await triggerSound(sound);
    toast[type || 'success'](
        i18n.t(message || messages[type || 'success']), 
        { 
            autoClose: 3000, 
            theme, 
            ...rest,
        }
    );
}
