export const getColorPalette = (data, index) => {

    const palettes = [
      ['#ff2e63', '#ffcdd2'], // pink shades
      ['#ff9800', '#ffe0b2'], // orange shades
      ['#4caf50', '#c8e6c9'], // green shades
      ['#2196f3', '#bbdefb'], // blue shades
      ['#9c27b0', '#e1bee7'], // purple shades
      ['#673ab7', '#d1c4e9'], // deep purple shades
      ['#3f51b5', '#c5cae9'], // indigo shades
      ['#607d8b', '#cfd8dc'], // blue-grey shades
      ['#00bcd4', '#e0f7fa'], // cyan shades
      ['#ff6f00', '#ffe0b2'], // orange shades
      ['#00897b', '#e0f2f1'], // teal shades
      ['#8e24aa', '#f3e5f5'], // purple shades
      ['#c0ca33', '#f9fbe7'], // lime shades
      ['#6d4c41', '#d7ccc8'], // brown shades
      ['#e53935', '#ffcdd2'], // red shades
      ['#43a047', '#e8f5e9'], // green shades
      ['#ffb300', '#fffde7'], // amber shades
      ['#00838f', '#e0f2f1'], // teal shades
      ['#d81b60', '#fce4ec'], // pink shades
      ['#26c6da', '#b2ebf2'], // turquoise shades
      ['#ab47bc', '#f3e5f5'], // purple shades
      ['#f57f17', '#fff8e1'], // yellow shades
      ['#5c6bc0', '#e8eaf6'], // indigo shades
      ['#558b2f', '#dcedc8'], // lime green shades
      ['#bf360c', '#ffccbc'], // deep orange shades
      ['#9c27b0', '#f3e5f5'], // purple shades
      ['#ffab00', '#fff8e1'], // amber shades
      ['#283593', '#c5cae9'], // indigo shades
      ['#43a047', '#c8e6c9'], // green shades
      ['#f44336', '#ffcdd2'], // red shades
      ['#6a1b9a', '#f3e5f5'], // purple shades
      ['#039be5', '#90caf9'], // blue shades
      ['#d81b60', '#f8bbd0'], // pink shades
      ['#cddc39', '#f0f4c3'], // lime shades
      ['#00897b', '#80cbc4'], // teal shades
      ['#e64a19', '#ffccbc'], // deep orange shades
      ['#4db6ac', '#b2dfdb'], // teal shades
      ['#512da8', '#ede7f6'], // deep purple shades
      ['#0277bd', '#b3e5fc'], // blue shades
      ['#e91e63', '#f8bbd0'], // pink shades
      ['#9e9e9e', '#f5f5f5'], // grey shades
      ['#c2185b', '#fce4ec'], // pink shades
      ['#009688', '#80cbc4'],
    ]

    if (index > (palettes.length - 1)) return palettes[Math.floor(Math.random() * (palettes.length - 1))]
    return palettes[index];
}