import axiosInstance from "..";

export const getAllBannersApi = (query) => {
  return axiosInstance.get(`/banners`, {
    params: {
      lang: localStorage.getItem("I18N_LANGUAGE") || 'en',
      ...query
    }
  });
};

export const getOneBannerApi = (id, lang) => {
  return axiosInstance.get(`/banners/${id}?lang=${lang}`);
};

export const createBannerApi = (body, lang) => {
  return axiosInstance.post(`/banners?lang=${lang}`, body);
};

export const updateBannerAPi = (id, body, lang) => {
  return axiosInstance.post(`/banners/${id}?lang=${lang}`, body);
};

export const deleteBannerApi = (ids) => {
  return axiosInstance.delete("/banners", {
    data: {
      ids,
    },
  });
};
