import React, { useEffect, useState } from "react";
import { Card, CardBody, CardHeader, } from "reactstrap";
import CustomersChart from "./Chart";
import { useTranslation } from "react-i18next";

const Customers = ({data}) => {

  const [chartData, setchartData] = useState(null);

  const { t } = useTranslation();

  useEffect(() => {
    if (data) {
      setchartData([
        {
          name: t('New Customers'),
          type: 'bar',
          data: data.flat().map(item => item.newcustomers).reverse(),
        },
        {
          name: t('Guests'),
          type: 'line',
          data: data.flat().map(item => item.guest).reverse(),
        },
      ])
    }
  }, [data])

  return (
    <React.Fragment>
      <Card>
        <CardHeader className="border-0 align-items-center d-flex">
          <h4 className="card-title mb-0 flex-grow-1">{t('Customers')}</h4>
          <div className="d-flex gap-1">
            <button type="button" className="btn btn-soft-primary btn-sm pe-none">
              {'Last 12 Months'}
            </button>
          </div>
        </CardHeader>

        <CardBody className="p-0 pb-2">
          <div className="w-100">
            <div dir="ltr">
              {chartData ? 
              
              <CustomersChart 
                data={chartData} 
                months={data.flat().map(item => item.month).reverse()}
              /> : <></>}
            </div>
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default Customers;
