import React, { useEffect } from 'react'
import InventoryWithCombos from './InventoryWithCombos';
import InventoryWithoutCombos from './InventoryWithoutCombos';
import { foodFormTabs } from '../../../../../../data/foodFormTabs';

const Inventory = ({ initialValues, foodType, ...props }) => {
    
  useEffect(() => {
    if (!foodFormTabs.find(item => item.id === foodType)?.tabs?.some(tab => tab.value === 'inventory')) {
        props.setValue('recipes', null);
      }
  }, [foodType])

  if ([1, 2, 3, 5, 9].includes(foodType) || (foodType === 8 && initialValues?.entrees_sizes?.length)) return (
    <InventoryWithCombos initialValues={initialValues} foodType={foodType} {...props}  />
  ) 
  else if (foodType === 6 || (foodType === 8 && !initialValues?.entrees_sizes?.length)) return (
    <InventoryWithoutCombos initialValues={initialValues} foodType={foodType} {...props} />
  )
}

export default Inventory