import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Card } from "reactstrap";
import DataTableContainer from "../../../Components/Common/DataTableContainer";
import CustomButton from "../../../Components/Custom/CustomButton";
import CustomInput from "../../../Components/Custom/CustomInput";
import CustomLabel from "../../../Components/Custom/CustomLabel";
import CustomTableHeading from "../../../Components/Custom/CustomTableHeading";
import PageContainer from "../../../Components/Layout/PageContainer";
import useFilterData, { FILTER_TYPES } from "../../../hooks/useFilterData";
import useModal from "../../../hooks/useModal";
import { useCoupons } from "../../../hooks/useQueryHelpers";
import useSelectItems from "../../../hooks/useSelectItems";
import { getPathname } from "../../../Layouts/LayoutMenuData";
import { deleteCouponsApi } from "../../../apis/Orders/couponApis";
import useActions from "../../../hooks/useActions";
import toastAlert from "../../../helpers/toastAlert";
import { useTranslation } from "react-i18next";
import ListHeader from "../../../Components/Common/ListHeader";
import { useSelector } from "react-redux";
import { isAllowed } from "../../../helpers/isAllowed";
import BreadCrumb from "../../../Components/Common/BreadCrumb";

const Coupons = () => {
  
  const { coupons, isLoading, deleteCoupon } = useCoupons();

  const { t } = useTranslation();

  const {
    onLoading: onDeleting,
    isLoading: isDeleting,
    onFinally: onFinallyDeleting,
  } = useActions();

  const [query, setQuery] = useState("");

  const filteredData = useFilterData(coupons, {
    name: {
      key: "title",
      type: FILTER_TYPES.MATCH,
      value: query,
    },
  });

  const { getSelectedIds, isDisable, selectItems } = useSelectItems();
  const { isModalOpen, setIsModalOpen, openModal, closeModal } = useModal();

  const { user } = useSelector(state => state.admin);

  const onDeleteSubmit = async () => {
    onDeleting();
    const ids = getSelectedIds();
    try {
      await deleteCouponsApi(ids);
      deleteCoupon(ids);
      toastAlert({ type: 'success' });
      selectItems([]);
      closeModal();
    } catch (error) {
      toastAlert({ type: 'error' });
    } finally {
      onFinallyDeleting();
    }
  };

  const columns = [
    {
      name: <CustomTableHeading>Title</CustomTableHeading>,
      selector: (row) => row.title,
    },
    {
      name: <CustomTableHeading>Code</CustomTableHeading>,
      selector: (row) => row.code,
    },
    {
      name: <CustomTableHeading>Rate</CustomTableHeading>,
      selector: (row) => row.discount_rate,
    },
    {
      name: <CustomTableHeading>Amount</CustomTableHeading>,
      selector: (row) => row.qr_amount,
    },
    {
      name: <CustomTableHeading>For product</CustomTableHeading>,
      selector: (row) => t(row.all_products?.value),
    },
    {
      name: <CustomTableHeading>For delivery</CustomTableHeading>,
      selector: (row) => t(row.for_delivery?.value),
    },
    {
      name: <CustomTableHeading>For user</CustomTableHeading>,
      selector: (row) => t(row.users?.value),
    },
    {
      name: <CustomTableHeading>Min price</CustomTableHeading>,
      selector: (row) => row.min_price,
    },
    {
      name: <CustomTableHeading>Count use</CustomTableHeading>,
      selector: (row) => row.count_use,
    },
  ];

  return (
    <PageContainer isLoading={isLoading}>

      <BreadCrumb pageTitle="Orders" title="Coupons"/>

      <ListHeader
        isDisable={isDisable}
        openModal={openModal}
        pathname="orders/coupons/create"
        query={query}
        setQuery={setQuery}
      />

      <Card className="mt-4">
        <DataTableContainer
          modal={isModalOpen}
          isDeleting={isDeleting}
          handleDelete={onDeleteSubmit}
          setModal={setIsModalOpen}
          columns={columns}
          data={filteredData}
          selectableRows={isAllowed(user)}
          path="coupons"
          selectItems={selectItems}
        />
        
      </Card>
    </PageContainer>
  );
};

export default Coupons;
