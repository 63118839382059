import { useQuery } from "@tanstack/react-query";
import React, { useState } from "react";
import {
  deleteOneOpeningHour,
  getOpeningHoursApi,
  setOpeningHoursApi,
} from "../../../apis/Settings/webSettingsApi";
import generateUniqueID from "../../../helpers/generateUniqueID";
import toastAlert from "../../../helpers/toastAlert";
import useActions from "../../../hooks/useActions";
import useModal from "../../../hooks/useModal";
import DeleteModal from "../../Common/DeleteModal";
import LoaderComponent from "../../Common/LoaderComponent";
import CustomButton from "../../Custom/CustomButton";
import OpeningHourItem from "../../Settings/WebSettings/OpeningHourItem";
import SetupActions from "../SetupActions";
import { Row } from "reactstrap";

const OpeningHoursSetup = ({ id, next, skip }) => {

  const [error, setError] = useState();

  const [openingHours, setOpeningHours] = useState([]);
  
  const { onLoading, isLoading: isSubmitting, onFinally } = useActions();

  const {
    isLoading: isDeleting,
    onLoading: onDeleting,
    onFinally: onFinallyDeleting,
  } = useActions();

  const [itemToDelete, setItemToDelete] = useState(null);

  const { isModalOpen, openModal, closeModal } = useModal();

  const { isLoading } = useQuery(["web-settings", "opening-hours"], getOpeningHoursApi,
    {
      onSuccess({data: { data }}) {
        setOpeningHours(data);
      },
    }
  );

  const onCloseClick = () => {
    closeModal();
    setItemToDelete(null);
  };

  const onOpenDeleteModal = (item) => {
    setItemToDelete(item);
    openModal();
  };

  const onDeleteClick = async () => {
    if (!itemToDelete) return;
    onDeleting();
    try {
      if (!itemToDelete.isNew) {
        await deleteOneOpeningHour(itemToDelete.id);
      }
      setOpeningHours((current) => {
        return current.filter(
          (openingHour) => openingHour.id !== itemToDelete.id
        );
      });
      toastAlert({ type: 'success' });
      onCloseClick();
      setItemToDelete(null);
    } catch (error) {
      toastAlert({ type: 'error' });
    } finally {
      onFinallyDeleting();
    }
  };

  const onSubmit = async () => {
    if (!openingHours?.length) return setError('Please add at least 1 opening hour')
    onLoading();
    try {
      const body = {
        data: openingHours.map((openingHour, index) => {
          return {
            id: openingHour.isNew ? null : openingHour.id,
            day: openingHour.day,
            time: openingHour.time,
            order: index + 1,
          };
        }),
      };
      const { data } = await setOpeningHoursApi(body);
      setOpeningHours(data.data);
      setError(null);
      next(id);
    } catch (error) {
      toastAlert({ type: 'error' });
    } finally {
      onFinally();
    }
  };

  const addNewOpeningHour = () => {
    setOpeningHours((current) => {
      return [
        ...current,
        {
          id: generateUniqueID(),
          isNew: true,
          day: "",
          time: "",
        },
      ];
    });
    setError(null);
  };

  return (
    <LoaderComponent isLoading={isLoading}>

      <DeleteModal
        onCloseClick={onCloseClick}
        isDeleting={isDeleting}
        onDeleteClick={onDeleteClick}
        show={isModalOpen}
      />

      <Row className="gy-1">

        {openingHours.map((openingHour) => {
          return (
            <OpeningHourItem
              key={openingHour.id}
              item={openingHour}
              onOpenDeleteModal={() => onOpenDeleteModal(openingHour)}
              setOpeningHours={setOpeningHours}
            />
          );
        })}

        {error && <div className="mt-3 text-danger">
            {error}
        </div>}

        <div className="mt-4">
          <CustomButton onClick={addNewOpeningHour}>Add</CustomButton>
        </div>

      </Row>

      <SetupActions id={id} submit={onSubmit} skip={skip} isLoading={isSubmitting} />
  
    </LoaderComponent>
  );
};

export default OpeningHoursSetup;
