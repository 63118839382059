import { useState } from "react";

export default function useModal() {
  
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  const toggleModal = () => setIsModalOpen((current) => !current);

  return {
    isModalOpen,
    openModal,
    closeModal,
    setIsModalOpen,
    toggleModal,
  };
}
