import React from "react";
import { useSelector } from "react-redux";
import { Col, Container, Row } from "reactstrap";
import { useTranslation } from "react-i18next";

const Footer = () => {
  
  const { footer } = useSelector((state) => state.settings);

  const { t } = useTranslation();

  return (
    <React.Fragment>
      <footer className="footer">
        <Container fluid>
          <Row>
            <Col sm={6}>{footer.footer}</Col>
            <Col sm={6}>
              <div className="text-sm-end d-none d-sm-block">
                {footer.copyright}
              </div>
            </Col>

            <Col lg={12}>
                <div className="d-flex justify-content-center">
                  {t('Dashboard V')}1.0.0
                </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </React.Fragment>
  );
};

export default Footer;
