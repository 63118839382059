import React from 'react'
import { useSelector } from 'react-redux'
import { isAllowed } from '../../../helpers/isAllowed'
import { getPathname } from '../../../Layouts/LayoutMenuData'
import DataTableContainer from '../../Common/DataTableContainer'
import CustomTableHeading from '../../Custom/CustomTableHeading'

const ShelvesTable = ({navigateToPage, pageSize, setPageSize, setSelectedItems, modal, setModal, isLoading, handleDelete, loading, data}) => {

    const { user } = useSelector(state => state.admin);

    const columns = [

        {
            name: <CustomTableHeading>ID</CustomTableHeading>,
            selector: row => row.id || '-',
            grow: -1,
        },
        
        {
            name: <CustomTableHeading>Name</CustomTableHeading>,
            selector: row => row.name || '-',
        },

        {
            name: <CustomTableHeading>Side</CustomTableHeading>,
            selector: row => row.side || '-',
        },

    ]


  return (
    <DataTableContainer 
        loading={loading}
        data={data || []}
        path={getPathname('orders/shelves')}
        columns={columns}
        paginationServer
        navigateToPage={navigateToPage}
        pageSize={pageSize}
        setPageSize={setPageSize}
        selectableRows={isAllowed(user)}
        selectItems={setSelectedItems}
        modal={modal}
        setModal={setModal}
        isDeleting={isLoading}
        handleDelete={handleDelete}
    />
  )
}

export default ShelvesTable