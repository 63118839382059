export const getCenterCoords = (coords) => {

    var x = 0;
    var y = 0;
    var numPoints = coords.length;
  
    for (var i = 0; i < numPoints; i++) {
      x += parseFloat(coords[i].latitude);
      y += parseFloat(coords[i].longitude);
    }
  
    x /= numPoints;
    y /= numPoints;
  
    return {lat: x, lng: y};
}
  