import { useState } from "react";

const setLocalRecursively = (key, val, newVal) => {
    return [
        key,
        {
        ...val,
        ...Object.fromEntries(Object.entries(newVal).map(([key2, val2]) => {
            if(typeof val2 === 'object') return setLocalRecursively(key2, val[key2], val2);
            if (newVal[key2] || newVal[key2] === '')  return [key2, newVal[key2]];
            return [key2, val2];
            }))
        }
    ]
}

const setDefaultLocalRecursively = (key, val, newVal) => {
    return [
        key,
        {
        ...newVal,
        ...Object.fromEntries(Object.entries(val).map(([key2, val2]) => {
            if(typeof val2 === 'object') return setDefaultLocalRecursively(key2, val2, newVal[key2]);
            return [key2, val2];
            }))
        }
    ]
}

const changeValues = (oldVal, newVal) => {
    return Object.fromEntries(Object.entries(oldVal).map(([key, val]) => {
        return setLocalRecursively(key, val, newVal[key])
    }))
}

const getValues = (data) => {
    return Object.fromEntries(Object.entries(data).map(([key, newValue]) => {
        let value = null;
        value = JSON.parse(localStorage.getItem(key));
        if (value) return setDefaultLocalRecursively(key, value, newValue);
        localStorage.setItem(key, JSON.stringify(newValue));
        return [key, newValue]
    }))
}

const getQueriedValues = (key, val, values) => {
    return [
        key, 
        Object.fromEntries(Object.entries(val).map(([key2, val2]) => {
            if (typeof values[key2] === 'object') return getQueriedValues(key2, val2, values[key2]);
            return [key2, values[key2]];
        }))
    ]
}

export const useLocalStorage = (dataValues) => {

    const [values, setValues] = useState(getValues(dataValues));
    
    const [queriedValues, setQueriedValues] = useState(Object.fromEntries(Object.entries(dataValues).map(([key, val]) => {
        return getQueriedValues(key, val, getValues(dataValues)[key])
    })))

    const setNewValues = (value) => {
        let existingValues = Object.fromEntries([Object.entries(values).find(([key, val]) => {
            return Object.entries(value).some(([key2, val2]) => key === key2)
        })])

        const newValues = changeValues(existingValues, value);

        Object.entries(newValues).forEach(([key, val]) => {
            localStorage.setItem(key, JSON.stringify(val));
        })
        setValues(newValues);
        setQueriedValues(Object.fromEntries(Object.entries(newValues).map(([key, val]) => {
            return getQueriedValues(key, val, getValues(newValues)[key])
        })))
    }

    return { values: queriedValues, setNewValues }
}