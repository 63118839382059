import React from 'react'
import { Col, Row } from 'reactstrap';
import CustomLabel from '../../Custom/CustomLabel';
import CustomInput from '../../Custom/CustomInput';

const PushyWebview = ({ register }) => {

  return (

    <Row className="gy-3 p-3">

        <Col lg={6}>
            <CustomLabel>
                App ID
            </CustomLabel>
            <CustomInput
                {...register(`pushy_webview.app_id`)}
                type="text"
            />
        </Col>

        <Col lg={6}>
            <CustomLabel>
                Token
            </CustomLabel>
            <CustomInput 
                 {...register(`pushy_webview.token`)}
                type="text"
            />
        </Col>

    </Row>
  )
}

export default PushyWebview