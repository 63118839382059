import React, { useEffect } from 'react'
import { Col, Modal, ModalBody, Row } from 'reactstrap'
import CustomLabel from '../../../Custom/CustomLabel'
import CustomInput from '../../../Custom/CustomInput'
import CustomButton from '../../../Custom/CustomButton'
import { createFoodAction, updateFoodAction } from '../../../../redux/actions/foodActions'
import { useDispatch } from 'react-redux'
import useActions from '../../../../hooks/useActions'
import { useForm } from 'react-hook-form'
import { formatExample } from '../../../../helpers/formatExample'

const VatModal = ({isOpen, toggleModal, type, initialValues = null}) => {

    const {register, handleSubmit, reset} = useForm();
    
      const {isLoading, onLoading, onFinally, onError} = useActions();
    
      const dispatch = useDispatch();
    
      const submitForm = (data) => {
        onLoading();
        if (type === 'create') {
          dispatch(createFoodAction({
            field: 'vat',
            data
          }, {
            onSuccess() {
              onFinally();
              toggleModal();
            },
            onError
          }))
        } else {
          dispatch(updateFoodAction({
            field: 'vat',
            id: initialValues.id,
            data
          }, {
            onSuccess() {
                onFinally();
                toggleModal();
            },
            onError
          }))
        }
        
    }

    const clearForm = () => {
        reset({
            name: '',
            rate: '',
            delivery_price: ''
        });
    }

    useEffect(() => {
        if (initialValues) {
            reset(initialValues);
        }
    }, [initialValues])

  return (
    <Modal 
        centered 
        isOpen={isOpen}
        toggle={toggleModal}
        onClosed={clearForm}
        size='xl'
    >
        <ModalBody>
            <form onSubmit={handleSubmit(submitForm)}>
                <Row className='gy-3'>
                    <Col md={6}>
                        <CustomLabel>
                            Name
                        </CustomLabel>
                        <CustomInput 
                            type="text"
                            {...register('name')}
                            placeholder={formatExample("Grain products")}
                        />
                    </Col>

                    <Col md={6}>
                        <CustomLabel>
                            Delivery Price
                        </CustomLabel>
                        <CustomInput 
                            type="number"
                            {...register('delivery_price')}
                            placeholder={formatExample("25")}
                        />
                    </Col>

                    <Col md={6}>
                        <CustomLabel>
                            Rate
                        </CustomLabel>
                        <CustomInput 
                            type="number"
                            {...register('rate')}
                            placeholder={formatExample("25")}
                            min="0"
                            max="100"
                        />
                    </Col>

                </Row>


                <Row className='mt-3'>
                    <Col>
                        <CustomButton
                        className="btn-success"
                        isLoading={isLoading}
                        >
                            {type === 'create' ? 'Create' : 'Update'}
                        </CustomButton>
                    </Col>
                </Row>

            </form>
        </ModalBody>
    </Modal>
    
  )
}

export default VatModal