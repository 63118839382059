import React, { useState } from 'react'
import { Col, Row } from 'reactstrap'
import CustomInput from '../../../Custom/CustomInput'
import { useQuery } from '@tanstack/react-query'
import { deleteVippsWebhook, fixVippsWebhook, getVippsWebhook } from '../../../../apis/Settings/paymentApi'
import CustomButton from '../../../Custom/CustomButton'
import LoadingComponent from '../../../Common/Loading'
import toastAlert from '../../../../helpers/toastAlert'

const VippsWebhook = () => {

    const [data, setData] = useState(null);
    const [fetched, setFetched] = useState(false);

    const [fixing, setFixing] = useState(false);
    const [deleting, setDeleting] = useState(false);

    const { refetch, isLoading, isRefetching } = useQuery(['vipps-webhook'], () => getVippsWebhook(), {
        retry: false,
        cacheTime: 0,
        enabled: false,
        onSuccess({ data: { data }}) {
            if(data[0]) setData(data[0]);
        },
    })

    const fixWebhook = async () => {
        if (!data?.events?.length || data?.events?.every(event => event.statue)) return;
        setFixing(true);
        try {
            const { data: { data } } = await fixVippsWebhook({ id: data?.id });
            setData(data);
            toastAlert({ type: 'success' })
        } catch (error) {
            toastAlert({ type: 'error' })
        } finally {
            setFixing(false);
        }
    }

    const deleteWebhook = async () => {
        if (!data?.url) return;
        setDeleting(true);
        try {
            await deleteVippsWebhook({ id: data?.id });
            setData(null);
            toastAlert({ type: 'success' })
        } catch (error) {
            toastAlert({ type: 'error' })
        } finally {
            setDeleting(false);
        }
    }

  return (
    
    <Row className='gy-3'>

        <Col lg={12}>
            <CustomButton onClick={() => {
                setFetched(true);
                refetch();
            }}>
                Check Webhook
            </CustomButton>
        </Col>

        {fetched &&
            <LoadingComponent loading={isLoading || isRefetching} styles={{ minHeight: 'auto' }}>
                <Col lg={6} className='d-flex gap-2'>
                    
                    <CustomInput 
                        type="text"
                        name="client_id_test"
                        value={data?.url || ''}
                        disabled
                    />

                    <CustomButton
                        type="button"
                        className={`btn-primary d-flex gap-1 align-items-center`}
                        onClick={fixWebhook}
                        disabled={!data?.events?.length || data?.events?.every(event => event.statue)}
                        isLoading={fixing}
                    >
                        <i className="ri-tools-fill fs-16"></i>
                    </CustomButton>

                    <CustomButton
                        type="button"
                        className={`btn-danger d-danger gap-1 align-items-center`}
                        onClick={deleteWebhook}
                        disabled={!data?.url}
                        isLoading={deleting}
                    >
                        <i className="ri-delete-bin-4-line fs-16"></i>
                    </CustomButton>

                </Col>

                <Col lg={12} className='d-flex gap-2'>
                    {data?.events.map((item, index) => (
                        <div key={index} className={`p-3 border text-white text-capitalize bg-${item.statue ? 'success' : 'danger'}`}>
                            {item.name}
                        </div>
                    ))}
                </Col>
                
            </LoadingComponent>
        }

    </Row>
  )
}

export default VippsWebhook