import { useEffect, useState } from "react";
import { useGetProductCategories } from "../../../hooks/useQueryHelpers";
import DataTableContainer from "../../Common/DataTableContainer";
import StatusToggle from "../../Common/StatusToggle";
import TableImage from "../../Common/TableImage";
import CustomInput from "../../Custom/CustomInput";
import CustomOption from "../../Custom/CustomOption";
import CustomTableHeading from "../../Custom/CustomTableHeading";
import { t } from "i18next";
import CustomButton from "../../Custom/CustomButton";
import { Card } from "reactstrap";
import { inventoryApi } from "../../../apis/Inventory/api";
import toastAlert from "../../../helpers/toastAlert";
import { objectFilter } from "../../../helpers/objectFilter";

const ProductControlTable = ({
  type,
  data,
  navigateToPage,
  setPageSize,
  isLoading,
  refetch,
  name,
  setName,
  code,
  setCode,
  filters,
  setFilters,
}) => {

  const [products, setProducts] = useState([]);

  const [submitting, setSubmitting] = useState(false);

  const handleProductChange = (productId, field, value) => {
    if (products.find(product => product.id === productId)) {
        setProducts(prev => prev.map(product => {
            if (product.id === productId) return {
                ...product,
                [field]: value
            }
            return product
        }))
    } else {
        setProducts(prev => [...prev, {
            id: productId,
            [field]: value
        }])
    }
  }

  const { categories: { list: categories } } = useGetProductCategories({ page_size: 'all' })

  const handleFilter = (field, value) => {
    setFilters({
      [`${type}Filter`]: {
        [field]: value
      }
    })
  }

  const submit =  async () => {
    setSubmitting(true);
    try {
        const requestBody = {
          products: products.map(product => objectFilter({
            ...product,
            branche_id: filters.branche_id
        }))
      }
        await inventoryApi.products.control({ control: 'control', 'add-quantity': 'addStock', 'extract-quantity': 'waste', 'default-daily': 'default' }[type] ,requestBody);
        toastAlert({ type: 'success' })
        refetch();
        setProducts([]);
    } catch (error) {
      toastAlert({ type: 'error' })
    } finally {
        setSubmitting(false);
    }
}

  const columns = [
    {
      name: <CustomTableHeading>ID</CustomTableHeading>,
      selector: (row) => row.id,
    },
    {
      selector: row => <TableImage src={row.image} />,
    },
    {
      name: 
      <CustomInput 
        placeholder="Name"
        value={name ?? ''}
        onChange={(e) => setName(e.target.value)}
        data-filtered={!!name}
      />,
      selector: (row) => row.name ?? '-',
    },
    {
      name: 
      <CustomInput 
        placeholder="Code"
        value={code ?? ''}
        onChange={(e) => setCode(e.target.value)}
        data-filtered={!!code}
      />,
      selector: (row) => row.code ?? '-',
    },
    {
      name: <CustomTableHeading>Unit</CustomTableHeading>,
      selector: (row) => row.unit?.name ?? '-',
    },
    {
      name: (
        <select
          className="form-select"
          value={filters.category_id}
          onChange={(e) => handleFilter('category_id', e.target.value)}
          data-filtered={!!filters.category_id}
        >
          <CustomOption value="">Category</CustomOption>
          {categories.map(category => (
            <option key={category.id} value={category.id}>{category.name}</option>
          ))}
        </select>
      ),
      selector: (row) => row.category?.name ?? '-',
    },
    {
      name: (
        <select
          className="form-select"
          value={filters.daily}
          onChange={(e) => handleFilter('daily', e.target.value)}
          data-filtered={!!filters.daily}
        >
          <CustomOption value="">Daily</CustomOption>
          {[{ label: 'Is Daily', value: 1 }, { label: 'Not Daily', value: 0 }].map((daily, index) => (
            <CustomOption key={index} value={daily.value}>{daily.label}</CustomOption>
          ))}
        </select>
      ),
      selector: (row) => <StatusToggle type="products" id={row.id} field="daily" status={row.daily} />,
    },
    {
      name: <CustomTableHeading>Total Quantity</CustomTableHeading>,
      selector: (row) => row.total_qty ?? '-',
    },
    {
      name: t({
        control: 'Control',
        'default-daily': 'Default Daily',
        'add-quantity': 'Add Quantity',
        'extract-quantity': 'Waste Quantity',
      }[type]),
      selector: row => (
        <CustomInput
            type="number" 
            step="any"
            value={products.find(product => product.id === row.id)?.qty ?? ''}
            onChange={(e) => handleProductChange(row.id, 'qty', e.target.value)}
        />
      )
    }
  ];

  useEffect(() => {
    if (data.list?.length && type === 'default-daily') {
      setProducts(data.list.filter(product => product.default_daily_qty).map(product => ({
        id: product.id,
        qty: product.default_daily_qty
      })))
    }
  }, [data])

  return (
    <div>
      <Card className="mt-3">
        <DataTableContainer
            data={data.list ?? []}
            paginationTotalRows={data.total}
            setPageSize={setPageSize}
            paginationServer
            loading={isLoading}
            navigateToPage={navigateToPage}
            columns={columns}
        />
      </Card>
      <div className="d-flex justify-content-end">
        <CustomButton 
          className="btn btn-success mt-2"
          type="button"
          onClick={submit}
          isLoading={submitting}
          disabled={!products.length}
        >
          Save
        </CustomButton>
      </div>
    </div>
  );
};

export default ProductControlTable;
