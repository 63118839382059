import React, { useState } from "react";
import {
  Card,
  CardHeader,
  Nav,
  NavLink,
  NavItem,
  TabContent,
  TabPane,
  CardFooter,
  CardBody,
} from "reactstrap";
import PageContainer from "../../Components/Layout/PageContainer";
import classnames from "classnames";
import CustomButton from "../../Components/Custom/CustomButton";
import OrderTab from "../../Components/Orders/SmsSettings/OrderTab";
import DeliveryTab from "../../Components/Orders/SmsSettings/DeliveryTab";
import ActiveMobileTab from "../../Components/Orders/SmsSettings/ActiveMobileTab";
import AdminTab from "../../Components/Orders/SmsSettings/AdminTab";
import CancelTab from "../../Components/Orders/SmsSettings/CancelTab";
import EmailTab from "../../Components/Orders/SmsSettings/EmailTab";
import useActions from "../../hooks/useActions";
import toastAlert from "../../helpers/toastAlert";
import { useQuery } from "@tanstack/react-query";
import {
  getSmsSettingsApi,
  setSmsSettingsApi,
} from "../../apis/Orders/smsSettingsApis";
import { useTranslation } from "react-i18next";
import ReadyTab from "../../Components/Orders/SmsSettings/ReadyTab";
import AcceptedTab from "../../Components/Orders/SmsSettings/AcceptedTab";
import SmsSettingsTab from "../../Components/Orders/SmsSettings/SmsSettingsTab";
import SmsHistoryTab from "../../Components/Orders/SmsSettings/HistoryTab";

const SmsSettings = () => {

  const [activeTab, setActiveTab] = useState(1);

  const { t } = useTranslation();

  const { isLoading: isSubmitting, onLoading, onFinally } = useActions();

  const [values, setValues] = useState({
    order: {
      order_send_sms: false,
      order_sms_text: "",
      order_sms_keywords: [],
    },
    delivery: {
      delivery_send_sms: false,
      delivery_sms_text: "",
      delivery_sms_keywords: [],
    },
    accepted: {
      accepted_send_sms: false,
      accepted_sms_text: "",
      accepted_sms_keywords: [],
    },
    ready: {
      ready_send_sms: false,
      ready_sms_text: "",
      ready_sms_keywords: [],
    },
    activeMobile: {
      active_mobile_sms: "",
      active_mobile_keywords: [],
    },
    admin: {
      admin_send_sms: false,
      admin_sms_text: "",
      admin_sms_keywords: [],
      admin_mobile: "",
    },
    cancel: {
      cancel_send_sms: false,
      cancel_send_text: "",
      cancel_keywords: "",
    },
    settings: {
      sms_sender: '',
      sms_service_id: '',
      sms_service_key: '',
      sms_service_username: '',
      sms_service_password: '',
    },
    email: "",
  });

  const { isLoading } = useQuery(["order-sms-settings"], getSmsSettingsApi, {
    onSuccess({ data: { data }}) {
      setValues(prev => ({
        ...prev,
        ...data
      }));
    },
  });

  const handleChange = (parent) => (e) => {
    const isBoolean = e.target.type === "checkbox";
    setValues((current) => {
      return {
        ...current,
        [parent]: {
          ...current[parent],
          [e.target.name]: isBoolean ? e.target.checked : e.target.value,
        },
      };
    });
  };

  const handleChangeText = (field, value) => {
    setValues((current) => {
      return {
        ...current,
        [field]: value,
      };
    });
  };

  const toggleActiveTab = (tab) => {
    setActiveTab(tab);
  };

  const onSubmit = async () => {
    onLoading();
    try {
      await setSmsSettingsApi(values);
      toastAlert({ type: 'success' });
    } catch (error) {
      toastAlert({ type: 'error' });
    } finally {
      onFinally();
    }
  };

  const TABS = [
    {
      name: "New order",
      children: <OrderTab values={values.order} handleChange={handleChange} />,
    },
    {
      name: "Delivery",
      children: (
        <DeliveryTab values={values.delivery} handleChange={handleChange} />
      ),
    },
    {
      name: "Accepted",
      children: (
        <AcceptedTab values={values.accepted} handleChange={handleChange} />
      ),
    },
    {
      name: "Ready",
      children: <ReadyTab values={values.ready} handleChange={handleChange} />,
    },
    {
      name: "Active mobile",
      children: (
        <ActiveMobileTab
          values={values.activeMobile}
          handleChange={handleChange}
        />
      ),
    },
    {
      name: "Admin",
      children: <AdminTab values={values.admin} handleChange={handleChange} />,
    },
    {
      name: "Cancel",
      children: (
        <CancelTab values={values.cancel} handleChange={handleChange} />
      ),
    },
    {
      name: "Email",
      children: (
        <EmailTab value={values.email} handleChange={(e) => handleChangeText('email', e.target.value)} />
      ),
    },
    {
      name: "Settings",
      children: (
        <SmsSettingsTab value={values.settings} handleChange={handleChange} />
      ),
    },
    {
      name: "History",
      children: (
        <SmsHistoryTab isActive={activeTab === 10} />
      ),
    },
  ];

  return (
    <PageContainer isLoading={isLoading}>
      <Card className="mt-4">
        <CardHeader>
          <Nav className="nav-tabs-custom card-header-tabs border-bottom-0">
            {TABS.map((tab, index) => {
              const i = index + 1;
              return (
                <NavItem key={tab.name}>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: activeTab === i,
                    })}
                    onClick={() => {
                      toggleActiveTab(i);
                    }}
                  >
                    {t(tab.name)}
                  </NavLink>
                </NavItem>
              );
            })}
          </Nav>
        </CardHeader>
        <CardBody>
          <TabContent activeTab={activeTab}>
            {TABS.map((tab, index) => {
              const i = index + 1;
              return (
                <TabPane key={tab.name} tabId={i}>
                  {tab.children}
                </TabPane>
              );
            })}
          </TabContent>
        </CardBody>
        <CardFooter>
          <CustomButton
            color="success"
            onClick={onSubmit}
            isLoading={isSubmitting}
          >
            Save
          </CustomButton>
        </CardFooter>
      </Card>
    </PageContainer>
  );
};

export default SmsSettings;
