import React from 'react'
import BreadCrumb from '../../../Components/Common/BreadCrumb'
import PageContainer from '../../../Components/Layout/PageContainer'
import InventoryOrderForm from '../../../Components/Inventory/Orders/Form'

const CreateInventoryOrder = () => {
    
  return (
    <PageContainer>
      <BreadCrumb pageTitle="Orders" title="Create" to="inventory/orders"/>
      <InventoryOrderForm 
        type="create"
      />
    </PageContainer>
  )
}

export default CreateInventoryOrder