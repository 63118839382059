import React, { useEffect } from 'react'
import { Col, Row } from 'reactstrap'
import CustomInput from '../../../Custom/CustomInput'
import CustomLabel from '../../../Custom/CustomLabel'

const Prices = ({register, unregister}) => {

    useEffect(() => {
        return () => {
            unregister('price')
            unregister('price_in')
        }
    }, [])

  return (
    <Row className='mt-3'>
        <Col md={6}>
            <CustomLabel>
                Price
            </CustomLabel>
            <CustomInput
                type="number"
                {...register('price')}
            />
        </Col>

        <Col md={6}>
            <CustomLabel>
                Price in
            </CustomLabel>
            <CustomInput 
                type="number"
                {...register('price_in')}
            />
        </Col>
    </Row>
  )
}

export default Prices