import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { Card, Col, Row } from 'reactstrap'
import toastAlert from '../../../helpers/toastAlert'
import useActions from '../../../hooks/useActions'
import { getPathname } from '../../../Layouts/LayoutMenuData'
import CustomButton from '../../Custom/CustomButton'
import CustomInput from '../../Custom/CustomInput'
import CustomLabel from '../../Custom/CustomLabel'
import { createShelf, updateShelf } from '../../../apis/Orders/shelvesApi'

const ShelfForm = ({ type, initialValues = null }) => {

    const {register, handleSubmit, reset}  = useForm();

    const {isLoading, onLoading, onFinally, onError} = useActions();

    const history = useHistory();

    const submitForm = async (data) => {
        onLoading();
        try {
            const requestBody = data;
            if (type === 'create') {
                await createShelf(requestBody);
                toastAlert({ type: 'success' });
                history.push(getPathname('orders/shelves'))
            } else {
                await updateShelf(initialValues?.id, requestBody);
                toastAlert({ type: 'success' });
            }
        } catch (error) {
            toastAlert({ type: 'error' });
            onError();
        } finally {
            onFinally();
        }
    }

    useEffect(() => {
        if (initialValues) reset(initialValues);
    }, [initialValues])

  return (

    <form onSubmit={handleSubmit(submitForm)}>
        <Row>
            <Col lg={12}>
                <Card className='p-3'>

                    <Row className='gy-4'>

                        <Col lg={6}>
                            <CustomLabel>
                                Name
                            </CustomLabel>
                            <CustomInput 
                                type="text"
                                {...register('name')}
                            />
                        </Col>

                        <Col lg={6}>
                            <CustomLabel>
                                Side
                            </CustomLabel>
                            <CustomInput 
                                type="text"
                                {...register('side')}
                            />
                        </Col>

                    </Row>

                    <Row className='mt-3'>
                        <Col className='d-flex gap-2'>
                            <CustomButton
                                className="btn-success text-capitalize"
                                isLoading={isLoading}
                            >
                                {type}
                            </CustomButton>
                        </Col>
                    </Row>
                </Card>
            </Col>
        </Row>
    </form>
  )
}

export default ShelfForm