import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { Card } from 'reactstrap'
import BreadCrumb from '../../../Components/Common/BreadCrumb'
import FoodMenuTable from '../../../Components/Food/Menu/Table'
import PageContainer from '../../../Components/Layout/PageContainer'
import useDeleteModal from '../../../hooks/useDeleteModal'
import usePagination from '../../../hooks/usePagination'
import { getPathname } from '../../../Layouts/LayoutMenuData'
import { deleteFoodAction, getFoodDataAction } from '../../../redux/actions/foodActions';
import debounce from "lodash.debounce";
import { useLocalStorage } from '../../../hooks/useLocalStorage'
import { objectFilter } from '../../../helpers/objectFilter'
import Restricted from '../../../Components/Guard/Restricted'
import CreateButton from '../../../Components/Common/Buttons/CreateButton'
import DeleteButton from '../../../Components/Common/Buttons/DeleteButton'
import CustomButton from '../../../Components/Custom/CustomButton'
import useModal from '../../../hooks/useModal'
import ComponentModal from '../../../Components/Food/Menu/ComponentModal'
import AttachBranchesModal from '../../../Components/Food/AttachBranches'
import CloneButton from '../../../Components/Common/Buttons/CloneButton'

const FoodMenu = () => {

  const { currentPage, navigateToPage } = usePagination();

  const {selectedItems, setSelectedItems, load, isLoading, modal, setModal, callback, setIsLoading} = useDeleteModal();

  const { toggleModal: toggleComponentsModal, isModalOpen: isComponentsModalOpen } = useModal();

  const { toggleModal: toggleBranchesModal, isModalOpen: isBranchesModalOpen } = useModal();

  const [name, setName] = useState(null);

  const [number, setNumber] = useState(null);

  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch();

  const {values: { foodsFilter: localFilterValues }, setNewValues: setLocalFilterValues} = useLocalStorage({
      foodsFilter: {
        type_id: '',
        category_id: '',
        branche_id: '',
        active: '',
        restaurent: '',
        takeaway: '',
        page_size: 10
      }
  })

  const getFoods = (byName) => {
    if (byName) navigateToPage(1);
    setLoading(true);
    dispatch(getFoodDataAction({
      field: 'menu',
      params: objectFilter({
        page: byName ? 1 : currentPage,
        ...localFilterValues,
        name,
        number
      })
    }, {
      onSuccess() {
        setLoading(false);
      },
      onError(error) {
        console.log('error', error);
        setLoading(false);
      }
    }))
  }

  const clearFilters = () => {
    setLocalFilterValues({
        foodsFilter: {
          type_id: '',
          category_id: '',
          active: '',
          restaurent: '',
          takeaway: '',
        }
    })
  }

  const handleDelete = () => {
    load();
    const ids = selectedItems.map(item => item.id);
    dispatch(deleteFoodAction({
      field: 'menu',
      data: {
        ids
      }
    }, {
      onSuccess() {
        getFoods();
        callback();
      },
      onError() {
        setIsLoading(false);
      }
    }))
  }

  const setPageSize = (val) => {
    setLocalFilterValues({
        foodsFilter: {
          page_size: val
        }
    })
  }

  const debounceSearch = useMemo(() => {
    return debounce(() => getFoods(true), 200);
  }, [localFilterValues, currentPage, name, number]);

  useEffect(() => {
    getFoods();
  }, [localFilterValues, currentPage])

  useEffect(() => {
    if (name !== null) debounceSearch();
  }, [name])

  useEffect(() => {
    if (number !== null) debounceSearch();
  }, [number])

  return (
    <PageContainer>

      <BreadCrumb pageTitle="Food" title="Food Menu"/>

      <div className='hstack gap-2 gy-3'>

        <Restricted allowed={['super-admin']}>

          <Link to={getPathname('food/menu/create')}>
            <CreateButton />
          </Link>
          
        </Restricted>
        
        <Restricted allowed={['super-admin']}>
          <CloneButton 
            refetch={getFoods}
            selectedItems={selectedItems}
          />
        </Restricted>

        <Restricted allowed={['super-admin']}>

          <ComponentModal 
              isOpen={isComponentsModalOpen}
              toggleModal={toggleComponentsModal}
              foodTypes={Array.from(new Set(selectedItems.map(item => item.type_id)))}
              selectedItems={selectedItems.map(item => item.id)}
              setSelectedItems={setSelectedItems}
          />

          <CustomButton 
            onClick={toggleComponentsModal}
            disabled={!selectedItems.length}
          >
            Add Component
          </CustomButton>
        </Restricted>

        <Restricted allowed={['super-admin']}>

          <AttachBranchesModal 
              isOpen={isBranchesModalOpen}
              toggle={toggleBranchesModal}
              selectedItems={selectedItems.map(item => item.id)}
              setSelectedItems={setSelectedItems}
              route='attachFoods'
          />

          <CustomButton 
            className="btn-dark"
            onClick={toggleBranchesModal}
            disabled={!selectedItems.length}
          >
            Add Branches
          </CustomButton>
          
        </Restricted>

        <Restricted allowed={['super-admin']}>
          <DeleteButton 
            disabled={!selectedItems.length}
            onClick={() => setModal(true)}
          />
        </Restricted>

      </div>


        <Card className="mt-3">
          <FoodMenuTable 
              loading={loading}
              navigateToPage={navigateToPage}
              pageSize={localFilterValues.page_size}
              setPageSize={setPageSize}
              setSelectedItems={setSelectedItems}
              modal={modal}
              setModal={setModal}
              isLoading={isLoading}
              handleDelete={handleDelete}
              filters={localFilterValues}
              setFilters={setLocalFilterValues}
              clearFilters={clearFilters}
              name={name}
              setName={setName}
              number={number}
              setNumber={setNumber}
          />
        </Card>

    </PageContainer>
  )
}

export default FoodMenu