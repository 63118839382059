import React, { useEffect, useState } from 'react';
import { Nav, NavItem, Row, TabContent, TabPane } from 'reactstrap';
import { foodFormTabs } from '../../../../data/foodFormTabs';
import CustomNavLink from '../../../Custom/CustomNavLink';
import classnames from "classnames";
import Components from './TabContents/Components/Components';
import Products from './TabContents/Products/Products';
import OptionsAlergy from './TabContents/OptionsAllergy/OptionsAlergy';
import Entrees from './TabContents/Entrees/Entrees';
import Price from './TabContents/Price/Price';
import PizzaList from './TabContents/PizzaList/PizzaList';
import Sizes from './TabContents/Sizes/Sizes';
import DateTime from './TabContents/DateTime/DateTime';
import Inventory from './TabContents/Inventory/Inventory';

const ComponentTabs = ({initialValues, foodType, setValue, register, watch}) => {

  const [tabs, setTabs] = useState(null)

  const [activeTab, setactiveTab] = useState("1");

  const toggle = (tab) => {
    if (activeTab !== tab) {
      setactiveTab(tab);
    }
  };

  useEffect(() => {
    
      if (foodType) {
        const tabs = foodFormTabs.find(tab => tab.id === foodType)?.tabs;
        if (tabs) {
            setTabs(tabs);
            setactiveTab(tabs[0].value)
        } else {
            setTabs(null);
            setactiveTab("1");
        }
      }

      return () => {
        setValue('entries', null);
        setValue('components', null);
        setValue('multi', null);
        setValue('halves', null);
        setValue('options', null);
        setValue('allergies', null);
        setValue('prices', null);
        setValue('recipes', null);
      }

  }, [foodType])

  return (
    <div>
         <Row>
            <Nav tabs className="nav-tabs mb-3">
                {tabs && tabs.map((tab, index) => (
                    <NavItem key={index}>
                        <CustomNavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({ active: activeTab === tab.value })}
                            onClick={() => {
                            toggle(tab.value);
                            }}
                        >
                            {tab.label}
                        </CustomNavLink>
                    </NavItem>
                ))}
            </Nav>

            <TabContent activeTab={activeTab} className="text-muted">

                <TabPane tabId="components">
                    <Components 
                        initialValues={initialValues}
                        foodType={foodType}
                        setValue={setValue}
                        register={register}
                        watch={watch}
                    />
                </TabPane>

                <TabPane tabId="entrees">
                    <Entrees 
                        initialValues={initialValues}
                        foodType={foodType}
                        setValue={setValue}
                    />
                </TabPane>

                <TabPane tabId="optionsAllergy">
                    <OptionsAlergy 
                        initialValues={initialValues}
                        foodType={foodType}
                        setValue={setValue}
                    />
                </TabPane>

                <TabPane tabId="price">
                    <Price 
                        initialValues={initialValues}
                        foodType={foodType}
                        register={register}
                        setValue={setValue}
                    />
                </TabPane>    

                <TabPane tabId="products">
                    <Products
                        initialValues={initialValues}
                        foodType={foodType}
                        setValue={setValue} 
                    />
                </TabPane>

                <TabPane tabId="pizzaList">
                    <PizzaList 
                        initialValues={initialValues}
                        foodType={foodType}
                        setValue={setValue} 
                        register={register}
                        watch={watch}
                    />
                </TabPane>

                <TabPane tabId="sizes">
                    <Sizes 
                        foodType={foodType}
                        initialValues={initialValues}
                        register={register}
                        setValue={setValue} 
                        watch={watch}
                    />
                </TabPane>

                
                <TabPane tabId="dateTime">
                    <DateTime 
                        foodType={foodType}
                        initialValues={initialValues}
                        setValue={setValue} 
                    />
                </TabPane>
                       
                <TabPane tabId="inventory">
                    <Inventory 
                        foodType={foodType}
                        initialValues={initialValues}
                        setValue={setValue} 
                        watch={watch}
                    />
                </TabPane>

            </TabContent>

          </Row>
    </div>
  )
}

export default ComponentTabs