import React from "react";
import { Col, Row } from "reactstrap";
import generateInterval from "../../../data/generateInterval";
import CustomInput from "../../Custom/CustomInput";
import CustomLabel from "../../Custom/CustomLabel";
import CustomTextArea from "../../Custom/CustomTextArea";
import OrderCloseDays from "./OrderCloseDays";
import { useTranslation } from "react-i18next";
import SelectFile from "../../Common/SelectFile";

const OrderCloseTab = ({ openingHours, setOpeningHours, register, closeImg, setCloseImg }) => {

  const { t } = useTranslation();

  const list = generateInterval({});

  return (
   <>
      <Row className="gy-3">

        <Col>
          <div className="form-check form-switch">
            <input
              name="active"
              className="form-check-input fs-16"
              type="checkbox"
              role="switch"
              {...register("meny_close_cart")}
            />
            <CustomLabel>Close all websites</CustomLabel>
          </div>
        </Col>

        <Col>
          <div className="form-check form-switch">
            <input
              name="active"
              className="form-check-input fs-16"
              type="checkbox"
              role="switch"
              {...register("open_order")}
            />
            <CustomLabel>Open Order</CustomLabel>
          </div>
        </Col>

        <Col>
          <div className="form-check form-switch">
            <input
              name="active"
              className="form-check-input fs-16"
              type="checkbox"
              role="switch"
              {...register("select_order_time")}
            />
            <CustomLabel>Open select order time</CustomLabel>
          </div>
        </Col>

        <Col>
          <div className="form-check form-switch">
            <input
              name="active"
              className="form-check-input fs-16"
              type="checkbox"
              role="switch"
              {...register("qr_code_order")}
            />
            <CustomLabel>Active QR Code</CustomLabel>
          </div>
        </Col>
              
        <Col>
          <div className="form-check form-switch">
            <input
              name="active"
              className="form-check-input fs-16"
              type="checkbox"
              role="switch"
              {...register("view_only")}
            />
            <CustomLabel className="text-danger">Make website view only</CustomLabel>
          </div>
        </Col>

      </Row>

      <Row className="mt-2 gy-3">

          <Col md={6}>
            <CustomLabel>Order to day</CustomLabel>
            <CustomInput
              type="number"
              {...register("order_to_day")}
            />
            <div className="mt-2">0 = {t('Current day only')}</div>
          </Col>

          <Col md={6}>
            <CustomLabel>Interval</CustomLabel>
            <select className="form-select" {...register("interval")}>
              <option value=""></option>
              {list.map((number) => {
                return (
                  <option key={number} value={number}>
                    {number}
                  </option>
                );
              })}
            </select>
          </Col>

          <Col md={6}>
            <CustomLabel translatable>Close message</CustomLabel>
            <CustomTextArea rows={2} {...register("close_message")} />
          </Col>

          {
            !!closeImg && 

            <Col>
              <CustomLabel>Close Image</CustomLabel>
              <SelectFile 
                  selectedFiles={closeImg}
                  setSelectedFiles={setCloseImg}
                  type='image'
              />
              {!!closeImg.url &&
                  <div className="form-check form-switch mt-3">
                      <input 
                          {...register('is_close_image_transparent')} 
                          type="checkbox" 
                          className="form-check-input fs-16" 
                          role="switch" 
                          id="is_close_image_transparent" 
                          disabled={!closeImg.url} 
                      />
                      <CustomLabel className="form-check-label" htmlFor="is_close_image_transparent">Is transparent</CustomLabel>
                  </div>
              }
            </Col>
          }

          <Col md={6}>
            <CustomLabel translatable>Close order alert</CustomLabel>
            <CustomTextArea rows={2} {...register("close_order_alert")} />
          </Col>

          <Col md={6}>
            <CustomLabel>Close order alert time</CustomLabel>
            <CustomTextArea rows={2} {...register("close_order_alert_time")} />
          </Col>

          <Col md={6}>
            <CustomLabel>Order cancel time</CustomLabel>
            <CustomInput type="number" {...register("order_cancel_time")} />
          </Col>

          <Col xs={12}>
            <CustomLabel className="mb-4">Opening hours</CustomLabel>
            <OrderCloseDays
              openingHours={openingHours}
              setOpeningHours={setOpeningHours}
            />
          </Col>
      </Row>
   </>
  );
};

export default OrderCloseTab;
