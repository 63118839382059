import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Card, CardBody, CardFooter, Col, Row } from "reactstrap";
import SelectFile from "../../Common/SelectFile";
import CustomButton from "../../Custom/CustomButton";
import CustomInput from "../../Custom/CustomInput";
import CustomLabel from "../../Custom/CustomLabel";
import { createUserApi, updateUser } from "../../../apis/users/usersApi";
import formatFormData from "../../../helpers/formatFormData";
import { useHistory } from "react-router-dom";
import { getPathname } from "../../../Layouts/LayoutMenuData";
import { setFormErrors } from "../../../helpers/setFormErrors";
import { renderErrorAsObject } from "../../../helpers/renderErrors";
import extractError from "../../../helpers/extractError";
import { useGetGroups } from "../../../hooks/useQueryHelpers";
import useActions from "../../../hooks/useActions";
import toastAlert from "../../../helpers/toastAlert";
import { errorMessage } from "../../../helpers/errorMessage";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  createUserSchema,
  updateUserSchema,
} from "../../../schemas/userSchemas";
import PhoneInput from "../../Common/PhoneInput";
import { formatExample } from "../../../helpers/formatExample";

const UserForm = ({ type, initValue }) => {
  
  const { push } = useHistory();
  const { groups } = useGetGroups();

  const { onLoading, isLoading, onFinally } = useActions();

  const {
    register,
    handleSubmit,
    setError,
    setValue,
    watch,
    clearErrors,
    control,
    formState: { errors },
    getValues,
  } = useForm({
    resolver: yupResolver(
      type === "Update" ? updateUserSchema : createUserSchema
    ),
    defaultValues:
      type === "Update"
        ? {
            name: initValue.name,
            phone: initValue.phone,
            email: initValue.email,
            groups: initValue.groups.map((group) => group.id),
            username: initValue.username || "",
          }
        : {
            groups: [],
            send_invitation: false,
          },
  });

  const [avatar, setAvatar] = useState(
    type === "Update"
      ? {
          url: initValue.image?.link,
          file: null,
        }
      : {
          url: "",
          file: null,
        }
  );

  useEffect(() => {
    clearErrors("image");
  }, [avatar]);

  const existingGroups = watch("groups");

  const onGroupsChange = (groupID) => {
    let existingGroups = watch("groups");
    if (existingGroups.includes(groupID)) {
      setValue(
        "groups",
        existingGroups.filter((group) => group !== groupID)
      );
    } else {
      setValue("groups", [...existingGroups, groupID]);
    }
  };

  const onSubmit = async (data) => {
    clearErrors();
    onLoading();
    let rest = {};
    if (avatar.file) {
      rest.image = avatar.file;
    }
    const body = formatFormData({ ...data, ...rest });
    try {
      if (type === "Create") {
        await createUserApi(body);
        push(getPathname("users"));
      } else {
        const { data } = await updateUser(initValue.id, body);
        const user = data.data;
        if (user.image) {
          setAvatar({
            file: null,
            url: user.image?.link,
          });
        }
        toastAlert({ type: 'success' });
        onFinally();
      }
    } catch (error) {
      onFinally();
      setFormErrors(renderErrorAsObject(extractError(error)), setError);
    }
  };

  useEffect(() => {
    // console.log({ initValue });
  }, []);

  return (
    <React.Fragment>
      <Row>
        <Col lg={3}>
          <Card className="p-3">
            <CustomLabel>Avatar</CustomLabel>
            <SelectFile
              type="image"
              max={1}
              display
              selectedFiles={avatar}
              setSelectedFiles={setAvatar}
            />
            {errorMessage(errors, "image")}
          </Card>
        </Col>
        <Col lg={9}>
          <Card>
            <CardBody>
              <Row className="gy-2">
                <Col md={6}>
                  <CustomLabel>Name</CustomLabel>
                  <CustomInput
                    {...register("name")}
                    placeholder={formatExample("Jhon Smith")}
                  />
                  {errorMessage(errors, "name")}
                </Col>
                <Col md={6}>
                  <CustomLabel>Username</CustomLabel>
                  <CustomInput
                    {...register("username")}
                    placeholder={formatExample("Jhon_Smith")}
                  />
                  {errorMessage(errors, "username")}
                </Col>
                <Col md={6}>
                  <CustomLabel>Email</CustomLabel>
                  <CustomInput
                    {...register("email")}
                    placeholder={formatExample("jhonsmith@gmail.com")}
                  />
                  {errorMessage(errors, "email")}
                </Col>
                <Col md={6}>
                  <CustomLabel>Phone</CustomLabel>
                  <Controller
                    control={control}
                    name="phone"
                    render={({ name, field: { onChange } }) => (
                      <PhoneInput
                        name={name}
                        country={"us"}
                        onChange={(val) => onChange(val)}
                        value={getValues("phone") || ""}
                      />
                    )}
                  />
                  {errorMessage(errors, "phone")}
                </Col>
                <Col md={12}>
                  {type === "Create" && (
                    <>
                      <CustomInput
                        type="checkbox"
                        className="productCheckBox form-check-input me-2"
                        id="sendInvitationByEmail"
                        {...register("send_invitation")}
                      />
                      <CustomLabel htmlFor="sendInvitationByEmail">
                        Send email invitation
                      </CustomLabel>
                    </>
                  )}
                </Col>

                <Col md={6}>
                  <CustomLabel>Password</CustomLabel>
                  <CustomInput {...register("password")} />
                  {errorMessage(errors, "password")}
                </Col>
 
                <Col xs={6}>
                  <CustomLabel>Groups</CustomLabel>
                  {groups.map((group) => {
                    return (
                      <div key={group.id}>
                        <CustomInput
                          type="checkbox"
                          className="productCheckBox form-check-input me-2"
                          onChange={() => onGroupsChange(group.id)}
                          checked={existingGroups.includes(group.id)}
                          id={`group-${group.id}`}
                        />
                        <CustomLabel htmlFor={`group-${group.id}`}>
                          {group.name}
                        </CustomLabel>
                      </div>
                    );
                  })}
                </Col>
              </Row>
            </CardBody>

            <CardFooter>
              <CustomButton
                isLoading={isLoading}
                color="success"
                onClick={handleSubmit(onSubmit)}
              >
                {type}
              </CustomButton>
            </CardFooter>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default UserForm;
