import React, { useEffect, useState } from 'react'
import AddFoodProductToComboSelect from './AddFoodProductToComboSelect';
import CustomLabel from '../../../../../Custom/CustomLabel';
import FoodProductsTable from './FoodProductsTable';
import generateUniqueID from '../../../../../../helpers/generateUniqueID';

const InventoryWithCombos = ({ initialValues, foodType, setValue, watch }) => {

    const [selectionType, setSelectionType] = useState('one');

    const [combos, setCombos] = useState([]); 

    const [selectedCombos, setSelectedCombos] = useState([]);
  
    const [products, setProducts] = useState([]);
  
    const prices = watch('prices');

    const selectAllCombos = () => setSelectedCombos(combos.map(combo => combo.id));

    const handleTypeSelection = (e) => {
        const selectedType = e.target.value;
        setSelectionType(selectedType);
        if (selectedType === 'all') {
          selectAllCombos();
        } else if (selectedType === 'one') {
          if (selectedCombos.length > 1) {
            setSelectedCombos(prev => [prev[0]]);
          }
        }
    }
  
    const handleComboSelection = (combo_id) => {
      if (selectionType === 'all') return;
      if (selectionType === 'one') {
        setSelectedCombos([combo_id]);
      } else if (selectionType === 'multiple') {
        if (selectedCombos.includes(combo_id)) {
          if (selectedCombos.length > 1) setSelectedCombos(prev => prev.filter(combo => combo !== combo_id));
        } else {
          setSelectedCombos(prev => [...prev, combo_id]);
        }
      }
    }
  
    const populateCombos = () => {
      if (foodType === 8) {
        if (initialValues?.entrees_sizes) {
          setCombos(prev => initialValues.entrees_sizes?.map((entree_size, index) => ({
            id: prev.find(combo => combo.entry_size_id === entree_size.id)?.id ?? generateUniqueID(),
            entry_size_id: entree_size.id,
            size: entree_size.size,
          })) ?? prev)
        }
      } else if (foodType !== 8) {
        setCombos(prev => prices?.reduce((accum, bread) => {
          return [...accum, ...bread.sizes.map((size, index) => ({
            id: prev.find(combo => combo.bread_id === bread.bread_id && combo.size_id === size.size_id)?.id ?? generateUniqueID(),
            bread_name: bread.name,
            bread_id: bread.bread_id,
            size_name: size.name,
            size_id: size.size_id,
          }))]
        }, []) ?? prev)
      }
    }

    const populateProducts = () => {
      if (combos.length) {
        if (foodType === 8) {
          setProducts(products => {
            const pertainingProducts = products.filter(product => combos.some(combo => combo.id === product.combo_id));
            return [...pertainingProducts, ...(initialValues?.recipes?.filter(recipe => {
              const productCombo = combos.find(combo => combo.entry_size_id === recipe.entry_size_id);
              const productNotPertained = pertainingProducts.every(product => product.combo_id !== productCombo?.id);
              return !!productCombo && productNotPertained;
            }).map(recipe => ({
              combo_id: combos.find(combo => combo.entry_size_id === recipe.entry_size_id)?.id,
              id: recipe.product?.id,
              name: recipe.product?.name,
              image: recipe.product?.image,
              qty: recipe.qty,
            })) ?? [])]
          })
        } else  {
          setProducts(products => {
            const pertainingProducts = products.filter(product => combos.some(combo => combo.id === product.combo_id));
            return [...pertainingProducts, ...(initialValues?.recipes?.filter(recipe => {
              const productCombo = combos.find(combo => combo.bread_id === recipe.bread_id && combo.size_id === recipe.size_id);
              const productNotPertained = pertainingProducts.every(product => product.combo_id !== productCombo?.id);
              return !!productCombo && productNotPertained;
            }).map(recipe => ({
              combo_id: combos.find(combo => combo.bread_id === recipe.bread_id && combo.size_id === recipe.size_id)?.id,
              id: recipe.product?.id,
              name: recipe.product?.name,
              image: recipe.product?.image,
              qty: recipe.qty,
              extra_qty: recipe.extra_qty,
              unit: recipe.product?.unit?.name,
              items: recipe.component_items?.map(item => ({
                id: item.id,
                name: item.name,
              })) ?? null
            })) ?? [])]
          })
        }
      } else {
        setProducts([]);
      }
    } 

    const setProductsToForm = () => {
      setValue('recipes', products.filter(product => combos.some(combo => combo.id === product.combo_id)).map(product => {
        const { id, bread_name, size_name, size, ...rest } = combos.find(combo => combo.id === product.combo_id);
        return {
          ...rest,
          product_id: product.id,
          qty: product.qty,
          extra_qty: product.extra_qty,
          component_items_id: product.items?.map(item => item.id)
        }
      }))
    }

    useEffect(() => {
        populateCombos();
    }, [foodType, prices])
   
    useEffect(() => {
        populateProducts();
        setSelectedCombos(combos?.length ? [combos[0].id] : [])
    }, [combos])
  
    useEffect(() => {
        setProductsToForm();
    }, [products])

  return (
    <div>
        <div className='d-flex gap-3 justify-content-between'>
          <AddFoodProductToComboSelect 
              combos={combos}
              products={products}
              selectedCombos={selectedCombos}
              setProducts={setProducts}
              foodType={foodType}
              isDisabled={!selectedCombos.length}
          />
          <div 
              className='hstack gap-4' 
              style={{ whiteSpace: 'nowrap' }}
          >
              {['one', 'multiple', 'all'].map(type => (
                  <div key={type} className="form-check">
                      <input 
                        value={type} 
                        checked={selectionType === type} 
                        className="form-check-input" 
                        type="radio" 
                        id={`select-${type}`} 
                        name="selectionType" 
                        onChange={handleTypeSelection} 
                      />
                      <CustomLabel className="form-check-label capitalize" htmlFor={`select-${type}`}>Select {type}</CustomLabel>
                  </div>
              ))}
          </div>
        </div>

        <div className='mt-3 d-flex gap-2 flex-wrap'>
          {combos?.map(combo => (
              <div 
                key={combo.id} 
                className={`p-2 border cursor-pointer user-select-none ${selectedCombos.includes(combo.id) ? 'bg-success text-white' : ''}`} 
                onClick={() => handleComboSelection(combo.id)}
              >
                {(combo.bread_name && combo.size_name) ? `Bread ${combo.bread_name} Size ${combo.size_name}` : combo.size}
              </div>
          ))}
        </div>

        {
          !!combos.length &&
            <FoodProductsTable 
                foodType={foodType}
                products={products}
                setProducts={setProducts}
                selectedCombos={selectedCombos}
                watch={watch}
                withCombos
            />
        }
    </div>
  )
}

export default InventoryWithCombos