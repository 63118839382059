import React, { useState } from "react";
import { Card, Col, Row } from "reactstrap";
import { deleteGalleriesApi } from "../../../apis/Settings/galleryApi";
import SortButton from "../../../Components/Common/Buttons/SortButton";
import ListHeader from "../../../Components/Common/ListHeader";
import PageContainer from "../../../Components/Layout/PageContainer";
import GalleriesTable from "../../../Components/Settings/Gallery/Table";
import toastAlert from "../../../helpers/toastAlert";
import useActions from "../../../hooks/useActions";
import useFilterData, { FILTER_TYPES } from "../../../hooks/useFilterData";
import useModal from "../../../hooks/useModal";
import { useGallery } from "../../../hooks/useQueryHelpers";
import useSelectItems from "../../../hooks/useSelectItems";

const Gallery = () => {

  const { isLoading, gallery, deleteGalleries } = useGallery();

  const {
    isLoading: isDeleting,
    onLoading: onDeleting,
    onFinally: onFinallyDeleting,
  } = useActions();

  const [query, setQuery] = useState("");

  const { selectItems, getSelectedIds, isDisable } = useSelectItems();

  const { isModalOpen, setIsModalOpen, openModal, closeModal } = useModal();

  const handleDelete = async () => {
    onDeleting();
    const ids = getSelectedIds();
    try {
      await deleteGalleriesApi(ids);
      selectItems([]);
      closeModal();
      deleteGalleries(ids);
    } catch (error) {
      toastAlert({ type: 'error' });
    } finally {
      onFinallyDeleting();
    }
  };

  const list = useFilterData(gallery, {
    name: {
      key: "name",
      type: FILTER_TYPES.MATCH,
      value: query,
    },
  });

  return (
    <PageContainer isLoading={isLoading}>
      <Row className="gy-4">
        <Col xs={12}>
          <ListHeader
            isDisable={isDisable}
            openModal={openModal}
            pathname="settings/gallery/create"
            query={query}
            setQuery={setQuery}
            afterChildren={<SortButton to="settings/gallery/sort" />}
          />
        </Col>
        <Col xs={12}>
          <Card>
            <GalleriesTable
              data={list}
              modal={isModalOpen}
              setModal={setIsModalOpen}
              selectItems={selectItems}
              handleDelete={handleDelete}
              isDeleting={isDeleting}
            />
          </Card>
        </Col>
      </Row>
    </PageContainer>
  );
};

export default Gallery;
