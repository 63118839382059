import React from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { Card, CardBody, CardFooter, Col, Row } from "reactstrap";
import { createGroupApi, updateGroupApi } from "../../../apis/users/groupsApis";
import extractError from "../../../helpers/extractError";
import { renderErrorAsObject } from "../../../helpers/renderErrors";
import { setFormErrors } from "../../../helpers/setFormErrors";
import toastAlert from "../../../helpers/toastAlert";
import useActions from "../../../hooks/useActions";
import { getPathname } from "../../../Layouts/LayoutMenuData";
import CustomButton from "../../Custom/CustomButton";
import CustomInput from "../../Custom/CustomInput";
import CustomLabel from "../../Custom/CustomLabel";
import CustomTextArea from "../../Custom/CustomTextArea";
import { yupResolver } from "@hookform/resolvers/yup";
import groupSchema from "../../../schemas/groupSchema";
import { errorMessage } from "../../../helpers/errorMessage";
import { formatExample } from "../../../helpers/formatExample";

const GroupsForm = ({ type, initValue }) => {
  const {
    register,
    handleSubmit,
    setValue,
    setError,
    formState: { errors },
  } = useForm({
    defaultValues:
      type === "Update"
        ? {
            name: initValue.name,
            code: initValue.code,
            description: initValue.description,
          }
        : {},
    resolver: yupResolver(groupSchema),
  });
  const { push } = useHistory();
  const { isLoading, onLoading, onFinally } = useActions();

  const onSubmit = async (data) => {
    onLoading();
    try {
      if (type === "Create") {
        await createGroupApi(data);
        push(getPathname("users/groups"));
      } else {
        await updateGroupApi(initValue.id, data);
        toastAlert({ type: 'success' });
        onFinally();
      }
    } catch (error) {
      onFinally();
      setFormErrors(renderErrorAsObject(extractError(error)), setError);
    }
  };
  return (
    <Card>
      <CardBody>
        <Row className="gy-2">
          <Col md={6}>
            <CustomLabel>Name</CustomLabel>
            <CustomInput
              {...register("name")}
              placeholder={formatExample("Client")}
            />
            {errorMessage(errors, "name")}
          </Col>
          <Col md={6}>
            <CustomLabel>Code</CustomLabel>
            <CustomInput
              {...register("code")}
              placeholder={formatExample("CL")}
            />
            {errorMessage(errors, "code")}
          </Col>
          <Col xs={12}>
            <CustomLabel>Description</CustomLabel>
            <CustomTextArea rows={3} {...register("description")} />
          </Col>
        </Row>
      </CardBody>
      <CardFooter>
        <CustomButton
          isLoading={isLoading}
          color="success"
          onClick={handleSubmit(onSubmit)}
        >
          {type}
        </CustomButton>
      </CardFooter>
    </Card>
  );
};

export default GroupsForm;
