import React from "react";
import CommonForm from "./CommonForm";

const CancelTab = ({ values, handleChange }) => {
  return (
    <CommonForm
      handleChange={handleChange}
      values={values}
      name="cancel"
      checkboxName="cancel_send_sms"
      inputName="cancel_send_text"
      keywordsName="cancel_keywords"
    />
  );
};

export default CancelTab;
