import React from 'react'
import SearchProduct from '../../../../../Common/SearchProduct';

const AddFoodProductToComboSelect = ({ combos, products, setProducts, isDisabled, selectedCombos }) => {

  const onProductSelected = (product) => {
    if (combos.length) {
      setProducts(prev => [...prev, ...combos.filter(combo => selectedCombos.includes(combo.id) && !products.some(prod => prod.combo_id === combo.id && prod.id === product.id)).map((combo) => ({
        combo_id: combo.id,
        id: product.id,
        name: product.name,
        image: product.image ?? product.menu_image ?? '',
        qty: 0,
        extra_qty: 0,
        unit: product.unit?.name
      }))])
    }
  }

  return (
    <div style={{ width: 'max(30%, 300px)'}}>
      <SearchProduct 
          onChange={onProductSelected}
          isDisabled={isDisabled}
      />
    </div>
  )
}

export default AddFoodProductToComboSelect