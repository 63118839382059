import React, { useState } from "react";
import { Col, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import classnames from "classnames";
import CouponDateTab from "./CouponDateTab";
import CouponProductsTab from "./CouponProductsTab";
import CouponUsersTab from "./CouponUsersTab";
import { useTranslation } from "react-i18next";

const CouponFormTabs = ({
  initialValues,
  register,
  control,
  watch,
  errors,
  setValue
}) => {
  
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState(1);

  const toggleActiveTab = (tab) => {
    setActiveTab(tab);
  };

  const TABS = [
    {
      name: "Date",
      children: (
        <CouponDateTab 
          initialValues={initialValues} 
          setValue={setValue} 
          register={register} 
          control={control} 
          errors={errors} 
        />
      ),
    },
    {
      name: "Products",
      children: (
        <CouponProductsTab
          register={register}
          initialValues={initialValues}
          setValue={setValue}
          watch={watch}
        />
      ),
    },
    {
      name: "Users",
      children: (
        <CouponUsersTab
          register={register}
          initialValues={initialValues}
          setValue={setValue}
          watch={watch}
        />
      ),
    },
  ];

  return (
    <Col xs={12} className="mt-4">
      <Nav tabs className="nav-tabs mb-3">
        {TABS.map((tab, index) => {
          const i = index + 1;
          return (
            <NavItem key={tab.name}>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classnames({ active: activeTab === i })}
                onClick={() => {
                  toggleActiveTab(i);
                }}
              >
                {t(tab.name)}
              </NavLink>
            </NavItem>
          );
        })}
      </Nav>

      <TabContent activeTab={activeTab} className="text-muted">
        {TABS.map((tab, index) => {
          const i = index + 1;

          return (
            <TabPane key={tab.name} tabId={i}>
              {tab.children}
            </TabPane>
          );
        })}
      </TabContent>
    </Col>
  );
};

export default CouponFormTabs;
