import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { Card, Col, Row } from "reactstrap";
import CustomButton from "../../Custom/CustomButton";
import CustomInput from "../../Custom/CustomInput";
import CustomLabel from "../../Custom/CustomLabel";
import useActions from "../../../hooks/useActions";
import { useHistory } from "react-router-dom";
import {
  addNewPageMenuApi,
  updatePageMenuApi,
} from "../../../apis/Settings/pageMenuApi";
import { getPathname } from "../../../Layouts/LayoutMenuData";
import toastAlert from "../../../helpers/toastAlert";
import { formatExample } from "../../../helpers/formatExample";
import SelectFile from "../../Common/SelectFile";
import formatFormData from "../../../helpers/formatFormData";
import TextEditor from "../../TextEditor";
import CustomTextArea from "../../Custom/CustomTextArea";
import SelectLanguage from "../../Common/SelectLanguage";

const PagesMenuForm = ({
  type,
  initialValues = {
    form: null,
    icon: {
      file: null,
      url: "",
    },
  },
  language,
  setLanguage
}) => {

  const { onLoading, isLoading, onFinally } = useActions();

  const { push } = useHistory();

  const { register, handleSubmit, control, reset } = useForm();

  const [icon, setIcon] = useState({
    file: null,
    url: "",
  });

  const submitForm = async (data) => {
    onLoading();
    let body = data;
    if (icon.file) {
      body.icon = icon.file;
    }
    body = formatFormData(body);
    try {
      if (type === "create") {
        await addNewPageMenuApi(body, language);
        push(getPathname("settings/pages-menu"));
      } else {
        const { data } = await updatePageMenuApi(initialValues.form.id, body, language);
        setIcon({
          file: null,
          url: data.data.icon || "",
          ...(data.data.file
            ? {
                id: data.data.file.id,
                name: data.data.file.name,
              }
            : {}),
        });
        toastAlert({ type: 'success' });
        onFinally();
      }
    } catch (error) {
      toastAlert({ type: 'error' });
      onFinally();
    }
  };

  useEffect(() => {
    if (initialValues?.form) {
        reset(initialValues?.form);

      if (initialValues.icon) {
        setIcon(initialValues.icon)
      }
    }
  }, [initialValues])

  return (
    <Card className="p-3">
      {type === 'update' && 
        <div className='d-flex justify-content-end'>
            <SelectLanguage
              language={language}
              onChange={lang => setLanguage(lang)}
            /> 
        </div>
      }
      <Row className="gy-4 mt-1">
        <Col md={6}>
          <CustomLabel translatable>Title</CustomLabel>
          <CustomInput
            type="text"
            {...register("title")}
            placeholder={formatExample("Categories")}
          />
        </Col>
        <Col md={6}>
          <CustomLabel>Class</CustomLabel>
          <CustomInput
            type="text"
            {...register("class")}
            placeholder={formatExample("Class")}
          />
        </Col>
        <Col md={6}>
          <CustomLabel translatable>Label</CustomLabel>
          <CustomInput
            type="text"
            {...register("label")}
            placeholder={formatExample("Label")}
          />
        </Col>
        <Col md={6}>
          <CustomLabel>Icon</CustomLabel>
          <SelectFile selectedFiles={icon} setSelectedFiles={setIcon} />
        </Col>
        <Col md={6}>
          <div className="form-check form-switch">
            <input
              {...register("active")}
              className="form-check-input fs-16"
              type="checkbox"
              role="switch"
              id="active"
            />
            <CustomLabel className="form-check-label" htmlFor="active">Active</CustomLabel>
          </div>
        </Col>

        <Col md={6}>
          <div className="form-check form-switch">
            <input
              {...register("top_nav")}
              className="form-check-input fs-16"
              type="checkbox"
              role="switch"
              id="top_nav"
            />
            <CustomLabel className="form-check-label" htmlFor="top_nav">Top nav</CustomLabel>
          </div>
        </Col>

        <Col md={6}>
          <div className="form-check form-switch">
            <input
              {...register("footer_nav")}
              className="form-check-input fs-16"
              type="checkbox"
              role="switch"
              id="footer_nav"
            />
            <CustomLabel className="form-check-label" htmlFor="footer_nav">Footer nav</CustomLabel>
          </div>
        </Col>

        <Col md={6}>
          <div className="form-check form-switch">
            <input
              {...register("same_page")}
              className="form-check-input fs-16"
              type="checkbox"
              role="switch"
              id="same_page"
            />
            <CustomLabel className="form-check-label" htmlFor="same_page">Same tab</CustomLabel>
          </div>
        </Col>

        <Col md={6}>
          <div className="form-check form-switch">
            <input
              {...register("text_page")}
              className="form-check-input fs-16"
              type="checkbox"
              role="switch"
              id="text_page"
            />
            <CustomLabel className="form-check-label" htmlFor="text_page">Text Page</CustomLabel>
          </div>
        </Col>

        <Col xs={12}>
          <CustomLabel translatable>Text</CustomLabel>

          <Controller
            name="text"
            control={control}
            render={({ name, field: { value, onChange } }) => (
              <TextEditor
                onChange={(value) => {
                  onChange(value);
                }}
                value={value}
              />
            )}
          />
        </Col>

        <Col md={6}>
          <CustomLabel>Seo Title</CustomLabel>
          <CustomInput {...register("seo_title")} />
        </Col>
        <Col md={6}>
          <CustomLabel>Seo Keywords</CustomLabel>
          <CustomInput {...register("seo_keywords")} />
        </Col>
        <Col xs={12}>
          <CustomLabel>Seo Description</CustomLabel>
          <CustomTextArea rows={3} {...register("seo_description")} />
        </Col>
      </Row>

      {/* <PagesMenuOptions register={register} /> */}

      <Row className="mt-5">
        <Col>
          <CustomButton
            isLoading={isLoading}
            onClick={handleSubmit(submitForm)}
            type="button"
            color="success"
          >
            {type === "create" ? "Create" : "Update"}
          </CustomButton>
        </Col>
      </Row>
    </Card>
  );
};

export default PagesMenuForm;
