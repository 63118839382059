import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody, CardHeader } from 'reactstrap';
import { getPathname } from '../../../Layouts/LayoutMenuData';
import defaultAvatar from '../../../assets/images/products/defaultFood.svg';
import { useTranslation } from 'react-i18next';

const TrendingMenus = ({data}) => {

    const [trending, setTrending] = useState(null);

    const { t } = useTranslation();

    useEffect(() => {
        if (data) {
          setTrending(data.top_foods)
        }
      }, [data])

    return (
        <React.Fragment>
                <Card>
                    <CardHeader className="align-items-center d-flex">
                        <h4 className="card-title mb-0 flex-grow-1">{t('Top Foods')}</h4>
                    </CardHeader>
                    <CardBody className="trending-menus-list p-0">
                        <ul className="list-group list-group-flush border-dashed mb-0">
                            {(trending || []).map((item, key) => (
                                <li className="list-group-item d-flex align-items-center gap-2" key={key}>

                                    <Link to={getPathname(`food/menu/update/${item.id}`)} target="_blank">
                                        <div className="flex-shrink-0" >
                                            <img src={item.image || defaultAvatar} className="avatar-xs rounded-circle" alt="" />
                                        </div>
                                    </Link>

                                    <div className="flex-grow-1">
                                        <Link to={getPathname(`food/menu/update/${item.id}`)} target="_blank">
                                            <h6 className="fs-14 mb-1 text-capitalize text-truncate" style={{maxWidth: '100px'}} title={item.name} >{item.name}</h6>
                                        </Link>
                                        <p className="text-muted mb-0 fs-13">{item.count} {t('Units Sold')}</p>
                                    </div>

                                    <div className="flex-shrink-0 text-end">
                                        <h6 className="fs-15 mb-1">#{key + 1}</h6>
                                    </div>

                                </li>
                            ))}
                        </ul>
                    </CardBody>
                </Card>
        </React.Fragment>
    );
};

export default TrendingMenus;