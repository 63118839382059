import React from 'react'
import CustomButton from '../Custom/CustomButton'

const SetupActions = ({ id, submit, skip, isLoading }) => {

    const handleSkip = () => {
        skip(id)
    }

  return (
    <div className='hstack gap-2 justify-content-end mt-3'>

        {skip && 
            <CustomButton
                type="button"
                className="btn btn-light"
                onClick={handleSkip}
            >
                Skip
            </CustomButton>
        }

        <CustomButton
            type="button"
            className="btn btn-success"
            onClick={submit}
            isLoading={isLoading}
        >
            Save
        </CustomButton>
 
    </div>
  )
}

export default SetupActions