import React, { useEffect, useState } from "react";
import { Col } from "reactstrap";
import SelectFile from "../../../Common/SelectFile";
import CustomLabel from "../../../Custom/CustomLabel";

const SliderFiles = ({ media, setMedia }) => {
  return (
    <div>
      <Col md={6}>
        <CustomLabel>Image / Video</CustomLabel>
        <SelectFile
          type="image"
          max={1}
          display
          selectedFiles={media}
          setSelectedFiles={setMedia}
        />
      </Col>
    </div>
  );
};

export default SliderFiles;
