import React from "react";
import PageContainer from "../../../Components/Layout/PageContainer";
import { Card, Col, Row } from "reactstrap";
import ListHeader from "../../../Components/Common/ListHeader";
import CustomTableHeading from "../../../Components/Custom/CustomTableHeading";
import StatusToggle from "../../../Components/Common/StatusToggle";
import DataTableContainer from "../../../Components/Common/DataTableContainer";
import useSelectItems from "../../../hooks/useSelectItems";
import useModal from "../../../hooks/useModal";
import { useDispatch, useSelector } from "react-redux";
import useFilterData, { FILTER_TYPES } from "../../../hooks/useFilterData";
import { useState } from "react";
import CustomInput from "../../../Components/Custom/CustomInput";
import { useEffect } from "react";
import {
  deletePrintersAction,
  getAllPrintersAction,
} from "../../../redux/actions/foodActions";
import useActions from "../../../hooks/useActions";
import toastAlert from "../../../helpers/toastAlert";
import CustomButton from "../../../Components/Custom/CustomButton";
import { TiTick } from 'react-icons/ti'
import { ImCross } from 'react-icons/im'
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { foodApi } from "../../../apis/Food/api";
import { toggleStatus } from "../../../apis/general";

const Printers = () => {

  const { getSelectedIds, isDisable, selectItems } = useSelectItems();

  const {
    isLoading: isDeleting,
    onLoading: onDeleting,
    onFinally: onFinallyDeleting,
  } = useActions();

  const dispatch = useDispatch();

  const [kitchen, setKitech] = useState(null);

  const [toggleKitchen, setTogglingKitech] = useState(false);

  const { t } = useTranslation();

  const { isModalOpen, setIsModalOpen, openModal, closeModal } = useModal();

  const { onLoading, onFinally, isLoading } = useActions();

  useQuery(['print-on-kitchen'], () =>  foodApi.printers.getPrintersSettings(), {
    cacheTime: 0,
    onSuccess({data: { data }}) {
      setKitech(!!data.print_to_kitchen);
    }
  })

  const togglePrintToKitchen = async () => {
    setTogglingKitech(true);
    try {
      const { data: { data }} = await toggleStatus({
        type: 'print_setting',
        print_to_kitchen: !kitchen
      });
      setKitech(!kitchen);
    } catch (error) {
      console.log('error', error);
    } finally {
      setTogglingKitech(false);
    }
  }

  const [query, setQuery] = useState("");

  const { printers } = useSelector((state) => state.food);

  const data = useFilterData(printers.list, {
    title: {
      key: "name",
      type: FILTER_TYPES.MATCH,
      value: query,
    },
  });

  const onDeleteSubmit = () => {
    const ids = getSelectedIds();
    dispatch(
      deletePrintersAction(ids, {
        onLoading: onDeleting,
        onSuccess: () => {
          onFinallyDeleting();
          toastAlert({ type: 'success' });
          selectItems([]);
          closeModal();
        },
        onFinally: onFinallyDeleting,
      })
    );
  };

  const columns = [
    {
      name: (
        <CustomInput
          placeholder="Name"
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          data-filtered={!!query}
        />
      ),
      selector: (row) => row.name,
    },
    {
      name: <span>IP Adress</span>,
      selector: (row) => row.ip,
    },
    {
      name: <span>Port</span>,
      selector: (row) => row.port,
    },
    {
      name: <CustomTableHeading>Is Stick</CustomTableHeading>,
      selector: (row) => <StatusToggle type='printers' id={row.id} field="is_stick" status={row.is_stick} />,
    },
    {
      name: <CustomTableHeading>Active</CustomTableHeading>,
      selector: (row) => <StatusToggle type='printers' id={row.id} field="active" status={row.active} />,
    },
  ];

  useEffect(() => {
    dispatch(
      getAllPrintersAction({
        onLoading,
        onFinally,
      })
    );
  }, []);

  return (
    <PageContainer isLoading={isLoading}>

      <ListHeader
        isDisable={isDisable}
        openModal={openModal}
        pathname="food/printers/create"
        afterChildren={
          kitchen !== null &&
          <CustomButton
            type="button"
            className={`d-flex align-items-center gap-2 btn-soft-${kitchen ? 'success' : 'danger'}`}
            onClick={togglePrintToKitchen}
            isLoading={toggleKitchen}
          >
            {kitchen ? <TiTick fontSize='1.5rem'/> : <ImCross fontSize=".7rem" />}
            {t('Print when order reaches kitchen')}
          </CustomButton>
        }
      />

      <Card className="mt-4">
        <DataTableContainer
          modal={isModalOpen}
          isDeleting={isDeleting}
          handleDelete={onDeleteSubmit}
          setModal={setIsModalOpen}
          columns={columns}
          data={data}
          selectableRows
          path="printers"
          selectItems={selectItems}
        />
      </Card>
    </PageContainer>
  );
};

export default Printers;
