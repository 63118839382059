import { useEffect, useState } from 'react'
import React from 'react'
import { Col, Row } from 'reactstrap'
import SelectFile from '../../../Common/SelectFile'
import CustomInput from '../../../Custom/CustomInput'
import CustomLabel from '../../../Custom/CustomLabel'
import { toBase64 } from '../../../../helpers/toBase64'

const Reepay = ({data, setData}) => {

    const [image, setImage] = useState({
        url: data?.logo,
        file: null
    })

    const handleChange = (e) => {
        const field = e.target.name;
        const value = e.target.value;
        setData(prev => prev.map(item => {
          if (item.id === data.id) return {
            ...item,
            [field]: value
          }
          return item
        }))
    }
    
    const handleCheckChange = (e) => {
        const field = e.target.name;
        const value = e.target.checked;
        setData(prev => prev.map(item => {
            if (item.id === data.id) return {
            ...item,
            [field]: value
            }
            return item
        }))
    }

    const handleFileChange = async () => {
        const url = await toBase64(image.file);
        setData(prev => prev.map(item => {
            if (item.id === data.id) return {
            ...item,
            logo: url
            }
            return item
        }))
    }   

    useEffect(() => {
        if (image.file) {
            handleFileChange();
        }
    }, [image])

  return (
    <React.Fragment>

        <div className="hstack gap-3">

            <div className="form-check form-switch">
                <input name="status" checked={data.status} onChange={handleCheckChange} className="form-check-input fs-16" type="checkbox" role="switch" id="active-reepay" />
                <CustomLabel className="form-check-label" htmlFor="active-reepay">Active</CustomLabel>
            </div>

            <div className="form-check form-switch">
                <input name="status_test_mode" checked={data.status_test_mode} onChange={handleCheckChange} className="form-check-input fs-16" type="checkbox" role="switch" id="active-reepay-test-mode" />
                <CustomLabel className="form-check-label" htmlFor="active-reepay-test-mode">Test Mode</CustomLabel>
            </div>
        
            <div className="form-check form-switch">
                <input name="guest" checked={data.guest} onChange={handleCheckChange} className="form-check-input fs-16" type="checkbox" role="switch" id="reepay-guest" />
                <CustomLabel className="form-check-label" htmlFor="reepay-guest">Guest</CustomLabel>
            </div>

            <div className="form-check form-switch">
                <input name="shop" checked={data.shop} onChange={handleCheckChange} className="form-check-input fs-16" type="checkbox" role="switch" id="reepay-shop" />
                <CustomLabel className="form-check-label" htmlFor="reepay-shop">Shop</CustomLabel>
            </div>

            <div className="form-check form-switch">
                <input name="pos" checked={data.pos} onChange={handleCheckChange} className="form-check-input fs-16" type="checkbox" role="switch" id="reepay-pos" />
                <CustomLabel className="form-check-label" htmlFor="reepay-pos">POS</CustomLabel>
            </div>

            <div className="form-check form-switch">
                <input name="cs" checked={data.cs} onChange={handleCheckChange} className="form-check-input fs-16" type="checkbox" role="switch" id="reepay-cs" />
                <CustomLabel className="form-check-label" htmlFor="reepay-cs">Call Center</CustomLabel>
            </div>

        </div>

        <Row className='mt-1 gy-3'>

            <Col lg={6}>
                <CustomLabel>Live Key</CustomLabel>
                <CustomInput 
                    type="text"
                    name="live_key"
                    value={data.live_key}
                    onChange={handleChange}
                />
            </Col>

            <Col lg={6}>
                <CustomLabel>Test Key</CustomLabel>
                <CustomInput 
                    type="text"
                    name="test_key"
                    value={data.live_key_test}
                    onChange={handleChange}
                />
            </Col>

        </Row>

    </React.Fragment>

  )
}

export default Reepay