import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { Card, Col, Row } from "reactstrap";
import extractError from "../../../helpers/extractError";
import { formatExample } from "../../../helpers/formatExample";
import formatFormData from "../../../helpers/formatFormData";
import {
  renderErrorAsString,
} from "../../../helpers/renderErrors";
import toastAlert from "../../../helpers/toastAlert";
import useActions from "../../../hooks/useActions";
import { getPathname } from "../../../Layouts/LayoutMenuData"; 
import SelectFile from "../../Common/SelectFile";
import CustomButton from "../../Custom/CustomButton";
import CustomInput from "../../Custom/CustomInput";
import CustomLabel from "../../Custom/CustomLabel";
import SelectLanguage from "../../Common/SelectLanguage";
import { createBranchApi, updateBranchApi } from "../../../apis/Settings/branchesApi";
import TextEditor from "../../TextEditor";
import BranchSettings from "./Settings";
import { objectFilter } from "../../../helpers/objectFilter";

const BranchForm = ({ type, initialValues = null, language, setLanguage }) => {

  const { register, handleSubmit, control, reset, watch } = useForm();

  const { isLoading, onLoading, onFinally } = useActions();

  const { push } = useHistory();

  const [media, setMedia] = useState({
    url: "",
    file: null,
  });

  const submitForm = async (data) => {
    let body = {...data};
    if (media.file) {
      body.file = media.file;
    }
    const formData = formatFormData(objectFilter(body));
    try {
       onLoading();
      if (type === "create") {
        await createBranchApi(formData, language);
        push(getPathname("settings/branches"));
      } else {
        const { data } = await updateBranchApi(initialValues.id, formData, language);
        if (data.data.file) {
          setMedia({ ...data.data.file, url: data.data.file.link });
        }
        onFinally();
        toastAlert({ type: 'success' });
      }
    } catch (error) {
      onFinally();
      toastAlert({ type: 'error', message: renderErrorAsString(extractError(error))});
    }
  };

  useEffect(() => {
    if (initialValues) {
      reset((() => {
          const { id, file, contact, settings, opening, hours, dates, ...rest } = initialValues;
          reset(rest);
          if (file) {
            setMedia({ ...file, url: file.link })
          }
      })())
    } 
  }, [initialValues])

  return (
    <>
      <form onSubmit={handleSubmit(submitForm)}>
        <Row>

            <Col lg={9}>
              <Card className="p-3">
                  {type === 'update' && 
                    <div className='d-flex justify-content-end'>
                        <SelectLanguage
                          language={language}
                          onChange={lang => setLanguage(lang)}
                        /> 
                    </div>
                  }
                  <Row className="gy-4 mt-1">
                    <Col md={6}>
                      <CustomLabel translatable>Name</CustomLabel>
                      <CustomInput 
                        type="text" 
                        {...register("name")} 
                        placeholder={formatExample("My Collection")}
                      />
                    </Col>

                    <Col md={6} className="d-flex align-items-end ">
                      <div className="form-check form-switch mb-2">
                        <input
                          {...register("active")}
                          className="form-check-input fs-16"
                          type="checkbox"
                          role="switch"
                          id="active"
                        />
                        <CustomLabel className="form-check-label" htmlFor="active">
                          Active
                        </CustomLabel>
                      </div>
                    </Col>

                    <Col md={12}>
                      <CustomLabel translatable>Description</CustomLabel>
                      <Controller 
                          control={control}
                          name="description"
                          render={({name, field: {onChange}}) => 
                            <TextEditor
                                name={name}
                                onChange={(value) => onChange(value)}
                                value={watch('description')}
                            />
                          }      
                      />
                    </Col>

                  </Row>

                  <Row className="mt-3">
                    <Col>
                      <CustomButton isLoading={isLoading} className="btn-success">
                        {type === "create" ? "Create" : "Update"}
                      </CustomButton>
                    </Col>
                  </Row>
              </Card>
            </Col>
            
            <Col lg={3}>
              <Card className="p-3">
                <CustomLabel>Image</CustomLabel>
                <SelectFile
                  type="image"
                  max={1}
                  display
                  selectedFiles={media}
                  setSelectedFiles={setMedia}
                />
              </Card>
            </Col>

        </Row>
      </form>

      {type === 'update' && <BranchSettings  initialValues={initialValues} />}
    </>

  );
};

export default BranchForm;
