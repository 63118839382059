import React, { useState } from "react";
import { Button, Col, Row } from "reactstrap";
import CustomInput from "../../Custom/CustomInput";
import CustomLabel from "../../Custom/CustomLabel";

const OpeningHourItem = ({ item, onOpenDeleteModal, setOpeningHours }) => {
  
  const handleChange = (e) => {
    setOpeningHours((current) => {
      return current.map((openingHour) => {
        if (openingHour.id === item.id) {
          return {
            ...openingHour,
            [e.target.name]: e.target.value,
          };
        }
        return openingHour;
      });
    });
  };

  return (
    <Row className="mt-2">
      
      <Col md={5}>
        <CustomLabel>Day</CustomLabel>
        <CustomInput value={item.day} name="day" onChange={handleChange} />
      </Col>

      <Col md={5}>
        <CustomLabel>Time</CustomLabel>
        <CustomInput value={item.time} name="time" onChange={handleChange} />
      </Col>

      <Col md={2} className="d-flex align-items-center mt-4">
        <Button color="danger" size="sm" onClick={onOpenDeleteModal}>
          <i className="ri-delete-bin-line"></i>
        </Button>
      </Col>

    </Row>
  );
};

export default OpeningHourItem;
