import React from 'react'
import BreadCrumb from '../../../Components/Common/BreadCrumb'
import PageContainer from '../../../Components/Layout/PageContainer'
import { useFetchByLang } from '../../../hooks/useFetchByLang'
import OfferForm from '../../../Components/Food/Offers/Form'

const CreateOffer = () => {

  const { language, setLanguage } = useFetchByLang();
    
  return (
    <PageContainer>
      <BreadCrumb pageTitle="Offers" title="Create" to="food/offers"/>
      <OfferForm 
        type="create"k
        language={language}
        setLanguage={setLanguage}
      />
    </PageContainer>
  )
}

export default CreateOffer