import React, { useEffect } from 'react'
import { useState } from 'react'
import PageContainer from '../../Components/Layout/PageContainer';
import { Card, CardHeader, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import classnames from "classnames";
import BreadCrumb from '../../Components/Common/BreadCrumb';
import { useForm } from 'react-hook-form';
import CustomButton from '../../Components/Custom/CustomButton';
import PusherConfig from '../../Components/Settings/Keys/PusherConfig';
import PushyWebview from '../../Components/Settings/Keys/PushyWebview';
import Email from '../../Components/Settings/Keys/Email';
import Env from '../../Components/Settings/Keys/Env';
import { useDispatch, useSelector } from 'react-redux';
import { getKeysSettingsActions, setKeysSettingsAction } from '../../redux/actions/settingsActions';
import { isEmpty } from '../../helpers/objectFilter';

const tabs = [
    {
        name: 'Pusher Dashboard',
        component: PusherConfig,
        prefix: 'pusher_dashboard'
    },
    {
        name: 'Pusher Web',
        component: PusherConfig,
        prefix: 'pusher_web'
    },
    {
        name: 'Pusher Map',
        component: PusherConfig,
        prefix: 'pusher_map'
    },
    {
        name: 'Pushy Webview',
        component: PushyWebview,
    },
    {
        name: 'Email',
        component: Email,
    },
    {
        name: 'ENV',
        component: Env,
    },
]

const Keys = () => {

    const [activeTab, setActiveTab] = useState(1);

    const {register, handleSubmit, reset} = useForm();

    const [isSubmitting, setIsSubmitting] = useState(false);

    const { keysSettings } = useSelector(state => state.settings);

    const dispatch = useDispatch();

    const submitForm = async (data) => {
        setIsSubmitting(true);
        dispatch(setKeysSettingsAction({
            data,
            onSuccess(data) {
                reset(data)
                setIsSubmitting(false);
            },
            onError(error) {
                console.log('error', error);
                setIsSubmitting(false);
            }
        }))
    }

    useEffect(() => {
        if (!isEmpty(keysSettings)) reset(keysSettings);
    }, [keysSettings])

    useEffect(() => {
        dispatch(getKeysSettingsActions());
    }, [dispatch])

  return (
    <PageContainer isLoading={isEmpty(keysSettings)}>
        <BreadCrumb pageTitle="Settings" title="Keys"/>
        <Card>
            <CardHeader>
                <Nav className="nav-tabs-custom card-header-tabs border-bottom-0">
                    {tabs?.map((tab, index) => {
                        return (
                            <NavItem key={index}>
                                <NavLink
                                    style={{ cursor: "pointer" }}
                                    className={`${classnames({active: activeTab === index + 1})} text-capitalize`}
                                    onClick={() => { setActiveTab(index + 1) }}
                                >
                                    {tab.name}
                                </NavLink>
                            </NavItem>
                        );
                    })}
                </Nav>
            </CardHeader>

            <form onSubmit={handleSubmit(submitForm)}>
                <TabContent activeTab={activeTab}>
                        {tabs.map((item, index) => (
                            <TabPane key={index} tabId={index + 1}>
                                <item.component 
                                    register={register}
                                    prefix={item.prefix}
                                />
                            </TabPane>
                        ))}
            
                </TabContent>
                <div className='p-3'>
                    <CustomButton
                        className="btn-success"
                        isLoading={isSubmitting}
                    >
                        Save
                    </CustomButton>
                </div>
            </form>

      </Card>
    </PageContainer>
  )
}

export default Keys