import React, { useEffect, useState } from "react";
import generateUniqueID from "../../../../helpers/generateUniqueID";
import CustomButton from "../../../Custom/CustomButton";
import OpeningHourItem from "../../WebSettings/OpeningHourItem";
import OrderCloseDays from '../../../Orders/OnlineSettings/OrderCloseDays'
import { Row } from 'reactstrap'

const OpeningHoursTab = ({ data, setValue }) => {

  const [hours, setHours] = useState([]);
  const [openingHours, setOpeningHours] = useState([]);

  const addNewOpeningHour = () => {
    setOpeningHours((current) => {
      return [
        ...current,
        {
          id: generateUniqueID(),
          day: "",
          time: "",
        },
      ];
    });
  };

  useEffect(() => {
    setValue('hours', hours.map(hour => ({
      id: hour.id,
      open: hour.open,
      times: hour.times.map((time) => {
        return {
          start: time.start?.slice(0, 5),
          end: time.end?.slice(0, 5),
        };
      }),
    })))
  }, [hours]), 

  useEffect(() => {
    setValue('opening', openingHours.map((hour, index) => ({
        day: hour.day,
        time: hour.time,
        order: index + 1
    })));
  }, [openingHours])

  useEffect(() => {
    if (data) {
      setOpeningHours(data.opening?.length ? data.opening.map(hour => ({
        id: generateUniqueID(),
        day: hour.day,
        time: hour.time
      })) : [{
        id: generateUniqueID(),
        day: "",
        time: "",
      }]);
      setHours(data.hours ?? [])
    }
  }, [data])

  return (
      <Row className="gy-3">

        {openingHours.map((openingHour) => {
          return (
            <OpeningHourItem
              key={openingHour.id}
              item={openingHour}
              onOpenDeleteModal={() => setOpeningHours(prev => prev.filter(hour => hour.id !== openingHour.id))}
              setOpeningHours={setOpeningHours}
            />
          );
        })}

        <div className="mt-4">
          <CustomButton onClick={addNewOpeningHour}>Add</CustomButton>
        </div>

        <OrderCloseDays 
          openingHours={hours}
          setOpeningHours={setHours}
          forBranch
        />

      </Row>
  );
};

export default OpeningHoursTab;
