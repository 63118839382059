import { useEffect, useState } from "react";

export default function useDebounceValue({ value, cb, time = 500 }) {
  
  const [values, setValues] = useState(value);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (cb) {
        cb();
      }
      setValues(value);
    }, time);

    return () => clearTimeout(timeout);
  }, [value, time]);

  return values;
}
