import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { Card, Col, Row } from "reactstrap";
import useActions from "../../../hooks/useActions";
import CustomButton from "../../Custom/CustomButton";
import CustomInput from "../../Custom/CustomInput";
import CustomLabel from "../../Custom/CustomLabel";
import { inventoryApi } from "../../../apis/Inventory/api";
import toastAlert from "../../../helpers/toastAlert";
import { objectFilter } from "../../../helpers/objectFilter";
import { getPathname } from "../../../Layouts/LayoutMenuData";
import SelectLanguage from "../../Common/SelectLanguage";

const ProductCategoryForm = ({ type, initialValues, language, setLanguage }) => {

  const { register, handleSubmit, reset } = useForm();

  const history = useHistory();

  const { isLoading, onLoading, onFinally } = useActions();

  const submitForm = async (data) => {
    onLoading();
    try {
        const requestBody = objectFilter(data);
        if (type === "create") {
            await inventoryApi.category.create(requestBody, language);
            toastAlert({ type: 'success' })
            history.push(getPathname('inventory/categories'))
        } else {
            await inventoryApi.category.update(initialValues?.id, requestBody, language);
            toastAlert({ type: 'success' })
        }
    } catch (error) {
        toastAlert({ type: 'error' })
    } finally {
        onFinally();
    }
  };

  useEffect(() => {
    if (initialValues) {

      reset(
        (() => {
          const { id, ...rest } = initialValues;
          return rest;
        })()
      );
    }
  }, [initialValues, language]);

  return (
    <Row>
        <Col lg={12}>
            <Card className="p-3">
                <Row className="gy-3">

                    <Col lg={12}>
                        {type === 'update' && 
                            <SelectLanguage
                                language={language}
                                onChange={lang => setLanguage(lang)}
                            /> 
                        }
                    </Col>

                    <Col md={6}>
                        <CustomLabel translatable>Name</CustomLabel>
                        <CustomInput
                            type="text"
                            {...register("name")}
                        />
                    </Col>
                </Row>
    
            </Card>

            <div className="mb-3 inner-submit-button">
            <CustomButton 
                className="btn-success" 
                isLoading={isLoading}
                onClick={handleSubmit(submitForm)}
            >
                {type === "create" ? "Create" : "Update"}
            </CustomButton>
            </div>
        </Col>
    </Row>
  );
};

export default ProductCategoryForm;
