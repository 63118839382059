import axiosInstance from "..";

export const getAllPagesMenuApi = (query) => {
  return axiosInstance.get('/pages', {
    params: {
      lang: localStorage.getItem("I18N_LANGUAGE") || 'en',
      ...query
    }
  });
};

export const getOnePageMenuApi = (id, lang) => {
  return axiosInstance.get(`/pages/${id}?lang=${lang}`);
};

export const addNewPageMenuApi = (body, lang) => {
  return axiosInstance.post(`/pages?lang=${lang}`, body);
};

export const updatePageMenuApi = (id, body, lang) => {
  return axiosInstance.post(`/pages/${id}?lang=${lang}`, body);
};

export const deletePagesMenuApi = (ids) => {
  return axiosInstance.delete("/pages", {
    data: {
      ids,
    },
  });
};
