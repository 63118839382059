import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { Card, Col, Row } from "reactstrap";
import formatFormData from "../../../helpers/formatFormData";
import toastAlert from "../../../helpers/toastAlert";
import useActions from "../../../hooks/useActions";
import { getPathname } from "../../../Layouts/LayoutMenuData";
import CustomButton from "../../Custom/CustomButton";
import CustomInput from "../../Custom/CustomInput";
import CustomLabel from "../../Custom/CustomLabel";
import SelectLanguage from "../../Common/SelectLanguage";
import { createBannerApi, updateBannerAPi } from "../../../apis/Settings/bannersApi";
import SelectFile from "../../Common/SelectFile";

const BannerForm = ({ type, initialValues = null, language, setLanguage }) => {

  const { register, handleSubmit, reset } = useForm();

  const { isLoading, onLoading, onFinally } = useActions();

  const { push } = useHistory();

  const [media, setMedia] = useState({
    url: "",
    file: null,
  });

  const submitForm = async (data) => {
    onLoading();
    let body = { ...data };
    if (media.file) {
      body.file = media.file;
    }
    const formData = formatFormData(body);
    try {
      if (type === "create") {
        await createBannerApi(formData, language);
        push(getPathname("settings/banners"));
      } else {
        const { data } = await updateBannerAPi(initialValues.id, formData, language);
        if (data?.data?.file) {
          setMedia({
            ...data.data.file,
            url: data.data.file.link,
          });
        }
        toastAlert({ type: 'success' });
      }
    } catch (error) {
      toastAlert({ type: 'error' });
    } finally {
      onFinally();
    }
  };

  useEffect(() => {
    if (initialValues) {
      reset((() => {
          const { id, file, ...rest } = initialValues;
          reset(rest);
          if (file) {
            setMedia({ ...file, url: file.link })
          }
      })())
    } 
  }, [initialValues])

  return (
    <form onSubmit={handleSubmit(submitForm)}>
      <Row>
        <Col lg={3}>
          <Card className="p-3">
            <CustomLabel>Image</CustomLabel>
            <SelectFile
                selectedFiles={media}
                setSelectedFiles={setMedia}
                type='image'
            />
          </Card>
        </Col>

        <Col lg={9}>
          <Card className="p-3">
            {type === 'update' && 
              <div className='d-flex justify-content-end'>
                  <SelectLanguage
                    language={language}
                    onChange={lang => setLanguage(lang)}
                  /> 
              </div>
            }
            <Row className="gy-4 mt-1">
              <Col md={6}>
                <CustomLabel translatable>Title</CustomLabel>
                <CustomInput 
                  type="text" {...register("title")} 
                />
              </Col>

              <Col md={6}>
                <CustomLabel translatable>Link</CustomLabel>
                <CustomInput 
                  type="text" 
                  {...register("link")} 
                />
              </Col>

              <Col md={6} className="d-flex align-items-end">
                <div className="form-check form-switch">
                  <input
                    {...register("active")}
                    className="form-check-input fs-16"
                    type="checkbox"
                    role="switch"
                    id="active"
                  />
                  <CustomLabel className="form-check-label" htmlFor="active">
                    Active
                  </CustomLabel>
                </div>
              </Col>

              <Col lg={12}>
                <CustomButton isLoading={isLoading} className="btn-success">
                  {type === "create" ? "Create" : "Update"}
                </CustomButton>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </form>
  );
};

export default BannerForm;
