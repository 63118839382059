import React from 'react'
import { useSelector } from 'react-redux'
import { isAllowed } from '../../../helpers/isAllowed'
import { getPathname } from '../../../Layouts/LayoutMenuData'
import DataTableContainer from '../../Common/DataTableContainer'
import CustomTableHeading from '../../Custom/CustomTableHeading'
import StatusToggle from '../../Common/StatusToggle'
import TableImage from '../../Common/TableImage'

const TableServicesTable = ({navigateToPage, pageSize, setPageSize, setSelectedItems, modal, setModal, isLoading, handleDelete, loading, data}) => {

    const { user } = useSelector(state => state.admin);

    const columns = [

        {
            name: <CustomTableHeading>ID</CustomTableHeading>,
            selector: row => row.id || '-',
            grow: -1,
        },

        {
            selector: row => <TableImage src={row.image} />,
        },
               
        {
            name: <CustomTableHeading>Title</CustomTableHeading>,
            selector: row => row.title || '-',
        },

        {
            name: <CustomTableHeading>Type</CustomTableHeading>,
            selector: row => row.type || '-',
        },

        {
            name: <CustomTableHeading>Active</CustomTableHeading>,
            selector: row => <StatusToggle type='table_services' id={row.id} field="active" status={row.active} />, 
        },
    
    ]


  return (
    <DataTableContainer 
        loading={loading}
        data={data || []}
        path={getPathname('orders/table-services')}
        columns={columns}
        paginationServer
        navigateToPage={navigateToPage}
        pageSize={pageSize}
        setPageSize={setPageSize}
        selectableRows={isAllowed(user)}
        selectItems={setSelectedItems}
        modal={modal}
        setModal={setModal}
        isDeleting={isLoading}
        handleDelete={handleDelete}
    />
  )
}

export default TableServicesTable