import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "language",
  initialState: {
    activeLang: null,
    languages: [],
  },
  reducers: {
    setActiveLang: (state, { payload }) => {
      state.activeLang = payload;
    },
    getLanguages: (state, { payload }) => {
      state.languages = payload;
    },
    addNewLang: (state, action) => {
      state.languages = [...state.languages, action.payload];
    },
    updateLang: (state, { payload }) => {
      state.languages = state.languages.map((lang) => {
        if (lang.code === payload.code) {
          return payload;
        }
        return lang;
      });
    },
    deleteLang: (state, { payload }) => {
      state.languages = state.languages.filter((lang) => lang.code !== payload);
    },
  },
});

export const languageActions = slice.actions;

const langaugeReducer = slice.reducer;

export default langaugeReducer;
