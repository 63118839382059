import React from 'react';
import { Col, Row } from 'reactstrap';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import CustomButton from '../Custom/CustomButton';
import { Link } from 'react-router-dom';
import { getPathname } from '../../Layouts/LayoutMenuData';

const Welcome = () => {

    const { user } = useSelector(state => state.admin);

    const { t } = useTranslation();

    return (
        <React.Fragment>
            <Row className="mb-3 pb-1">
                <Col xs={12}>
                    <div className="d-flex align-items-lg-center flex-lg-row flex-column">
                        <div className="flex-grow-1">
                            <h4 className="fs-16 mb-1">{t('Welcome')}, <span className='text-capitalize'>{user?.name}</span>!</h4>
                            <p className="text-muted mb-0">{t(`Here's what's happening with your store today`)}.</p>
                        </div>
                        <div className="mt-3 mt-lg-0">
                            <form action="#">
                                <Row className="g-3 mb-0 align-items-center">
                                    <div className="col-auto">
                                        <Link to={getPathname('food/menu/create')}>
                                            <CustomButton type="button" className="btn btn-soft-secondary"><i className="ri-add-circle-line align-middle me-1"></i>{t('Add Food')}</CustomButton>
                                        </Link>
                                    </div>
                                </Row>
                            </form>
                        </div>
                    </div>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default Welcome;