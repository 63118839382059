import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "reactstrap";
import PageContainer from "../../../Components/Layout/PageContainer";
import { useComplaints } from "../../../hooks/useQueryHelpers";
import ComplaintsTable from "../../../Components/Settings/Complaints/Table";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import { getPathname } from "../../../Layouts/LayoutMenuData";
import { objectFilter } from "../../../helpers/objectFilter";

const Complaints = () => {

  const [complaintType, setComplaintType] = useState('');
  
  const [complaintStatus, setComplaintStatus] = useState('');

  const { isLoading, isRefetching, data, refetch } = useComplaints(objectFilter({
    type: complaintType,
    status: complaintStatus
  }));

  useEffect(() => {
    refetch();
  }, [complaintType, complaintStatus])

  const complaints = data?.data?.data ?? [];

  return (
    <PageContainer isLoading={isLoading || isRefetching}>
      <BreadCrumb pageTitle="Settings" title="Complaints" to={getPathname('settings/complaints')} />
      <Row className="gy-4">
        <Col xs={12}>
          <Card>
            <ComplaintsTable
              data={complaints}
              isLoading={isLoading}
              complaintType={complaintType}
              setComplaintType={setComplaintType}
              complaintStatus={complaintStatus}
              setComplaintStatus={setComplaintStatus}
            />
          </Card>
        </Col>
      </Row>
    </PageContainer>
  );
};

export default Complaints;
