import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Card, Col, Row } from 'reactstrap'
import { formatExample } from '../../../helpers/formatExample'
import useActions from '../../../hooks/useActions'
import { getPathname } from '../../../Layouts/LayoutMenuData'
import { createFoodAction, getFoodDataAction, updateFoodAction } from '../../../redux/actions/foodActions'
import CustomButton from '../../Custom/CustomButton'
import CustomInput from '../../Custom/CustomInput'
import CustomLabel from '../../Custom/CustomLabel'
import SelectLanguage from '../../Common/SelectLanguage'

const SpiceLevelForm = ({type, initialValues = null, language, setLanguage}) => {

    const {register, handleSubmit, reset} = useForm();
    
    const [icon, setIcon] = useState({
        url: '',
        file: null
    })

    const dispatch = useDispatch();

    const history = useHistory();

    const { isLoading, onLoading, onFinally, onError } = useActions();

    const submitForm = (data) => {
        onLoading();
        if (type === 'create') {
            dispatch(createFoodAction({
                field: 'spiceLevels',
                data,
                language
            }, {
            onSuccess() {
                onFinally();
                history.push(getPathname('food/spice-levels'))
            },
            onError
            }))
        } else {
            dispatch(updateFoodAction({
                field: 'spiceLevels',
                id: initialValues.id,
                data,
                language
            }, {
                onSuccess() {
                    onFinally();
                    setIcon({
                        url: icon.url,
                        file: null
                    })
                },
                onError
            }))
        }
    
    }
    

    useEffect(() => {
        dispatch(getFoodDataAction({
            field: 'foodTypes'
        }))
    }, [])

    useEffect(() => {
        if (initialValues) {
            reset(initialValues);

            if (initialValues?.icon) {
                setIcon({
                    id: initialValues.file?.id,
                    name: initialValues.file?.name,
                    url: initialValues.icon,
                    file: null
                })
            }
        }
    }, [initialValues])

  return (

    <Card className='p-3'>
        
        {type === 'update' && 
            <div className='d-flex justify-content-end'>
                <SelectLanguage
                    language={language}
                    onChange={lang => setLanguage(lang)}
                /> 
            </div>
        }

        <form onSubmit={handleSubmit(submitForm)}>
            <Row className='gy-4 mt-1'>

                <Col md={6}>
                    <CustomLabel translatable>
                        Name
                    </CustomLabel>
                    <CustomInput 
                        type="text"
                        {...register('name')}
                        placeholder={formatExample("Flaming Hot")}
                    />
                </Col>

                <Col lg={1} md={2}>
                    <CustomLabel>
                        Level
                    </CustomLabel>
                    <select
                        className='form-select'
                        {...register('level')}
                    >
                        {[0, 1, 2, 3, 4, 5].map((item, index) => (
                            <option key={index} value={item}>{item}</option>
                        ))}
                    </select>
                </Col>
            </Row>
            <Row className='mt-3'>
                <Col>
                    <CustomButton
                        className="btn-success"
                        isLoading={isLoading}
                    >
                        {type === 'create' ? 'Create' : 'Update'}
                    </CustomButton>
                </Col>
            </Row>
        </form>
    </Card>
  )
}

export default SpiceLevelForm