import React from 'react'
import CustomButton from '../../Custom/CustomButton'
import { useTranslation } from 'react-i18next'

const DeleteButton = ({className, children, ...props}) => {

  const { t } = useTranslation();

  return (
    <CustomButton
        className={`btn-danger d-flex gap-1 align-items-center ${className}`}
        {...props}
    >
        <i className="ri-delete-bin-4-line fs-16"></i>
        {t(children || 'Delete')}
    </CustomButton>
  )
}

export default DeleteButton