import React, { useEffect, useState } from 'react'
import PageContainer from '../../Components/Layout/PageContainer'
import { Card, CardBody, CardHeader, Nav, NavItem, TabContent, TabPane } from 'reactstrap'
import { DragDropContext } from "react-beautiful-dnd";
import CustomNavLink from '../../Components/Custom/CustomNavLink';
import classNames from 'classnames';
import MenuPage from '../../Components/Settings/Menu/MenuPage';
import { reorderList } from '../../helpers/dragAndDrop';
import useFormModal from '../../hooks/useFormModal';
import PageModal from '../../Components/Settings/Menu/PageModal';
import SliderPage from '../../Components/Settings/Menu/SliderPage';
import VideoPage from '../../Components/Settings/Menu/VideoPage';
import { useQueries } from '@tanstack/react-query';
import { getCategoriesWithFoods, getMenuPages } from '../../apis/Settings/menuApi';
import { BiFoodMenu, BiSlideshow } from 'react-icons/bi'
import { AiOutlineVideoCamera } from 'react-icons/ai'
import LoaderComponent from '../../Components/Common/LoaderComponent';
import { extractIds } from '../../helpers/extractIds';
import { sortFromIndex } from '../../helpers/sortFromIndex';

const pageIcons = {
  menu: BiFoodMenu,
  video: AiOutlineVideoCamera,
  slider: BiSlideshow
}

const pageComponents = {
  menu: MenuPage,
  slider: SliderPage,
  video: VideoPage
}

const Menu = () => {

  const [sortedList, setSortedList] = useState(null);

  const [activePage, setActivePage] = useState(null);

  const [pages, setPages] = useState({
    menu: [],
    video: [],
    slider: []
  });

  const queryData = useQueries({
    queries: [
      {
        queryKey: ['cateogires-with-items'],
        queryFn: () => getCategoriesWithFoods({ page_size: 'all' }),
        onSuccess({data: { data }}) {
          setSortedList(data.map(category => ({
            id: category.id,
            name: category.name,
            subtitle: '',
            description: '',
            layout_type: 'flow',
            image: {
              id: category.menu_image_file?.id ?? null,
              url: category.menu_image_file?.link ?? '',
            },
            show_allergy: category.show_allergy,
            show_comp: category.show_comp,
            breads: category.breads?.map(bread => ({
              id: bread.id,
              name: bread.name,
              sizes: category.sizes.map(size => ({
                id: size.id,
                name: size.name,
                active: false
              })) ?? []
            })) ?? [],
            type: 'category',
            children: category.foods ? category.foods.map(item => ({
              id: item.id,
              name: item.name,
              subtitle: '',
              description: '',
              show: true,
              type: `category-item-${category.id}`
            })) : []
          })))
        }
      },
      {
        queryKey: ['menu-pages'],
        enabled: false,
        queryFn: () => getMenuPages({ page_size: 'all'}),
        onSuccess({ data: { data } }) {
            setPages(Object.fromEntries(Object.keys(pages).map(key => {
              const dataPages = data.filter(page => page.type.toLowerCase() === key).map(page => ({
                id: page.id,
                name: page.name,
                type: page.type,
                link: page.link,
                images: page.images.map(image => ({
                  id: image.id,
                  url: image.link,
                })),
                background: page.image_file,
                note: page.note ?? '',
                show_allergy: !!page.show_allergy,
                price_type: page.price_type ?? 'price',
                sliders: page.sliders.map(slide => ({
                  id: slide.food.id,
                  name: slide.food.name,
                  subtitle: slide.subtitle,
                  description: slide.description,
                  image: {
                    id: '',
                    url: '',
                },
                  active: true
                })),
                items: page.categories?.map(category => ({
                  type: 'category',
                  id: category.category_id,
                  name: category.category_name,
                  subtitle: category.subtitle,
                  description: category.description,
                  layout_type: category.layout_type ?? 'flow',
                  show_allergy: category.show_allergy,
                  show_comp: category.show_comp,
                  image: category.image ?? {
                    id: category.menu_image_file?.id ?? null,
                    url: category.menu_image_file?.link ?? '',
                  },
                  breads: [],
                  children: [],
                  link: category.link,
                  breads: (() => {
                    const cat = sortedList.find(cat => cat.id === category.category_id);
                    return cat.breads?.sort((breadA, breadB) => {
                      const breadAIndex = category.breads.findIndex(bread => bread.bread_id === breadA.id);
                      const breadBIndex = category.breads.findIndex(bread => bread.bread_id === breadB.id);
                      return sortFromIndex(breadAIndex, breadBIndex);
                    }).map(bread => ({
                      id: bread.id,
                      name: bread.name,
                      sizes: bread.sizes.sort((sizeA, sizeB) => {
                        const sizes = category.breads.find(brd => brd.bread_id === bread.id)?.sizes;
                        if (!sizes) return 0
                        const sizeAIndex = sizes.findIndex(size => size.size_id === sizeA.id);
                        const sizeBIndex = sizes.findIndex(size => size.size_id === sizeB.id);
                        return sortFromIndex(sizeAIndex, sizeBIndex);
                      }).map(size => ({
                        id: size.id,
                        name: size.name,
                        active: category.breads?.find(brd => brd.bread_id === bread.id)?.sizes?.find(sz => sz.size_id === size.id) ?? false
                      })) ?? []
                    })) ?? []
                  })(),
                  children: category.foods?.map(food => ({
                    id: food.food.id,
                    name: food.food.name,
                    subtitle: food.subtitle,
                    description: food.description,
                    price: food.price,
                    show: !!food.show,
                    type: `category-item-${category.id}`
                  })) ?? [],
                }))
              }));
              return [key, dataPages]
            })))
        }
      },

    ],
  });

  const { isLoading, isRefetching, refetch: refetchPages } = queryData[1];

  const { type, initialValues, openModal, isOpen, toggleModal } = useFormModal();

  const onDragEnd = (data) => {

    if (!data.destination) return;

    const currentParent = data.source.droppableId;
    const destinationParent = data.destination.droppableId;

    // Sort sizes insead Breads
    if (/^page-(\d+)-category-(\d+)-bread-(\d+)-sizes$/.test(currentParent)) {

      const { page: pageId, category: categoryId, bread: breadId, size: itemId } = extractIds(data.draggableId);

      const sizes = pages.menu.find(page => page.id === pageId)?.items?.find(category => category.id === categoryId)?.breads.find(bread => bread.id === breadId)?.sizes;
      const newList = reorderList(sizes, itemId, data.destination.index);
      
      setPages({
        ...pages,
        menu: pages.menu.map(page => {
          if (page.items.some(category => category.id === categoryId)) return {
            ...page,
            items: page.items.map(category => {
              if (category.id === categoryId) return {
                ...category,
                breads: category.breads.map(bread => {
                  if (bread.id === breadId) return {
                    ...bread,
                    sizes: newList
                  }
                  return bread
                })
              }
              return category
            })
          }
          return page
        })
      })

    // Sort Breads inside category

    } else if (/^page-(\d+)-category-(\d+)-breads$/.test(currentParent)) {

      const { page: pageId, category: categoryId, bread: itemId } = extractIds(data.draggableId);

      const breads = pages.menu.find(page => page.id === pageId)?.items?.find(category => category.id === categoryId)?.breads;
      const newList = reorderList(breads, itemId, data.destination.index);

      setPages({
        ...pages,
        menu: pages.menu.map(page => {
          if (page.items.some(category => category.id === categoryId)) return {
            ...page,
            items: page.items.map(category => {
              if (category.id === categoryId) return {
                ...category,
                breads: newList
              }
              return category
            })
          }
          return page
        })
      })
    
      // Sort Items inside Cateogry
    } else if (currentParent.includes('category-'))  {
      const categoryId = +currentParent.replace('category-items-', '');
      const itemId = +data.draggableId.replace('category-item-', '');

      const parentPage = pages.menu.find(page => page.items.some(category => category.id === categoryId));

      const list = parentPage.items.find(category => category.id === categoryId)?.children;
      const newList = reorderList(list, itemId, data.destination.index);
      setPages({
        ...pages,
        menu: pages.menu.map(page => {
          if (page.id === parentPage.id) return {
            ...page,
            items: page.items.map(category => {
              if (category.id === categoryId) return {
                ...category,
                children: newList
              }
              return category
            })
          }
          return page
        })
      })
    // Sort Items inside Page
    } else if (currentParent.includes('page-')) {

      // Sort Items inside slider page

      if (data.draggableId.includes('slider-')) {
        const pageId = +destinationParent.replace('page-', '');
        const listItems = pages.slider.find(page => page.id === pageId)?.sliders;
        const itemId = +data.draggableId.replace('slider-item-', '');
        const newList = reorderList(listItems, itemId, data.destination.index);
        setPages({
          ...pages,
          slider: pages.slider.map(page => {
            if (page.id === pageId) return {
              ...page,
              sliders: newList
            }
            return page
          })
        })

      // Sort Categories inside menu page

      } else {
        const pageId = +destinationParent.replace('page-', '');
        const listItems = pages.menu.find(page => page.id === pageId)?.items;
        const categoryId = +data.draggableId.replace('category-', '');
        const newList = reorderList(listItems, categoryId, data.destination.index);
        setPages({
          ...pages,
          menu: pages.menu.map(page => {
            if (page.id === pageId) return {
              ...page,
              items: newList
            }
            return page
          })
        })
      }

    // Sort Breads inside Category 

    }
  }

  const focusFirstPage = ({ ignoreCurrentActive } = { }) => {
    if (Object.values(pages).some(val => val.length) && (ignoreCurrentActive ? true : !activePage)) {
      const firstPage = Object.values(pages).find(item => item.length > 0)[0];
      setActivePage(firstPage.id);
    }
  }

  useEffect(() => {
    if (sortedList) refetchPages();
  }, [sortedList])

  useEffect(() => {
    focusFirstPage();
  }, [pages])

  return (
    <PageContainer>
        <Card>
            <CardHeader>
                Menu
            </CardHeader>
            
            <CardBody>

            <PageModal 
              type={type}
              initialValues={initialValues}
              isOpen={isOpen}
              setActivePage={setActivePage}
              toggleModal={toggleModal}
              setPages={setPages}
            />

            <LoaderComponent isLoading={!sortedList || isLoading || isRefetching}>
                <DragDropContext onDragEnd={onDragEnd}>
                    <Nav tabs className="nav-tabs my-3 nav-primary flex-1">
                          {Object.keys(pages).map(key => {

                            const tabs = pages[key];

                            return tabs.map(page => (
                              <NavItem key={page.id}>
                                <CustomNavLink
                                  style={{ cursor: "pointer" }}
                                  className={classNames({ active: activePage === page.id }) + ' d-flex align-items-center gap-2'}
                                  onClick={() => setActivePage(page.id)}
                                >
                                    <div className='d-flex gap-2 align-items-center'>
                                      {(() => {
                                        const IconComponent = pageIcons[key.toLocaleLowerCase()]
                                        return <IconComponent fontSize="1.2rem" />
                                      })()}
                                      {page.name}
                                    </div>
                                </CustomNavLink>
                              </NavItem>
                            ))

                          })}

                          <NavItem>
                            <CustomNavLink
                              style={{ cursor: "pointer" }}
                              onClick={toggleModal}
                            >
                                <i className="ri-add-line fs-20"></i>
                            </CustomNavLink>
                          </NavItem>

                    </Nav>
                    
                    <TabContent activeTab={activePage}>
                          {Object.keys(pages).map(key => {
                            const typePages = pages[key];
                            const Component = pageComponents[key.toLowerCase()];

                            if (!Component) return <></>
                            
                            return typePages.map(page => (
                              <TabPane key={page.id} tabId={page.id}>
                                  <Component 
                                    page={page}
                                    setPages={setPages}
                                    categories={sortedList}
                                    openUpdateModal={openModal}
                                    focusFirstPage={focusFirstPage}
                                  />
                              </TabPane>
                            ))
                          })}
                    </TabContent>

                </DragDropContext>
              </LoaderComponent>
          </CardBody>
        </Card>
    </PageContainer>
  )
}

export default Menu